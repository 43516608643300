import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { RenderIf } from '../../utils/RenderIf';
import SuccessAlert from '../../Alerts/SuccessAlert';
import ErrorAlert from '../../Alerts/ErrorAlert';
import { Badge } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Image } from 'react-bootstrap';
import { CategoriesDashFetchAPI } from '../../../api/CategoriesDash';
import { TaxesFetchAPI } from '../../../api/Taxes';
import { ListGroup } from 'react-bootstrap';
import { GroupsFechtAPI } from '../../../api/Groups';
import Select from 'react-select';
import { checkUserModules } from '../../utils/CheckUserModules';
import { checkUserPermission } from '../../utils/CheckUserPermitions';
//Icons
import eye from '../../img/icons/eye.png'
import on from '../../img/icons/on.png'
import off from '../../img/icons/off.png'
import trash from '../../img/icons/delete.png'

function ModalViewGroup({ product, branchoffice_id, loadProducts }) {
    const [showModal, setShowModal] = useState(false)
    const [eventHandle, setEventHandle] = useState(0);
    const [infoGroup, setInfoGroup] = useState([])
    const [categories, setCategories] = useState([])
    const [imgpreview, setImgpreview] = useState(product.img)
    const [categoriesSelect, setCategoriesSelect] = useState([])
    const [categorySelectNow, setCategorySelectNow] = useState('')
    const [showModalAsing, setShowModalAsing] = useState(false);
    const [showModalAddUpc, setShowModalAddUpc] = useState(false);
    const [showModalTaxes, setShowModalTaxes] = useState(false);
    const [newUpc_code, setNewUpc_code] = useState('')
    const [taxes_list, setTaxes_list] = useState([])
    const [taxSelect, setTaxSelect] = useState("");
    const [taxes, setTaxes] = useState([])
    const [upc_List, setUpc_List] = useState([])
    const [edit, setEdit] = useState(false)
    const [groupType, setGroupType] = useState('infinite')
    let token = localStorage.getItem("x-access-token")
    let user = JSON.parse(localStorage.getItem('user'))

    //Params data group or product
    const [name, setName] = useState(product.name);
    const [description, setDescription] = useState(product.description);
    const [price, setPrice] = useState(product.price);
   

    //==============================   EDIT   ==============================
    //Function to register the product 
    const submit = async (e) => {

        e.preventDefault();
        //We get the data from the form
        const form = new FormData(e.target);
        //We check that the integration module is active
        console.log(user);
        if (user.business.posIntegration !== undefined && user.business.posIntegration !== null) {
            form.append("pos_integration_id", user.business.posIntegration.id)
        }
        //We check if the product is from a branch office
        let editGroup = Object.fromEntries(form.entries());
        try {
            //We update the data 
            await GroupsFechtAPI.updateGroup(product.group_id, editGroup, token);
            //We check if the product is from a branch office
            if (branchoffice_id !== 0) {
                //We add the product id on the edit object
                editGroup.group_id = product.group_id;
                //We update the product in the branch office
                await GroupsFechtAPI.updateGroupBranchoffice(branchoffice_id, editGroup, token);
            }
            //We show the success alert 
            ErrorAlert('', 'Producto editada con exito', 'success')
            loadProducts();
            //setShowModal(false);
            setEdit(false)
            setImgpreview(product.img)
        } catch (err) {
            setImgpreview(product.img)
            ErrorAlert(err.response.data.error, 'Error en la edicion', 'error')
            console.log(err)
        }
    }

    const changeimg = (e) => {
        setImgpreview(URL.createObjectURL(e.target.files[0]))
    }
    //==============================   API REQUESTS   ==============================


    //get group by id
    const getGroupById = async () => {
        try {
            const repsonse = await GroupsFechtAPI.getGroupById(product.group_id, token)
            //setInfoGroup(repsonse.data.data)
            setCategories(repsonse.data.data.categories)
            setUpc_List(repsonse.data.data.upc_codes)
            setTaxes_list(repsonse.data.data.taxes)
            console.log(repsonse.data.data)
        } catch (err) {
            console.log(err)
        }
    }

    const deleteTaxByGroupId = async (tax_id) => {
        let data = {
            tax_id: tax_id,
            group_id: product.group_id
        }
        try {
            const response = await TaxesFetchAPI.deleteTaxByGroupId(data, token);
            console.log(response);
            getGroupById();

        } catch (err) {
            console.log(err);
            ErrorAlert(err.response.data.error, 'Error, no se pudo remover el impuestro', 'error')
        }

    }

    //delete category relaiton by group
    const deleteCategoryToProduct = async (category_id) => {
        let data = {
            group_id: product.group_id
        }
        try {
            const response = await GroupsFechtAPI.deleteCategoryProduct(category_id, data, token)
            getGroupById()
        } catch (err) {
            console.log(err);
            ErrorAlert(err.response.data.error, 'Error, no se pudo remover la categoria', 'error')
        }
    }

    //get Categories
    const getCategoriesByBusiness = async () => {
        user = JSON.parse(localStorage.getItem('user'))
        try {
            const response = await CategoriesDashFetchAPI.getCategoriesByBusiness(user.business_id, token)

            console.log(response.data.data);
            let categorieInfo = []
            response.data.data.forEach(category => {
                categorieInfo.push({ value: category.category_id, label: category.category_name })
            });

            setCategoriesSelect(categorieInfo)
        } catch (err) {
            console.log(err)
        }
    }

    const asingTaxToGroup = async () => {
        let data = {
            tax_id: taxSelect,
            group_id: product.group_id
        }
        try {
            const response = await TaxesFetchAPI.asingTaxToGroup(data, token)
            console.log(response);
            getGroupById()
            setShowModalTaxes(false)
            ErrorAlert('', 'Impuesto asignado correctamente', 'success')
        } catch (err) {
            console.log(err);
            ErrorAlert(err.response.data.error, 'Error de asignacion', 'error')
        }
    }

    //add category to product
    const asingProductToCategory = async () => {
        try {
            const response = await GroupsFechtAPI.asingproductToCategory(categorySelectNow, product.group_id, token)
            getGroupById()
            setShowModalAsing(false)
            ErrorAlert('', 'Categoria asignado correctamente', 'success')

        } catch (err) {
            console.log(err)
            ErrorAlert(err.response.data.error, 'Error de asignacion', 'error')
        }
    }

    const deleteUpcToProduct = async (upc_code_id) => {

        if (upc_List.length == 1) {
            ErrorAlert('', 'El producto debe tener minimo 1 codigo upc', 'error')
        } else {
            let data = {
                "group_id": product.group_id,
                "upc_code_id": upc_code_id
            }
            try {
                const response = await GroupsFechtAPI.deleteUpctoProduct(data, token)
                getGroupById()

            } catch (err) {
                console.log(err)
                ErrorAlert(err.response.data.error, 'Error no se pudo remover', 'error')
            }
        }
    }



    const addUpcCodeToProduct = async () => {
        user = JSON.parse(localStorage.getItem('user'))

        if (newUpc_code.length < 0) {
            ErrorAlert('', 'El upc debe contener minimo 6 caracteres', 'error')
        } else {
            let data = {
                "group_id": product.group_id,
                "upc_code": newUpc_code,
                "business_id": user.business_id
            }

            try {
                const response = await GroupsFechtAPI.addUpcToGroup(data, token)
                getGroupById()
                setShowModalAddUpc(false)
                ErrorAlert('', 'Upc asignado correctamente', 'success')
            } catch (err) {
                console.log(err);
                ErrorAlert(err.response.data.error, 'Error no se pudo asignar el upc', 'error')
            }
        }
    }

    const handleSaveTableSelect = (option) => {
        setCategorySelectNow(option.value);
    }

    const getTaxes = async () => {
        try {
            const response = await TaxesFetchAPI.getTaxes(token);
            console.log(response);
            setTaxes(response.data.data)

        } catch (err) {
            console.log(err);
        }
    }


    //Function to listen the swicht 
    const handleSwitchChange = async (event) => {
        //We set the data to update 
        let data = {
            status: event.target.checked ? 1 : 0,
            group_id: event.target.id,
            user_id: user.id
        }
        try {
            //We update the data 
            let response = await GroupsFechtAPI.updateGroupBranchoffice(branchoffice_id, data, token);
            //We show the success alert 
            SuccessAlert(response.data.message);
            // Recarga los productos al cambiar el estado del switch
            loadProducts();
        } catch (err) {
            //We show the success alert 
            ErrorAlert(err.response.data.error);
            //We put the swicht checked false 
            event.target.checked = false
            // Recarga los productos al cambiar el estado del switch
            loadProducts();
        }
    };
    return (
        <>

            <button type="button" className='btn btn-outline-light pulse rounded-3 p-2 lh-1 border'
                onClick={() => (setShowModal(true), getGroupById(), getCategoriesByBusiness())}
            >
                <img className='icon-options-cards' src={eye} alt="Edit" />

            </button>

            <Modal
                show={showModal}
                onHide={() => (setShowModal(false))}
                fullscreen
                centered>
                <Modal.Header className='text-center'>
                    <h3>
                        <i className="uil uil-eye"></i>
                        Detalle del producto  </h3>
                </Modal.Header>
                <Modal.Body>

                    <div className='d-flex align-item-center justify-content-center'>
                        <img
                            className='imgEditModal'
                            src={imgpreview}
                            alt={product.name}
                        />
                    </div>

                    <RenderIf isTrue={edit == true}><div className='d-flex align-item-center justify-content-center m-3'>
                        <label htmlFor="upload-button">
                            <h5 className="text-center">Actualizar imagen</h5>
                        </label>
                    </div></RenderIf>

                    <Container>
                        <Row>
                            <Col>
                                <div className='mt-3'>


                                    <ListGroup as="ol" >

                                        <Form
                                            className="needs-validation"
                                            onSubmit={submit}
                                        >

                                            <Form.Group className="mb-3" controlId="name"></Form.Group>
                                            <ListGroup.Item
                                                as="li"
                                                className="d-flex justify-content-center align-items-start"
                                            ><button type="button" className='btn btn-outline-primary pulse rounded-3 p-2 lh-1 border'
                                                onClick={() => (edit == false ? setEdit(true) : setEdit(false))}
                                            ><i class="uil uil-edit"> Editar producto</i>
                                                </button></ListGroup.Item>

                                            <ListGroup.Item
                                                as="li"
                                                className="d-flex justify-content-between align-items-start"
                                            >

                                                {edit == false ? <><div className="ms-2 me-auto">
                                                    <div className="fw-bold">Nombre</div>
                                                    {product.name}
                                                </div></> : <div className="ms-2 me-auto">   <Form.Group className="mb-3" controlId="name">
                                                    <Form.Label>Nombre</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={name}
                                                        placeholder={product.name}
                                                        onChange={(e) => setName(e.target.value)}
                                                        name="name"
                                                    />
                                                </Form.Group></div>}


                                                <div className="ms-2 me-auto">
                                                    <div className="fw-bold">Estado</div>
                                                    {/* {product.status == 1 ? <><Badge bg="success">Habilitado</Badge></> : <><Badge bg="danger">Deshabilitado</Badge></>}*/}

                                                    <div>
                                                        <RenderIf isTrue={checkUserPermission('enable_disable_product', user.permissions) !== -1 && branchoffice_id !== 0}>
                                                            <input type="checkbox" className="btn-check" autocomplete="off"
                                                                id={product.group_id} label="" checked={product.status === 1 ? true : false} onChange={handleSwitchChange}
                                                            />
                                                            {product.status === 1
                                                                ? <> <label className="icon-on pulse rounded-3 p-2 lh-1 border-on" for={product.group_id}>
                                                                    <img className='icon-options-cards' src={on} alt="restart" />
                                                                    <small className='ms-1 text-on fw-semibold'>Activo</small>
                                                                </label>
                                                                </>
                                                                : <>
                                                                    <label className="btn bg-light pulse rounded-3 p-2 lh-1 border opacity-75" for={product.group_id} >
                                                                        <img className='icon-options-cards opacity-25' src={off} alt="restart" />
                                                                        <small className='ms-1 text-secondary'>Inactivo</small>
                                                                    </label>
                                                                </>}
                                                        </RenderIf>
                                                    </div>
                                                </div>



                                            </ListGroup.Item>
                                            <ListGroup.Item
                                                as="li"
                                                className="d-flex justify-content-between align-items-start"
                                            >

                                                {edit == false ? <><div className="ms-2 me-auto">
                                                    <div className="fw-bold">Descripcion</div>
                                                    {product.description}
                                                </div></> : <> <Form.Group className="mb-3 w-100" controlId="secundaryName">
                                                    <Form.Label>Descripcion</Form.Label>
                                                    <Form.Control
                                                        as="textarea" rows={3}
                                                        value={description}
                                                        placeholder={product.description}
                                                        onChange={(e) => setDescription(e.target.value)}
                                                        name="description"
                                                    />
                                                </Form.Group></>}


                                            </ListGroup.Item>
                                            <ListGroup.Item
                                                as="li"
                                                className="d-flex justify-content-between align-items-start"
                                            >
                                                {edit == false ? <> <div className="ms-2 me-auto">
                                                    <div className="fw-bold">Precio</div>
                                                    ${product.price}
                                                </div></> : <><Form.Group className="mb-3" controlId="secundaryName">
                                                    <Form.Label>Precio</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={price}
                                                        placeholder={product.price}
                                                        onChange={(e) => setPrice(e.target.value)}
                                                        name="price"
                                                    />
                                                </Form.Group></>}
                                            </ListGroup.Item>


                                            <ListGroup.Item
                                                as="li"
                                                className="d-flex justify-content-between align-items-start"
                                            >


                                                {edit == false ? <><div className="ms-2 me-auto">
                                                    <div className="fw-bold">Preparacion</div>
                                                    {product.preparation == 0 ? <>No</> : <>Si</>}
                                                </div></> : <div className="ms-2 me-auto">
                                                    <Form.Group className="mb-3" >
                                                        <Form.Label>Preparacion</Form.Label>
                                                        <Form.Select aria-label="" name="preparation">
                                                            <RenderIf isTrue={product.branchoffice_preparation == 0}>
                                                                <option value={0}>No</option>
                                                                <option value={1}>Si</option>
                                                            </RenderIf>
                                                            <RenderIf isTrue={product.branchoffice_preparation == 1}>
                                                                <option value={1}>Si</option>
                                                                <option value={0}>No</option>
                                                            </RenderIf>
                                                        </Form.Select>
                                                    </Form.Group></div>}

                                                {/**  */}


                                                <RenderIf isTrue={branchoffice_id !== 0}>

                                                    {edit == false ? <><div className="ms-2 me-auto">
                                                        <div className="fw-bold">Inventario</div>
                                                        {product.group_type === "limited" ? product.quantity : "No"}
                                                    </div></> : <div className="ms-2 me-auto">
                                                        <Form.Group className="mb-3" >
                                                            <Form.Label>Inventario</Form.Label>
                                                            <Form.Select aria-label="" name="group_type" onChange={e => setGroupType(e.target.value)}>
                                                                <RenderIf isTrue={product.group_type == "infinite"}>
                                                                    <option value={"infinite"}>No</option>
                                                                    <option value={"limited"}>Si</option>
                                                                </RenderIf>
                                                                <RenderIf isTrue={product.group_type == "limited"}>
                                                                    <option value={"limited"}>Si</option>
                                                                    <option value={"infinite"}>No</option>
                                                                </RenderIf>
                                                            </Form.Select>
                                                        </Form.Group></div>}

                                                </RenderIf>

                                                <Form.Group controlId="formFileMultiple" className="mb-3">
                                                    <Form.Label></Form.Label>
                                                    <Form.Control
                                                        id="upload-button"
                                                        type="file"
                                                        placeholder="Imagen"
                                                        name="files"
                                                        onChange={(e) => changeimg(e)}
                                                        hidden
                                                    />
                                                </Form.Group>
                                            </ListGroup.Item>

                                            {edit == true ? <>
                                                <ListGroup.Item
                                                    as="li"
                                                    className="d-flex justify-content-between align-items-start"
                                                >
                                                    <div className="ms-2 me-auto">
                                                        <RenderIf isTrue={branchoffice_id !== 0}>
                                                            <Form.Group className="mb-3" >
                                                                <Form.Label>Habilitar producto para venta cruzada</Form.Label>
                                                                <Form.Select aria-label="" name="cross_sell" defaultChecked={product.cross_sell}>
                                                                    <RenderIf isTrue={product.cross_sell == 0}>
                                                                        <option value="0">No</option>
                                                                        <option value="1">Si</option>
                                                                    </RenderIf>
                                                                    <RenderIf isTrue={product.cross_sell == 1}>
                                                                        <option value="1">Si</option>
                                                                        <option value="0">No</option>
                                                                    </RenderIf>
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </RenderIf></div>

                                                    {/**  */}


                                                    <RenderIf isTrue={branchoffice_id !== 0}>

                                                        <div className="ms-2 me-auto">
                                                            <RenderIf isTrue={branchoffice_id !== 0}>
                                                                <Form.Group className="mb-3" >
                                                                    <Form.Label>Favorito/destacado</Form.Label>
                                                                    <Form.Select aria-label="" name="favorite">
                                                                        <RenderIf isTrue={product.favorite == 0}>
                                                                            <option value={0}>No</option>
                                                                            <option value={1}>Si</option>
                                                                        </RenderIf>
                                                                        <RenderIf isTrue={product.favorite == 1}>
                                                                            <option value={1}>Si</option>
                                                                            <option value={0}>No</option>
                                                                        </RenderIf>
                                                                    </Form.Select>
                                                                </Form.Group>
                                                            </RenderIf></div>
                                                    </RenderIf>

                                                </ListGroup.Item></> : <></>}

                                            <ListGroup.Item
                                                as="li"
                                                className="d-flex justify-content-between align-items-start"
                                            >


                                                {edit == false ? <><div className="ms-2 me-auto">
                                                    <div className="fw-bold">Referencia externa</div>
                                                    {product.external_reference !== null ? product.external_reference : 'No tiene'}
                                                </div></> : <>
                                                    <RenderIf isTrue={checkUserModules('pos_integrations_module', user.modules) !== -1 && user.business.posIntegration !== undefined}>
                                                        <Form.Group className="mb-3" >
                                                            <Form.Label>Referencia externa</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Referencia externa"
                                                                name="external_reference"
                                                            />
                                                        </Form.Group>
                                                    </RenderIf></>}

                                            </ListGroup.Item>

                                            <RenderIf isTrue={edit == true}>
                                                <ListGroup.Item
                                                    as="li"
                                                    className="d-flex justify-content-between align-items-start"
                                                >
                                                    <div className="mt-3 d-flex justify-content-end">
                                                        <Button size="sm" variant="danger" type="button" onClick={() => setEdit(false)}>
                                                            Cancelar
                                                        </Button>
                                                    </div>
                                                    <div className="mt-3 d-flex justify-content-end">
                                                        <Button size="sm" variant="success" type="submit">
                                                            Guardar
                                                        </Button>
                                                    </div>
                                                </ListGroup.Item>
                                            </RenderIf>
                                        </Form>

                                    </ListGroup>
                                </div>
                            </Col>
                            <Col>
                                <div className='mt-3'>

                                    <ListGroup as="ol" >
                                        {/** Taxes */}
                                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start" >
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Impuestos
                                                    <button className='btn btn-primary btn-sm border-0 m-2' onClick={() => (getTaxes(), setShowModalTaxes(true))}>
                                                        <i className="uil uil-plus">Agregar impuestos</i>
                                                    </button>
                                                </div>
                                                <ListGroup as="ol" >
                                                    {taxes_list.map((tax) => (
                                                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                                            <div className="ms-2 me-auto" >
                                                                {tax.tax} - {tax.percentage}
                                                                <button className='btn btn-outline-danger btn-sm border-0' onClick={async () => deleteTaxByGroupId(tax.tax_id)} >
                                                                    <i className="uil uil-trash-alt"></i>
                                                                </button>
                                                            </div>
                                                        </ListGroup.Item>
                                                    ))}
                                                </ListGroup>
                                            </div>
                                        </ListGroup.Item>
                                        {/** Categories */}
                                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start" >
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Categorias
                                                    <button className='btn btn-primary btn-sm border-0 m-2' onClick={() => setShowModalAsing(true)}>
                                                        <i className="uil uil-plus">Agregar categoria</i>
                                                    </button>
                                                </div>
                                                <ListGroup as="ol" >
                                                    {categories.map((category) => (
                                                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                                            <div className="ms-2 me-auto" >
                                                                {category.name}
                                                                <button className='btn btn-outline-danger btn-sm border-0' onClick={async () => deleteCategoryToProduct(category.category_id)}>
                                                                    <i className="uil uil-trash-alt"></i>
                                                                </button>
                                                            </div>
                                                        </ListGroup.Item>
                                                    ))}
                                                </ListGroup>

                                            </div>
                                        </ListGroup.Item>
                                        {/** Upc */}
                                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Codigos upc:
                                                    <button className='btn btn-primary btn-sm border-0 m-2' onClick={() => setShowModalAddUpc(true)}>
                                                        <i className="uil uil-plus">Agregar un upc</i>
                                                    </button>
                                                </div>
                                                <ListGroup as="ol" >
                                                    {upc_List.map((upc) => (
                                                        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                                            <div className="ms-2 me-auto" >
                                                                {upc.upc_code}
                                                                <button className='btn btn-outline-danger btn-sm border-0' onClick={() => deleteUpcToProduct(upc.id)}>
                                                                    <i className="uil uil-trash-alt"></i>
                                                                </button>
                                                            </div>
                                                        </ListGroup.Item>
                                                    ))}
                                                </ListGroup>
                                            </div>
                                        </ListGroup.Item>
                                        {/** Warehouses */}
                                        <RenderIf isTrue={branchoffice_id !== 0 && product.group_type === "limited"}>
                                            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                                <div className="ms-2 me-auto">
                                                    <div className="fw-bold">Bodegas
                                                    </div>
                                                    <ListGroup as="ol" >
                                                        {product.inventory.map((inv) => (
                                                            <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                                                <div className="ms-2 me-auto" >
                                                                    {inv.name} - {"Cantidad: " + inv.quantity}
                                                                </div>
                                                            </ListGroup.Item>
                                                        ))}
                                                    </ListGroup>
                                                </div>
                                            </ListGroup.Item>
                                        </RenderIf>
                                    </ListGroup>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => (setImgpreview(product.img), setShowModal(false))}>Cerrar</Button>
                </Modal.Footer>
            </Modal >


            {/*Modal add  to product*/}
            <Modal
                show={showModalTaxes}
                onHide={() => (setShowModalTaxes(false))}
                size='large'
                centered>
                <Modal.Header>
                    <h3><i className="uil uil-plus"></i>Asignar impuesto</h3>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex justify-content-center align-item-center'>
                        <select className='form-select m-3' name="user_branch" id="user_branch" onChange={(e) => setTaxSelect(e.target.value)}>
                            <option >Seleccione un impuesto</option>
                            {taxes.map(tax => (
                                <option value={tax.id}>{tax.tax} - {tax.percentage}</option>
                            ))}
                        </select>
                        <Button size="sm" variant="success" type="button" className='m-3' onClick={async () => asingTaxToGroup()} >
                            Guardar
                        </Button>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => (setShowModalTaxes(false))}>Cerrar</Button>
                </Modal.Footer>
            </Modal>

            {/*Modal add category to product*/}
            <Modal
                show={showModalAsing}
                onHide={() => (setShowModalAsing(false))}
                size='large'
                centered>
                <Modal.Header>
                    <h3><i className="uil uil-plus"></i>Asignar Categoria</h3>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex justify-content-center align-item-center'>
                        <Select
                            name="user_branch" id="user_branch"
                            className='w-100 m-3'
                            options={categoriesSelect}
                            onChange={handleSaveTableSelect}
                        />
                        <Button size="sm" variant="success" type="button" className='m-3' onClick={async () => asingProductToCategory()}>
                            Guardar
                        </Button>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => (setShowModalAsing(false))}>Cerrar</Button>
                </Modal.Footer>
            </Modal>



            {/*Modal add up to product*/}
            <Modal
                show={showModalAddUpc}
                onHide={() => (setShowModalAddUpc(false))}
                size='large'
                centered>
                <Modal.Header>
                    <h3><i className="uil uil-plus"></i>Asignar upc</h3>
                </Modal.Header>
                <Modal.Body>
                    <Form className="needs-validation">

                        <div className='d-flex justify-content-center align-item-center'>
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label>Ingrese un upc</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="000000000000"
                                    name="name" onChange={(e) => setNewUpc_code(e.target.value)} />
                            </Form.Group>
                            <Button size="sm" variant="success" type="button" className='m-3' onClick={async () => addUpcCodeToProduct()}>
                                Guardar
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => (setShowModalAddUpc(false))}>Cerrar</Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default ModalViewGroup