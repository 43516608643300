//Component react 
import React, { useState, useEffect } from 'react';
//Components 
import { Modal, Button } from 'react-bootstrap';
//Alerts
import ErrorAlert from '../../Alerts/ErrorAlert';
import SuccessAlert from '../../Alerts/SuccessAlert';
//API 
import { MachinesFetchAPI } from '../../../api/Machines';

//Component
const ModalSettleMachine = ({ machine_id,machine_code, selectedBranch, showModal, setShowModal, setEventHandler, eventHandler, token }) => {

    //State of payment integration
    const [paymentMethodIntegrations, setPaymentMethodIntegrations] = useState([]);

    //Function to make the settle of the machine
    const makeSettle = async (paymentmethod_integration_id) => {
        try {
            //We make the settle of the machine
            const res = await MachinesFetchAPI.settleMachine(machine_code, selectedBranch.code, paymentmethod_integration_id, token);
            //We show the message
            SuccessAlert(res.data.message)
        } catch (err) {
            ErrorAlert("Error al realizar el cierre de la maquina", "Error", "error")
        }
    }

    //Function to get machine payment method integrations
    const getMachinePaymentMethodIntegrations = async () => {
        try {
            //We get the machine payment method integrations
            const paymentMethodIntegrations = await MachinesFetchAPI.getMachinePaymentMethodIntegrations(machine_id, token);
            //We set the payment method integrations
            setPaymentMethodIntegrations(paymentMethodIntegrations.data.integrations)
        } catch (err) {
            setPaymentMethodIntegrations([])
        }
    }

    //Function to populate the payment method integrations
    const paymentMethodIntegrationsList = () => {
        const listPaymentMethodIntegrations = paymentMethodIntegrations.map((integration) => (
            <>
                <div className="col-12-col-md-2 col-lg-4 mt-2">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">{integration.name}</h5>
                            <button className="btn btn-primary" onClick={() => makeSettle(integration.id)}>Realizar cierre</button>
                        </div>
                    </div>
                </div>
            </>
        ));
        return listPaymentMethodIntegrations
    }

    useEffect(() => {
        getMachinePaymentMethodIntegrations()
        setEventHandler(0)
    }, [eventHandler]);

    return (
        <>
            <Modal
                show={showModal}
                onBackdropClick={() => setShowModal(false)}
                size="lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Realizar cierre</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row rounded-3">
                        {paymentMethodIntegrationsList()}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ModalSettleMachine;
