import React from 'react';
//Components 
import { Tab, Nav } from 'react-bootstrap';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
//Internal components
import Navbar from '../NavBar/Navbar';
import BankStatements from './BankStatements';
import TransactionConciliation from './TransactionReconciliation';
import StatesBankConsolidation from "./StatesBankConsolidation";
import StatesBankBatchConsolidation from './StatesBankBatchConsolidation';
import ConsilitionHistory from './ConsilitionHistory';
import ImgConciliation from './ImageConciliations';
const BankConsiliation = () => {
    return (
        <>
            <Navbar titlePage="Informes" />
            <div className='bg-light-subtle pt-4'>
                <div className="container">
                    {/** Main container */}
                    <main>
                        {/** Header */}
                        <section className='banner-sp bg-light-subtle d-lg-flex justify-content-lg-between 
                  align-items-center rounded-4 mb-3 flex-column flex-lg-row justify-content-center py-2 py-lg-0'>
                            <div>
                                <div className='d-lg-flex flex-lg-stack flex-wrap gap-4 w-100 flex-nowrap d-flex justify-content-center align-items-center 
                  justify-content-lg-start'>
                                    <div className='page-title d-flex flex-column gap-0 me-3'>
                                        <h2 className='banner-reports-tittle mb-0 pb-0'>
                                            Conciliación bancaria
                                        </h2>
                                        <p className='m-0 pt-1 banner-reports-description'>Conciliación bancaria.</p>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/** End Header  */}
                        {/**Tabs */}
                        <Tab.Container defaultActiveKey={"BankStatements"}>
                            <div className="row">
                                {/** Internal navb */}
                                <div className="col-12 col-md-3 col-lg-3 col-xl-3 pt-3">
                                    <div className="rounded-3 sticky-content mt-lg-2 mt-0">
                                        <Nav variant="pills" className="d-flex flex-column gap-2 p-2 mt-lg-2" >
                                            {/**Con */}

                                            <li className="nav-sp-item bg-light rounded-3 border">
                                                <Nav.Link eventKey="BankStatements" ><i className="uil  uil-book-alt  me-1"></i>
                                                    Movimientos bancarios</Nav.Link>
                                            </li>
                                            {/* Conciliacion lotes  */}
                                            <li className="nav-sp-item bg-light rounded-3 border"  >
                                                <Nav.Link eventKey="loteConsolidation" title="lote">
                                                    <i className="uil uil-book-open me-1"></i>
                                                    Conciliación  de transacciones por lote
                                                </Nav.Link>
                                            </li>

                                            {/** Consolidacion bancaria */}
                                            <li className="nav-sp-item bg-light rounded-3 border">
                                                <Nav.Link eventKey="StatesBankBatchConsolidation" ><i className="uil  uil-book-alt  me-1"></i>
                                                    Conciliación de metodos de pago por lotes</Nav.Link>
                                            </li>

                                            <li className="nav-sp-item bg-light rounded-3 border">
                                                <Nav.Link eventKey="StatesBankConsolidation" ><i className="uil  uil-book-alt  me-1"></i>
                                                    Consolidación de estados bancarios</Nav.Link>
                                            </li>
                                            {/* Report of Conciliacion   */}
                                            <li className="nav-sp-item bg-light rounded-3 border"  >
                                                <Nav.Link eventKey="reportConciliation" title="report">
                                                    <i className="uil uil-graph-bar "></i>
                                                    Historial de conciliaciones
                                                </Nav.Link>
                                            </li>
                                            {/* Report of Conciliacion   */}
                                            <li className="nav-sp-item bg-light rounded-3 border"  >
                                                <Nav.Link eventKey="imgConciliation" title="image">
                                                    <i className="uil uil-graph-bar "></i>
                                                    Análisis de vouchers
                                                </Nav.Link>
                                            </li>
                                        </Nav>
                                    </div>
                                </div>

                                {/** Content of the tabs */}
                                <div className="col-12 col-md-9 col-lg-9 col-xl-9">
                                    <Tab.Content>
                                        <Tab.Pane eventKey="BankStatements">
                                            <BankStatements />
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="loteConsolidation">
                                            <div className=' m-0'>
                                                <TransactionConciliation />
                                            </div>
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="StatesBankBatchConsolidation">
                                            <div className=' m-0'>
                                                <StatesBankBatchConsolidation />
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="StatesBankConsolidation">
                                            <div className=' m-0'>
                                                <StatesBankConsolidation />
                                            </div>
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="reportConciliation">
                                            <div className=' m-0'>
                                                <ConsilitionHistory />
                                            </div>
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="imgConciliation">
                                            <div className=' m-0'>
                                                <ImgConciliation />
                                            </div>
                                        </Tab.Pane>


                                    </Tab.Content>

                                </div>
                            </div>

                        </Tab.Container>
                    </main>
                </div>
            </div>
        </>
    );
}

export default BankConsiliation;
