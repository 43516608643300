import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
//API
import { BranchOfficeFetchAPI } from '../../../api/BranchOffices';
import { UsersFetchAPI } from '../../../api/Users';
import { InformZFetchAPI } from '../../../api/InformZ';
import { OrdersFetchAPI } from '../../../api/Orders';
import TransactionHistory from "../../transactions/TransactionHistory";

// External Componets
import moment from 'moment-timezone';
import Select from 'react-select';
//Internal components
import ReportTable from '../table/ReportTable';
import Modal from 'react-bootstrap/Modal';
//Modals 
import ModalInformZOrders from '../../Modal/ModalInformZOrders';

//Utils 
import { RenderIf } from '../../utils/RenderIf';
import OrderDetails from '../../Orders/OrderDetails';
//img
import empty from '../../img/icons/empty.png'

//Alerts 
import SuccessAlert from '../../Alerts/SuccessAlert';
import LoadingAlert from '../../Alerts/LoadingAlert';
import ErrorAlert from '../../Alerts/ErrorAlert';
//Utils
import Socket from '../../utils/Socket.config';

//Closure report 
const ClosuresReport = () => {
    //Params
    let { business_code } = useParams();
    //We get the user data 
    let user = JSON.parse(localStorage.getItem('user'))
    let token = localStorage.getItem('x-access-token');
    
    //States 
    const [selectedBranch, setSelectedBranch] = useState({
        code: 0,
        id: 0,
        name: ""
    });
    const [userBranchoffices, setUserBranchs] = useState([]);
    const [userBusiness, setUserBusiness] = useState({
        id: 0
    });
    const [modalShowDetail, setModalShowDetail] = useState(false);

    const [initialDate, setInitialDate] = useState(moment(new Date()).tz('America/Bogota').format('YYYY-MM-DD') + 'T00:00:00.000+00:00');
    const [maxDate, setMaxDate] = useState(moment(new Date()).tz('America/Bogota').format('YYYY-MM-DD') + 'T23:59:59.000+00:00')
    //States of search select 
    const [selectedUserOption, setSelectedUserOption] = useState(null);
    const [cashiers, setCashiers] = useState([]);
    const [data, setReportData] = useState({
        informsz: [],
    });
    const [detailOrder, setDetailOrder] = useState();
    const [order_type, setOrderType] = useState("temporal");
    const [selectedInfomZ, setSelectedInformZ] = useState({
        code: 0,
        name: "",
        user: {
            name: ""
        },
        branchoffice: {
            name: ""
        },
        createdAt: new Date().toISOString(),
    });
    //Totals states 
    const [subtotalOrders, setSubtotalOrders] = useState(0)
    const [taxOrders, setTaxOrders] = useState(0)
    const [totalOrders, setTotalOrders] = useState(0)
    const [branchOrdersData, setBranchOrdersData] = useState([]);
    const [temporal, setTemporal] = useState([]);
    const [informZVoucher, setInformZVoucher] = useState(null);

    const [modalShowInformzDetail, setmodalShowInformzDetail] = useState(false)
    const [showModalVoucherSettle, setShowModalVoucherSettle] = useState(false);

    //Function to get the branchoffice info 
    const getBranchoffice = async (business_code, branchoffice_code) => {
        try {
            const response = await BranchOfficeFetchAPI.getBranchOfficeByCode(business_code, branchoffice_code);
            setSelectedBranch(response.data.branchoffice);
            //We set the user selected to null
            setSelectedUserOption(null);
        } catch (err) {
            console.log(err)
            setSelectedBranch({
                code: 0,
                id: 0,
                name: ""
            });
            //We set the user selected to null
            setSelectedUserOption(null);
            setCashiers([])
        }
    }
    //Function to populate the users branch 
    const branchList = () => {
        const listBracnh = userBranchoffices.map((branch) => (
            <>
                <option value={branch.code}>{branch.branchoffice_name}</option>
            </>
        ));
        return listBracnh
    }

    //Function to search any result on the search filter 
    function handleChangeFiltro(event) {
        let resultFilter = [];
        if (event.target.value == "") {
            console.log(event.target.value);
            setBranchOrdersData(temporal);
        } else {
            for (
                let recorrer = 0;
                recorrer < branchOrdersData.length;
                recorrer++
            ) {
                const examinar = branchOrdersData[recorrer];

                const info = JSON.stringify(examinar);
                const resultado = info
                    .toLowerCase()
                    .includes(event.target.value.toLowerCase());

                if (resultado == true) {
                    resultFilter.push(examinar);

                    setBranchOrdersData(resultFilter);
                    console.log(resultFilter);
                }
            }
        }
    }
    //Functio to get cashier users depending of the branch selected
    const getCashiers = async (branchSelected) => {
        let response;
        try {
            //We get the users depending the branch selected 
            if (branchSelected === 0) {
                //We get the user cashiers of the business
                response = await UsersFetchAPI.getUsersByBusinessAndRole(userBusiness.id, [4]);
            } else {
                //We get the user cashiers of the branch selected
                response = await UsersFetchAPI.getUsersByRoleAndBranchoffice(branchSelected, 4);
            }
            setCashiers(response.data.users)
        } catch (err) {
            setCashiers([])
        }
    }

    //The default option of the select input 
    const defaultOption = [{ label: 'Seleccione...', value: 0 }];

    //Get users (cashiers)
    const usersList = cashiers.map((user) => ({
        label: user.user_name + ' ' + user.lastName + ': ' + user.idCard,
        value: user.user_id
    }));

    //Function to get the report of the branch office 
    const getReport = async (dateMin, dateMax) => {
        try {
            //We show a loading alert 
            LoadingAlert();
            dateMin = new Date(dateMin).toJSON();
            dateMax = moment(new Date(dateMax)).format('YYYY-MM-DD');
            //We get the report
            const response = await InformZFetchAPI.getinformZ(selectedBranch.code, userBusiness.code, token, dateMin, dateMax, selectedUserOption);
            //We set the report data
            setReportData(response.data);
            //We show a success alert
            SuccessAlert();
        } catch (err) {
            //We show a error alert 
            ErrorAlert('No tiene información')
            console.log(err)
        }
    }
    //Functions to show and hide the modal 
    const handleCloseModalDetail = () => setModalShowDetail(false);
    const handleShowModalDetail = () => setModalShowDetail(true)


    //Event to detect the user selected on search select 
    const handleChangeUsers = (option) => {
        //We check if the option is null 
        if (option.value === 0) {
            setSelectedUserOption(null);
        } else {
            setSelectedUserOption(option);
        }
    };

    //Hook to load the initial data 
    useEffect(() => {
        //We set the branchoffices of the user 
        setUserBranchs(user.branchoffices);
        //We set the business user 
        setUserBusiness(user.business);
    }, []);

    //Function to get order by id (order_id)
    const getOrderById = async (order_id) => {
        try {
            const response = await OrdersFetchAPI.getOrderById(order_id);
            setDetailOrder(response.data.order)
            console.log(detailOrder)
            setOrderType("permanent")
        } catch (err) {
            setDetailOrder({})
            setOrderType("permanent")
            console.log(err)
        }
    }
    //Function to gen the empty card to the transaction history 
    const EmptyCard = () => {
        return (
            <div className="m-3 w-auto ">
                <div className="border-history d-flex justify-content-md-center py-4 px-4 " aria-current="true">
                    <div className="d-flex w-100 justify-content-center align-items-center  flex-column">
                        <img src={empty} alt="Vacio" width="35px" height="35px" />
                        <div className='text-center mt-3'>
                            <h5>Vacío</h5>
                            <h6 className="mb-0 text-secondary fw-bold">No hay transacciones </h6>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    //Function to populate the list orders 
    const listOrders = () => {

        const listOrders = branchOrdersData.map((data) => (
            <>
                <div>
                    <TransactionHistory data={data} />
                </div>

            </>
        ));
        //We check if the list orders has data 
        if (listOrders.length === 0) {
            return EmptyCard()
        } else {
            return (listOrders)
        }
    }

    //Function to get the informz by id
    const getInformZById = async (inform_z_id) => {
        try {
            //We get the informz by id
            const response = await InformZFetchAPI.getinformzById(inform_z_id, token)
            //wE Get the variables to show the detail of the informz
            let ordersFilter = response.data.orders
            let total = 0
            let subtotal = 0
            let tax = 0;
            //We get the total of the orders
            ordersFilter.forEach(order => {
                subtotal = (subtotal + parseFloat(order.subtotal))
                tax = (tax + parseFloat(order.tax))
            });
            //set the variables
            setInformZVoucher(response.data.voucher)
            setSubtotalOrders(subtotal)
            setTaxOrders(tax)
            setTotalOrders((subtotal + tax))
            setBranchOrdersData(ordersFilter);
            setTemporal(ordersFilter);
            setmodalShowInformzDetail(true)
        } catch (err) {
            console.log(err)
        }
    }


    //Hook to load the users depending the selection of branch 
    useEffect(() => {
        //We get the users 
        getCashiers(selectedBranch.id)
    }, [selectedBranch]);
    return (
        <div>
            <h4>Filtros</h4>
            <div className="row gx-3 gy-2 align-items-center bg-white p-2 my-3 border-sp border shadow-sm">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="form-group">
                        <label htmlFor="input_from">Sucursal</label>
                        <select onChange={e => getBranchoffice(business_code, e.target.value)} className='form-select' name="branch" id="branch">
                            <option value={0}>Mostrar todos</option>
                            {branchList()}
                        </select>
                    </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6">
                    <div className="form-group">
                        <label htmlFor="input_from">Desde</label>
                        <input type="date" name="initialDate" onChange={e => setInitialDate(e.target.value + "T00:00:00.000+00:00")} className="control mt-3" />
                    </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6">
                    <div className="form-group">
                        <label htmlFor="input_from">Hasta</label>
                        <input type="date" name="finishDate" onChange={e => setMaxDate(e.target.value + "T23:59:59.000+00:00")} className="control mt-3" />
                    </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <label htmlFor="input_from">Cajeros (opcional)</label>
                    <Select
                        className='w-100'
                        value={selectedUserOption}
                        options={[defaultOption[0], ...usersList]}
                        onChange={handleChangeUsers}
                    />

                </div>
                <div className=" d-flex justify-content-center justify-content-sm-end mb-3">
                    <button type="button" className="btn btn-success px-5" onClick={e => getReport(initialDate, maxDate)}>Aceptar</button>
                </div>
            </div>


            {/**Table */}
            <ReportTable
                table_name="Tabla de cierres"
                table_headers={["Fecha", "Hora", "Codigo de cierre", "Acciones"]}
                table_type="informz_report"
                table_data={data.informsz}
                handleShowModalDetail={handleShowModalDetail}
                setSelectedInformZ={setSelectedInformZ}
                getInformZById={getInformZById}
            />

            {/* Modal detail order */}
            <Modal show={modalShowDetail} onHide={handleCloseModalDetail}>
                <Modal.Header closeButton>
                    <Modal.Title>Detalles</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* 1 */}
                    <div className="w-auto bg-light px-1 py-2 border rounded my-2">
                        <div className="border-0" >
                            <div className="d-flex justify-content-between align-items-center">
                                <button className="btn btn-primary rounded-pill" onClick={() => setShowModalVoucherSettle(true)}>Voucher</button>
                                <h6 className="mb-0 text-secondary fw-bold">Buscador:</h6>
                                <div className='border-0 fw-bold fs-3'>
                                    <input
                                        spellcheck="false"
                                        className="form-control"
                                        type="text"
                                        step="any"
                                        onChange={handleChangeFiltro}
                                        placeholder="Buscar orden "
                                    />
                                </div>
                            </div>
                        </div>

                        {/* 2*/}
                        <div className="item2 p-2 border">
                            {listOrders()}

                        </div>
                        {/* 3 */}
                        <div className='d-flex justify-content-between mt-1'>
                            <h5><strong>Total de ventas:</strong></h5>
                            <h5><strong>${parseFloat(totalOrders).toFixed(2)}</strong></h5>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>



            {/* Modal voucher */}
            <Modal show={showModalVoucherSettle} onHide={setShowModalVoucherSettle}>
                <Modal.Header closeButton>
                    <Modal.Title>Detalles</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex alight-item-center justify-content-center">
                        <div>
                            <pre>{informZVoucher}</pre>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </div>
    );
}

export default ClosuresReport;
