import { useState, useEffect } from "react";
import { Table, Form, Button, Modal, Tab, Row, Col, Nav } from "react-bootstrap";
import { BankConsilationFetchAPI } from "../../api/BankConsilation";
import SuccessAlert from "../Alerts/SuccessAlert";
import ErrorAlert from "../Alerts/ErrorAlert";
//Utils 
import { RenderIf } from "../utils/RenderIf";
const ConsilitionHistory = () => {
    const [history, setHistory] = useState([]);
    const [filters, setFilters] = useState({ startDate: "", endDate: "" });
    const [selectedLote, setSelectedLote] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const token = localStorage.getItem('x-access-token');

    const fetchHistory = async () => {
        try {
            let response = await BankConsilationFetchAPI.getBankConsiliationHistory(
                filters.startDate,
                filters.endDate + "T23:59:59",
                token
            );
            setHistory(response.data.history);
            SuccessAlert("Historial cargado", "El historial de conciliaciones ha sido obtenido correctamente", "success");
        } catch (err) {
            console.error(err);
            setHistory([]);
            ErrorAlert("Error al obtener el historial", "Ocurrió un error al obtener el historial de conciliaciones", "error");
        }
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters((prev) => ({ ...prev, [name]: value }));
    };

    const handleShowDetails = (lote) => {
        setSelectedLote(lote);
        setShowModal(true);
    };

    return (
        <div className="mx-lg-2 mx-1">
            <h2 className="text-xl font-bold">Historial de Conciliaciones</h2>

            {/* Filtros */}
            <Form className="mb-3">
                <Form.Group>
                    <Form.Label>Fecha de inicio</Form.Label>
                    <Form.Control type="date" name="startDate" onChange={handleFilterChange} />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Fecha de fin</Form.Label>
                    <Form.Control type="date" name="endDate" onChange={handleFilterChange} />
                </Form.Group>
                <Button onClick={fetchHistory}>Buscar</Button>
            </Form>

            <Tab.Container defaultActiveKey={"loteConsolidation"}>
                <Row>
                    <Col sm={12}>
                        <Nav variant="underline" className="d-flex horizontal-scroll border-bottom  pe-3 ">
                            {/* Conciliacion lotes  */}
                            <li className="nav-sp-item"  >
                                <Nav.Link eventKey="loteConsolidation" title="lote">
                                    <i className="uil uil-book-open me-1"></i>
                                    Historial de lotes conciliados
                                </Nav.Link>
                            </li>

                            {/* Conciliacion lotes  */}
                            <li className="nav-sp-item"  >
                                <Nav.Link eventKey="bankStatementConsolidation" title="lote">
                                    <i className="uil uil-book-open me-1"></i>
                                    Historial de conciliaciones
                                </Nav.Link>
                            </li>
                        </Nav>
                    </Col>
                    <Col sm={12}>
                        <Tab.Content>

                            <Tab.Pane eventKey="loteConsolidation">
                                <div className=' m-0'>

                                    {/* Tabla de Historial */}
                                    <RenderIf isTrue={history.length > 0}>
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>Fecha</th>
                                                    <th>Lote</th>
                                                    <th>Total</th>
                                                    <th>Estado</th>
                                                    <th>Acciones</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {history.map((record, idx) => (
                                                    <tr key={idx}>
                                                        <td>{record.createdAt}</td>
                                                        <td>{record.lote_reference}</td>
                                                        <td>${record.total.toFixed(2)}</td>
                                                        <td>Conciliado</td>
                                                        <td>
                                                            <Button
                                                                variant="info"
                                                                onClick={() => handleShowDetails(record)}
                                                            >
                                                                Ver Detalles
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>

                                    </RenderIf>


                                    <RenderIf isTrue={history.length === 0}>
                                        <p>No hay conciliaciones registradas</p>

                                    </RenderIf>
                                </div>
                            </Tab.Pane>

                            <Tab.Pane eventKey="bankStatementConsolidation">
                                <div className=' m-0'>

                                </div>
                            </Tab.Pane>
                        </Tab.Content>

                    </Col>
                </Row>

            </Tab.Container >

            {/* Modal de Detalles */}
            < Modal show={showModal} onHide={() => setShowModal(false)} centered >
                <Modal.Header closeButton>
                    <Modal.Title>Detalles de la Conciliación</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedLote && (
                        <>
                            <p><strong>Fecha:</strong> {selectedLote.createdAt}</p>
                            <p><strong>Lote:</strong> {selectedLote.lote_reference}</p>
                            <p><strong>Total:</strong> ${selectedLote.total.toFixed(2)}</p>

                            <h5>Transacciones Asociadas:</h5>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Código Transacción</th>
                                        <th>Total contable</th>
                                        <th>Propina</th>
                                        <th>Método de Pago</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedLote.orders.map((order, idx) => (
                                        <tr key={idx}>
                                            <td>{order.transaction_code}</td>
                                            <td>${order.total.toFixed(2)}</td>
                                            <td>${order.tips.toFixed(2)}</td>
                                            <td>{order.paymenthod}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal >
        </div >
    );
};

export default ConsilitionHistory;
