import axios from 'axios';


const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': 'application/json' }
})

export class UsersFetchAPI {
    //Funtion to get the business users 
    static async getBusinessUser(business_id) {
        const res = await server(`/users/${business_id}/business`, {
            method: "get",
        });
        return res;
    }

    //Function to get users by role and branchoffice
    static async getUsersByRoleAndBranchoffice(branchoffice_id, users_role_id) {
        const res = await server(`/users/${branchoffice_id}/branchoffice/${users_role_id}/role`, {
            method: "get",
        });
        return res;
    }

    //Function to get users by role and business
    static async getUsersByBusinessAndRole(business_id, users_role_id) {
        let final_string_users_role_id = "role=" + users_role_id.join("&role=")
        const res = await server(`/users/${business_id}/business?${final_string_users_role_id}`, {
            method: "get",
        });
        return res;
    }

    //Function to get user by code
    static async getUserById(id, token) {
        const res = await server(`/user/${id}`, {
            method: "get",
            headers: {
                "x-access-token": token
            }
        });
        return res;
    }
    
    //Function to get user 
    static async getUsersByBranchofficesAndRole(branchoffices, roles, token) {
        const res = await server(`/user_per_branchoffice?branchoffices=[${branchoffices}]&roles=[${roles}]`, {
            method: "get",
            headers: {
                'x-access-token': token
            }
        });
        return res;
    }

    //Function to create a new business user role
    static async createBusinessUserRole(data, token) {
        const res = await server(`/users/roles`, {
            method: "post",
            data: data,
            headers: {
                'x-access-token': token
            }
        });
        return res;
    }


    //Function to get role by user
    static async getRoleByUser(user_role_id, token) {
        const res = await server(`/users/roles?role_id=${user_role_id}`, {
            method: "get",
            headers: {
                'x-access-token': token
            }

        });
        return res;
    }
    //Function to get user roles by business 
    static async getUserRolesByBusiness(business_id, token) {
        const res = await server(`/users/roles?business_id=${business_id}`, {
            method: "get",
            headers: {
                'x-access-token': token
            }

        });
        return res;            
    }

    //Function to remove a user role from a user 
    static async removeUserRole(user_id, role_id, token) {
        const res = await server(`/userrole?role_id=${role_id}&user_id=${user_id}`, {
            method: "delete",
            headers: {
                'x-access-token': token
            }
        });
        return res;
    }

    //Function to asing a user role to a user
    static async asingUserRole(user_id, role_id, token) {
        const res = await server(`/userrole?role_id=${role_id}&user_id=${user_id}`, {
            method: "post",
            headers: {
                'x-access-token': token
            }
        });
        return res;
    }
    
    //Function to register a new user 
    static async registerUser(data, token) {
        const res = await server(`/auth/register`, {
            method: "post",
            data: data,
            headers: { 
                'Content-Type': 'multipart/form-data', 
                'x-access-token': token 
            },
        });
        return res;
    }

    //Function to update a user
    static async updateUser(user_id,data, token) {
        const res = await server(`/user/${user_id}`, {
            method: "put",
            data: data,
            headers: {
                'Content-Type': 'multipart/form-data',
                'x-access-token': token
            },
        });
        return res;
    }

    //Function to remove a user from a branchoffice
    static async removeUserFromBranchoffice(user_id, branchoffice_id, token) {
        const res = await server(`/user_per_branchoffice/${user_id}/user/${branchoffice_id}/branchoffice`, {
            method: "delete",
            headers: {
                'x-access-token': token
            }
        });
        return res;
    }

    //Function to asing a user to a branchoffice
    static async asingUserToBranchoffice(user_id, branchoffice_id, token) {
        const res = await server(`/user_per_branchoffice/${user_id}/user/${branchoffice_id}/branchoffice`, {
            method: "post",
            headers: {
                'x-access-token': token
            }
        });
        return res;
    }

    //Function to remove a user 
    static async removeUser(user_id, token) {
        const res = await server(`/user/${user_id}`, {
            method: "delete",
            headers: {
                'x-access-token': token
            }
        });
        return res;
    }
}