import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { Modal, Button, Table, Form } from 'react-bootstrap';
//API 
import { PaymentMethodFetchAPI } from '../../api/PaymentMethod';
import { BankConsilationFetchAPI } from '../../api/BankConsilation';
//Alerts 
import ErrorAlert from '../Alerts/ErrorAlert';
import SuccessAlert from '../Alerts/SuccessAlert';
import LoadingAlert from '../Alerts/LoadingAlert';

const BankStatements = () => {
    const [file, setFile] = useState(null);
    const [headers, setHeaders] = useState([]);
    const [previewData, setPreviewData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [mapping, setMapping] = useState({
        date: "",
        reference: "",
        debit: "",
        credit: "",
    });
    const [bankName, setBankName] = useState("");
    const [integrations, setIntegrations] = useState([]);
    const [bankStatementTemplate, setBankStatementTemplate] = useState(null);
    let token = localStorage.getItem('x-access-token');
    let { business_code } = useParams();
    const [bankStatements, setBankStatements] = useState([]);
    const [selectedStatement, setSelectedStatement] = useState(null);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    // Upload of excel 
    const handleFileUpload = (e) => {
        //We just allow to upload  the file if the bank statement template is null
        const uploadfile = e.target.files[0];
        setFile(uploadfile);
        if (!uploadfile) return;

        const reader = new FileReader();
        reader.onload = (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const sheet = workbook.Sheets[workbook.SheetNames[0]];
            const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
            const headers = jsonData[0];
            setHeaders(headers);
            setPreviewData(jsonData.slice(1)); // Guarda los datos (sin los encabezados)
            if (bankStatementTemplate === null) {
                setShowModal(true); // Muestra el modal para mapeo
            }
        };
        reader.readAsArrayBuffer(uploadfile);


    };

    // Manage of change of mapping excel columns 
    const handleMappingChange = (key, value) => {
        setMapping((prev) => ({ ...prev, [key]: value }));
    };

    // We save the mapping and close the modal
    const handleSaveMapping = async () => {
        LoadingAlert("Guardando mapeo", "Guardando el mapeo de columnas...");
        try {
            console.log(token)
            const response = await BankConsilationFetchAPI.saveBankStatementTemplate(token, bankName, business_code, mapping);
            setBankStatementTemplate(mapping);
            console.log(response);
            SuccessAlert("Mapeo guardado", "El mapeo se ha guardado correctamente", "success");
        } catch (err) {
            ErrorAlert("Error al guardar el mapeo", err.message, "error");
        }
        setShowModal(false);
    };
    // Function to save the bank statement 
    const handleSaveBankStatement = async () => {
        //We check if the file is null or the bank name is null
        if (!file || !bankName) {
            ErrorAlert("Error", "Debe seleccionar un banco y un archivo.", "error");
            return;
        } else {

            LoadingAlert("Procesando archivo", "Subiendo y analizando el archivo...");
            try {
                await BankConsilationFetchAPI.saveBankStatement(token, bankName, business_code, file, bankStatementTemplate);
                SuccessAlert("Archivo procesado", "Los movimientos han sido registrados correctamente", "success");
                getBankStatements();
            } catch (err) {
                ErrorAlert("Error al procesar el archivo", err.message, "error");
            }
        }
    };

    // Show prev table mapped data 
    const renderPreviewTable = () => {
        const mappedData = previewData.map((row) => ({
            date: row[headers.indexOf(mapping.date)],
            reference: row[headers.indexOf(mapping.reference)],
            description: row[headers.indexOf(mapping.description)],
            debit: row[headers.indexOf(mapping.debit)],
            credit: row[headers.indexOf(mapping.credit)],
        }));

        return (
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Fecha</th>
                        <th>Referencia</th>
                        <th>Descripción</th>
                        <th>Débito</th>
                        <th>Crédito</th>
                    </tr>
                </thead>
                <tbody>
                    {mappedData.map((row, index) => (
                        <tr key={index}>
                            <td>{row.date}</td>
                            <td>{row.reference}</td>
                            <td>{row.description}</td>
                            <td>{row.debit}</td>
                            <td>{row.credit}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        );
    };


    //Function to load the payment integrations 
    const loadPaymentIntegrations = async () => {
        try {
            const response = await PaymentMethodFetchAPI.getPaymentMethodIntegrations(token);
            setIntegrations(response.data.integrations);
        } catch (err) {
            ErrorAlert("Error al cargar las integraciones", err.message, "error");
            setIntegrations([]);
        }
    }

    //Function to get the bank statements templates
    const getBankStatementsTemplates = async (bankName) => {
        try {
            const response = await BankConsilationFetchAPI.getBankStatementsTemplates(token, bankName, business_code);
            //If the template is not null we set the template
            if (response.data.template !== null) {
                setBankStatementTemplate(response.data.template);
            }
        } catch (err) {
            setBankStatementTemplate(null);
        }
    }

    //Function to get the bank statements
    const getBankStatements = async () => {
        try {
            const response = await BankConsilationFetchAPI.getBankStatements(token, business_code);
            setBankStatements(response.data.bankStatements);
        } catch (err) {
            setBankStatements([]);
        }
    }

        // Function to open details modal
        const openDetailsModal = (statement) => {
            setSelectedStatement(statement);
            setShowDetailsModal(true);
        };
    

    //Hook to load the initial data 
    useEffect(() => {
        loadPaymentIntegrations();
        getBankStatements()
    }, []);

    return (
        <div className="p-4">
            <h2>Subir Estado Bancario</h2>

            <Form.Label>Seleccione la integracion a guardar:</Form.Label>
            <Form.Select
                value={bankName}
                onChange={(e) => { setBankName(e.target.value); getBankStatementsTemplates(e.target.value); }}
            >
                <option value="">Seleccione...</option>
                {integrations.map((integration, index) => (
                    <option key={index} value={integration.name}>
                        {integration.name}
                    </option>
                ))}
            </Form.Select>

            <input
                type="file"
                onChange={handleFileUpload}
                className="form-control mb-4"
            />
            {/**Button to save the movements */}
            <Button onClick={handleSaveBankStatement}>Guardar Movimientos</Button>

            {/* Modal of  excel mapping  */}
            <Modal size="lg" show={showModal} onHide={() => setShowModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Mapear Columnas</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>Selecciona las columnas correspondientes:</h5>
                    <Form>
                        {['date', 'reference', "description", 'debit', 'credit'].map((key) => (
                            <Form.Group key={key} className="mb-3">
                                <Form.Label>{key.charAt(0).toUpperCase() + key.slice(1)}</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={mapping[key]}
                                    onChange={(e) => handleMappingChange(key, e.target.value)}
                                >
                                    <option value="">Seleccionar columna</option>
                                    {headers.map((header, index) => (
                                        <option key={index} value={header}>
                                            {header}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        ))}
                    </Form>

                    {/* Previsualización de los datos mapeados */}
                    {previewData.length > 0 && renderPreviewTable()}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Cancelar
                    </Button>
                    <Button variant="primary" onClick={handleSaveMapping}>
                        Guardar Mapeo
                    </Button>
                </Modal.Footer>
            </Modal>
            {/** Table of bank statements */}
            <Table className="  border border-gray-300">
                <thead>
                    <tr className="bg-gray-200">
                        <th className="border p-2">Banco</th>
                        <th className="border p-2">Fecha de creacion</th>
                        <th className="border p-2">Codigo del movimiento</th>
                        <th className="border p-2">Estado</th>
                        <th className="border p-2">Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {bankStatements.length > 0 ? (
                        bankStatements.map((statement) => (
                            <tr key={statement._id} className="hover:bg-gray-100">
                                <td className="border p-2">{statement.bank_name}</td>
                                <td className="border p-2">{new Date(statement.createdAt).toLocaleDateString()}</td>
                                <td className="border p-2">{statement.code}</td>
                                <td className="border p-2">{statement.status}</td>
                                <td className="border p-2">{
                                    <button onClick={() => openDetailsModal(statement)} className="btn btn-primary">
                                        <i className="uil uil-pen"></i>
                                    </button>
                                }</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="5" className="text-center border p-4">
                                No data available
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
            {/* Modal to show the details of the statement */}
            <Modal show={showDetailsModal} onHide={() => setShowDetailsModal(false)} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Detalles del Estado Bancario</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedStatement && (
                        <>
                            <h5>Transacciones Registradas</h5>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Fecha</th>
                                        <th>Referencia</th>
                                        <th>Descripción</th>
                                        <th>Monto</th>
                                        <th>Estado</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedStatement.transactions.map((transaction, index) => (
                                        <tr key={index}>
                                            <td>{transaction.date}</td>
                                            <td>{transaction.reference}</td>
                                            <td>{transaction.description}</td>
                                            <td>{transaction.amount}</td>
                                            <td>{transaction.status}</td>
                                            <td>
                                                <Button variant="success" size="sm">
                                                    Conciliar
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <Button variant="primary">Asignar Lote</Button>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDetailsModal(false)}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default BankStatements;
