import axios from 'axios'; 



const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {'Content-Type': "application/json"}, 
});


export class OcrFetchAPI{ 

    static async analyzeImg(data, token){ 
        const res = await server(`/image/ocr`, {
            headers: {
                'Content-Type': "multipart/form-data",
                "x-access-token": token
            },
            method: 'POST', 
            data: data
        }); 
        return res
    }
}