import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
function ModalimgZoom({showModalimgZoom, setShowModalimgZoom, img }) {




    return (
        <>



            <Modal
                centered
                className='bg-blur-sp-2 '
                size="xl"
                show={showModalimgZoom}
                onHide={() => setShowModalimgZoom(false)}
                close>

                <Modal.Body className='bg-blur-sp-2 '>

                    <div className='position-relative '>
                        <img className='img-modal-info ' src={img} alt="" />
                        <button type="button"
                            className="btn-close btn-close-img-info position-absolute top-100 start-100 translate-middle  bg-white" onClick={() => setShowModalimgZoom(false)}></button>
                    </div>

                </Modal.Body>



            </Modal>
        </>
    )
}

export default ModalimgZoom