import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': 'application/json' }
});


export class ExcelModuleFechtAPI {

    //Function to upload Group
    static async UploadBusinessGroup(business_id, data, token) {
        const res = await server(`/excel/upload/products/${business_id}/business`, {
            method: "post",
            data: data,
            headers: { 'Content-Type': 'multipart/form-data', 'x-access-token': token },
        });
        return res
    }

    //Function to get template Group
    static async DownloadTemplateGroups() {
        const res = await server(`/excel/download/excel/product/template`, {
            method: "get",
        });
        return res
    }

    //Function to upload segments
    static async UploadBusinessSegments(business_id, data, token) {
        const res = await server(`/excel/upload/segments/${business_id}/business`, {
            method: "post",
            data: data,
            headers: { 'Content-Type': 'multipart/form-data', 'x-access-token': token },
        });
        return res
    }


    //Function to get template segment
    static async DownloadTemplateSegments(business_id) {
        const res = await server(`/excel/download/excel/segment/template`, {
            method: "get",
        });
        return res
    }



    //Function to upload item
    static async UploadBusinessItem(business_id, data, token) {
        const res = await server(`/excel/upload/items/${business_id}/business`, {
            method: "post",
            data: data,
            headers: { 'Content-Type': 'multipart/form-data', 'x-access-token': token },
        });
        return res
    }

    //Function to get template item
    static async DownloadTemplateItem(business_id) {
        const res = await server(`/excel/download/excel/item/template`, {
            method: "get",
        });
        return res
    }



    //Function to get template item
    static async downloadBankConsolidationReport(matched, unmatched, token) {
        try {
            const res = await server(`/excel/bank/consiliation`, {
                method: "post",
                data: {
                    matched,
                    unmatched
                },
                headers: {
                    "x-access-token": token,
                    "Content-Type": "application/json" // Cambia aquí a JSON para el cuerpo
                },
                responseType: "blob" // Asegúrate de recibir la respuesta como un blob (archivo)
            });

            // Crear un enlace para descargar el archivo
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "conciliacion.xlsx"); // El nombre del archivo a descargar
            document.body.appendChild(link);
            link.click();
            return true
        } catch (error) {
            console.error("Error descargando el archivo", error);
            return false
        }
    }



}