import React from 'react'
import invoice from '../img/icons/receipt.png'
import bill from '../img/icons/bill-blue.png'
import devolution from '../img/icons/return-yw.png'
import { RenderIf } from '../utils/RenderIf'
const TransactionHistory = (props) => {
   //We format the date and hour of the order
   let options = { year: 'numeric', month: 'long', day: 'numeric' };
   const date = new Date(props.data.createdAt).toLocaleDateString('es-Es', options)
   const hour = props.data.createdAt.split('T')[1].split(':')[0] + ':' + props.data.createdAt.split('T')[1].split(':')[1] + ' ' + (props.data.createdAt.split('T')[1].split(':')[0] <= 11 ? 'AM' : 'PM')
   return (
      <>


         {/* <div className=" px-4 py-3 border mx-2" key={props.data.order_id}>
            <div className=' d-flex gap-3'>
               <img className="flex-shrink-0 " src={props.data.order_type === "devolution" ? devolution : bill} alt="twbs" width="32" height="32" />
               <div className="d-flex gap-2 w-100 justify-content-between">
                  <div>
                     <h6 className="mb-0 text-muted">Orden # {props.data.transaction_code}</h6>
                     <h6 className="mt-2"> Total: <strong> {props.data.total.toFixed(2)}</strong></h6>
                  </div>
                  <div>
                     <small className="text-nowrap text-primary fw-bold"> {props.data.order_type}  </small>

                  </div>
                  <small className="text-nowrap text-success">
                     <div> <i className="fa-solid fa-check"></i> Realizada</div>
                     <small className="text-nowrap text-primary ">{props.data.paymenthod}</small>
                  </small>
               </div>
            </div>
            <div className='d-flex gap-2 w-100 justify-content-between'>
               <small className='ms-5 text-muted '><i className="uil uil-calendar-alt"></i>{date}</small>
               <small className='text-muted '> <i className="uil uil-clock"></i>{hour}</small>
            </div>
         </div> */}

         {/* New Card  */}
         <div className="card-ht px-lg-2 px-1 py-2 rounded-4 bg-white" key={props.data.order_id}>
            <div className=' d-flex gap-0'>
               <img className='flex-shrink-0 mx-1 mt-2' width="40" height="40" src={props.data.order_type === "devolution" ? devolution : bill} alt="bill" />
               {/* <img className="flex-shrink-0 " src={props.data.order_type === "devolution" ? devolution : invoice} alt="twbs" width="32" height="32" /> */}
               <div className='w-100 px-lg-2'>
                  {/* 0*/}
                  <div className='d-flex justify-content-between'>
                     <span className={props.data.order_type === "devolution" ? "mb-0 text-warning fw-semibold" : "mb-0 text-primary fw-semibold"}>
                        Orden: #{props.data.transaction_code}
                     </span>

                     <small className='rounded-pill fw-semibold text-on  icon-on px-2 border-0'> <i class="uil uil-check-circle"></i> Realizada</small>
                  </div>

                  {/* 1 */}



                  <div className="d-flex justify-content-between align-items-center border-top mt-1">
                     <div>
                        <small className="text-secondary me-1 fw-light opacity-75 text-uppercase"> {props.data.order_type}  </small>
                     </div>
                     <div className='d-inline-flex align-items-center'>
                        <span className='text-secondary me-1 fw-light opacity-75'> {props.data.paymenthod} </span>
                     </div>
                  </div>
                  {/* 1 */}
                  <div className="d-flex justify-content-between align-items-center">
                     <div className='d-inline-flex align-items-center'>
                        <span className='text-secondary me-1 fw-light opacity-75'>

                           {date}
                        </span>
                     </div>

                     <div className='d-inline-flex align-items-center'>
                        <span className='text-secondary me-1 fw-light opacity-75'>
                           {hour}
                        </span>
                     </div>
                  </div>
                  <div className='border-top'>
                     <div className='d-flex  justify-content-center align-items-center '>
                        <span className='text-card-ht fw-semibold  rounded-3 p-2 h5 text-on'> Total:  {props.data.total.toFixed(2)} $ </span>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   )
}

export default TransactionHistory;