//React components 
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
//Components 
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ErrorAlert from '../Alerts/ErrorAlert';
import { Container } from 'react-bootstrap';
import { RenderIf } from '../utils/RenderIf';
import { PreOrderFetchAPI } from '../../api/Paymentpost/PreOrder';
import { BranchOfficeFetchAPI } from '../../api/BranchOffices';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import DocPdf from '../Pdf/DocPdf';



const OrderDetailPreOrder = ({ orderData, userBranchoffices, handlerUpdatePreOrder, handleCloseModal, statusDetailModalDinamic, branchOfficeData }) => {
    const [newBranchoffice, setNewBranchoffice] = useState({});

    //Total taxes 
    const [totalTaxes, setTotalTaxes] = useState([
        { total_base: 0, tax: "0%", total_tax: 0 },
        { total_base: 0, tax: "7%", total_tax: 0 },
        { total_base: 0, tax: "10%", total_tax: 0 },
        { total_base: 0, tax: "15%", total_tax: 0 }
    ]);

    //Function to populate the users branch 
    const branchList = () => {
        const listBracnh = userBranchoffices.map((branch) => (
            <>
                <option value={JSON.stringify(branch)}>{branch.branchoffice_name}</option>
            </>
        ));
        return listBracnh
    }

    //put preOrder change branchoffice
    const updatePreOrder = async () => {
        if (newBranchoffice != "0") {
            let token = localStorage.getItem('x-access-token')
            let dataBranchoffice = JSON.parse(newBranchoffice);
            try {

                const responseBranchOffice = await BranchOfficeFetchAPI.getBranchOfficesByid(dataBranchoffice.branchoffice_id, token);
                let dataResponseBranchoffice = responseBranchOffice.data.data.branchoffice;
                let data = {
                    "_id": orderData._id,
                    "groups": orderData.groups,
                    "branchoffice": {
                        "id": dataResponseBranchoffice.branchoffice_id,
                        "code": dataResponseBranchoffice.code,
                        "name": dataResponseBranchoffice.name,
                        "ruc": dataResponseBranchoffice.ruc,
                        "dv": dataResponseBranchoffice.dv
                    }
                }

                const response = await PreOrderFetchAPI.updateOrderTempPreOrder(data, token)
                handleCloseModal();
                handlerUpdatePreOrder();
                ErrorAlert("", "Se asigno la precuenta a la otra sucursal", "success")

            } catch (err) {
                ErrorAlert('', 'Lo sentimos ocurrio un error', 'error');
                console.log(err);
            }
        } else {
            ErrorAlert('', 'Debes seleccionar una sucursal', 'info');
        }

    }

    //We charge the items of the order 
    const orderItems = () => {
        let items = orderData.groups.map((product) => (
            <div className="d-flex text-muted pt-3 px-xs-2" key={product._id}>
                <img className="bd-placeholder-img flex-shrink-0 me-2 rounded" src={product.img} width="42" height="42" />
                <div className="pb-3 mb-0  lh-sm  w-100">
                    <div className="d-flex justify-content-between ">
                        {/* Name */}
                        <span className="text-dark fw-bold">{product.name}</span>
                        {/* Cantidad/ Quantity*/}
                        <div>
                            <span className='px-3 label-card'>x {product.amount} </span>
                            <label className="fw-bold text-dark" translate='no'>$ {product.price.toFixed(2)}</label>
                        </div>

                    </div>
                    {/** Segment items */}
                    <>

                        {product.segments.map((segments) => (
                            <div className="d-flex justify-content-between" key={segments._id}>
                                {/* item name */}
                                <span className=" label-card">{segments.item.name}</span>
                            </div>
                        ))}

                        <div className='mt-2'>
                            <div>
                                <span className='label-card p-1' >
                                    Entrega: {product.delivery_type}
                                </span>
                            </div>
                            <div>
                                <RenderIf isTrue={product.note != ""}>
                                    <small className='text-danger p-1' >
                                        Nota: {product.note}
                                    </small>
                                </RenderIf>
                            </div>
                        </div>




                    </>
                </div>
            </div>
        ));
        return <>{items}</>
    }

    return (<>


        <div className='position-relative'>
            <div className='position-absolute top-0 start-100 translate-middle mt-2 '>
                <div className='me-3'>
                    <button class="btn btn-outline-secondary border-0 bg-light text-secondary rounded-circle p-2 lh-1" type="button" title='Cerrar'
                        onClick={() => handleCloseModal()}

                    >
                        <i class="uil uil-multiply"></i>
                    </button>
                </div>
            </div>

            <h2 className="title-main-login  text-center ">
                <i class="uil uil-receipt-alt" translate='no'></i>
                Detalles de Precuenta
            </h2>
            <div className='mx-3 flex-column mb-2'>

                <div className='header-modal-info-pre-order '>
                    <div className='border-dashed  d-flex flex-wrap gap-3 rounded-3 p-0 my-2 p-2'>
                        <div>
                            <span className="text-success">
                                <span className="text-secondary">
                                    <i class="uil uil-receipt-alt"></i> Orden:
                                </span>
                            </span>
                            <span className="fw-bold"> # {orderData.code ? orderData.code : 0}</span>
                        </div>

                        <div className='text-black'>
                            <span className="text-secondary">
                                <i class="uil uil-user-circle"></i> Cliente:
                            </span>
                            <span className="fw-bold"> {!orderData.client_name ? "No Registrado" : orderData.client_name}</span>
                        </div>

                        <div className='text-black'>
                            <span className="text-secondary">
                                <i class="uil uil-location-pin-alt"></i> Modo de entrega:
                            </span>
                            <span className="fw-bold"> {orderData.order_type}</span>
                        </div>
                        <span className="text-nowrap text-black h6 d-block">


                            <span className="text-secondary">
                                <i class="uil uil-store"></i> Sucursal:
                            </span> {orderData.branchoffice.name}
                        </span>

                        <span className="text-nowrap text-black h6 d-block">
                            <i className="fa-solid fa-cash-register"></i>


                            <span className="text-secondary">
                                <i className="fa-solid fa-store"></i> Usuario:
                            </span> {orderData.machine_name} {!orderData.user ? 'No es cajera' : orderData.user.name}
                        </span>
                        {orderData.paymentInfo ? <>
                            {orderData.paymentInfo.map((payment) => (
                                <span className="text-nowrap text-dark h6 d-block">

                                    <span className="text-secondary">
                                        <i className="fa-solid fa-store"></i> Método de pago:
                                    </span> {payment.name}
                                </span>
                            ))}</> : <></>}
                    </div>
                </div>

                <div className='main-modal-info-pre-order'>
                    <div className='row '>
                        <div className='col-12 col-md-8  rounded-3'>
                            <div className=' border-dashed rounded-3 p-2'>
                                <div className='d-flex justify-content-between border-bottom fw-bold label-card mt-2 '>

                                    <span>Producto</span>

                                    <span>Precio</span>

                                </div>
                                {/* Item cards */}
                                {orderItems()}

                                <div className='overflow-auto '>
                                    <RenderIf isTrue={orderData.justification}>
                                        <p className='text-center'><small className='text-danger p-1 ' >
                                            Justificacion: {orderData.justification}
                                        </small></p>
                                    </RenderIf>
                                </div>
                            </div>

                        </div>
                        <div className='col-12 col-md-4 mt-lg-0 mt-2'>
                            <div className=' border-dashed rounded-3 p-2'>
                                <div className='d-flex justify-content-between border-bottom fw-bold label-card mt-2'>
                                    <span>Detalles de orden</span>
                                </div>
                                <div className="flex-column gap-4 text-muted py-2 my-2 ">
                                    <div className="d-flex justify-content-between">
                                        <span className="text-secondary fw-normal"> Descuento: </span>
                                        <span className='text-dark fw-bold'>
                                            <span className='px-2 '>$</span>
                                            {orderData.discount.toFixed(2)}
                                        </span>
                                    </div>

                                    <div className="d-flex justify-content-between">
                                        <span className="text-secondary fw-normal"> Subtotal:  </span>
                                        <span className='text-dark fw-bold'>
                                            <span className='px-2 '>$</span>
                                            {orderData.subtotal.toFixed(2)}
                                        </span>
                                    </div>

                                    {orderData.taxes.map((tax) => (
                                        <>
                                            <div className="d-flex justify-content-between">
                                                <span className="text-secondary fw-normal"> Impuesto({tax.tax}):   </span>
                                                <span className='text-dark fw-bold'>
                                                    <span className='px-2 '>$</span>
                                                    {tax.total.toFixed(2)}
                                                </span>
                                            </div>
                                        </>
                                    ))}

                                    <div className="d-flex justify-content-between border-top">
                                        <span className="text-secondary fw-bold h4 mt-3"> Total:  </span>
                                        <span className='text-dark fw-bold h4 mt-3'>
                                            <span className='px-2 '>$</span>
                                            {orderData.total.toFixed(2)}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='footer-modal-info-pre-order'>
                    <RenderIf isTrue={statusDetailModalDinamic == true}>
                        <div className='d-flex justify-content-between mt-3'>
                            <span className="text-nowrap text-secondary h6 d-block">
                                <div class="tooltip-container">
                                    <i class="uil uil-info-circle"></i>
                                    <span class="tooltip-text">Puedes reasignar la precuenta a una sucursal diferente.</span>
                                </div>   Cambiar sucursal:
                                <form className='filter-sp-trn'>
                                    <div className="d-block">
                                        <select className='form-select' name="user_branch" id="user_branch" onChange={(e) => setNewBranchoffice(e.target.value)}>
                                            <option value={0}> Seleccione una sucursal</option>
                                            {branchList()}
                                        </select>
                                    </div>
                                </form>
                            </span>
                        </div>
                        <div className='text-end'>
                            <button type="button" class="btn btn-primary" onClick={async () => updatePreOrder()}  >Guardar</button>
                        </div>
                    </RenderIf>


                    {orderData.paymentInfo ?
                        <>
                            <PDFDownloadLink className='pdf-viewer-sp' document={<DocPdf
                                doc_type={"invoice"} invoiceQR={orderData.dgi_qr} invoiceDate={orderData.createdAt} cufe={orderData.cufe} auth_protocol={orderData.auth_protocol ? orderData.auth_protocol : "00000000"}
                                receptor={{
                                    name: orderData.client_name,
                                    ruc: orderData.client_ruc,
                                    direction: "Panama",
                                    dv: orderData.client_dv,
                                }}
                                emitter={branchOfficeData}
                                products={orderData.groups} invoiceSubtotal={orderData.subtotal} totalTaxes={totalTaxes}
                                totalCashReceived={orderData.total} invoiceTotal={orderData.total} invoiceTotalItbms={orderData.tax} paymentMethod={orderData.paymenthod}
                            />} >
                                <button className='btn btn-success  rounded-2 mt-2' > <i class="uil uil-file-download"></i>  Descargar Factura Electrónica </button>
                            </PDFDownloadLink>
                        </> : <></>}
                </div>

            </div >
        </div>

    </>);
}

export default OrderDetailPreOrder;