import React, { useEffect, useState, useContext } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
//Context
import UserContext from "../../context/UserContext";
//Api 
import { AuthFetchAPI } from '../../api/Auth';
import { UsersFetchAPI } from '../../api/Users';
import { BusinessFetchAPI } from '../../api/Business';
//Components 
import Swal from "sweetalert2";
//Img
import logo_sp from '../../assets/img/logos/blue.png';
//CSS
import "./login.css";
import Particles from '../../utils/Particles/Particles';
import Aurora from '../../utils/Aurora/Aurora';

const Login = () => {
    //States 
    const [businessData, setBusinessData] = useState({
        name: '',
        id: 0
    });
    const [eventListener, setEventListener] = useState(0)
    const [selectedBranch, setSelectedBranch] = useState();
    const [users, setUsers] = useState([]);
    //Context 
    const { setUserData } = useContext(UserContext);
    //Params
    const { business_code, branchoffice_code } = useParams();
    //Navigation
    const navigate = useNavigate();
    //Location page 
    const url_path = useLocation();

    //Function to get the initial data 
    const getInitialData = async (business_code) => {
        //Variables
        let response_business = '';
        try {
            //We get the business
            response_business = await BusinessFetchAPI.getBusinessByCode(business_code)
            //We set the business data 
            setBusinessData(response_business.data.business)
        } catch (err) {
            //If we get some error we redirect to a no mactch page 
            navigate('/nomatch')
        }

        //We set the handler to force to charge the state of the business and branchoffice 
        setEventListener(1)
    }

    //Function to get the users of a branchoffice or a business depending the role 
    const getUsers = async (branchoffice_id, branch_code) => {
        try {
            //Variables 
            let user_role_id;
            let response;
            //We check what type of login
            if (url_path.pathname.includes('cashier')) {
                user_role_id = 4 //Cashier role 
            } else if (url_path.pathname.includes('dispatcher')) {
                user_role_id = 5 //Dispatcher role
            } else {
                user_role_id = 3; //Admin role 
            }

            //We check if the role is tha admin role to make the consult 
            if (user_role_id === 3) {
                //If the role is admin we just get the admin users by business 
                response = await UsersFetchAPI.getUsersByBusinessAndRole(businessData.id, [3, 6, 7, 8]);
            } else {
                //We get the users by branch and role 
                response = await UsersFetchAPI.getUsersByRoleAndBranchoffice(branchoffice_id, user_role_id);
                setSelectedBranch(branch_code);
            }
            setUsers(response.data.users);

        } catch (err) {
            //If we get some error we set the users data empty 
            setUsers([])
            setSelectedBranch(null)
        }
    }

    //Event to login
    const submit = async (e) => {
        e.preventDefault();

        localStorage.setItem('x-access-token', "");
        localStorage.removeItem('user');
        localStorage.removeItem('branchoffice_selected');
        localStorage.removeItem('branchoffice');
        //We check if the user check one branch office 
        if ((url_path.pathname.includes('cashier') && selectedBranch !== null) || (url_path.pathname.includes('dispatcher') && selectedBranch !== null) || (!url_path.pathname.includes('dispatcher') || !url_path.pathname.includes('cashier') && selectedBranch === undefined || selectedBranch === null)) {
            const form = new FormData(e.target);
            let user_logged = Object.fromEntries(form.entries());
            user_logged.business_id = businessData.id;
            try {
                const response = await AuthFetchAPI.login(user_logged);
                //We get the token and user data 
                let token = response.data.token;
                let user = response.data.user;
                //We set the user info and the token access 
                localStorage.setItem('user', JSON.stringify(user));
                localStorage.setItem('x-access-token', token);
                localStorage.setItem('branchoffice', "");
                //We set the context of the user 
                setUserData({ token, user: user });

                //We check the role of the user and depending of it we redirect 
                if (user.role.find(element => element.role_id === 4) !== undefined) {
                    navigate(`/cashier/${business_code}/${selectedBranch}`)
                } else if (user.role.find(element => element.role_id === 5) !== undefined) {
                    navigate(`/orders_customers/${business_code}/${selectedBranch}`)
                } else if (user.role.find(element => element.role_id === 7 || element.role_id === 3 || element.role_id === 6) !== undefined) {
                    navigate(`/transactions/${business_code}`)
                } else if (user.role.find(element => element.role_id === 8) !== undefined) {
                    navigate(`/products/${business_code}`)
                }

            } catch (err) {
                Swal.fire({
                    icon: "error",
                    title: err.response.data.error,
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } else {
            //If the user dont select any branch we throw an error
            Swal.fire({
                icon: "error",
                title: 'Seleccione una sucursal',
                showConfirmButton: false,
                timer: 1500,
            });
        }

    }

    //Event valid sesion of the machine
    /* const authValidSesion = () => {
         if (localStorage.getItem("x-access-machine-token") != "") {
             let business = JSON.parse(localStorage.getItem("business"))
             let branchoffice_info = JSON.parse(localStorage.getItem("branchoffice"))
             navigate(`/payments/${business.code}/${branchoffice_info.code}`)
         }
     }*/

    //Hook to get data when the page is loaded
    useEffect(() => {
        //We get the business by code 
        getInitialData(business_code);
        if (branchoffice_code === undefined) {
            getUsers(0, 0);
        }

    }, [eventListener]);

    //Function to list the users of branchoffice
    const listUsers = () => {
        let users_list;
        if (users !== undefined) {
            users_list = users.map((data) => (
                <>
                    <input type="radio" className="btn-check" name="email" id={data.user_id} value={data.email} />
                    <label className="btn btn-outline-dark border
                    rounded-3 fw-semibold" for={data.user_id}>{data.user_name}</label>
                </>
            ))
        } else {
            users_list = ''
        }

        //We check the users list
        return (<>{users_list} </>)
    }

    const [showPassword, setShowPassword] = useState('')
    const toogglePasword = () => {
        setShowPassword(!showPassword);
    }


    useEffect(() => {

        let urlNow = window.location.href + ""
        let arrayParams = urlNow.split('/');
        console.log(arrayParams[3] + " " + arrayParams[4]);

        if (arrayParams[3] == "machines" && arrayParams[4] == "login") {
            console.log("entre");

            if (localStorage.getItem("x-access-token") == "" || localStorage.getItem("x-access-token") == undefined || localStorage.getItem("x-access-machine-token") == "" || localStorage.getItem("x-access-machine-token") == undefined || localStorage.getItem("branchoffice") == "" || localStorage.getItem("branchoffice") == undefined || localStorage.getItem("machine") == "" || localStorage.getItem("machine") == undefined || localStorage.getItem("user") == "" || localStorage.getItem("user") == undefined) {

            } else {

                let branchoffice = JSON.parse(localStorage.getItem('branchoffice'));
                let machine = JSON.parse(localStorage.getItem('machine'));
                let business = JSON.parse(localStorage.getItem("business"))

                //console.log(`/kiosk/${business.code}/${branchoffice.code}`);
                navigate(`/payments/${business.code}/${branchoffice.code}`)
            }
        }

    }, []);

    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

    // Opcional: Si quieres actualizar el año automáticamente cada año, puedes usar useEffect.
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentYear(new Date().getFullYear());
        }, 60000); // Actualiza cada minuto, ajusta según necesites
        return () => clearInterval(interval);
    }, []);

    return (
        <>


            <main className="dashboard-login-sp ">
                <Aurora
                    colorStops={["#1b1d5c", "#102e95", "#1b1d5c"]}
                    blend={0.5}
                    amplitude={1.0}
                    speed={1.2}
                />
                <div className='container login-container'>

                    {/* <div className="position-absolute top-0 start-0 bg-login">
                        <span className='text-white'>¡Bienvenido a </span>
                        <span className='text-white'>Smart Pay </span> 😄!
                    </div> */}

                    <div className='row align-items-center'>
                        <div className="col-12 col-md-6 col-lg-12 col-xl-6 info-display    position-relative order-xxl-0 order-1 h-100 ">
                            <div className="d-flex justify-content-center info-bussiness p-3 align-items-center">
                                <div>
                                    {/* <img
                                        className="img-logo-brand  img-fluid rounded"
                                        src={logo_sp}
                                        alt="Logo Smart Pay "
                                        loading="lazy"
                                    /> */}

                                    <h2 className="h1 mb-4 fw-bold text-white">Smart Pay</h2>
                                    <p className="lead mb-5 fw-semibold text-white ">Transformando la gestión y  eficiencia de tu negocio,
                                        impulsando su crecimiento a través de soluciones innovadoras.</p>
                                    <div className="text-endx">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" className="bi bi-grip-horizontal" viewBox="0 0 16 16">
                                            <path d="M2 8a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>



                        </div>
                        <div className="col-12 col-md-12 col-lg-12 col-xl-6 justify-content-center align-items-lg-center order-xxl-1 order-0">
                            <div className="border-0 rounded-4">
                                <div className=" p-0">
                                    <div className='main-login-sp mt-lg-4'>
                                        <section className="form-signin-sp bg-white pt-">
                                            <div className="d-flex justify-content-center  justify-content-lg-center">
                                                <img
                                                    className="img-logo-brand "
                                                    src={logo_sp}
                                                    alt="Logo Smart Pay "
                                                />

                                            </div>
                                            <div>

                                                <h2 className="title-main-login  text-center ">Iniciar Sesión</h2>
                                                <div className='d-flex justify-content-center'>
                                                    <h5 className="text-dark-50 text-center opacity-50 rounded-3 border px-2">
                                                        <i className="uil uil-store"></i>
                                                        {businessData.name}
                                                    </h5>
                                                </div>

                                                <form className=' pb-4' onSubmit={submit} noValidate>
                                                    <label className="form-label fw-bold text-black">
                                                        <i className="uil uil-store"></i> Usuarios
                                                    </label>
                                                    <div className='login-wrapper  no-scroll'>
                                                        <div className='d-flex flex-column gap-1 p-2 bg-body' >
                                                            {listUsers()}
                                                        </div>
                                                    </div>

                                                    <div className="mt-1">
                                                        <label className='form-label fw-bold text-black' htmlFor="floatingPassword">{!showPassword ? <i className="uil uil-lock-alt"></i> : <i className="uil uil-lock-open-alt"></i>} Contraseña</label>

                                                        <input
                                                            type={showPassword ? 'text' : 'password'}
                                                            className="form-control rounded-3 border-1 text-muted"
                                                            id="password"
                                                            name='pass'
                                                            placeholder="Ingresar contraseña"
                                                            required
                                                            spellcheck="false"
                                                        />
                                                    </div>

                                                    <div className="form-check text-start my-3">
                                                        <input className="form-check-input" type="checkbox"
                                                            value="remember-me" id="flexCheckDefault"
                                                            onChange={toogglePasword}
                                                        />
                                                        <label className="form-check-label" for="flexCheckDefault">
                                                            Mostrar Contraseña
                                                        </label>
                                                    </div>
                                                    <button className="control" type="submit">
                                                        <span className='rotated-icon'>
                                                            <i className="uil uil-message "></i>
                                                        </span>
                                                        Ingresar
                                                    </button>
                                                </form>
                                            </div>

                                            <footer className=''>
                                                <div className='container d-lg-flex justify-content-between align-content-center align-items-center w-100 text-center text-secondary'>
                                                    <div className='my-2'>
                                                        <small className='opacity-50'>
                                                            <i class="uil uil-copyright"></i> {currentYear} Smart Pay
                                                        </small>
                                                    </div>
                                                    <div>

                                                        <small className='opacity-50'>
                                                            <i class="uil uil-bolt"></i> Powered by Smart Retail Group
                                                        </small>
                                                    </div>
                                                </div>

                                            </footer>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            {/* <main className='d-flex align-items-center align-content-end vh-100'>
                <div class="container my-5 w-50 ">
                    <section class="d-flex justify-content-center align-items-center position-relative border rounded-4 bg-success-subtle">
                        <div class="d-block">
                            <div className='d-flex justify-content-center mt-3'>
                                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48">
                                    <path fill="none" stroke="#198754" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M34.3,39.4c-2.9,2-6.5,3.1-10.3,3.1C13.8,42.5,5.5,34.2,5.5,24c0-4.4,1.6-8.5,4.1-11.7"></path><path fill="none" stroke="#198754" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M20.1,5.9c1.3-0.3,2.6-0.4,3.9-0.4c10.2,0,18.5,8.3,18.5,18.5c0,2.9-0.7,5.6-1.8,8"></path><polyline fill="none" stroke="#198754" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" points="16.5,24.5 21.5,29.5 31.5,19.5"></polyline>
                                </svg>
                            </div>
                            <h1 class="display-5 fw-bold mt-3 text-center text-success " id="paymentStatus">¡Éxito!</h1>
                            <div class="flex-column justify-content-center p-2">
                                <h5 class=" mb-1 text-center text-wrap text-success-emphasis" id="reason">
                                    Su pago ha sido procesado correctamente.
                                </h5>
                                <p class=" mb-4 text-dark-emphasis  text-center p-2 opacity-75 text-wrap" id="userinfo">

                                </p>
                                <div className='d-flex justify-content-center'>
                                    <button type="button" id="closePage"
                                        class="btn btn-success btn-lg px-4 gap-3">
                                        OK
                                    </button>
                                </div>
                            </div>
                            <span id="paymentMethodTitle"
                                class="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-success">
                                Método de Pago: YAPPY
                            </span>
                        </div>
                    </section>
                </div>


            </main> */}

        </>
    )
}

export default Login