import axios from "axios";


const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': "application/json" },
});


export class BankConsilationFetchAPI {

    //Function to make the consiltation of the bank
    static async getConsilationBankReport(file, startDate, endDate, selectedColumn, token) {
        const res = await server(`/bank/consiliation`, {
            method: "post",
            data: {
                files: file,
                startDate, endDate,
                referencedColum: selectedColumn
            },
            headers: {
                "x-access-token": token,
                'Content-Type': 'multipart/form-data',
            }
        });
        return res
    }

    //Function to make the consolidation of the transacionts by lote 
    static async getConsilationLoteReport(data, token) {
        const res = await server(`/bank/consolidate-transactions`, {
            method: "post",
            data: data,
            headers: {
                "x-access-token": token,
            }
        });
        return res
    }


    //Function to make the consolidation of the transacionts by lote 
    static async getConsilationBankStatement(data, token) {
        const res = await server(`/bank/consolidate-batchs`, {
            method: "post",
            data: data,
            headers: {
                "x-access-token": token,
            }
        });
        return res
    }

    //Function to save the consilated transaction bank bactch 
    static async saveBankConciliationBatch(data, token) {
        const res = await server(`/consiliation/batch/save`, {
            method: "post",
            data: data,
            headers: {
                "x-access-token": token,
            }
        })
        return res
    }


    //Function to save the consilated transaction bank bactch 
    static async saveBankConciliation(data, token) {
        const res = await server(`/bank/consiliation`, {
            method: "post",
            data: data,
            headers: {
                "x-access-token": token,
                'Content-Type': 'multipart/form-data',
            }
        })
        return res
    }



    //Function to get the bank conciliation batches 
    static async getBankConciliationBatches(token) {
        const res = await server(`/consiliation/batch`, {
            method: "get",
            headers: {
                "x-access-token": token,
            }
        })
        return res
    }

    //Function to get the bank conciliation history
    static async getBankConsiliationHistory(startDate, endDate, token) {
        const res = await server(`/consiliation/history?startDate=${startDate}&endDate=${endDate}`, {
            method: "get",
            headers: {
                "x-access-token": token,
            }
        })
        return res
    }

    //Function to get the bank statements templates 
    static async getBankStatementsTemplates(token, bankName, business_code) {
        const res = await server(`/bankstatement/template/format?bank_name=${bankName}&business_code=${business_code}`, {
            method: "get",
            headers: {
                "x-access-token": token,
            }
        })
        return res
    }

    //Function to get the bank statements
    static async getBankStatements(token, business_code) {
        const res = await server(`/bankstatement?business_code=${business_code}`, {
            method: "get",
            headers: {
                "x-access-token": token,
            }
        })
        return res
    }

    //Function to save a bank statement 
    static async saveBankStatement(token, bankName, business_code, file, template_colums) {    
        const res = await server(`/bankstatement`, {
            method: "post",
            data: {
                bank_name: bankName,
                business_code,
                files: file, 
                template_columns: template_colums
            },
            headers: {
                "x-access-token": token,
                'Content-Type': 'multipart/form-data',
            }
        })
        return res
    }

    //Function to save a bank statement template 
    static async saveBankStatementTemplate(token, bankName, business_code, template) {
        const res = await server(`/bankstatement/template/format`,
            {
                method: "post",
                data: {
                    bank_name: bankName,
                    business_code,
                    template
                },
                headers: {
                    "x-access-token": token,
                }
            })
        return res
    }


}