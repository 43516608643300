import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': "application/json" },
});

export class PaymentMethodFetchAPI {

    static async getPaymentMethod(machine_id, token) {
        const res = await server(`/machine/${machine_id}/paymentmethod?status=true`, {
            method: "get",
            headers: {'x-access-token': token},
        });
        return res
    }

    static async updatePaymentMethod(machine_id, payment_method_id, integration_id, status, token) {
        const res = await server(`/paymentmethod/${payment_method_id}/paymentmethod/${machine_id}/machine`, {
            method: "put",
            headers: {'x-access-token': token},
            data: {
                integration_id, status
            },	
        });
        return res
    }

    static async removePaymentMethod(machine_id, payment_method_id, integration_id, token) {
        const res = await server(`/paymentmethod/${payment_method_id}/paymentmethod/${machine_id}/machine`, {
            method: "delete",
            headers: {'x-access-token': token},
            data: {
                integration_id
            }
        });
        return res
    }

    static async getPaymentMethodIntegrations(token) {
        const res = await server(`/paymentmethod/integrations`, {
            method: "get",
            headers: {'x-access-token': token},
        });
        return res
    }

    //Function to get the payment method by integration
    static async getPaymentMethodByIntegration(integration_id, token) {
        const res = await server(`/paymentmethod/integrations/${integration_id}`, {
            method: "get",
            headers: {'x-access-token': token},
        });
        return res
    }

    //Function to get the payment methods params by payment method and integration
    static async getPaymentMethodParams(payment_method_id, integration_id, token) {
        const res = await server(`/paymentmethod/params/${payment_method_id}/${integration_id}`, {
            method: "get",
            headers: {'x-access-token': token},
        });
        return res
    }

    //Function to asing the payment method to the machine
    static async asingPaymentMethodToMachine(machine_id, payment_id, data, token) {
        const res = await server(`/paymentmethod/${payment_id}/paymentmethod/${machine_id}/machine`, {
            method: "post",
            headers: {'x-access-token': token},
            data: data
        });
        return res
    }

    //Function to update the payment method param value 
    static async updatePaymentMethodParamValue(param_value_id, param_value, machine_id, token) {
        const res = await server(`/machine/paymentmethod/param/value`, {
            method: "put",
            headers: {'x-access-token': token},
            data: {
                param_value_id, param_value, machine_id
            }
        });
        return res
    }
}