import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';

import ErrorAlert from '../../Alerts/ErrorAlert';
import { Badge } from 'react-bootstrap';
//Icon
import pencil from '../../img/icons/pencil.png'

import { ListGroup } from 'react-bootstrap';
import { CategoriesDashFetchAPI } from '../../../api/CategoriesDash';

function ModalEditCategorie({ category, loadCategories }) {
    const [showModal, setShowModal] = useState(false)
    const [eventHandle, setEventHandle] = useState(0);
    const [typeCategory, setTypeCategory] = useState([])
    const [notifications, setnotifications] = useState([])
    const [imgpreview, setImgpreview] = useState(category.img)
    let token = localStorage.getItem("x-access-token")

    let user = localStorage.getItem('user');

    //Function to register the product 
    const submit = async (e) => {
        e.preventDefault();
        user = JSON.parse(localStorage.getItem('user'))
        const form = new FormData(e.target);
        const createCategorie = Object.fromEntries(form.entries());

        try {
            //We update the data 
            let response = await CategoriesDashFetchAPI.UpdateCategories(createCategorie, category.category_id, token);
            //We show the success alert 
            console.log(response)

            ErrorAlert('', 'Categoria editada con exito', 'success')
            loadCategories();
            setShowModal(false);
            setImgpreview(category.img)
        } catch (err) {
            setImgpreview(category.img)
            ErrorAlert('', 'Error en la edicion', 'error')
            console.log(err)
        }
    }

    const getCategoryType = async () => {
        try {
            const response = await CategoriesDashFetchAPI.getCategoryType(token);
            setTypeCategory(response.data.categorie_types)
        } catch (err) {
            console.log(err)
        }
    }

    const viewTypeCategory = () => {
        const view = typeCategory.map((type) => (
            <><option value={type.id}>{type.name}</option></>
        ));

        return (view)
    }



    const changeimg = (e) => {
        setImgpreview(URL.createObjectURL(e.target.files[0]))
    }





    useEffect(() => {
        setEventHandle(0)

    }, [eventHandle])




    return (
        <>
            <button type="button" className='btn btn-outline-light pulse rounded-3 p-2 lh-1 border'
                tooltip="Editar Categoria" flow="up"
                onClick={() => (setShowModal(true), getCategoryType())}
            >
                <img className='icon-options-cards' src={pencil} alt="Edit" />

            </button>

            <Modal
                show={showModal}
                onHide={() => (setShowModal(false), setImgpreview(category.img))}
                size='lg'
                centered>

                <Modal.Body >
                    <div className="position-relative">
                        <div className='position-absolute top-0 start-100 translate-middle mt-2 '>
                            <div className='me-3'>
                                <button class="btn btn-outline-secondary border-0 bg-light text-secondary rounded-circle p-2 lh-1" type="button" title='Cerrar'
                                    onClick={() => (setShowModal(false), setImgpreview(category.img))}

                                >
                                    <i class="uil uil-multiply"></i>
                                </button>
                            </div>
                        </div>

                        <h2 className="title-main-login text-center ">
                            Editar Categoria
                        </h2>

                        <div className='row'>
                            <div className="col-md-7">
                                <div className='border-dashed border-primary rounded-3 p-2 bg-light-subtle'>
                                    <div className='d-flex align-item-center justify-content-center'  >
                                        <img
                                            className='imgEditModal border-dashed'
                                            src={imgpreview}
                                            alt={category.category_name}

                                        />
                                    </div>

                                    <div className='d-flex align-item-center justify-content-center m-3'>
                                        <label htmlFor="upload-button" className='border-dashed border-primary rounded-3 p-2 bg-primary-subtle'>
                                            <span className="text-center text-primary pulse fw-semibold" role="button" > <i class="uil uil-image-upload"></i> Actualizar Imagen </span>
                                        </label>
                                    </div>
                                </div>


                            </div>
                            <div className="col-md-5">

                                <Form
                                    className="needs-validation"
                                    onSubmit={submit}
                                >
                                    <Form.Group controlId="formFileMultiple">

                                        <Form.Control
                                            id="upload-button"
                                            type="file"
                                            placeholder="Imagen"
                                            name="files"
                                            onChange={(e) => changeimg(e)}
                                            hidden
                                        />
                                    </Form.Group>


                                    <Form.Group className="mb-3" controlId="name">
                                        <Form.Label>Nombre</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder={category.category_name}
                                            name="name"

                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="secundaryName">
                                        <Form.Label>Descripción</Form.Label>
                                        <Form.Control
                                            as="textarea" rows={8}
                                            placeholder={category.secundaryName}
                                            name="description"

                                        />
                                    </Form.Group>



                                    {/*<Form.Group className="mb-3" controlId="clientId">
                            <Form.Label>Tipo de categoria</Form.Label>
                            <Form.Select aria-label="" name="category_type">
                                <option>Seleccione un tipo</option>
                                {viewTypeCategory()}
                            </Form.Select>
                </Form.Group>*/}

                                    <div className="d-flex justify-content-end gap-2">
                                        <button type='button' className='btn btn-secondary bg-light text-secondary border-0 fw-semibold'
                                            onClick={() => (setShowModal(false), setImgpreview(category.img))}>
                                            <i class="uil uil-times me-1"></i>   Cerrar
                                        </button>
                                        <button className='btn btn-save-sp fw-bold' type="submit">
                                            <i class="uil uil-save"></i>  Guardar
                                        </button>
                                    </div>
                                </Form>

                            </div>
                        </div>
                    </div>




                </Modal.Body>
            </Modal >

        </>
    )
}

export default ModalEditCategorie