import React, { useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";



const EditConsiliatedTransactionModal = ({ showModal, handleClose, transaction, updateTransaction }) => {
    const [editedTransaction, setEditedTransaction] = useState(transaction || {});

    useEffect(() => {
        console.log(transaction);
        setEditedTransaction(transaction || {});
    }, [transaction]);

    const handleSave = () => {
        updateTransaction(editedTransaction);
        handleClose();
    }


    return (
        <>
            <Modal show={showModal} onHide={handleClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Editar Transacción Conciliada</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Propina</Form.Label>
                            <Form.Control
                                type="number"
                                value={editedTransaction.tips || 0}
                                onChange={(e) =>
                                    setEditedTransaction({ ...editedTransaction, total: e.target.value })
                                }
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Impuesto</Form.Label>
                            <Form.Control
                                type="number"
                                value={editedTransaction.tax || 0}
                                onChange={(e) =>
                                    setEditedTransaction({ ...editedTransaction, total: e.target.value })
                                }
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Total contable (sin propina)</Form.Label>
                            <Form.Control
                                type="number"
                                value={editedTransaction.total || ""}
                                onChange={(e) =>
                                    setEditedTransaction({ ...editedTransaction, total: e.target.value })
                                }
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Total final(el que aparece en el excel bancario)</Form.Label>
                            <Form.Control
                                type="number"
                                value={editedTransaction.total_with_tips || ""}
                                onChange={(e) =>
                                    setEditedTransaction({ ...editedTransaction, total_with_tips: e.target.value })
                                }
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                        Guardar Cambios
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );

}

export default EditConsiliatedTransactionModal;