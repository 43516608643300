//React components 
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';

//----Components 
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Tabs, Tab } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import TransactionPill from "./TransactionPill";
import TransactionHistory from "./TransactionHistory";
import Navbar from '../NavBar/Navbar';
import Modal from 'react-bootstrap/Modal';
import LoadingAlert from '../Alerts/LoadingAlert';
import SuccessAlert from '../Alerts/SuccessAlert';
import ErrorAlert from '../Alerts/ErrorAlert';
import OrderDetailsTemporal from '../Orders/OrderDetailsTemporal';
import OrderDetailPreOrder from '../Orders/OrderDetailPreOrder';
import OrderDetails from '../Orders/OrderDetails';
import ModalBranchOffice from '../NavBar/ModalBranchofficeSelect';

//------APIS
import { PreOrderFetchAPI } from '../../api/Paymentpost/PreOrder';
import { MachinesFetchAPI } from '../../api/Machines';
import { DeletedTempOrdersFetchAPI } from '../../api/DeletedTemporalOrders';
import { OrdersFetchAPI } from '../../api/Orders';
import { BranchOfficeFetchAPI } from '../../api/BranchOffices';

//----IMG
import cashback from '../img/icons/cashback.png'
import refund from '../img/icons/refund.png'
import deleted from '../img/icons/deleted.png'
import success from '../img/icons/success.png'
import empty from '../img/icons/empty.png'
import update from '../img/icons/update-list.png';

import "../Orders/order.css"

//External modules 
import moment from 'moment-timezone';
import Swal from 'sweetalert2';
import Footer from '../Footer/Footer';
import ModalBranchofficeSelect from '../NavBar/ModalBranchofficeSelect';

function TransactionsTemporal() {
   //Params
   let { business_code } = useParams();
   //We get the user data 
   let user = JSON.parse(localStorage.getItem('user'));
   let token = localStorage.getItem('x-access-token');
   //States
   const [branchOrdersData, setBranchOrdersData] = useState([]);
   const [temporal, setTemporal] = useState([]);
   const [userBranchoffices, setUserBranchs] = useState([]);
   const [userBusiness, setUserBusiness] = useState({});
   const [selectedBranch, setSelectedBranch] = useState({
      code: user.branchoffices[0].code
   });
   const [temporalOrdersData, setTemporalOrdersData] = useState([]);
   const [temporalOrdersDeletedData, setTemporalOrdersDeletedData] = useState([]);
   const [temporalOrdersSuccessData, setTemporalOrdersSuccessData] = useState([]);
   const [detailOrder, setDetailOrder] = useState();
   const [order_type, setOrderType] = useState("temporal")
   const [modalShow, setModalShow] = useState(false);
   const [reload, setReLoad] = useState();
   const [orderData, setOrderData] = useState({});
   const [minDate, setMinDate] = useState(moment(new Date()).tz('America/Bogota').format('YYYY-MM-DD') + 'T00:00:00.000+00:00');
   const [maxDate, setMaxDate] = useState(moment(new Date()).tz('America/Bogota').format('YYYY-MM-DD') + 'T23:59:59.000+00:00');
   const [statusDetailModalDinamic, setStatusDetailDinamic] = useState(true);
   const [paymentInfo, setPaymentInfo] = useState({});
   const [branchOfficeDataOrder, setBranchofficeDataOrder] = useState({
      "ruc": "",
      "dv": "",
      "name": "",
      "ruc_type": "",
      "direction": "",
      "dgi_name": "",
   });


   //Variables 
   //We get the actual date 
   const actualDate = moment(new Date()).tz('America/Bogota').format('YYYY-MM-DD')



   //Functions to show and hide the modal 
   const handleCloseModal = () => setModalShow(false);
   const handleShowModal = () => setModalShow(true);

   //Function to populate the users branch 
   const branchList = () => {
      const listBracnh = userBranchoffices.map((branch, index) => (
         <>
            <option value={branch.code}>{branch.branchoffice_name}</option>
         </>
      ));
      return listBracnh
   }

   //Function to gen the empty card to the transaction history 
   const EmptyCard = () => {
      return (
         <div className="col-12 ">
            <div className="border-history d-flex justify-content-md-center py-4 px-4 " aria-current="true">
               <div className="d-flex w-100 justify-content-center align-items-center  flex-column">
                  <img src={empty} alt="Vacio" width="35px" height="35px" />
                  <div className='text-center mt-3'>
                     <h5>Vacío</h5>
                     <h6 className="mb-0 text-secondary fw-bold">No hay transacciones </h6>
                  </div>
               </div>
            </div>
         </div>
      )
   }

   const actionSelectBranch = (branchCode) => {
      setSelectedBranch({ code: branchCode })
   }



   /*---------------------------------------------PRE ORDERS PENDING------------------------------------------------------------------------------------------------ */

   //get pre orders
   const getPreOrder = async (business_code, branch_code, token) => {
      try {
         const response = await PreOrderFetchAPI.getPreOrder(business_code, branch_code, token)
         console.log(response.data.pre_orders);

         let dataPreOrder = response.data.pre_orders

         let newArrayPreorder = []
         dataPreOrder.forEach(order => {
            if (selectedBranch.code == order.branchoffice.code) {
               newArrayPreorder.push(order)
            }
         });

         setBranchOrdersData(newArrayPreorder);
         //This are for the discount temporal orders 
         setTemporalOrdersData(newArrayPreorder);
         //This are for the search filter
         setTemporal(newArrayPreorder);
      } catch (err) {
         console.log(err);
         setBranchOrdersData([]);
         //This are for the discount temporal orders 
         setTemporalOrdersData([]);
         //This are for the search filter
         setTemporal([]);
         //ErrorAlert('', 'No hay precuentas abriertas', 'info');
      }
   }

   //Function to populate the temporal list orders 
   const listTemporalOrders = () => {
      const temporalOrders = temporalOrdersData.map((data) => (
         <>

            <div className='col-12 col-sm-6  col-md-6 col-lg-4 col-xl-4 ' onClick={() => { setOrderData(data); setStatusDetailDinamic(true); handleShowModal() }} >


               <div class="card border rounded-4 mt-4 p-2">
                  <h5 class="card-header alert alert-warning p-2 m-0 rounded-3  border-0 d-flex justify-content-between">
                     <span className=''>
                        <i class="uil uil-sync-exclamation"></i>   Orden #{data.code}
                     </span>

                     <span className='text-nowrap'>
                        Total: <strong > {data.total.toFixed(2)} $ </strong >
                     </span>

                  </h5>
                  <div class="card-body m-0 p-0  rounded-3 mt-2">
                     <div class="card-title border-dashed rounded-3  p-2">
                        <span className='label-card'>
                           <i class="uil uil-chat-bubble-user"></i>    Cliente:
                        </span> {!data.client_name ? "No Ingresado" : data.client_name}

                     </div>
                     <div class="card-text">

                        <div className='p-2 border-dashed  rounded-3'>
                           {/* <h6 className="fw-semibold text-dark-800 text-center ">
                              Información de precuenta
                           </h6> */}
                           <div className='d-flex flex-wrap gap-3'>
                              <span className="text-nowrap h6">
                                 <span className='label-card'>

                                    <i class="uil uil-store"></i>   Sucursal: </span>{data.branchoffice.name}
                              </span>

                              <span className="text-nowrap  h6 text-dark-800">
                                 <span className='label-card'>
                                    <i class="uil uil-desktop-alt"></i>  Máquina:

                                 </span> {data.machine_code}
                              </span>
                              <span>

                              </span>
                              <span className="text-nowrap  text-dark-800 h6 d-block mb-2">
                                 <span className='label-card'>
                                    <i class="uil uil-user"></i>  Usuario:

                                 </span>  {!data.machine_name ? 'No es caja' : data.machine_name} / {!data.user ? 'No es cajera' : data.user.name}
                              </span>
                           </div>
                        </div>

                        <div className='p-2 border-dashed  rounded-3 mt-2'>
                           {/* <h6 className="fw-semibold text-secondary">
                              Creación de precuenta
                           </h6> */}
                           <div className='d-flex flex-wrap gap-3'>
                              <span>

                                 <span className='label-card'>
                                    <i class="uil uil-calender"></i>   Fecha:
                                 </span> {data.createdAt.split("T")[0]}
                              </span>
                              <span>
                                 <span className='label-card'>
                                    <i class="uil uil-clock"></i>  Hora:
                                 </span> {data.createdAt.split("T")[1].split(".")[0]}
                              </span>
                           </div>
                        </div>
                     </div>

                     <div className='d-flex justify-content-end mt-2'>
                        <button class="btn btn-outline-primary border-0 opacity-50"
                           onClick={() => { setOrderData(data); setStatusDetailDinamic(true); handleShowModal() }} >
                           Ver más
                        </button>
                     </div>
                  </div>
               </div>
            </div>
            {/* End Cart */}

         </>
      ));
      if (temporalOrders.length === 0) {
         return EmptyCard()
      } else {
         return (temporalOrders)
      }
   }

   //update preOrders of the branchoffice
   const handlerUpdatePreOrder = () => {
      getPreOrder(business_code, selectedBranch.code, token);
   }


   /*---------------------------------------DELETED TRANSACTIONS------------------------------------------------------------------------------------------------------ */


   // function get order deleted
   const getDeleteTemporal = async () => {
      try {
         const response = await DeletedTempOrdersFetchAPI.getDeletedTransactionReport(business_code, selectedBranch.code, minDate, maxDate, 0, 0, token);
         console.log(response.data.temp_orders);


         let dataOrders = response.data.temp_orders;

         let dataSuccess = []
         let dataDeleted = []
         dataOrders.forEach((item) => {
            if (item.deleted == true) {
               dataDeleted.push(item)
            } else {
               dataSuccess.push(item);
            }
         });

         setTemporalOrdersDeletedData(dataDeleted);
         setTemporalOrdersSuccessData(dataSuccess)

      } catch (err) {
         console.log(err);
         setTemporalOrdersDeletedData([]);
      }

   }

   //Function to populate the temporal list orders deleted
   const listTemporalOrdersDeleted = () => {
      const temporalOrders = temporalOrdersDeletedData.map((data) => (
         <>

            <div className='col-12 col-sm-6  col-md-6 col-lg-4 col-xl-4 ' onClick={() => { setStatusDetailDinamic(false); getDeleteOrderTemporalById(data._id); handleShowModal() }} >


               <div class="card border rounded-4 mt-4 p-2">
                  <h5 class="card-header alert alert-danger p-2 m-0 rounded-3  border-0 d-flex justify-content-between">
                     <span className=' '>
                        <i class="uil uil-trash"></i> Orden #{data.code}
                     </span>

                     <span className='text-nowrap'>
                        Total: <strong > {data.total.toFixed(2)} $ </strong >
                     </span>

                  </h5>
                  <div class="card-body m-0 p-0">
                     <h5 class="card-title text-secondary mt-2">Usuario:       {data.user.name} </h5>
                     <div class="card-text">

                        <div className='p-2 border-dashed rounded-3'>
                           <h6 className="fw-bold">
                              Creación
                           </h6>
                           <div className='d-flex justify-content-around'>
                              <span>
                                 <i className="uil uil-calendar-alt"></i> {"Fecha: " + data.createdAt.split("T")[0]}
                              </span>
                              <span>
                                 <i className="uil uil-clock"></i>{" Hora: " + data.createdAt.split("T")[1].split(".")[0]}
                              </span>
                           </div>
                        </div>

                        <div className='p-2 border-dashed rounded-3 mt-2'>
                           <h6 className="fw-bold">
                              Eliminada
                           </h6>
                           <div className='d-flex justify-content-around'>
                              <span>   <i className="uil uil-calendar-alt"></i>{" " + data.deletedAt.split("T")[0]}</span>
                              <span> <i className="uil uil-clock"></i>{" Hora: " + data.deletedAt.split("T")[1].split(".")[0]}</span>
                           </div>
                        </div>

                        {/* <span className="text-nowrap text-secondary h6 d-block ">
                           <i className="uil uil-book-medical"> </i> Creada:
                           <span className='text-success'>
                              <i className="uil uil-calendar-alt"></i>{" " + data.createdAt.split("T")[0] + " / "}
                           </span>
                           <span className='text-primary'>
                              <i className="uil uil-clock"></i>{" Hora: " + data.createdAt.split("T")[1].split(".")[0]}
                           </span>
                        </span>

                        <span className="text-nowrap text-secondary h6 d-block">
                           <i className="uil uil-trash-alt"> </i> Eliminada:
                           <span className='text-danger'>
                              <i className="uil uil-calendar-alt"></i>{" " + data.deletedAt.split("T")[0] + " / "}
                           </span>
                           <span className='text-primary'>
                              <i className="uil uil-clock"></i>{" Hora: " + data.deletedAt.split("T")[1].split(".")[0]}
                           </span>
                        </span>
 */}
                     </div>

                     <div className='d-flex justify-content-end mt-2'>
                        <button class="btn btn-outline-primary border-0"
                           onClick={() => { setStatusDetailDinamic(false); getDeleteOrderTemporalById(data._id); handleShowModal() }}  >
                           Ver más
                        </button>
                     </div>
                  </div>
               </div>
            </div>

         </>
      ));
      if (temporalOrders.length === 0) {
         return EmptyCard()
      } else {
         return (temporalOrders)
      }
   }

   // get Order deleted by id
   const getDeleteOrderTemporalById = async (_id) => {
      try {
         const response = await DeletedTempOrdersFetchAPI.getDeletedTransactionById(_id, token);
         //get info machine by id
         const response_Data_machine = await MachinesFetchAPI.getmachineByid(response.data.deleted_order.machine_id, token)

         let dataOrder = response.data.deleted_order

         //add param machine name to the order object
         dataOrder.machine_name = response_Data_machine.data.data.machine_name
         setOrderData(dataOrder);



         handleShowModal()
      } catch (err) {
         console.log(err);
      }
   }

   /*---------------------------------------PRE ORDER PAYMENT------------------------------------------------------------------------------------------------------ */

   //Function to populate the temporal list orders deleted
   const listTemporalOrdersSuccess = () => {
      const temporalOrders = temporalOrdersSuccessData.map((data) => (
         <>

            <div className='col-12 col-sm-6  col-md-6 col-lg-4 col-xl-4 ' onClick={() => { setStatusDetailDinamic(false); getTransactioByCode(data.finished_order_reference.transaction_code) }}  >
               <div class="card border rounded-4 mt-4 p-2">
                  <h5 class="card-header alert alert-success p-2 m-0 rounded-3  border-0 d-flex justify-content-between">
                     <span className=''>
                        <i class="uil uil-check-circle"></i> Orden #{data.code}
                     </span>

                     <span className='text-nowrap'>
                        Total: <strong > {data.total.toFixed(2)} $ </strong >
                     </span>

                  </h5>
                  <div class="card-body m-0 p-0">
                     <h5 class="card-title text-secondary mt-2">Usuario:       {data.user.name} </h5>
                     <div class="card-text">

                        <div className='p-2 border-dashed rounded-3'>
                           <h6 className="fw-bold">
                              Creación
                           </h6>
                           <div className='d-flex justify-content-around'>
                              <span>
                                 <i className="uil uil-calendar-alt"></i> {"Fecha: " + data.createdAt.split("T")[0]}
                              </span>
                              <span>
                                 <i className="uil uil-clock"></i>{" Hora: " + data.createdAt.split("T")[1].split(".")[0]}
                              </span>
                           </div>
                        </div>

                        <div className='p-2 border-dashed rounded-3 mt-2'>
                           <h6 className="fw-bold">
                              Eliminada
                           </h6>
                           <div className='d-flex justify-content-around'>
                              <span>   <i className="uil uil-calendar-alt"></i>{" " + data.deletedAt.split("T")[0]}</span>
                              <span> <i className="uil uil-clock"></i>{" Hora: " + data.deletedAt.split("T")[1].split(".")[0]}</span>
                           </div>
                        </div>

                        {/* <span className="text-nowrap text-secondary h6 d-block ">
                           <i className="uil uil-book-medical"> </i> Creada:
                           <span className='text-success'>
                              <i className="uil uil-calendar-alt"></i>{" " + data.createdAt.split("T")[0] + " / "}
                           </span>
                           <span className='text-primary'>
                              <i className="uil uil-clock"></i>{" Hora: " + data.createdAt.split("T")[1].split(".")[0]}
                           </span>
                        </span>

                        <span className="text-nowrap text-secondary h6 d-block">
                           <i className="uil uil-trash-alt"> </i> Eliminada:
                           <span className='text-danger'>
                              <i className="uil uil-calendar-alt"></i>{" " + data.deletedAt.split("T")[0] + " / "}
                           </span>
                           <span className='text-primary'>
                              <i className="uil uil-clock"></i>{" Hora: " + data.deletedAt.split("T")[1].split(".")[0]}
                           </span>
                        </span>
 */}
                     </div>

                     <div className='d-flex justify-content-end mt-2'>
                        <button href="#" class="btn btn-outline-primary border-0"
                           onClick={() => { setStatusDetailDinamic(false); getTransactioByCode(data.finished_order_reference.transaction_code) }}  >
                           Ver más
                        </button>
                     </div>
                  </div>
               </div>
            </div>

         </>
      ));
      if (temporalOrders.length === 0) {
         return EmptyCard()
      } else {
         return (temporalOrders)
      }
   }


   const getTransactioByCode = async (transaction_code) => {
      try {
         const response = await OrdersFetchAPI.getTransactionByCode(transaction_code, business_code, selectedBranch.code, token)
         let dataOrder = response.data.order
         //get info machine by id
         const response_Data_machine = await MachinesFetchAPI.getmachineByid(response.data.order.machine_id, token)

         //We get the branchoffice info 
         const response_branch = await BranchOfficeFetchAPI.getBranchOfficeByCode(dataOrder.business.code, dataOrder.branchoffice.code);
         //add param machine name to the order object
         setBranchofficeDataOrder(response_branch.data.branchoffice)
         dataOrder.machine_name = response_Data_machine.data.data.machine_name
         dataOrder.paymentInfo = response.data.paymentmetod
         setOrderData(response.data.order);
         handleShowModal()
      } catch (err) {
         console.log(err);
      }
   }



   //Hook to charge data when the page is loaded 
   useEffect(() => {
      (async () => {
         try {
            //We show the loading alert 
            //LoadingAlert();
            //We set the branchoffices of the user 
            setUserBranchs(user.branchoffices);
            //
            //We set the business user 
            setUserBusiness(user.business);
            //We search the branch orders for default we send 0 to show all 
            //getTransactionsByBranchOffice(business_code, selectedBranch.code, actualDate, maxDate);

            if (selectedBranch.code != 0) {
               getPreOrder(business_code, selectedBranch.code, token)
               getDeleteTemporal()
            } else {
               setBranchOrdersData([]);
               //This are for the discount temporal orders 
               setTemporalOrdersData([]);
               //This are for the search filter
               setTemporal([]);
               Swal.fire({
                  title: "Debes seleccionar una sucursal",
                  text: "No hay sucursal seleccionada",
                  icon: "warning"
               });
            }

            //We show the success alert 

         } catch (err) {
            console.log(err);
         }

      })();
      //We set the load state 
      setReLoad(false)
   }, [reload, selectedBranch])


   useEffect(() => {
      if (modalShow) {
         document.body.classList.add("modal-open-fix");
      } else {
         document.body.classList.remove("modal-open-fix");
      }

      return () => {
         document.body.classList.remove("modal-open-fix");
      };
   }, [modalShow]);

   return (

      <>
         <Navbar titlePage="Precuentas" />
         <main className='bg-light-subtle pt-4'>
            <div className=' container'>

               {/* Header Page */}
               <section className='banner-sp bg-light-subtle d-lg-flex justify-content-lg-between 
                  align-items-center rounded-4 mb-3 flex-column flex-lg-row justify-content-center py-2 py-lg-0'>

                  <div className='d-lg-flex flex-lg-stack flex-wrap gap-4 w-100 flex-nowrap d-flex justify-content-center align-items-center 
                  justify-content-lg-start'>
                     <div className='page-title d-flex flex-column gap-0 me-3'>
                        <h2 className='banner-reports-title mb-0 pb-0'>
                           Precuentas
                        </h2>
                        <p className='m-0 pt-1 banner-reports-description'>Gestión de cuentas pendientes.</p>
                     </div>
                  </div>
                  {/*<form className='filter-sp-trn'>
                     <div className="d-block">
                        <select onChange={e => setSelectedBranch({ code: e.target.value })} className='form-select' name="user_branch" id="user_branch">
                           {branchList()}
                        </select>
                     </div>
                  </form>*/}

                  <div className='d-lg-flex flex-lg-stack flex-wrap gap-4 w-100 flex-nowrap d-flex justify-content-center align-items-center 
                  justify-content-lg-end mt-3 mt-lg-0'>
                     <ModalBranchofficeSelect actionSelectBranch={actionSelectBranch}></ModalBranchofficeSelect>
                  </div>

               </section>
               {/* End Header Page */}


               <section className='m-0 p-0'>
                  <Tab.Container defaultActiveKey="pendings">
                     <Row>
                        <Col sm={12} >
                           <Nav variant="underline" className="d-flex horizontal-scroll border-bottom  pe-3 bg-white " >
                              <li className="nav-sp-item ms-1"  >
                                 <Nav.Link eventKey="pendings" title="Categorias">Precuentas Pendientes</Nav.Link>
                              </li>
                              <li className="nav-sp-item">
                                 <Nav.Link eventKey="delete">Precuentas Pendientes</Nav.Link>
                              </li>

                              <li className="nav-sp-item">
                                 <Nav.Link eventKey="checks" >Precuentas Realizadas</Nav.Link>
                              </li>
                           </Nav>
                        </Col>
                        <Col sm={12}>
                           <Tab.Content>
                              <Tab.Pane eventKey="pendings">
                                 <div className='rounded-3 '>
                                    <div className='border rounded-4 bg-white my-4'>
                                       <div className='p-lg-2 p-3'>
                                          <div className='d-flex justify-content-between align-items-center mb-2 w-100'>
                                             <h4 className='fs-4 fw-bold'>Precuentas Pendientes:{temporalOrdersData.length}</h4>

                                             <div class="tooltip-container-x">



                                                <button className='btn-ht-update btn bg-primary-subtle rounded-circle p-2 lh-1 border-primary'
                                                   onClick={e => getPreOrder(business_code, selectedBranch.code, token)}>
                                                   {/* <img src={update} alt="Update List" width="16" height="16" /> */}
                                                   <img className='' width="25" height="25" src={update} alt="restart" />
                                                   {/* <small> Actualizar </small> */}
                                                </button>
                                             </div>

                                          </div>
                                          <div className='transaction-wrapper  no-scroll row'>
                                             {listTemporalOrders()}
                                          </div>
                                       </div>
                                    </div>
                                 </div>

                              </Tab.Pane>

                              <Tab.Pane eventKey="delete">
                                 <div className='rounded-3 '>
                                    <div className='border rounded-4 bg-white my-4'>
                                       <div className='p-lg-2 p-3'>
                                          <div className='d-lg-flex justify-content-lg-between  align-items-center mb-2 w-100 bg-white rounded-4'>
                                             <h4 className='fs-4 fw-bold'>Precuentas Eliminadas</h4>



                                             <div className=" d-flex justify-content-start ">
                                                <div className="" >
                                                   <p className='text-center m-2'>Fecha:</p>
                                                </div>
                                                <div className=" ms-1">
                                                   <input type="date" name="date" id=""
                                                      onChange={e => (setMinDate(e.target.value + "T00:00:00.000+00:00"), setMaxDate(e.target.value + "T23:59:59.000+00:00"))} className="form-control" />
                                                </div>
                                                <div className=" ms-1">
                                                   <button className="btn btn-success rounded" id="btn-analitycs" onClick={async () => getDeleteTemporal()}>Aceptar</button>
                                                </div>
                                             </div>


                                          </div>


                                          <div className='transaction-wrapper   no-scroll row'>
                                             {listTemporalOrdersDeleted()}
                                          </div>
                                       </div>
                                    </div>
                                 </div>

                              </Tab.Pane>

                              <Tab.Pane eventKey="checks">

                                 <div className='rounded-3 '>
                                    <div className='border rounded-4 bg-white my-4'>
                                       <div className='p-lg-2 p-3'>
                                          <div className='d-lg-flex justify-content-lg-between  align-items-center mb-2 w-100 bg-white rounded-4'>
                                             <h4 className='fs-4 fw-bold'>Precuentas Realizadas</h4>



                                             <div className=" d-flex justify-content-start ">
                                                <div className="" >
                                                   <p className='text-center m-2'>Fecha:</p>
                                                </div>
                                                <div className=" ms-1">
                                                   <input type="date" name="date" id=""
                                                      onChange={e => (setMinDate(e.target.value + "T00:00:00.000+00:00"), setMaxDate(e.target.value + "T23:59:59.000+00:00"))} className="form-control" />
                                                </div>
                                                <div className=" ms-1">
                                                   <button className="btn btn-success rounded" id="btn-analitycs" onClick={async () => getDeleteTemporal()}>Aceptar</button>
                                                </div>
                                             </div>


                                          </div>


                                          <div className='transaction-wrapper   no-scroll row'>
                                             {listTemporalOrdersSuccess()}
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </Tab.Pane>

                           </Tab.Content>
                        </Col>
                     </Row>
                  </Tab.Container>
               </section>
            </div>
         </main >
         {/* Modal */}
         < Modal show={modalShow} onHide={handleCloseModal} size="lg" modalClassName="custom-modal">

            <Modal.Body>

               <OrderDetailPreOrder
                  orderData={orderData}
                  userBranchoffices={userBranchoffices}
                  handlerUpdatePreOrder={handlerUpdatePreOrder}
                  handleCloseModal={handleCloseModal}
                  statusDetailModalDinamic={statusDetailModalDinamic}
                  branchOfficeData={branchOfficeDataOrder}>
               </OrderDetailPreOrder>

            </Modal.Body>
         </Modal>


         {/* Footer */}
         < Footer />
      </>
   )
}

export default TransactionsTemporal