//React Components
import React, { useState } from 'react';
import { Modal, Button, Form, Container, Row, Col, ListGroup } from 'react-bootstrap';
import Select from 'react-select';
//Alerts
import ErrorAlert from '../../Alerts/ErrorAlert';
//API
import { SetGroupsFetchAPI } from '../../../api/SetGroups';
//Modal
import ModalAddGroupSetGroup from './ModalAddGroupSetGroup';
import list from '../../img/icons/list.png'
//Modal to list the setGroups by combo
function ModalListSetGroupByCombo({ combo, branchoffice_id }) {
    //Modals states
    const [showModal, setShowModal] = useState(false)
    const [showModalAsing, setShowModalAsing] = useState(false)
    //States
    const [setGroups, setSetGroups] = useState([])
    const [businessSetGroups, setBusinessSetGroups] = useState([])
    //Local storage
    let token = localStorage.getItem("x-access-token")
    let user = localStorage.getItem('user');

    //Function to get set Groups by combo
    const getSetGroupsByCombo = async () => {
        try {
            const response = await SetGroupsFetchAPI.getSetGroupsByCombo(combo.combo_id, token)
            setSetGroups(response.data.sets)
        } catch (err) {
            console.log(err);
            setSetGroups([])
        }
    }

    //Function to get set groups by business
    const getSetGroupsByBusiness = async () => {
        //Variables
        user = JSON.parse(localStorage.getItem('user'))
        let setGroupInfo = []
        try {
            //We get the set groups
            const response = await SetGroupsFetchAPI.getSetGroupsByBusiness(user.business_id, token)
            //We set the data
            response.data.sets.forEach(set => {
                setGroupInfo.push({ value: set.id, label: set.name })
            });
            setBusinessSetGroups(setGroupInfo)
        } catch (err) {
            console.log(err)
            setBusinessSetGroups([])
        }
    }
    //Function to  remove a group from a set group on a combo
    const removeGroupFromSetGroup = async (combo_id, set_id, group_id) => {
        //Variables
        let data = {
            group_id: group_id
        }
        try {
            //We remove the group from the set group
            await SetGroupsFetchAPI.removeGroupFromSetGroup(combo_id, set_id, data, token)
            //We reload the data
            getSetGroupsByCombo()
        } catch (err) {
            console.log(err);
            ErrorAlert(err.response.data.error, 'Error no se pudo remover el item', 'error')
        }
    }

    //Function to remove a set group from a combo
    const removeSetGroupFromCombo = async (set_id, combo_id) => {
        //variables
        let data = {
            "set_id": set_id
        }
        try {
            //We remove the set group from the combo
            const response = await SetGroupsFetchAPI.removeSetGroupFromCombo(combo_id, data, token)
            console.log(response);
            //We reload the data
            getSetGroupsByCombo()
            ErrorAlert('', 'Conjunto de productos removido', 'success')
        } catch (err) {
            console.log(err)
            ErrorAlert(err.response.data.error, 'Error no se pudo remover el conjunto de productos', 'error')
        }
    }
    //Function to asing the set group to a combo
    const asingSetGroupToCombo = async (e) => {
        e.preventDefault();
        try {
            //We get the data
            const form = new FormData(e.target);
            let data = {
                set_groups: [
                    Object.fromEntries(form.entries())
                ]
            }
            //We asing the set group to the combo
            const response = await SetGroupsFetchAPI.asingSetGroupToCombo(data, combo.combo_id, token)
            //We reload the data
            getSetGroupsByCombo()
            setShowModalAsing(false)
            ErrorAlert('', 'Conjunto de producto asignado con exito', 'success')
        } catch (err) {
            console.log(err)
            ErrorAlert(err.response.data.message, 'Alerta', 'warning')
        }
    }

    //We get the groups of the set group
    const groupsBySetGroup = (setGroup) => {
        let groups = setGroup.groups
        {/** The list of groups of the set group */ }
        const viewGroups = groups.map(group => (

            <>
                <div>
                    <div
                        className="d-flex justify-content-between align-items-start border-dashed rounded-3 mt-2"
                    >
                        <img
                            className='imgitemsList m-1'
                            src={group.img}
                            alt={group.name}
                        />
                        <div className="ms-2 me-auto">
                            <div className="fw-bold">
                                {group.name}
                            </div>
                            <div className=" text-muted opacity-75">
                                <span>Precio:</span> ${parseFloat(group.price).toFixed(2)}
                            </div>
                            <div className="text-muted opacity-75">
                                <span> Descripcion:</span> {group.description}
                            </div>
                        </div>

                        <div>
                            {/* <ModalEditItemTosegment item={item} segment={segment} load={getSegmentsByGroups}></ModalEditItemTosegment> */}
                            <button className='btn btn-outline-danger btn-sm border-0' onClick={async () => removeGroupFromSetGroup(setGroup.combo_id, setGroup.set_id, group.group_id)}>
                                <i className="uil uil-trash-alt"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </>


        ));
        //We return the view of the groups
        return (viewGroups)
    }

    return (
        <>



            <button className='btn btn btn-outline-light  pulse rounded-3 p-2 lh-1 border'
                onClick={(e) => (setShowModal(true), getSetGroupsByCombo(), getSetGroupsByBusiness())}
            >
                <img className='icon-options-cards' src={list} alt="List" />

            </button>



            {/**Modal to list the set groups */}
            <Modal
                show={showModal}
                onHide={() => (setShowModal(false))}
                size='xl'
            >
                <Modal.Body className='m-0'>
                    <div className='position-relative'>

                        <div className='position-absolute top-0 start-100 translate-middle mt-2 '>
                            <div className='me-3'>
                                <button class="btn btn-outline-secondary border-0 bg-light text-secondary rounded-circle p-2 lh-1" type="button" title='Cerrar'
                                    onClick={() => (setShowModal(false))}>
                                    <i class="uil uil-multiply"></i>
                                </button>
                            </div>
                        </div>

                        <h2 className="title-main-login text-center ">
                            Conjuntos de productos
                        </h2>
                        <div

                            className="d-flex justify-content-between my-2"
                        >
                            <img
                                className='imgitemsList m-1'
                                src={combo.img}
                                alt={combo.name}
                            />
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">{combo.name}</div>
                                {combo.description}
                            </div>
                        </div>
                        <div className='d-flex justify-content-between align-item-center mb-2'>
                            <h5 className='text-primary fw-semibold m-2 text-center'>Detalles de los productos</h5>
                            <div className='d-flex justify-content-center align-item-center'>

                                <button className='btn btn-primary btn-sm border-0'
                                    onClick={() => (setShowModalAsing(true))}>
                                    <i className="uil uil-plus"></i>
                                    Asignar Conjunto
                                </button>
                            </div>
                        </div>
                        <div>

                            <div className='segments-wrapper no-scroll'>
                                {setGroups.map(setGroup => (
                                    <div
                                        className="d-flex justify-content-between"
                                    >
                                        <div className="p-2 w-100 ">

                                            <div className='border-dashed border-primary rounded-3 p-2 bg-light-subtle'>
                                                <div className="d-flex justify-content-between mb-2">
                                                    <div className=' bg-primary-subtle text-primary rounded-2 text-center'>
                                                        <small className='  px-3  py-1 fw-semibold' >
                                                            {setGroup.isMainSetGroup === 1
                                                                ? <> <i class="fa-solid fa-tag"></i> Principal: </>
                                                                : <> <i class="fa-solid fa-tags"></i> Secundario: </>
                                                            }
                                                            {setGroup.name}

                                                        </small>
                                                    </div>

                                                    <div>
                                                        <ModalAddGroupSetGroup setGroup={setGroup} branchoffice_id={branchoffice_id} loadSetGroup={getSetGroupsByCombo} ></ModalAddGroupSetGroup>
                                                    </div>

                                                    <div >
                                                        <button className='btn btn-danger btn-sm border-0' onClick={async () => removeSetGroupFromCombo(setGroup.set_id, setGroup.combo_id)}>
                                                            <i className="uil uil-trash-alt"></i> Remover conjunto
                                                        </button>
                                                    </div>
                                                </div>

                                                {/** The list of groups on the set group */}
                                                {groupsBySetGroup(setGroup)}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </Modal.Body >
            </Modal >

            {/** Modal to asing the set groups */}
            < Modal
                show={showModalAsing}
                onHide={() => (setShowModalAsing(false))
                }
                size='md'
                centered >

                <Modal.Body className='shadow-lg'>

                    <div className='position-relative'>

                        <div className='position-absolute top-0 start-100 translate-middle mt-2 '>
                            <div className='me-3'>
                                <button class="btn btn-outline-secondary border-0 bg-light text-secondary rounded-circle p-2 lh-1" type="button" title='Cerrar'
                                    onClick={() => (setShowModalAsing(false))}
                                >
                                    <i class="uil uil-multiply"></i>
                                </button>
                            </div>
                        </div>

                        <h2 className="title-main-login text-center ">
                            Asignar Conjunto
                        </h2>
                        <Form onSubmit={asingSetGroupToCombo} className="needs-validation">
                            {/** Select of set groups */}
                            <Form.Group className="mb-3" controlId="clientId">
                                <Form.Label>Seleccione conjunto</Form.Label>
                                <Select
                                    name="set_id"
                                    className='w-100 '
                                    options={businessSetGroups}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="clientId">
                                <Form.Label>Cantidad de selecciones</Form.Label>
                                <Form.Control
                                    type='number'
                                    name="count"
                                    className='w-100'
                                    defaultValue={1}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <Form.Label>Es el producto principal del combo?</Form.Label>
                                <Form.Select aria-label="" name="isMainSetGroup">
                                    <option value={0}>No</option>
                                    <option value={1}>Si</option>
                                </Form.Select>
                            </Form.Group>
                            <div className="mt-3 d-flex justify-content-end">
                                <button type="submit" className='btn btn-save-sp rounded-2'  >
                                    Guardar
                                </button>
                            </div>
                        </Form>
                    </div>

                </Modal.Body>
            </Modal >
        </>
    )
}

export default ModalListSetGroupByCombo