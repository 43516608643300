import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Button } from 'react-bootstrap';
import { LanguagesFetchAPI } from '../../api/Languages';
import LoadingAlert from '../Alerts/LoadingAlert';



function ModalLanguage() {
    const [showModalLanguage, setShowModalLanguage] = useState(false);
    const [statusLanguage, setStatusLanguage] = useState(0)
    let token = localStorage.getItem("x-access-token")
    const [languages, setLanguages] = useState([])


    //get languages 
    const getLanguages = async () => {
        try {
            const response = await LanguagesFetchAPI.getLanguages(token);
            setLanguages(response.data.languages)
        } catch (err) {
            console.log(err);
        }
    }

    //change Langage
    const setLanguage = (language) => {
        localStorage.setItem("lang", language);

        LoadingAlert("Cambiando idioma...", "Espere un momento por favor")
        setTimeout(() => {
            window.location.reload(true);
        }, 3000);
        /*if (statusLanguage == 0) {
            
            setStatusLanguage(1);
            document.cookie = "googtrans=/" + language;
            new window.google.translate.TranslateElement({ pageLanguage: 'es' }, 'google_translate_element')
            setShowModalLanguage(false)
        } else {
            LoadingAlert("Restaurando idioma...", "Espere un momento por favor")
            setTimeout(() => {
                window.location.reload(true);
            }, 3000);
        }*/
    }

    const changeLanguage = () => {

        if (localStorage.getItem("lang") != "" && localStorage.getItem("lang") != null && localStorage.getItem("lang") != undefined && localStorage.getItem("lang") != "es") {
            document.cookie = "googtrans=/" + localStorage.getItem("lang");
            new window.google.translate.TranslateElement({ pageLanguage: 'es' }, 'google_translate_element')
        }


    }

    // Remover cookie
    const removeCookie = (name) => {
        document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    };


    {/*view languages*/ }
    const viewLanguages = () => {

        const listItems = languages.map((language, index) => (
            <>
                {/* <div className='m-3'>
                    <div className='btn btn-outline-primary  d-block border border-3 rounded-pill p-3 ' onClick={() => setLanguage(language.code)}>
                        <span className='p-3  text-wrap px-3'>{language.name}</span>
                    </div>
                </div> */}

                <input type="radio" className="btn-check" name="items-language"
                    id={language.name + index} onClick={() => setLanguage(language.code)} />
                <label className="btn-language card-title rounded-3  p-2 
                                border text-center fw-semibold w-100" for={language.name + index}>
                    <span>
                        {language.name}
                    </span>
                </label>
            </>
        ));
        return (listItems)
    }

    useEffect(() => {
        getLanguages()
        changeLanguage()
    }, []);
    return (
        <>



            <div className="area-profile d-flex align-items-center text-white  my-lg-1 m-0 p-lg-1 rounded-4 position-relative" onClick={() => (setShowModalLanguage(true))}>
                <div className="d-flex align-items-center px-1 " title="Idioma">
                    <i className="uil uil-english-to-chinese  " translate='no'> </i>
                </div>
            </div>



            <Modal
                centered

                size="md"
                show={showModalLanguage}
                onHide={() => setShowModalLanguage(false)}>
                <Modal.Body   >

                    <div className='idioma-sp position-relative '>
                        <div className='position-absolute top-0 start-100 translate-middle mt-2 '>
                            <div className='me-3'>
                                <button class="btn btn-outline-secondary border-0 bg-light text-secondary rounded-circle p-2 lh-1" type="button" onClick={() => (setShowModalLanguage(false))} title='Cerrar'>
                                    <i class="uil uil-multiply"></i>
                                </button>
                            </div>
                        </div>
                        <section className="bg-white border-0">
                            <h2 className="title-main-login  text-center ">
                                <i className="uil uil-english-to-chinese  " translate='no'>
                                </i>
                                Idioma
                            </h2>
                            <div className=' pb-4' >
                                <label className="form-label fw-normal text-gray-600">
                                    Lista de idiomas
                                </label>
                                <div className='idioma-wrapper no-scroll'>
                                    <div className='d-flex flex-column gap-3 p-2 bg-body' >

                                        {viewLanguages()}
                                    </div>
                                </div>
                                <div className='d-block text-center mt-3 opacity-50'>
                                    <i class="uil uil-info-circle"></i>
                                    Escoge el idioma de preferencia.
                                </div>
                            </div>
                        </section>

                    </div>
                </Modal.Body>



            </Modal>
        </>
    )
}

export default ModalLanguage