import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
//Internal Components
import Navbar from '../NavBar/Navbar';
import GeneralReport from './tabs/GeneralReports';
//Components 
import { Tab } from 'react-bootstrap';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Nav from 'react-bootstrap/Nav';
import PaymentMethodReports from './tabs/PaymentMethodReports';
import ProductSalesReport from './tabs/ProductSalesReport';
import BuysAndSalesReport from './tabs/BuysAndSalesReport';
import PreAccountReport from './tabs/PreAccountReport';
import DiscountsReport from './tabs/DiscountsReport';
import ApprovedReturnsReport from './tabs/ApprovedReturnsReport';
import TipsReport from './tabs/TipsReport';
import ClosuresReport from './tabs/ClosuresReport';
//CSS
import "./reports.css"
//Utils
import { RenderIf } from '../utils/RenderIf';
import { checkUserPermission } from '../utils/CheckUserPermitions';
import { BranchOfficeFetchAPI } from '../../api/BranchOffices';

import ModalBranchofficeSelect from '../NavBar/ModalBranchofficeSelect';
const ReportStore = () => {
    //We get the user data 
    let { business_code } = useParams();
    let user = localStorage.getItem('user') !== '' || localStorage.getItem('user') !== undefined ? JSON.parse(localStorage.getItem('user')) : '';
    const [selectedUserOption, setSelectedUserOption] = useState(null);
    const [cashiers, setCashiers] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState({
        code: 0,
        id: 0,
        name: ""
    });

    const getBranchoffice = async (branchoffice_code) => {
        try {
            const response = await BranchOfficeFetchAPI.getBranchOfficeByCode(business_code, branchoffice_code);
            setSelectedBranch(response.data.branchoffice);
            //We set the user selected to null
            setSelectedUserOption(null);

        } catch (err) {
            console.log(err)
            setSelectedBranch({
                code: 0,
                id: 0,
                name: ""
            });
            //We set the user selected to null
            setSelectedUserOption(null);
            setCashiers([])
        }
    
        try {
            const response = await BranchOfficeFetchAPI.getBranchOfficeByCode(business_code, branchoffice_code);
            setSelectedBranch(response.data.branchoffice);
            //We set the user selected to null
            setSelectedUserOption(null);

        } catch (err) {
            console.log(err)
            setSelectedBranch({
                code: 0,
                id: 0,
                name: ""
            });
            //We set the user selected to null
            setSelectedUserOption(null);
            setCashiers([])
        }
    }

    const actionSelectBranch = (branchCode, branch) => {
        getBranchoffice(branchCode)
    }

    return (
        <>

            <Navbar titlePage="Reportes" />

            <div className='bg-light-subtle pt-4'>
                <div className='container'>
                    {/** Main container */}
                    <main >
                        {/** Header */}
                        <section className='banner-sp bg-light-subtle d-lg-flex justify-content-lg-between 
                  align-items-center rounded-4 mb-3 flex-column flex-lg-row justify-content-center py-2 py-lg-0'>
                            {/* Header Page */}
                            <div className='d-lg-flex flex-lg-stack flex-wrap gap-4 w-100 flex-nowrap d-flex justify-content-center align-items-center 
                  justify-content-lg-start '>
                                <div className='page-title d-flex flex-column gap-0 me-3'>
                                    <h2 className='banner-reports-title mb-0 pb-0'>
                                        Reportes
                                    </h2>
                                    <p className='m-0 pt-1 banner-reports-description'>Visualización de datos clave para análisis.</p>
                                </div>
                            </div>

                            <div className='d-lg-flex flex-lg-stack flex-wrap gap-4 w-100 flex-nowrap d-flex justify-content-center align-items-center 
                  justify-content-lg-end mt-3 mt-lg-0'>
                                <ModalBranchofficeSelect actionSelectBranch={actionSelectBranch}></ModalBranchofficeSelect>
                            </div>
                        </section>
                        {/** End Header  */}
                        {/**Tabs */}
                        <Tab.Container defaultActiveKey={checkUserPermission("report_general", user.permissions) !== -1 ? "General" : "payments"}>
                            <div className='row'>
                                <div className='col-12 col-md-3 col-lg-3 col-xl-3 pt-3'>
                                    <div className='rounded-3  sticky-content mt-lg-2 mt-0'>
                                        <Nav  variant="pills" className="flex-column gap-2  p-2 mt-lg-2  ">
                                            {/* General Report */}
                                            <RenderIf isTrue={checkUserPermission("report_general", user.permissions) !== -1}>
                                                <li className="nav-sp-item bg-light rounded-3 border"  >
                                                    <Nav.Link eventKey="General" title="General">
                                                        General
                                                    </Nav.Link>
                                                </li>
                                            </RenderIf>

                                            {/* <li className="nav-sp-item bg-light rounded-3 border">
                                                <Nav.Link eventKey="combo"> Combos</Nav.Link>
                                            </li> */}
                                            {/* Payments Report */}
                                            <RenderIf isTrue={checkUserPermission("report_payment_method", user.permissions) !== -1}>
                                                <li className="nav-sp-item bg-light rounded-3 border">
                                                    <Nav.Link eventKey="payments" >
                                                        Métodos de Pago
                                                    </Nav.Link>
                                                </li>
                                            </RenderIf>
                                            {/* Products Report */}
                                            <RenderIf isTrue={checkUserPermission("report_products", user.permissions) !== -1}>
                                                <li className="nav-sp-item bg-light rounded-3 border">
                                                    <Nav.Link eventKey="products" >
                                                        Productos
                                                    </Nav.Link>
                                                </li>
                                            </RenderIf>
                                            {/* Buy and Sales Report */}
                                            <RenderIf isTrue={checkUserPermission("report_entry_exit", user.permissions) !== -1}>
                                                <li className="nav-sp-item bg-light rounded-3 border">
                                                    <Nav.Link eventKey="buysales" >
                                                        Entrada y Salida de Productos
                                                    </Nav.Link>
                                                </li>
                                            </RenderIf>

                                            {/* PreAccount Report */}
                                            <RenderIf isTrue={checkUserPermission("report_deleted_pre_account", user.permissions) !== -1}>
                                                <li className="nav-sp-item bg-light rounded-3 border">
                                                    <Nav.Link eventKey="preaccount" >
                                                        Pre-Cuentas Eliminadas
                                                    </Nav.Link>
                                                </li>
                                            </RenderIf>

                                            {/* Discounts Report */}
                                            <RenderIf isTrue={checkUserPermission("report_approved_discount", user.permissions) !== -1}>
                                                <li className="nav-sp-item bg-light rounded-3 border">
                                                    <Nav.Link eventKey="discount" >
                                                        Descuentos Aprobados</Nav.Link>
                                                </li>
                                            </RenderIf>
                                            {/** Devoluciones aprobadas */}
                                            <RenderIf isTrue={checkUserPermission("report_approved_returns", user.permissions) !== -1}>
                                                <li className="nav-sp-item bg-light rounded-3 border">
                                                    <Nav.Link eventKey="approvedreturns" >
                                                        Devoluciones Aprobadas</Nav.Link>
                                                </li>
                                            </RenderIf>
                                            {/** Propinas */}
                                            <RenderIf isTrue={checkUserPermission("report_tips", user.permissions) !== -1}>
                                                <li className="nav-sp-item bg-light rounded-3 border">
                                                    <Nav.Link eventKey="tips" >
                                                        Propinas</Nav.Link>
                                                </li>
                                            </RenderIf>
                                            {/** Cierres */}
                                            <li className="nav-sp-item bg-light rounded-3 border">
                                                <Nav.Link eventKey="closures" >
                                                    Cierres</Nav.Link>
                                            </li>

                                            {/** Consolidacion */}
                                            <li className="nav-sp-item bg-light rounded-3 border">
                                                <Nav.Link eventKey="bankConsolidation" >
                                                    Consolidacion bancaria</Nav.Link>
                                            </li>
                                        </Nav>
                                    </div>

                                </div>
                                <div className='col-12 col-md-9 col-md-9 col-lg-9 col-xl-9'>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="General">
                                            <div className=' m-0'>
                                                <GeneralReport selectedUserOption={selectedUserOption} setSelectedUserOption={setSelectedUserOption} cashiers={cashiers} setCashiers={setCashiers} selectedBranch={selectedBranch} setSelectedBranch={setSelectedBranch} />
                                            </div>
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="payments">
                                            <div className=' m-0'>
                                                <PaymentMethodReports selectedUserOption={selectedUserOption} setSelectedUserOption={setSelectedUserOption} cashiers={cashiers} setCashiers={setCashiers} selectedBranch={selectedBranch} setSelectedBranch={setSelectedBranch} />
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="products">
                                            <div className=' m-0'>
                                                <ProductSalesReport selectedUserOption={selectedUserOption} setSelectedUserOption={setSelectedUserOption} cashiers={cashiers} setCashiers={setCashiers} selectedBranch={selectedBranch} setSelectedBranch={setSelectedBranch} />
                                            </div>
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="buysales">
                                            <div className=' m-0'>
                                                <BuysAndSalesReport selectedUserOption={selectedUserOption} setSelectedUserOption={setSelectedUserOption} cashiers={cashiers} setCashiers={setCashiers} selectedBranch={selectedBranch} setSelectedBranch={setSelectedBranch} />
                                            </div>
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="preaccount">
                                            <div className=' m-0'>
                                                <PreAccountReport selectedUserOption={selectedUserOption} setSelectedUserOption={setSelectedUserOption} cashiers={cashiers} setCashiers={setCashiers} selectedBranch={selectedBranch} setSelectedBranch={setSelectedBranch} />
                                            </div>
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="discount">
                                            <div className=' m-0'>
                                                <DiscountsReport selectedUserOption={selectedUserOption} setSelectedUserOption={setSelectedUserOption} cashiers={cashiers} setCashiers={setCashiers} selectedBranch={selectedBranch} setSelectedBranch={setSelectedBranch} />
                                            </div>
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="approvedreturns">
                                            <div className=' m-0'>
                                                <ApprovedReturnsReport selectedUserOption={selectedUserOption} setSelectedUserOption={setSelectedUserOption} cashiers={cashiers} setCashiers={setCashiers} selectedBranch={selectedBranch} setSelectedBranch={setSelectedBranch} />
                                            </div>
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="tips">
                                            <div className=' m-0'>
                                                <TipsReport selectedUserOption={selectedUserOption} setSelectedUserOption={setSelectedUserOption} cashiers={cashiers} setCashiers={setCashiers} selectedBranch={selectedBranch} setSelectedBranch={setSelectedBranch} />
                                            </div>
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="closures">
                                            <div className=' m-0'>
                                                <ClosuresReport />
                                            </div>
                                        </Tab.Pane>
                                    </Tab.Content >
                                </div>
                            </div >
                        </Tab.Container >
                    </main >

                </div >
            </div >

        </>
    );
}

export default ReportStore;
