//React Components
import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
//Utils
import { RenderIf } from '../../utils/RenderIf';
import { checkUserModules } from '../../utils/CheckUserModules';
//Alerts
import ErrorAlert from '../../Alerts/ErrorAlert';
//API
import { CombosFetchAPI } from '../../../api/Combos';

import pencil from '../../img/icons/pencil.png'

//Modal to edit a combo
function ModalEditCombo({ combo, loadCombo, branchoffice_id, products, user }) {
    //States
    const [showModal, setShowModal] = useState(false)
    const [eventHandle, setEventHandle] = useState(0);
    const [imgpreview, setImgpreview] = useState(combo.img)
    //Token
    let token = localStorage.getItem("x-access-token")

    //Function to register the product 
    const submit = async (e) => {
        e.preventDefault();
        const form = new FormData(e.target);
        let editCombo = Object.fromEntries(form.entries());
        //We check that the integration module is active
        if (user.business.posIntegration !== undefined) {
            form.append("pos_integration", user.business.posIntegration)
        }
        try {
            //We update the data 
            await CombosFetchAPI.updateCombo(combo.combo_id, editCombo, token);
            //We check if the user want to edit details of the combo on a branchoffice 
            if (branchoffice_id !== 0) {
                //We add the combo id on the edit object
                editCombo.combo_id = combo.combo_id;
                //We update the combo details on the branchoffice
                await CombosFetchAPI.updateComboBranch(editCombo, branchoffice_id, token);
            }
            //We show the success alert 
            ErrorAlert('', 'Combo editado con exito', 'success')
            loadCombo();
            setShowModal(false);
            setImgpreview(combo.img)
        } catch (err) {
            setImgpreview(combo.img)
            ErrorAlert(err.response.data.error, 'Error en la edicion', 'error')
        }
    }

    //Function to change the image
    const changeimg = (e) => {
        setImgpreview(URL.createObjectURL(e.target.files[0]))
    }



    //Hook to charge the data when the page is loaded
    useEffect(() => {
        setEventHandle(0)
    }, [eventHandle])




    return (
        <>
            <button type="button" className='btn btn-outline-light pulse rounded-3 p-2 lh-1 border'
                onClick={() => (setShowModal(true))}
            >

                <img className='icon-options-cards' src={pencil} />
            </button>

            <Modal
                show={showModal}
                onHide={() => (setShowModal(false), setImgpreview(combo.img))}
                size='lg'
                centered>

                <Modal.Body>

                    <div className='position-relative'>
                        <div className='position-absolute top-0 start-100 translate-middle mt-2 '>
                            <div className='me-3'>
                                <button class="btn btn-outline-secondary border-0 bg-light text-secondary rounded-circle p-2 lh-1" type="button" title='Cerrar'
                                    onClick={() => (setShowModal(false), setImgpreview(combo.img))}
                                >
                                    <i class="uil uil-multiply"></i>
                                </button>
                            </div>
                        </div>

                        <h2 className="title-main-login text-center ">
                            Editar Combo
                        </h2>
                    </div>

                    <div className='border-dashed p-3 rounded-3 mb-2' >
                        <div className='d-flex align-item-center justify-content-center'>
                            <img
                                className='imgEditModal'
                                src={imgpreview}
                                alt={combo.name}
                            />
                        </div>

                        <div >
                            <label role='button'  className='d-flex align-item-center justify-content-center m-3 border-primary border-dashed rounded-3 py-2'
                             htmlFor="upload-button">
                                <h6 className="text-center text-primary">Actualizar Imagen</h6>
                            </label>
                        </div>
                    </div>

                    <Form
                        className="needs-validation"
                        onSubmit={submit}
                    >

                        <div className='row'>

                            <div className='col-6 col-md-6'>         <Form.Group className="mb-3" controlId="name">
                                <Form.Label>Nombre</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={combo.name}
                                    name="name"

                                />
                            </Form.Group></div>
                            <div className='col-6 col-md-6'>              <Form.Group className="mb-3">
                                <Form.Label>Producto de referencia</Form.Label>
                                <Form.Select aria-label="" name="group_id">
                                    <option value="">Seleccione un producto</option>
                                    {products.map((product, index) => (
                                        <option value={product.group_id}>{product.name}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group></div>
                            <div className='col-12 col-md-6'>
                                <Form.Group className="mb-3" controlId="secundaryName">
                                    <Form.Label>Descripción</Form.Label>
                                    <Form.Control
                                        as="textarea" rows={3}
                                        placeholder={combo.description}
                                        name="description"

                                    />
                                </Form.Group></div>

                            <RenderIf isTrue={checkUserModules('pos_integrations_module', user.modules) !== -1 && user.business.posIntegration !== undefined}>

                                <div className='col-6 col-md-6'>                                    <Form.Group className="mb-3" >
                                    <Form.Label>Referencia externa</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Referencia externa"
                                        name="external_reference"
                                    />
                                </Form.Group>
                                </div></RenderIf>

                            <RenderIf isTrue={branchoffice_id !== 0}>
                                <div className='col-6 col-md-6'>


                                    <Form.Group className="mb-3" >
                                        <Form.Label>Favorito/destacado</Form.Label>
                                        <Form.Select aria-label="" name="favorite">
                                            <RenderIf isTrue={combo.favorite == 0}>
                                                <option value={0}>No</option>
                                                <option value={1}>Si</option>
                                            </RenderIf>
                                            <RenderIf isTrue={combo.favorite == 1}>
                                                <option value={1}>Si</option>
                                                <option value={0}>No</option>
                                            </RenderIf>
                                        </Form.Select>
                                    </Form.Group>
                                </div></RenderIf>

                            <RenderIf isTrue={branchoffice_id !== 0}>
                                <div className='col-6 col-md-6'>



                                    <Form.Group className="mb-3" >
                                        <Form.Label>Habilitar combo para venta cruzada</Form.Label>
                                        <Form.Select aria-label="" name="cross_sell" defaultChecked={combo.cross_sell}>
                                            <RenderIf isTrue={combo.cross_sell == 0}>
                                                <option value="0">No</option>
                                                <option value="1">Si</option>
                                            </RenderIf>
                                            <RenderIf isTrue={combo.cross_sell == 1}>
                                                <option value="1">Si</option>
                                                <option value="0">No</option>
                                            </RenderIf>
                                        </Form.Select>
                                    </Form.Group>
                                </div> </RenderIf>

                            <div className='col-6 col-md-6'>
                                <Form.Group controlId="formFileMultiple" className="mb-3">
                                    <Form.Label></Form.Label>
                                    <Form.Control
                                        id="upload-button"
                                        type="file"
                                        placeholder="Imagen"
                                        name="files"
                                        onChange={(e) => changeimg(e)}
                                        hidden
                                    />
                                </Form.Group>
                            </div>

                            <div className='col-12'>
                                <div className="d-flex justify-content-end">
                                    <Button className="btn btn-save-sp" type="submit">
                                        Guardar
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal >

        </>
    )
}

export default ModalEditCombo