import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
//Components
import Navbar from '../NavBar/Navbar';
import Table from '../Table/Table';
import Simphony from './Oracle/Simphony';
import Micros from './Oracle/Micros';
//Utils 
import { RenderIf } from '../utils/RenderIf';
//API
import { BranchOfficeFetchAPI } from '../../api/BranchOffices';
import { PosIntegrationFetchAPI } from '../../api/PosIntegrations';
import { GroupsFechtAPI } from '../../api/Groups';
import { CombosFetchAPI } from '../../api/Combos';
import { SynergyFetchAPI } from '../../api/Synergy';
export default function IntegrationManager() {
    //Params 
    let { business_code } = useParams();
    //LocalStorage
    let user = localStorage.getItem('user')
    let token = localStorage.getItem('x-access-token');
    //States 
    const [userBranchoffices, setUserBranchs] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState({
        code: 0,
        id: 0,
        name: ""
    });
    const [eventHandler, setEventHandler] = useState(0);
    const [posIntegrationParams, setPosIntegrationParams] = useState([]);
    const [syncronized, setSyncronized] = useState(0);
    //We valilink if the user is logged in
    if (user === null || user === undefined || user === '') {
        user = {
            business: {
                posIntegration: {
                    name: "Ninguna"
                },
                pos_integration_id: null
            },
        }
    } else {
        user = JSON.parse(localStorage.getItem('user'))
    }

    //Function to get the branch office
    const getBranchoffice = async (code) => {
        try {
            //We search the branch office
            const branch = await BranchOfficeFetchAPI.getBranchOfficeByCode(business_code, code);
            setSelectedBranch({
                name: branch.data.branchoffice.name,
                code: branch.data.branchoffice.code,
                id: branch.data.branchoffice.id
            });
            //We set the event handler
            setEventHandler(eventHandler + 1);
        } catch (err) {
            //We set the branch office to 0
            setSelectedBranch({
                code: 0,
                id: 0
            });
            //We set the event handler
            setEventHandler(eventHandler + 1);
        }
    }

    //Function to populate the users branch 
    const branchList = () => {
        const listBracnh = userBranchoffices.map((branch) => (
            <>
                <option value={branch.code}>{branch.branchoffice_name}</option>
            </>
        ));
        return listBracnh
    }

    //Get integration params
    const getIntegrationParams = async () => {
        try {
            const params = await PosIntegrationFetchAPI.getPosIntegrationParamsByBranch(user.business.pos_integration_id, selectedBranch.id, token)
            //We set the params 
            setPosIntegrationParams(params.data.params_values);
        } catch (err) {
            setPosIntegrationParams([])
        }
    }

    //Hook to get the integration params every time the event handler changes 
    useEffect(() => {
        getIntegrationParams()
    }, [eventHandler])

    //Hook to load the initial data 
    useEffect(() => {
        //We set the branchoffices of the user 
        setUserBranchs(user.branchoffices);
        setEventHandler(0);
    }, []);

    //Function to sincronyze the synergy database
    const sincronyzeSynergy = async () => {
        let synergyProducts = [];
        //We get the combos of the branch office
        let branchofficeCombos = [];
        try {
            //We get the products of the branch office
            let branchofficeProducts = await GroupsFechtAPI.getGroupsByBranchoffice(selectedBranch.id, token);
            //We set the products to insert in the synergy database
            branchofficeProducts.data.data.forEach(group => {
                synergyProducts.push({
                    "oracle_reference": group.external_reference,
                    "is_combo": 0,
                    "name": group.name,
                    "description": group.description,
                    "price": group.price,
                    "status": group.status !== null && group.status !== undefined ? group.status : 0,
                    "img_url": group.img,
                });
            });

            try {
                branchofficeCombos = await CombosFetchAPI.getCombosByBranchOffice(selectedBranch.id, token);
                //We set the combos to insert in the synergy database
                branchofficeCombos.data.combos.forEach(combo => {
                    synergyProducts.push({
                        "oracle_reference": combo.external_reference,
                        "is_combo": 1,
                        "name": combo.name,
                        "description": combo.description,
                        "price": combo.referenced_group !== null && combo.referenced_group !== undefined ? combo.referenced_group.price : 0,
                        "status": combo.status !== null && combo.status !== undefined ? combo.status : 0,
                        "img_url": combo.img,
                    });
                });
            } catch (err) {
                branchofficeCombos = [];
            }


            //We get the revenue center reference and location reference
            let revenueCenter = posIntegrationParams.find(param => param.param_name === "RvcRef");
            let locationReference = posIntegrationParams.find(param => param.param_name === "LocRef");
            //We insert the products in the synergy database
            let dataSynergy = {
                "products": synergyProducts,
                "business_code": business_code,
                "branchoffice_code": selectedBranch.code,
                "revenue_center_reference": revenueCenter.value,
                "location_reference": locationReference.value,
            }
            //We insert the products in the synergy database
            await SynergyFetchAPI.insertProducts(dataSynergy);

        } catch (err) {
            console.log(err)
        }


    }
    //Hook to know when the syncronization is done
    useEffect(() => {
        if (syncronized === 1) {
            sincronyzeSynergy();
        }
        setSyncronized(0);
    }, [syncronized])


    return (
        <>
            <Navbar titlePage={user.business.pos_integration_id === 2 ? "Micros" : "Simphony"} />
            <div className=' bg-light pt-4'>
                <div className='container'>
                    {/** Main container */}
                    <main className='m-2'>
                        <section className='banner-sp d-lg-flex justify-content-lg-between  align-items-center rounded-4 mx-2  p-2 '>
                            <div>
                                <div className='d-flex justify-content-between  align-items-center'>
                                    <h2 className='banner-reports-title mb-0 pb-0'>
                                        {user.business.pos_integration_id === 2 ? "Micros" : "Simphony"}
                                    </h2>

                                </div>
                                <p className='m-0 p-0 banner-reports-description'>Manejo de {user.business.pos_integration_id === 2 ? "Micros" : "Simphony"} en tiempo real.</p>
                            </div>
                            <form className='filter-sp-trn'>
                                <div className="d-block">
                                    <select onChange={e => getBranchoffice(e.target.value)} className='form-select' name="user_branch" id="user_branch">
                                        <option value={0}>Seleccione sucursal</option>
                                        {branchList()}
                                    </select>
                                </div>
                            </form>
                        </section>

                        <div className="container">
                            {/**Depending of the integration we render the sincronization button this is for Simphony */}
                            <RenderIf isTrue={user.business.pos_integration_id === 1}>
                                <Simphony
                                    branchoffice={selectedBranch}
                                    token={token}
                                    pos_integration_id={user.business.pos_integration_id}
                                    setSyncronized={setSyncronized}
                                />
                            </RenderIf>

                            {/** This is for Micros*/}
                            <RenderIf isTrue={user.business.pos_integration_id === 2}>
                                <Micros
                                    branchoffice={selectedBranch}
                                    business_code={business_code}
                                    token={token}
                                    pos_integration_id={user.business.pos_integration_id}
                                />
                            </RenderIf>
                            {/** Table of integration params  */}
                            <Table
                                table_name={`Parametros de: ${user.business.posIntegration.name}`}
                                table_headers={["Parametro", "Valor", "Descripcion"]}
                                table_data={posIntegrationParams}
                                table_type={"integration_param_table"}
                            />
                        </div>
                    </main>
                </div>
            </div>
        </>
    )
}
