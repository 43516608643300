//React components
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
//API
import { BranchOfficeFetchAPI } from '../../api/BranchOffices';
import { MachinesFetchAPI } from '../../api/Machines';
import { PaymentMethodFetchAPI } from '../../api/PaymentMethod';
import { CategoriesDashFetchAPI } from '../../api/CategoriesDash';

//Internal components 
import NavBar from '../NavBar/Navbar';
import Table from '../Table/Table';
//Modals 
import ModalBranchofficeSelect from '../NavBar/ModalBranchofficeSelect';
import ModalMachineDetails from './Modals/ModalMachineDetails';
import ModalSettleMachine from './Modals/ModalSettleMachine';
//Alerts
import SuccessAlert from '../Alerts/SuccessAlert';
import ErrorAlert from '../Alerts/ErrorAlert';
const Machines = () => {
    //Params 
    let { business_code } = useParams();
    //LocalStorage
    let user = JSON.parse(localStorage.getItem('user'))
    let token = localStorage.getItem('x-access-token');
    //States
    const [selectedBranch, setSelectedBranch] = useState({
        code: 0,
        id: 0,
        name: ""
    });
    const [userBranchoffices, setUserBranchs] = useState([]);
    const [paymentMethodIntegrations, setPaymentMethodIntegrations] = useState([]);
    const [eventHandler, setEventHandler] = useState(0);
    const [eventHandlerSettle, setEventHandlerSettle] = useState(0);
    const [userCategories, setCategories] = useState([]);

    //Machines 
    const [machinesList, setMachinesList] = useState([]);
    const [selectedMachine, setSelectedMachine] = useState(0);
    const [selectedMachineCode, setSelectedMachineCode] = useState(0);
    //Modal states 
    const [showModalDetails, setShowModalDetails] = useState(false);
    const [showModalSettle, setShowModalSettle] = useState(false);
    //Function to get the branch office
    const getBranchoffice = async (code) => {
        try {
            //We search the branch office
            const branch = await BranchOfficeFetchAPI.getBranchOfficeByCode(business_code, code);
            setSelectedBranch({
                name: branch.data.branchoffice.name,
                code: branch.data.branchoffice.code,
                id: branch.data.branchoffice.id
            });
            //We set the event handler
            setEventHandler(eventHandler + 1);
        } catch (err) {
            //We set the branch office to 0
            setSelectedBranch({
                code: 0,
                id: 0
            });
            //We set the event handler
            setEventHandler(eventHandler + 1);
        }
    }

    //Function to get the payment method integrations
    const getPaymentMethodIntegrations = async () => {
        try {
            //We get the payment method integrations
            const paymentMethodIntegrations = await PaymentMethodFetchAPI.getPaymentMethodIntegrations(token);
            console.log(paymentMethodIntegrations)
            setPaymentMethodIntegrations(paymentMethodIntegrations.data.integrations)
        } catch (err) {
            setPaymentMethodIntegrations([])
        }
    }


    //Function to load actegories
    const getCategories = async () => {
        try {
            if (selectedBranch.code == 0) {
                const response = await CategoriesDashFetchAPI.getCategoriesByBusiness(user.business_id, token)
                setCategories(response.data.data)
            } else {
                const response = await CategoriesDashFetchAPI.getCategoriesByBranchOffice(selectedBranch.id, token)
                setCategories(response.data.data)
            }
        } catch (err) {
            setCategories([])
        }
    }


    //Function to populate the users branch 
    const branchList = () => {
        const listBracnh = userBranchoffices.map((branch) => (
            <>
                <option value={branch.code}>{branch.branchoffice_name}</option>
            </>
        ));
        return listBracnh
    }

    //Function to get the machines of the branch office
    const getMachines = async (branchoffice_id) => {
        try {
            //We get the machines of the branch office
            const machines = await MachinesFetchAPI.getMachinesByBranchOffice(branchoffice_id, token);
            //console.log(machines)
            setMachinesList(machines.data.data)
        } catch (err) {
            setMachinesList([])
        }
    }

    //Function to update the login machine
    const updateMachineStatus = async (machine_code, status) => {
        try {
            //We update the machine status
            const res = await MachinesFetchAPI.updateMachineStatus(machine_code, status, token);

            //We show the message
            SuccessAlert(res.data.message)
        } catch (err) {
            //We show the message
            ErrorAlert("Error al actualizar el estado de la maquina", "Error", "error")
        }

    }


    const actionSelectBranch = (branchCode) => {
        getBranchoffice(branchCode);
    }


    //Hook to load the initial data 
    useEffect(() => {
        //We set the branchoffices of the user 
        setUserBranchs(user.branchoffices);
        getPaymentMethodIntegrations()
        getCategories()
        setEventHandler(0);
    }, []);

    //Hook to get the machines of the branch office
    useEffect(() => {
        if (selectedBranch.code !== 0) {
            getMachines(selectedBranch.id)
            getCategories()
        }
        setEventHandler(0)
    }, [eventHandler])

    return (
        <>
            <NavBar titlePage="Máquinas" />
            <div className=' bg-light pt-5'>
                <div className='container'>
                    {/** Main container */}
                    <div className="m-2">
                        <section className='banner-reports d-flex d-lg-flex justify-content-lg-between align-items-center
                     mb-4 banner-sp rounded-4 py-2 px-3 '>
                            <div>
                                <div className='d-flex justify-content-lg-between align-items-center'>
                                    <h2 className='banner-reports-title mb-0 pb-0'>
                                        Máquinas:  {selectedBranch.name ? <>{selectedBranch.name}</> : <>Seleccione sucursal</>}
                                    </h2>
                                </div>
                                <p className='m-0 p-0 banner-reports-description'>Gestión de máquinas.</p>
                            </div>
                            {/*<div className="mt-2 w-50">

                                <select onChange={e => getBranchoffice(e.target.value)} className='form-select' name="user_branch" id="user_branch">
                                    <option value={0}>Seleccione sucursal</option>
                                    {branchList()}
                                </select>
                            </div>*/}

                            <ModalBranchofficeSelect actionSelectBranch={actionSelectBranch}></ModalBranchofficeSelect>

                        </section>

                            {/** Table */}
                            <Table
                                table_name={"Máquinas"}
                                table_headers={['Sesión', 'Nombre', 'Tipo de máquina', 'Código de Máquina', 'Acciones']}
                                table_data={machinesList}
                                table_type={"machine_table"}
                                setSelectedMachine={setSelectedMachine}
                                setSelectedMachineCode={setSelectedMachineCode}
                                setShowModalDetails={setShowModalDetails}
                                setEventHandler={setEventHandler}
                                setEventHandlerSettle={setEventHandlerSettle}
                                updateMachineStatus={updateMachineStatus}
                                setShowSettleModal={setShowModalSettle}
                                user={user}
                            />

                        {/** Detail Modal */}
                        <ModalMachineDetails
                            showModal={showModalDetails}
                            setShowModal={setShowModalDetails}
                            machine_id={selectedMachine}
                            token={token}
                            eventHandler={eventHandler}
                            setEventHandler={setEventHandler}
                            setEventHandlerSettle={setEventHandlerSettle}
                            eventHandlerSettle={eventHandlerSettle}
                            userBranchoffices={userBranchoffices}
                            paymentMethodIntegrations={paymentMethodIntegrations}
                            user={user}
                            selectedBranchoffice={selectedBranch}
                            userCategories={userCategories}
                        />
                        {/** Detail Modal */}
                        <ModalMachineDetails
                            showModal={showModalDetails}
                            setShowModal={setShowModalDetails}
                            machine_id={selectedMachine}
                            token={token}
                            eventHandler={eventHandler}
                            setEventHandler={setEventHandler}
                            setEventHandlerSettle={setEventHandlerSettle}
                            eventHandlerSettle={eventHandlerSettle}
                            userBranchoffices={userBranchoffices}
                            paymentMethodIntegrations={paymentMethodIntegrations}
                            user={user}
                            selectedBranchoffice={selectedBranch}
                            userCategories={userCategories}
                        />

                            {/** Settle modal */}
                            <ModalSettleMachine
                                showModal={showModalSettle}
                                setShowModal={setShowModalSettle}
                                token={token}
                                setEventHandler={setEventHandlerSettle}
                                eventHandler={eventHandlerSettle}
                                machine_id={selectedMachine}
                                selectedBranch={selectedBranch}
                                machine_code={selectedMachineCode}
                            />

                    </div>
                </div>
            </div>
        </>
    );
}

export default Machines;
