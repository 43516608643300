//React components
import React, { useState, useRef, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
//Images
import defaultImage from "../../../assets/img/default.png";
//Utils
import { RenderIf } from '../../utils/RenderIf';
//API
import { CouponPosFetchAPI } from '../../../api/Coupon';
//Alerts
import SuccessAlert from '../../Alerts/SuccessAlert';
import ErrorAlert from '../../Alerts/ErrorAlert';

//css

import './cupon.css';

// Modal create cupons 
const ModalCreateCupon = ({ user, token, combos, groups, setEventHandler }) => {
    const [showModal, setShowModal] = useState(false);
    const [imgpreview, setImgPreview] = useState(defaultImage);
    const [cuponType, setCuponType] = useState('ORDER');
    const [showDateLimit, setShowDateLimit] = useState(false);
    const [showSpecificHours, setShowSpecificHours] = useState(false);
    const [showSpecificDays, setShowSpecificDays] = useState(false);
    const [selectedDays, setSelectedDays] = useState([]);
    const [discount_type, setDiscountType] = useState('PERCENTAGE');
    const [hasUsageLimit, setHasUsageLimit] = useState(false);
    const [usageLimit, setUsageLimit] = useState('');
    const [needsUniqueCodes, setNeedsUniqueCodes] = useState(false);
    const [uniqueCodeQuantity, setUniqueCodeQuantity] = useState('');
    const [selectedProducts, setSelectedProducts] = useState([]);
    const fileInputRef = useRef(null);


    const [couponName, setCouponName] = useState('');

    // Function to trigger file input click
    const handleClick = () => {
        fileInputRef.current.click();
    };

    // Handle image preview update
    const changeImage = (e) => {
        setImgPreview(URL.createObjectURL(e.target.files[0]));
    };

    // Handle day selection for specific days
    const handleDaySelection = (day) => {
        setSelectedDays((prevDays) =>
            prevDays.includes(day)
                ? prevDays.filter((d) => d !== day)
                : [...prevDays, day]
        );
    };

    // Handle form submission
    const handleFormSubmit = async (e) => {
        e.preventDefault(); // Previene el comportamiento por defecto del formulario

        // Recopilación de los datos del formulario
        const formData = new FormData();

        // Agregamos la imagen del cupón (si se seleccionó)
        if (fileInputRef.current.files[0]) {
            formData.append('files', fileInputRef.current.files[0]);
        }

        // Agregamos los demás campos del formulario
        const couponData = {
            name: e.target.name.value,
            type: cuponType,
            business_id: user.business_id, // Suponiendo que tienes el ID del negocio en `user`
            is_general: needsUniqueCodes ? 1 : 0, // O lo que sea relevante
            discount_type: discount_type,
            discount_value: e.target.discount_value.value,
            description: e.target.description.value,
            min_order_value: cuponType === "ORDER" ? e.target.min_order_value.value : null,
            max_discount: cuponType === "ORDER" ? e.target.max_discount.value : null,
            products: selectedProducts.map(product => ({
                product_id: product.product_id,
                is_combo: product.is_combo
            })),
            conditions: [],
        };

        // Agregar condiciones si son aplicables
        if (showSpecificDays) {
            selectedDays.forEach(day => {
                couponData.conditions.push({ type: 'DAY', value: day });
            });
        }

        if (e.target.usage_limit !== undefined || null) {
            couponData.usage_limit = e.target.usage_limit.value;
        } else {
            couponData.usage_limit = 0;
        }

        if (e.target.code_count !== undefined || null) {
            couponData.code_count = e.target.code_count.value;
        } else {
            couponData.code_count = 0;
        }

        if (showSpecificHours) {
            couponData.conditions.push({ type: 'TIME', value: `${e.target.initial_hour.value} - ${e.target.expiration_hour.value}` });
        }

        if (showDateLimit) {
            couponData.conditions.push({ type: 'expiration_date', value: e.target.expiration_date.value });
        }

        if (couponData.min_order_value) {
            couponData.conditions.push({ type: 'min_order_value', value: couponData.min_order_value });
        }

        if (couponData.max_discount) {
            couponData.conditions.push({ type: 'max_discount', value: couponData.max_discount });
        }

        //We add the coupon data to the form data


        formData.append("name", couponName);
        setCouponName(couponData.name);
        formData.append("type", couponData.type);
        formData.append("business_id", couponData.business_id);
        formData.append("is_general", couponData.is_general);
        formData.append("discount_type", couponData.discount_type);
        formData.append("discount_value", couponData.discount_value);
        formData.append("description", couponData.description);
        formData.append("products", JSON.stringify(couponData.products));
        formData.append("conditions", JSON.stringify(couponData.conditions));
        formData.append("usage_limit", couponData.usage_limit);
        formData.append("code_count", couponData.code_count);
        formData.append("min_order_value", couponData.min_order_value);
        formData.append("max_discount", couponData.max_discount);



        try {
            //We send the data to the API
            await CouponPosFetchAPI.newCupon(formData, token);
            //We close the modal
            setShowModal(false);
            //We show a success message
            SuccessAlert("Cupón creado exitosamente");
            //We update the event handler
            setEventHandler(1);
        } catch (err) {
            console.error(err);
            //We show an error message
            ErrorAlert("Error al crear el cupón", "Inténtalo de nuevo", "error");
        }
    };

    const [step, setStep] = useState(1); // Estado para el paso actual
    const totalSteps = 7; // Número total de pasos

    const nextStep = () => {
        if (step < totalSteps) setStep(step + 1);
    };

    const prevStep = () => {
        if (step > 1) setStep(step - 1);
    };

    useEffect(() => {
        if (!showModal) {
            setStep(1);
        }
    }, [showModal]);
    return (
        <>
            <button
                className={'btn btn-primary border-0 d-block '}
                onClick={() => setShowModal(true)}          >
                Crear Cupón
            </button>

            <Modal
                show={showModal}
                backdrop="static"
                onHide={() => (setShowModal(false), setImgPreview(defaultImage), setSelectedProducts([]), setDiscountType("PERCENTAGE"))}
                size='lg'
                centered
            >
                <Modal.Body className='position-relative'>
                    <div class="d-flex justify-content-between align-items-center border-bottom-0 pb-4">
                        <h2 class="modal-title fs-5">Crear cupón</h2>
                        <button type="button" class="btn-close" onClick={() => setShowModal(false)} title='Cerrar'></button>
                    </div>

                    <div className="">
                        <Form onSubmit={handleFormSubmit} >
                            {/* <ProgressBar now={(step / totalSteps) * 100} label={`Paso ${step} de ${totalSteps}`} /> */}

                            {/* ******** Step 01: Imagen de cupón ******** */}
                            <div className={`paso ${step === 1 ? "activo" : ""}`}>
                                <div className='row'>

                                    <div className='col-12 col-md-6' title='Clic para seleccionar una imagen'>
                                        <span className='text-black fw-semibold'>
                                            Imagen de cupón
                                        </span>
                                        <div className='p-2 border rounded-2' onClick={handleClick}>
                                            <div className='d-flex align-items-center justify-content-center'>
                                                <img className='imgEditModal border rounded-2 ' src={imgpreview} alt="Preview" />
                                            </div>
                                            <div className='d-flex align-items-center justify-content-center mt-2'>
                                                <h6 className="text-center text-primary opacity-50">Seleccione una imagen</h6>
                                            </div>
                                        </div>
                                        <Form.Group className="mb-3 visually-hidden">
                                            <Form.Control
                                                type="file"
                                                id="upload-button"
                                                placeholder="Imagen"
                                                name="files"
                                                onChange={(e) => changeImage(e)}
                                                ref={fileInputRef}
                                            />
                                        </Form.Group>

                                    </div>
                                    <div className='col-12 col-md-6'>

                                        {/* Step 02: Nombre y Descripción */}
                                        <Form.Group className="mb-3">
                                            <Form.Label className='text-black fw-semibold'>Nombre</Form.Label>
                                            <Form.Control type="text" placeholder="Nombre del cupón" name="name" />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label className='text-black fw-semibold'>Descripción</Form.Label>
                                            <Form.Control as="textarea" rows={8} placeholder="Descripción del cupón" name="description" />
                                        </Form.Group>

                                    </div>
                                </div>

                            </div>
                            {/* ******** End Step 01: Imagen de cupón ******** */}

                            <div className={`paso ${step === 2 ? "activo" : ""}`}>
                                {/* Step 03: Tipo de cupón y Tipo de descuento */}
                                {/* <Form.Group className="mb-3">
                                    <Form.Label className='text-black fw-semibold'>Tipo de cupón</Form.Label>
                                    <Form.Select name="type" onChange={(e) => { setCuponType(e.target.value); setDiscountType("PERCENTAGE"); setSelectedProducts([]) }}>
                                        <option value="ORDER">Toda la compra</option>
                                        <option value="PRODUCT">Producto en específico</option>
                                    </Form.Select>
                                </Form.Group> */}
                                <Form.Label className='text-black fw-semibold'>Tipo de cupón</Form.Label>
                                <div>
                                    {/* <Form.Check
                                        type="radio"
                                        label="Toda la compra"
                                        name="type"
                                        value="ORDER"
                                        checked={cuponType === "ORDER"}
                                        onChange={(e) => { setCuponType(e.target.value); setDiscountType("PERCENTAGE"); setSelectedProducts([]) }}
                                    />
                                    <Form.Check
                                        type="radio"
                                        label="Producto en específico"
                                        name="type"
                                        value="PRODUCT"
                                        checked={cuponType === "PRODUCT"}
                                        onChange={(e) => { setCuponType(e.target.value); setDiscountType("PERCENTAGE"); setSelectedProducts([]) }}
                                    /> */}

                                    <div className='row g-3'>
                                        <div className='col-12 col-md-6'>
                                            <input type="radio" class="btn-check" name="options-outlined" id="ORDER" autocomplete="off"
                                                value="ORDER"
                                                checked={cuponType === "ORDER"}
                                                onChange={(e) => { setCuponType(e.target.value); setDiscountType("PERCENTAGE"); setSelectedProducts([]) }}

                                            />
                                            <label className="btn btn-outline-primary " for="ORDER">
                                                <div className='flex-column'>
                                                    <span className="d-block p-2  text-start fw-bold"> <i class="uil uil-shopping-cart"></i> Toda la compra</span>
                                                    <span className="d-block p-2   text-start m-0">Este tipo de cupón solo es válido a toda la compra, ofreciendo un descuento sobre el total del pedido antes de finalizar el pago.</span>
                                                </div>
                                            </label>
                                        </div>
                                        <div className='col-12 col-md-6'>
                                            <input type="radio" class="btn-check" name="options-outlined" id="PRODUCT" autocomplete="off"

                                                value="PRODUCT"
                                                checked={cuponType === "PRODUCT"}
                                                onChange={(e) => { setCuponType(e.target.value); setDiscountType("PERCENTAGE"); setSelectedProducts([]) }}

                                            />
                                            <label class="btn btn-outline-primary " for="PRODUCT">
                                                <div className='flex-column'>
                                                    <span class="d-block p-2  text-start fw-bold">
                                                        <i class="uil uil-package me-2"></i>
                                                        Producto en específico</span>
                                                    <span class="d-block p-2   text-start m-0"> Este cupón es válido solo para un producto específico y su descuento no se aplica a otros artículos del pedido.
                                                    </span>

                                                </div>

                                            </label>
                                        </div>
                                    </div>
                                </div>



                                {/* <div className='row g-3'>
                                    <div className='col-12 col-md-6'>
                                        <input type="radio" class="btn-check" name="options-discount" id="Porcentaje" autocomplete="off"


                                        />
                                        <label className="btn btn-outline-success " for="Porcentaje">
                                            <div className='flex-column'>
                                                <span className="d-block p-2  text-start fw-bold"> <i class="uil uil-percentage"></i>Porcentaje</span>
                                                <span className="d-block p-2   text-start m-0">Aplica un porcentaje de descuento sobre el precio del producto o el total de la compra, reduciendo el costo. </span>
                                            </div>
                                        </label>
                                    </div>
                                    <div className='col-12 col-md-6'>
                                        <input type="radio" class="btn-check" name="options-discount" id="Monto" autocomplete="off"


                                        />
                                        <label class="btn btn-outline-success " for="Monto">
                                            <div className='flex-column'>
                                                <span class="d-block p-2  text-start fw-bold">
                                                    <i class="uil uil-dollar-alt"></i>
                                                    Monto </span>
                                                <span class="d-block p-2   text-start m-0"> Reduce el precio en una cantidad específica, restando un monto fijo al producto o al total del pedido.
                                                </span>

                                            </div>

                                        </label>
                                    </div>

                                    <RenderIf isTrue={cuponType === "PRODUCT"}>
                                        <div className='col-12 col-md-6'>
                                            <input type="radio" class="btn-check" name="options-discount" id="FREE_PRODUCT" autocomplete="off"
                                                value="FREE_PRODUCT"

                                            />
                                            <label class="btn btn-outline-success " for="FREE_PRODUCT">
                                                <div className='flex-column'>
                                                    <span class="d-block p-2  text-start fw-bold">
                                                        <i class="uil uil-dollar-alt"></i>
                                                        Producto gratis </span>
                                                    <span class="d-block p-2   text-start m-0"> Al aplicar este cupón, recibirás un producto sin costo adicional, ya sea como regalo promocional o al cumplir ciertas condiciones de compra.
                                                    </span>

                                                </div>

                                            </label>
                                        </div>

                                    </RenderIf>
                                </div> */}
                            </div>

                            <div className={`paso ${step === 3 ? "activo" : ""}`}>

                                <Form.Group className="mt-3">
                                    <Form.Label className='text-black fw-semibold '>Tipo de descuento</Form.Label>
                                    {/* <Form.Select name="discount_type" onChange={(e) => { setDiscountType(e.target.value); }}>
                                        <option value="PERCENTAGE">Porcentaje</option>
                                        <option value="FIXED">Monto</option>
                                        <RenderIf isTrue={cuponType === "PRODUCT"}>
                                            <option value="FREE_PRODUCT">Producto gratis</option>
                                        </RenderIf>
                                    </Form.Select> */}
                                    <div className='row g-3'>
                                        <div className='col-12 col-md-6'>
                                            <input type="radio" class="btn-check" name="discount_type" id="PERCENTAGE" value="PERCENTAGE"
                                                checked={discount_type === "PERCENTAGE"}
                                                onChange={(e) => setDiscountType(e.target.value)}
                                            />
                                            <label className="btn btn-outline-success" for="PERCENTAGE">
                                                <div className='flex-column'>
                                                    <span className="d-block p-2 text-start fw-bold"><i class="uil uil-percentage"></i> Porcentaje</span>
                                                    <span className="d-block p-2 text-start m-0">Aplica un porcentaje de descuento sobre el precio del producto o el total de la compra, reduciendo el costo.</span>
                                                </div>
                                            </label>
                                        </div>
                                        <div className='col-12 col-md-6'>
                                            <input type="radio" class="btn-check" name="discount_type" id="FIXED" value="FIXED"
                                                checked={discount_type === "FIXED"}
                                                onChange={(e) => setDiscountType(e.target.value)}
                                            />
                                            <label class="btn btn-outline-success" for="FIXED">
                                                <div className='flex-column'>
                                                    <span class="d-block p-2 text-start fw-bold"><i class="uil uil-dollar-alt"></i> Monto</span>
                                                    <span class="d-block p-2 text-start m-0">Reduce el precio en una cantidad específica, restando un monto fijo al producto o al total del pedido.</span>
                                                </div>
                                            </label>
                                        </div>
                                        <RenderIf isTrue={cuponType === "PRODUCT"}>
                                            <div className='col-12 col-md-6'>
                                                <input type="radio" class="btn-check" name="discount_type" id="FREE_PRODUCT" value="FREE_PRODUCT"
                                                    checked={discount_type === "FREE_PRODUCT"}
                                                    onChange={(e) => setDiscountType(e.target.value)}
                                                />
                                                <label class="btn btn-outline-success" for="FREE_PRODUCT">
                                                    <div className='flex-column'>
                                                        <span class="d-block p-2 text-start fw-bold"><i class="uil uil-gift"></i> Producto gratis</span>
                                                        <span class="d-block p-2 text-start m-0">Al aplicar este cupón, recibirás un producto sin costo adicional, ya sea como regalo promocional o al cumplir ciertas condiciones de compra.</span>
                                                    </div>
                                                </label>
                                            </div>
                                        </RenderIf>
                                    </div>
                                </Form.Group>

                            </div>

                            <div className={`paso ${step === 4 ? "activo" : ""}`}>
                                <div className='row'>
                                    {/* Step 04: Valores adicionales */}
                                    <RenderIf isTrue={cuponType === 'ORDER'}>

                                        <div className='col-12 col-md-4'>
                                            <Form.Group className="mb-3">
                                                <Form.Label className='text-black fw-semibold'>Valor mínimo de la orden</Form.Label>
                                                <Form.Control type="number" placeholder="0.00" name="min_order_value" />
                                            </Form.Group></div>
                                        <div className='col-12 col-md-4'>
                                            <Form.Group className="mb-3">
                                                <Form.Label className='text-black fw-semibold'>Máximo descuento</Form.Label>
                                                <Form.Control type="number" placeholder="0.00" name="max_discount" />
                                            </Form.Group>
                                        </div>

                                    </RenderIf>

                                    <RenderIf isTrue={cuponType === 'PRODUCT'}>
                                        <div className='col-12 col-md-4'>
                                            <Form.Group className="mb-3">
                                                <Form.Label className='text-black fw-semibold' onChange={e => {
                                                    setSelectedProducts(...{ combo_id: e.target.value, is_combo: 1 })
                                                }}>Combos</Form.Label>
                                                <Form.Select>
                                                    <option value={null}>Ninguno seleccionado</option>
                                                    {combos.map((combo) => (
                                                        <option key={combo.id} value={combo.id}>{combo.name}</option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>

                                        </div>
                                        <div className='col-12 col-md-4'>
                                            <Form.Group className="mb-3">
                                                <Form.Label className='text-black fw-semibold' onChange={e => {
                                                    setSelectedProducts(...{ group_id: e.target.value, is_combo: 0 })
                                                }}>Productos</Form.Label>
                                                <Form.Select>
                                                    <option value={null}>Ninguno seleccionado</option>
                                                    {groups.map((group) => (
                                                        <option key={group.group_id} value={group.group_id}>{group.name}</option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </div>
                                    </RenderIf>

                                    <div className='col-12 col-md-4'>

                                        <RenderIf isTrue={discount_type !== "FREE_PRODUCT"}>
                                            <Form.Group className="mb-3">
                                                <Form.Label className='text-black fw-semibold'>Valor</Form.Label>
                                                <Form.Control type="number" placeholder="0.00" name="discount_value" />
                                            </Form.Group>
                                        </RenderIf>
                                    </div>
                                </div>
                            </div>

                            <div className={`paso ${step === 5 ? "activo" : ""}`}>
                                <div className='row'>
                                    <div className='col-12 col-md-6'>
                                        {/* Step 05: Opciones adicionales */}
                                        <Form.Label className='text-black fw-semibold'>¿Deseas programar una fecha  de expiración?</Form.Label>
                                        <Form.Check
                                            type="switch"
                                            label={!showDateLimit ? "No" : "Sí, claro"}
                                            onChange={(e) => setShowDateLimit(e.target.checked)}
                                        />
                                        <RenderIf isTrue={showDateLimit}>
                                            <Form.Group className="mb-3 border rounded-2 p-2">
                                                <Form.Label className='text-primary fw-semibold'>Ingrese fecha de expiración</Form.Label>
                                                <Form.Control type="date" name="expiration_date" />
                                            </Form.Group>
                                        </RenderIf>
                                    </div>

                                    <div className='col-12 col-md-6'>


                                        <Form.Label className='text-black fw-semibold'>       ¿Quieres definir un rango de horas específico?</Form.Label>
                                        <Form.Check
                                            type="switch"
                                            label={!showSpecificHours ? "No" : "Sí, claro"}
                                            onChange={(e) => setShowSpecificHours(e.target.checked)}
                                        />
                                        <RenderIf isTrue={showSpecificHours}>
                                            <div className='row'>

                                                <div className='col-6'>
                                                    <Form.Group className="mb-3 border rounded-2 p-2">
                                                        <Form.Label className='text-primary fw-semibold'>Hora de inicio</Form.Label>
                                                        <Form.Control type="time" name="initial_hour" />
                                                    </Form.Group>
                                                </div>

                                                <div className='col-6'>
                                                    <Form.Group className="mb-3 border rounded-2 p-2">
                                                        <Form.Label className='text-primary fw-semibold'>Hora límite</Form.Label>
                                                        <Form.Control type="time" name="expiration_hour" />
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        </RenderIf>
                                    </div>
                                </div>
                            </div>



                            <div className={`paso ${step === 6 ? "activo" : ""}`}>

                                {/* <Form.Check
                                    type="checkbox"
                                    label="¿Días específicos?"
                                    onChange={(e) => setShowSpecificDays(e.target.checked)}
                                /> */}



                                <Form.Label className='text-black fw-semibold'> ¿Quieres establecer días específicos para activar el cupón? </Form.Label>
                                <Form.Check
                                    type="switch"
                                    label={!showSpecificDays ? "No" : "Sí, claro"}
                                    onChange={(e) => setShowSpecificDays(e.target.checked)}
                                />


                                <RenderIf isTrue={showSpecificDays}>
                                    <div className=' border rounded-2 p-2'>
                                        <Form.Label className='text-primary fw-semibold'>Seleccione los días</Form.Label>
                                        <div className='d-flex flex-wrap gap-3'>

                                            {['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo'].map((day) => (

                                                <>
                                                    {/* <Form.Check
                                                    key={day}
                                                    type="checkbox"
                                                    label={day}
                                                    onChange={() => handleDaySelection(day)}
                                                /> */}
                                                    <div key={day} >
                                                        <input type="checkbox" class="btn-check" name="options-days" id={`opt-day-${day}`} onChange={() => handleDaySelection(day)} />
                                                        <label class="btn btn-outline-primary" for={`opt-day-${day}`}>{day}</label>
                                                    </div>
                                                </>

                                            ))}
                                        </div>
                                    </div>

                                </RenderIf >

                                {/* <Form.Check
                                    type="checkbox"
                                    label="¿Límite de uso?"
                                    onChange={(e) => setHasUsageLimit(e.target.checked)}
                                /> */}


                                <Form.Label className='text-black fw-semibold mt-3'> ¿Quieres establecer un Límite de uso? </Form.Label>
                                <Form.Check
                                    type="switch"
                                    label={!hasUsageLimit ? "No" : "Sí, claro"}
                                    onChange={(e) => setHasUsageLimit(e.target.checked)}
                                />
                                <RenderIf isTrue={hasUsageLimit}>
                                    <Form.Group className="mb-3">
                                        <div className=' border rounded-2 p-2 w-50'>
                                            <Form.Label className='text-primary fw-semibold'>Ingrese cantidad máxima de usos por usuarios </Form.Label>
                                            <Form.Control
                                                className='w-50'
                                                type="number"
                                                placeholder="0"
                                                value={usageLimit}
                                                onChange={(e) => setUsageLimit(e.target.value)}
                                                name="usage_limit"
                                            />
                                        </div>
                                    </Form.Group>
                                </RenderIf>
                            </div>

                            <div className={`paso ${step === 7 ? "activo" : ""}`}>
                                <Form.Label className='text-black fw-semibold'>Cantidad de códigos únicos que deseas generar.</Form.Label>
                                <Form.Check
                                    type="switch"
                                    label={!hasUsageLimit ? "No" : "Sí, claro"}
                                    onChange={(e) => setNeedsUniqueCodes(e.target.checked)}
                                />
                                <RenderIf isTrue={needsUniqueCodes}>

                                    <Form.Group className="mb-3 w-50">
                                        <Form.Control
                                            type="number"
                                            placeholder="0"
                                            value={uniqueCodeQuantity}
                                            onChange={(e) => setUniqueCodeQuantity(e.target.value)}
                                            name="code_count"
                                        />
                                    </Form.Group>
                                </RenderIf>

                                <div className="mt-3 d-flex justify-content-end ">
                                    <button className='btn btn-save-sp' type="submit" >
                                        Guardar Cupón
                                    </button>
                                </div>
                            </div>

                            <div className={`paso ${step === 8 ? "activo" : ""}`}>
                                {/* Step 06: Guardar */}
                                <h6>
                                    Previzualización de Cupón Creado
                                </h6>
                                <div className='row'>
                                    <div className='col-12 col-md-12 border py-1'>
                                        <div className='p-2 border rounded-2' onClick={handleClick}>
                                            <div className='d-flex align-items-center justify-content-center'>
                                                <img className='imgEditModal border rounded-2 ' src={imgpreview} alt="Preview" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-12 border py-5'>
                                        <h2 name="name">{couponName}</h2>
                                    </div>
                                    <div className='col-12 col-md-12 border py-5'>


                                    </div>
                                </div>
                                <div className="mt-3 d-flex justify-content-end ">
                                    <Button variant="success" type="submit" >
                                        Guardar
                                    </Button>
                                </div>
                            </div>

                            <div className="botones-navegacion">
                                <button onClick={prevStep} disabled={step === 1} type="button">
                                    Anterior
                                </button>
                                <button onClick={nextStep} disabled={step === totalSteps} type="button" >
                                    Siguiente
                                </button>
                            </div>

                        </Form>
                    </div>
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button onClick={() => setShowModal(false)}>Cerrar</Button>
                </Modal.Footer> */}
            </Modal >
        </>
    );
};

export default ModalCreateCupon;
