import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom'
//Utils 
import { RenderIf } from '../utils/RenderIf';
import { checkUserModules } from "../utils/CheckUserModules";
//Img
import ModalLanguage from "./ModalLanguage";
import logo_sp from '../img/logos/icon-white.png';
import user_profile from '../img/icons/usuario.png';
//CSS
import "./navbar.css"

import "./offcanvas.css"
import Footer from "../Footer/Footer";


const NavBar = ({ titlePage }) => {

    //Nav Bar 
    const [outLogShow, setOutLogShow] = useState(false);

    //Params 
    let { business_code } = useParams();
    let user = localStorage.getItem('user')

    const navigate = useNavigate();


    //We valilink if the user is logged in
    if (user === null || user === undefined || user === '') {
        user = {
            name: 'No registrado',
            img: 'https://www.pngitem.com/pimgs/m/30-307416_profile-icon-png-image-free-download-searchpng-employee.png'
        }
    } else {
        user = JSON.parse(localStorage.getItem('user'))
    }


    function signout() {
        //We delete the token 
        localStorage.setItem('x-access-token', "");
        localStorage.removeItem('user');
        localStorage.removeItem('branchoffice_selected');
        localStorage.removeItem('branchoffice');
        //We check the role of the user and depending of it we redirect 
        if (user.role.find(element => element.role_id === 4) !== undefined) {
            navigate(`/cashier/login/${business_code}`)
        } else if (user.role.find(element => element.role_id === 5) !== undefined) {
            navigate(`/dispatcher/login/${business_code}`)
        } else if (user.role.find(element => element.role_id === 3 || element.role_id === 6 || element.role_id === 7 || element.role_id === 8) !== undefined) {
            console.log('entro')
            navigate(`/login/${business_code}`)
        }
    }

    const menuMain = [
        {
            title: 'Operaciones ',
            icon: "uil uil-dashboard",
            subtitle: ['Transacciones', 'Precuentas'],
            link: ``,
            integrationOption: false,
            permition: checkUserModules('pay_module', user.modules) !== -1
                || checkUserModules('preaccount_module', user.modules) !== -1 ? true : false,
            items: [
                {
                    title: 'Transacciones',
                    description: "Pagos en tiempo real",
                    icon: 'uil uil-bill',
                    link: `/transactions/${business_code}`,
                    integrationOption: false,
                    permition: checkUserModules('pay_module', user.modules) === -1 ? false : true,
                },

                {
                    title: 'Precuentas',
                    description: "Resumen preliminar del consumo de un cliente",
                    icon: 'uil uil-invoice ',
                    link: `/temporal/${business_code}`,
                    integrationOption: false,
                    permition: checkUserModules('preaccount_module', user.modules) === -1 ? false : true

                },

            ]
        },

        {
            title: 'Stock',
            icon: 'uil uil-database-alt',
            link: ``,
            integrationOption: false,
            permition: checkUserModules('product_module', user.modules) !== -1
                || checkUserModules('inventory_module', user.modules) !== -1
                || checkUserModules('buy_module', user.modules) !== -1 ? true : false,

            items: [
                {
                    title: 'Productos',
                    description: "Gestión y creación de artículos y elementos para sus sucursales",
                    icon: 'uil  uil-shopping-bag ',
                    link: `/products/${business_code}`,
                    integrationOption: false,
                    permition: checkUserModules('product_module', user.modules) === -1 ? false : true

                },
                {
                    title: 'Inventario',
                    description: "Listado de productos o bienes disponibles en stock",
                    icon: 'uil uil-box',
                    link: `/inventory/${business_code}`,
                    integrationOption: false,
                    permition: checkUserModules('inventory_module', user.modules) === -1 ? false : true
                },

                {
                    title: 'Compras',
                    description: "Adquisición de productos para abastecer sus sucursales",
                    icon: 'uil uil-shopping-cart',
                    link: `/buys/${business_code}`,
                    integrationOption: false,
                    permition: checkUserModules('buy_module', user.modules) === -1 ? false : true

                },
            ]
        },


        {
            title: 'Análisis',
            icon: 'uil uil-chart-line ',
            link: `/notifications/${business_code}`,
            integrationOption: false,
            permition: checkUserModules('analitics_module', user.modules) !== -1
                || checkUserModules('report_module', user.modules) !== -1 ? true : false,

            items: [

                {
                    title: 'Analíticas',
                    icon: 'uil uil-arrow-growth',
                    description: "Visualización de datos sobre pagos, sucursales y transacciones",
                    link: `/analitics/${business_code}`,
                    integrationOption: false,
                    permition: checkUserModules('analitics_module', user.modules) === -1 ? false : true
                },
                {
                    title: 'Reportes',
                    icon: 'uil uil-file-graph',
                    description: "Muestra de resultados clave y análisis",
                    link: `/reports/${business_code}`,
                    integrationOption: false,
                    permition: checkUserModules('report_module', user.modules) === -1 ? false : true

                },
            ]
        },

        {
            title: 'Gestión ',
            icon: 'uil uil-sliders-v',
            link: ``,
            integrationOption: false,
            permition: checkUserModules('users_module', user.modules) !== -1
                || checkUserModules('event_module', user.modules) !== -1
                || checkUserModules('table_module', user.modules) !== -1
                || checkUserModules('notification_module', user.modules) !== -1 ? true : false,
            items: [
                {
                    title: 'Usuarios',
                    description: "Gestión y creación de usuarios",
                    icon: 'uil uil-user-square ',
                    link: `/users/${business_code}`,
                    integrationOption: false,
                    permition: checkUserModules('users_module', user.modules) === -1 ? false : true

                },

                {
                    title: 'Eventos',
                    icon: 'uil uil-calendar-alt ',
                    description: "Gestión y creación de eventos",
                    link: `/events/${business_code}`,
                    integrationOption: false,
                    permition: checkUserModules('event_module', user.modules) === -1 ? false : true
                },
                {
                    title: user.business.business_type.panamanianCode !== "9010" ? "Habitaciones" : "Mesas",
                    icon: user.business.business_type.panamanianCode !== "9010" ? "uil uil-bed-double" : 'uil uil-restaurant',
                    description: `Administración y creación de usuarios  ${user.business.business_type.panamanianCode !== "9010" ? "habitaciones" : "mesas"}`,
                    link: `/tables/${business_code}`,
                    integrationOption: false,
                    permition: checkUserModules('table_module', user.modules) === -1 ? false : true
                },
                {
                    title: 'Notificaciones',
                    description: "Envío de mensajes de clientes a la plataforma de administración",
                    icon: 'uil uil-bell',
                    link: `/notifications/${business_code}`,
                    integrationOption: false,
                    permition: checkUserModules('notification_module', user.modules) === -1 ? false : true
                },
            ]
        },

        {
            title: 'Integraciones',
            icon: 'uil uil-cloud-data-connection',
            link: ``,
            integrationOption: false,
            permition: checkUserModules('webhook_module', user.modules) !== -1
                || checkUserModules('pos_integrations_module', user.modules) !== -1 ? true : false,

            items: [
                {
                    title: ' Webhook',
                    icon: 'uil uil-channel',
                    description: "Integración de servicios externos",
                    link: `/webhook/${business_code}`,
                    integrationOption: false,
                    permition: checkUserModules('webhook_module', user.modules) === -1 ? false : true

                },

                {
                    title: user.business.posIntegration !== null && user.business.posIntegration !== undefined ? user.business.posIntegration.name : 'Ninguna integración',
                    icon: 'uil uil-monitor ',
                    description: "Servicios externos de facturación",
                    link: `/manager/integration/${business_code}`,
                    integrationOption: true,
                    permition: checkUserModules('pos_integrations_module', user.modules) !== -1 && user.business.posIntegration !== undefined ? true : false
                },


            ]

        },

        {
            title: 'Equipos',
            icon: 'uil uil-robot',
            link: ``,
            integrationOption: false,
            permition: checkUserModules('machine_module', user.modules) !== -1
                || checkUserModules('vending_module', user.modules) !== -1 ? true : false,
            items: [

                {
                    title: 'Máquinas',
                    icon: 'uil uil-desktop',
                    description: "Dispositivos con licencias que habilitan funciones específicas en la plataforma",
                    link: `/machines/${business_code}`,
                    integrationOption: false,
                    permition: checkUserModules('machine_module', user.modules) === -1 ? false : true
                },
                {
                    title: 'Vending',
                    description: "Máquinas automáticas expendedoras de productos como alimentos o bebidas",
                    icon: 'uil uil-web-section-alt',
                    link: `/vending/${business_code}`,
                    integrationOption: false,
                    permition: checkUserModules('vending_module', user.modules) === -1 ? false : true

                },


            ]

        },


        {
            title: "Finanzas",
            icon: 'uil uil-money-bill',
            link: ``,
            integrationOption: false,
            permition: false,
            items: [
                {
                    title: 'Conciliacion bancaria',
                    icon: 'uil uil-building',
                    description: "Agrupa varios créditos, proceso contable que compara los registros contables de una empresa con los movimientos bancarios. ",
                    link: `/bank/consiliation/${business_code}`,
                    integrationOption: false,
                    permition: true
                },
            ]
        },


        {
            title: "Promociones",
            icon: 'uil  uil-percentage',
            link: ``,
            integrationOption: false,
            permition: checkUserModules('cupon_module', user.modules) !== -1 ? true : false,
            items: [

                {
                    title: "Cupones",
                    icon: 'uil uil-ticket',
                    description: "Códigos o vales que ofrecen descuentos o beneficios en productos o servicios",
                    link: `/cupons/${business_code}`,
                    integrationOption: false,
                    permition: checkUserModules('cupon_module', user.modules) === -1 ? false : true
                },

            ]

        },


    ];

    const [isOpen, setIsOpen] = useState(false);
    const [showHeader, setShowHeader] = useState(false);

    const toggleOffCanvas = () => {
        setIsOpen(!isOpen);
    };

    const toggleHeader = () => {
        setShowHeader(!showHeader);
    };


    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "";
        }

        return () => {
            document.body.style.overflow = "";
        };
    }, [isOpen]);

    return (
        <>

            <header className="bg-primary-subtle shadow-sm z-3 app-header-container ">
                <div className="flex-column  py-0 w-100 app-header-primary">
                    <div className="container d-flex justify-content-between align-items-center">
                        <div className="d-flex justify-content-start align-items-center mb-lg-2 mb-md-0 me-md-0">

                            <div className="menu-bar" onClick={toggleOffCanvas} >
                                <div className="d-flex align-items-center " title="Menú">
                                    <i className="menu-link-icon-responsive uil uil-bars  text-white"></i>
                                </div>
                            </div>

                            <span className="navbar-logo logo ms-3 ms-lg-0">
                                <img className="navbar-logo-icon" src={logo_sp} alt="Logo Smart Pay" />
                            </span>
                        </div>

                        <div className="d-flex justify-content-end align-items-center gap-2">
                            <div className="area-profile d-flex align-items-center text-black  my-lg-1 m-0 p-lg-1 px-2 rounded-4 z-3 ">
                                <div className="d-flex align-items-center">
                                    {/* <div className="flex-shrink-0">
                        <div className="d-flex justify-content-end align-items-center gap-2">
                            <div className="area-profile d-flex align-items-center text-black  my-lg-1 m-0 p-lg-1 px-2 rounded-4 z-3 ">
                                <div className="d-flex align-items-center">
                                    {/* <div className="flex-shrink-0">
                                    <div className="conrainer-profile-img-sidebar rounded-4">
                                        <img className="profile-img-sidebar rounded-4"
                                            src={user_profile} alt="User" />
                                    </div>
                                </div> */}
                                    <div className="flex-grow-1 px-2   ">
                                        <span className="fw-bold text-white"> <i class="uil uil-user-circle"></i> {user.name} </span>
                                        {/* <small className="d-block text-white opacity-50">Usuario</small> */}
                                    </div>
                                </div>
                            </div>

                            <ModalLanguage></ModalLanguage>

                            <div className="area-profile d-flex align-items-center text-black  my-lg-1 m-0 p-lg-1 rounded-4 position-relative">
                                <div className="d-flex align-items-center px-1 " onClick={() => signout()} title="Cerrar Sesión">
                                    <i className="uil uil-signout text-white "></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>


            <div className="menu-wrapper bg-white border">
                <div className="  container d-flex align-items-stretch">
                    <div className="menu-container d-flex align-items-stretch flex-grow-1  ps-0 ms-0 py-3 rounded-4 gap-4">
                        <ul className="menu ">
                            {menuMain.map((option, index) => (
                                <>
                                    {option.integrationOption === false && option.permition === true ?
                                        <li key={index} className="nav-item" >
                                            <span className="dropdown-container" >
                                                <NavLink to={option.link}

                                                    className={option.subtitle === titlePage ? 'menu-link text-decoration-none' : ' text-decoration-none fw-normal'}
                                                >
                                                    <i className={`menu-link-icon ${option.icon}`}
                                                    ></i>
                                                    <span className="menu-title  mx-1">
                                                        {option.title}
                                                    </span>
                                                </NavLink>

                                                <div className="dropdown-content border p-0">
                                                    {option.items?.map((item) => (

                                                        <>
                                                            <RenderIf isTrue={item.permition === true}>
                                                                <NavLink to={item.link}
                                                                    className={item.title === titlePage ? 'dropdown-title-item-active text-decoration-none' : ' dropdown-title-item text-decoration-none '}
                                                                >
                                                                    <i className={`menu-link-icon ${item.icon}`}
                                                                    ></i>
                                                                    <span className={item.title === titlePage ? 'fw-bold' : ' fw-normal'}>
                                                                        {item.title}
                                                                    </span>

                                                                    <p className="dropdown-description-item opacity-75">{item.description}</p>
                                                                </NavLink>
                                                            </RenderIf>
                                                        </>
                                                    ))}

                                                </div>
                                            </span>
                                        </li > :
                                        (option.integrationOption === true && user.business.pos_integration_id !== null)
                                            && option.permition === true ?




                                            // <li className="menu-link" key={index} >
                                            //     <span>
                                            //         <Link to={option.link}
                                            //             className="menu-link text-decoration-none">
                                            //             <i className={option.icon}></i>
                                            //             <span className="menu-title  mx-1">
                                            //                 {option.title}
                                            //             </span>
                                            //         </Link>
                                            //     </span>
                                            // </li>
                                            <li key={index} className="nav-item" >
                                                <span className="dropdown-container" >
                                                    <NavLink to={option.link}

                                                        className={option.subtitle === titlePage ? 'menu-link text-decoration-none' : ' text-decoration-none fw-normal'}
                                                    >
                                                        <i className={`menu-link-icon ${option.icon}`}
                                                        ></i>
                                                        <span className="menu-title  mx-1">
                                                            {option.title}
                                                        </span>
                                                    </NavLink>

                                                    <div className="dropdown-content border p-0">
                                                        {option.items?.map((item) => (

                                                            <>
                                                                <RenderIf isTrue={item.permition === true}>
                                                                    <NavLink to={item.link}
                                                                        className={item.title === titlePage ? 'dropdown-title-item-active text-decoration-none' : ' dropdown-title-item text-decoration-none '}
                                                                    >
                                                                        <i className={`menu-link-icon ${item.icon}`}
                                                                        ></i>
                                                                        <span className={item.title === titlePage ? 'fw-bold' : ' fw-normal'}>
                                                                            {item.title}
                                                                        </span>

                                                                        <p className="dropdown-description-item opacity-75">{item.description}</p>
                                                                    </NavLink>
                                                                </RenderIf>
                                                            </>
                                                        ))}

                                                    </div>
                                                </span>
                                            </li >




                                            :
                                            ""}
                                </>
                            ))}
                        </ul>

                    </div>
                </div>
            </div >


            {/* Responsive Menú */}
            {/* 
            {isOpen && (
                <div className="menu-overlay" onClick={closeMenu}>
                    <nav className="mobile-menu" ref={menuRef} onClick={(e) => e.stopPropagation()}>
                        <ul>
                            <li><a href="#opcion1">Opción 1</a></li>
                            <li><a href="#opcion2">Opción 2</a></li>
                            <li><a href="#opcion3">Opción 3</a></li>
                        </ul>
                    </nav>

                </div>

            )} */}


            <aside className={`off-canvas ${isOpen ? 'open' : ''}`}>
                <div className="off-canvas-content d-flex flex-column flex-shrink-0 ">



                    <div className="d-flex justify-content-between align-items-center  position-fixed bg-white border-bottom w-100 p-2  shadow-sm z-3">
                        <h5 className="offcanvas-title text-primary" id="offcanvasExampleLabel">Menú</h5>
                        <button type="button" className="btn-close" onClick={toggleOffCanvas}></button>
                    </div>







                    <div className="mt-5 mb-auto">
                        <ul className="m-0 p-0">
                            {menuMain.map((option, index) => (
                                <>
                                    {option.integrationOption === false && option.permition === true ?
                                        <li key={index} className="" >
                                            <details>

                                                <summary>     <i className={`menu-title-responsive ${option.icon}`}


                                                ></i> {option.title}</summary>
                                                <span >
                                                    {/* <NavLink to={option.link}

                                                        className={'d-flex text-decoration-none fw-normal'}
                                                    >
                                                        <i className={`menu-title-responsive ${option.icon}`}
                                                        ></i>
                                                        <span className="menu-title-responsive  mx-1">
                                                            {option.title}
                                                        </span>
                                                    </NavLink> */}

                                                    <div className="  ps-2">
                                                        {option.items?.map((item) => (

                                                            <>
                                                                <RenderIf isTrue={item.permition === true}>
                                                                    <NavLink to={item.link}
                                                                        className="text-decoration-none  "
                                                                    >
                                                                        <div className="border rounded-4 p-2 mt-2">
                                                                            <i className={`menu-link-icon ${item.icon}`}
                                                                            ></i>
                                                                            <span className={'menu-subtitle-responsive  '}>
                                                                                {item.title}
                                                                            </span>

                                                                            <p className="dropdown-description-item opacity-75">{item.description}</p>
                                                                        </div>

                                                                    </NavLink>
                                                                </RenderIf>
                                                            </>
                                                        ))}

                                                    </div>
                                                </span>
                                            </details>
                                        </li > :
                                        (option.integrationOption === true && user.business.pos_integration_id !== null)
                                            && option.permition === true ?

                                            <li className="menu-link" key={index} >
                                                <span>
                                                    <Link to={option.link}
                                                        className="menu-link text-decoration-none"
                                                    // className={option.title === titlePage ? 'nav-link fw-semibold' : '  nav-link fw-normal'}
                                                    >
                                                        <i className={option.icon}></i>
                                                        <span className="menu-title  mx-1">
                                                            {option.title}
                                                        </span>
                                                    </Link>
                                                </span>
                                            </li>
                                            :
                                            ""}
                                </>
                            ))}
                        </ul>
                    </div>
                    {/* Footer */}


                    <div className='flex-column align-content-center align-items-center p-2 bg-light border-top w-100 text-center'>
                        <div>
                            <small className='opacity-50'>
                                <i class="uil uil-copyright"></i>Smart Pay
                            </small>
                        </div>


                        <small className='opacity-50'>
                            <i class="uil uil-bolt"></i> Powered by Smart Retail Group
                        </small>
                    </div>
                </div>
            </aside >



            {/* <div ref={menuRef} className={`d-flex flex-column p-lg-1 text-black rounded-4 bg-white shadow-sm  ${isMenuOpen
                ? " sidebar-main-open"
                : " sidebar-main"}`}>

                <div className=" d-flex flex-lg-column flex-xl-column   justify-content-between align-items-center   rounded-pill p-lg-1 p-1">

                    <div className="d-flex justify-content-start align-items-center mb-0 mb-lg-2 mb-md-0 me-md-0 text-black text-decoration-none ">
                        <span className="navbar-logo logo">
                            <img className="navbar-logo-icon" src={logo_sp} alt="Logo Smart Pay" />
                            <span className="navbar-logo-text">Smart Pay Extreme </span>
                        </span>
                    </div>

                    <span className="area-profile d-flex align-items-center text-black  my-lg-2 m-0 p-lg-2 p-0 rounded-4 ">

                        <div>
                            <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                    <div className="conrainer-profile-img-sidebar rounded-4">
                                        <img className="profile-img-sidebar rounded-4"
                                            src={user_profile} alt="User" />
                                    </div>
                                </div>
                                <div className="flex-grow-1 ms-1 ">
                                    <span className="fw-bold "> {user.name + " " + user.lastName} </span>
                                    <small className="d-block  "><ModalLanguage></ModalLanguage></small>

                                </div>


                            </div>
                            
                        </div>

                    </span>

                    <div className="icon-menu-sp  rounded-circle bg-primary-subtle  text-primary border-light border shadow-sm "
                        onClick={toggleMenu}>
                        <i className={`display-6 uil ${isMenuOpen
                            ? "uil-multiply "
                            : "uil-bars"}`}></i>

                    </div>




                </div>
                <div className={`w-100 text-center mt-1 border-top ${isMenuOpen ? " list-menu-main-sp-open" : " list-menu-main-sp"}`}>
                    <small className="fw-bold text-primary  text-subtitle-menu">Menú</small>
                </div>

                <div className={`mb-auto ${isMenuOpen ? " list-menu-main-sp-open slide-in-blurred-top" : " list-menu-main-sp slide-in-blurred-top"}`}>
                    <ul className="nav nav-pills flex-column  overflow-y-auto ">
                        {menuMain.map((option, index) => (
                            <>
                                {option.integrationOption === false && option.permition === true ?
                                    <li key={index} className="nav-item" >
                                        <span>
                                            <NavLink to={option.link}
                                                className={option.title === titlePage ? 'nav-link fw-semibold' : '  nav-link fw-normal'}
                                            >
                                                <i className={option.icon}></i>
                                                <span className="mx-1">
                                                    {option.title}
                                                </span>
                                            </NavLink>
                                        </span>
                                    </li> :
                                    (option.integrationOption === true && user.business.pos_integration_id !== null) && option.permition === true ?

                                        <li key={index} >
                                            <span>
                                                <Link to={option.link}
                                                    className={option.title === titlePage ? 'nav-link fw-semibold' : '  nav-link fw-normal'}
                                                >
                                                    <i className={option.icon}></i>
                                                    <span className="mx-1">
                                                        {option.title}
                                                    </span>
                                                </Link>
                                            </span>
                                        </li>
                                        :
                                        ""}
                            </>
                        ))}


                    </ul>



                </div>
                <div className={`${isMenuOpen ? " list-menu-main-sp-open slide-in-blurred-top" : " list-menu-main-sp "}`}>
                    <div className="close-login rounded-3 py-2 fw-bold" onClick={() => signout()}>
                        <i className="uil uil-signout px-1 pt-3 ms-1"></i>
                        Cerrar Sesión
                    </div>
                </div>


            </div> */}
        </>
    );
}

export default NavBar;
