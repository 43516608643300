//React components
import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Select from 'react-select';

//Alerts
import ErrorAlert from '../../Alerts/ErrorAlert';
//API
import { SetGroupsFetchAPI } from '../../../api/SetGroups';
import { GroupsFechtAPI } from '../../../api/Groups';

//Modal to add a group to a set group
function ModalAddGroupSetGroup({ setGroup, loadSetGroup, branchoffice_id }) {
    //States
    const [groups, setGroups] = useState([])
    const [showModalAsing, setShowModalAsing] = useState(false)
    //Token
    let token = localStorage.getItem("x-access-token")

    //Function to get products 
    const getProducts = async () => {
        //Variables
        let productInfo = []
        try {
            //We get the products
            const response = await GroupsFechtAPI.getGroupsByBranchoffice(branchoffice_id, token)
            //We set the products
            response.data.data.forEach(group => {
                productInfo.push({ value: group.group_id, label: group.name })
            });
            setGroups(productInfo)
        } catch (err) {
            setGroups([])
        }
    }

    //Function to asing product to set group
    const asingProductToSetGroup = async (e) => {
        e.preventDefault();
        //We get the data
        const form = new FormData(e.target);
        let data = {
            groups: [
                Object.fromEntries(form.entries())
            ]
        }
        try {
            //We asing the product to the set group
            const response = await SetGroupsFetchAPI.asingGroupToSetGroup(data, setGroup.combo_id, setGroup.set_id, token)
            console.log(response);
            //We reload the data
            loadSetGroup()
            setShowModalAsing(false)
            ErrorAlert('', 'Producto asignado', 'success')

        } catch (err) {
            console.log(err)
            ErrorAlert(err.response.data.message, 'Error de asignacion', 'warning')
        }
    }

    return (
        <>

            <button className='btn btn-dark btn-sm border-0 opacity-75' onClick={() => (setShowModalAsing(true), getProducts())}>
                <i className="uil uil-plus"></i>

                Asignar producto

            </button>
            {/** Modal to add group to set groups */}
            <Modal
                show={showModalAsing}
                onHide={() => (setShowModalAsing(false))}
                size='md'
                centered>

                <Modal.Body className='shadow-lg' >
                    <div className='position-relative'>
                        <div className='position-absolute top-0 start-100 translate-middle mt-2 '>
                            <div className='me-3'>
                                <button class="btn btn-outline-secondary border-0 bg-light text-secondary rounded-circle p-2 lh-1" type="button" title='Cerrar'
                                    onClick={() => (setShowModalAsing(false))}
                                >
                                    <i class="uil uil-multiply"></i>
                                </button>
                            </div>
                        </div>

                        <h2 className="title-main-login text-center ">
                            Asignar producto
                        </h2>
                        <Form
                            className="needs-validation"
                            onSubmit={asingProductToSetGroup}
                        >

                            <Form.Group className="mb-3" controlId="clientId">
                                <Form.Label>Seleccione producto</Form.Label>
                                <Select
                                    className='w-100'
                                    options={groups}
                                    name='group_id'
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label>Precio</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="$0.00"
                                    name='price'
                                />
                            </Form.Group>

                            <div className="mt-3 d-flex justify-content-end">
                                <button className="btn btn-save-sp" type="button" onClick={asingProductToSetGroup}>
                                    Guardar
                                </button>
                            </div>
                        </Form>
                    </div>
                </Modal.Body>             
            </Modal>

        </>
    )
}

export default ModalAddGroupSetGroup