import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Modal, Table, Form } from "react-bootstrap";
import * as XLSX from "xlsx";
import { RenderIf } from "../utils/RenderIf";
import ErrorAlert from "../Alerts/ErrorAlert";
import ReactPaginate from "react-paginate";
//Modals
import PreChargeBankDataModal from "./Modals/PreChargeBankDataModal";
import EditConsiliatedTransactionModal from "./Modals/EditConsiliatedTransactionModal";
//API 
import { OrdersFetchAPI } from "../../api/Orders";
import { PaymentMethodFetchAPI } from "../../api/PaymentMethod";
import { BankConsilationFetchAPI } from "../../api/BankConsilation";
import SuccessAlert from "../Alerts/SuccessAlert";


const TransactionBankConsiliation = () => {
    //Params
    let { business_code } = useParams();
    //Tokens and user data
    let token = localStorage.getItem('x-access-token');
    let user = JSON.parse(localStorage.getItem('user'));
    //states
    const [showModal, setShowModal] = useState(false);
    const [excelData, setExcelData] = useState([]);
    const [files, setFiles] = useState([]);
    const [dateRange, setDateRange] = useState({ start: "", end: "" });
    const [selectedColumn, setSelectedColumn] = useState("");
    const [loteReferenceInput, setLoteReferenceInput] = useState("");
    const [columns, setColumns] = useState([]);
    const [orders, setOrders] = useState([]);
    const [ordersDataToSend, setOrdersDataToSend] = useState([]);
    const [dataPage, setDataPagePage] = useState(0);
    const [showEditModalConsiliate, setShowEditModalConsiliate] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [integrations, setIntegrations] = useState([{
        id: 0,
        name: ""
    }]);
    let ordersDataPerPage = 10;
    //Pagination
    const handlePageChange = ({ selected }) => {
        setDataPagePage(selected);
    };

    //Function to handle the file upload
    const handleFileUpload = (e) => {
        const selectedFiles = Array.from(e.target.files);
        let error = false;
        setFiles(selectedFiles);
        let allData = [];
        let allColumns = [];
        selectedFiles.forEach((file) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const workbook = XLSX.read(event.target.result, { type: "binary" });
                const sheetName = workbook.SheetNames[0];
                const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });
                //We get the first row as the columnss
                allColumns = [...allColumns, ...sheetData[0]];
                setColumns(allColumns);
                //We get the rest of the data
                allData = [...allData, ...sheetData.slice(1)];
                setExcelData(allData);
            };
            reader.readAsArrayBuffer(file);
        });
        //We show the modal if there is no error
        if (!error) {
            setShowModal(true);
        }
    };
    //Function to handle the date change
    const handleDateChange = (e) => {
        const { name, value } = e.target;
        setDateRange((prev) => ({ ...prev, [name]: value }));
    };

    //Function to handle the edit consiliate
    const handleSubmit = async () => {
        if (!excelData.length || !ordersDataToSend.length) {
            ErrorAlert("No hay datos para conciliar", "error", "warining");
            return;
        } else {
            // We build the data with the orders and excel data 
            const payload = {
                lote_reference: loteReferenceInput,
                transactions_db: ordersDataToSend, // Transacciones obtenidas del backend
                transactions_excel: excelData.map((row) => ({
                    referenceIndex: columns.findIndex(element => element === selectedColumn), // La columna seleccionada como referencia
                    raw_data: row, // Guardamos toda la fila por si es necesaria
                }))
            };

            //We send the data to the backend
            const response = await BankConsilationFetchAPI.getConsilationLoteReport(payload, token);
            console.log(response);

            console.log("Enviando JSON al backend:", payload);
            // Actualizar el estado de las órdenes con los resultados de conciliación
            const updatedOrders = orders.map(order => {
                const isMatched = response.data.matched.findIndex(match => match.reference === order.transaction_code);
                if (isMatched !== -1) {
                    return {
                        ...order,
                        consiliation: true,
                        consiliationType: "Automatica"
                    };
                } else {
                    return {
                        ...order,
                        consiliation: false,
                        consiliationType: "No consiliada"
                    }
                }
            });

            setOrders(updatedOrders); // Asegúrate de que este estado existe en tu componente
            setOrdersDataToSend(updatedOrders); // Asegúrate de que este estado existe en tu componente
            // Alert 
            SuccessAlert("Conciliación realizada", "La conciliación se ha realizado con éxito", "success");
            setShowModal(false);
        }
    };

    //Function to save the consiliation 
    const saveConsiliation = async () => {
        try {
            //We filter all the orders that are consiliated
            let ordersToSend = ordersDataToSend.filter(order => order.consiliation === true);
            //We make the json to send to the backend
            let data = {
                orders: ordersToSend,
                lote_reference: loteReferenceInput,
                totalExcel: excelData.length,
                totalSystem: ordersToSend.length,
                business_code: business_code
            }
            //We send the data to the backend
            await BankConsilationFetchAPI.saveBankConciliationBatch(data, token);
            //We show the alert
            SuccessAlert("Conciliación guardada", "La conciliación se ha guardado con éxito", "success");
            //We clean the orders
            setOrders([]);
            setOrdersDataToSend([]);
            //We clean the date range
            setDateRange({ start: "", end: "" });
            //We clean the lote reference
            setLoteReferenceInput("");
            //We clean the files
            setFiles([]);
            setExcelData([]);
            //We clean the form inputs
            document.getElementsByName("start")[0].value = "";
            document.getElementsByName("end")[0].value = "";
            document.getElementsByName("loteReferenceInput")[0].value = "";
            document.getElementsByName("excels")[0].value = "";

        } catch (err) {
            console.log(err);
            //we show an error 
            ErrorAlert(err.response.data.error !== undefined ? err.response.data.error : "Ha ocurrido un error al guardar la conciliación", "Advertencia", "warning");
        }
    }
    //Function to get the orders from the backend
    const getOrders = async (e) => {
        try {
            let response = await OrdersFetchAPI.getTransactionsByBranchOfficePaymentMethodbyUser(business_code, 0, dateRange.start, e.target.value + "T23:59:59", 0, user.id, token, undefined, undefined, true);
            //We set the orders
            setOrders(response.data.orders);
            setOrdersDataToSend(response.data.orders);
        } catch (err) {
            console.log(err);
            setOrders([]);
            setOrdersDataToSend([]);
        }
    }
    let ordersData = orders.slice(dataPage * ordersDataPerPage, (dataPage + 1) * ordersDataPerPage);

    const updateTransaction = (updatedTx) => {
        updatedTx.consiliation = true;
        updatedTx.consiliationType = "Manual";
        setOrders(prevOrders =>
            prevOrders.map(order =>
                order.transaction_code === updatedTx.transaction_code ? updatedTx : order
            )
        );
        setOrdersDataToSend(prevOrders =>
            prevOrders.map(order =>
                order.transaction_code === updatedTx.transaction_code ? updatedTx : order
            )
        );
    }

    useEffect(() => {
        setSelectedColumn(selectedColumn);
    }, [selectedColumn]);

    //Hook to get the integrations
    useEffect(() => {
        const getIntegration = async () => {
            try {
                let response = await PaymentMethodFetchAPI.getPaymentMethodIntegrations(token);
                setIntegrations(response.data.integrations);
            } catch (err) {
                console.log(err);
                setIntegrations([]);
            }
        }
        getIntegration();
    }, []);

    return (
        <>
            <div className='mx-lg-2 mx-1'>
                <main className="m-2">
                    <h2>Conciliación de transacciones por lote</h2>
                    <Form>
                        <Form.Group>
                            <Form.Label>Fecha de inicio</Form.Label>
                            <Form.Control type="date" name="start" onChange={handleDateChange} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Fecha de fin</Form.Label>
                            <Form.Control type="date" name="end" onChange={(e) => { handleDateChange(e); getOrders(e) }} />
                        </Form.Group>
                    </Form>
                    {/** Input text */}
                    <Form.Group>
                        <Form.Label>Referencia de lote</Form.Label>
                        <Form.Control type="text" placeholder="Referencia de lote" name="loteReferenceInput" onChange={(e) => setLoteReferenceInput(e.target.value)} />
                    </Form.Group>
                    {/** Input file  */}
                    <Form.Group>
                        <Form.Label>Subir archivos Excel</Form.Label>
                        <Form.Control type="file" multiple accept=".xlsx, .xls" name="excels" onChange={handleFileUpload} />
                    </Form.Group>

                    {/** Pre charge bank data modal  */}
                    <PreChargeBankDataModal
                        showModal={showModal}
                        handleClose={() => setShowModal(false)}
                        columns={columns}
                        selectedColumn={selectedColumn}
                        setSelectedColumn={setSelectedColumn}
                        excelData={excelData}
                        handleSubmit={handleSubmit}
                    />

                    {/** Table of the precharge orders of the s */}
                    <RenderIf isTrue={orders.length > 0}>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Estado</th>
                                    <th>Tipo de Conciliación </th>
                                    <th>Fecha</th>
                                    <th>Transaccion</th>
                                    <th>Total</th>
                                    <th>Integracion</th>
                                    <th>Metodo de pago</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {ordersData.map((tx, idx) => (
                                    <tr key={idx}>
                                        <td>{tx.consiliation !== undefined ? tx.consiliation !== false ? "Consiliada" : "No consiliado" : "No consiliado"}</td>
                                        <td>{tx.consiliation !== undefined ? tx.consiliation !== false ? tx.consiliationType : "No consiliado" : "No consiliado"}</td>
                                        <td>{tx.createdAt.split("T")[0]}</td>
                                        <td>{tx.transaction_code}</td>
                                        <td>{tx.total_with_tips}</td>
                                        <td>{integrations.find(element => element.id === tx.paymenthod_integration_id)?.name || "No encontrado"}</td>
                                        <td>{tx.paymenthod}</td>
                                        {/** Button  option */}
                                        <RenderIf isTrue={tx.consiliation === false}>
                                            <td>
                                                <button onClick={() => { setSelectedTransaction(tx); setShowEditModalConsiliate(true) }} className='btn btn-outline-primary btn-sm border-0' >
                                                    <i className="uil uil-pen"></i>
                                                </button>
                                            </td>
                                        </RenderIf>

                                        {/** Empty option  */}
                                        <RenderIf isTrue={tx.consiliation !== false}>
                                            <td></td>
                                        </RenderIf>

                                    </tr>
                                ))}
                            </tbody>
                        </Table>

                        {/**    Pagination */}
                        <ReactPaginate
                            previousLabel={"Anterior"}
                            nextLabel={"Siguiente"}
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            pageCount={Math.ceil(orders.length / ordersDataPerPage)}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageChange}
                            containerClassName="pagination"
                            activeClassName="active"
                        />

                        {/** Button to save the consiliation */}
                        <Button variant="primary" onClick={() => saveConsiliation()}>
                            Confirmar y Enviar
                        </Button>
                    </RenderIf>

                    {/** Edit consiliated transaction modal */}
                    <EditConsiliatedTransactionModal
                        showModal={showEditModalConsiliate}
                        handleClose={() => setShowEditModalConsiliate(false)}
                        transaction={selectedTransaction}
                        updateTransaction={updateTransaction}
                    />
                </main>

            </div>
        </>
    );
};

export default TransactionBankConsiliation;
