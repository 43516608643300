import React, { useState } from 'react';
//API 
import { OcrFetchAPI } from "../../api/Smartpay.Ocr";

const ImgConciliation = () => {
  //Tokens and user data
  let token = localStorage.getItem('x-access-token');

  const [selectedFile, setSelectedFile] = useState(null);
  const [extractedText, setExtractedText] = useState('');
  const [imageUrl, setImageUrl] = useState('');

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      alert('Por favor, selecciona una imagen primero.');
      return;
    }

    const formData = new FormData();
    formData.append('files', selectedFile);
    try {
      const response = await OcrFetchAPI.analyzeImg(formData, token);
      setExtractedText(response.data.ocrResult.data[0].fullTextAnnotation.text);
      setImageUrl(URL.createObjectURL(selectedFile));
    } catch (error) {
      console.error('Error al subir la imagen:', error);
    }
  };

  return (
    <div>
      <h2>Analizar voucher en base a imagen</h2>
      <input type="file" accept="image/*" onChange={handleFileChange} />
      <button onClick={handleUpload}>Subir y Extraer Texto</button>
      {imageUrl && (
        <div>
          <h3>Imagen Subida:</h3>
          <img src={imageUrl} alt="Subida" style={{ maxWidth: '300px' }} />
        </div>
      )}
      {extractedText && (
        <div>
          <h3>Texto Extraído:</h3>
          <p>{extractedText}</p>
        </div>
      )}
    </div>
  );
};

export default ImgConciliation;