//React Components
import React, { useState } from 'react';
import { Modal, Button, Badge, Container, Row, Col, ListGroup } from 'react-bootstrap';
import Select from 'react-select';
//Utils
import { RenderIf } from '../../utils/RenderIf';
//Alerts
import ErrorAlert from '../../Alerts/ErrorAlert';
//API
import { CategoriesDashFetchAPI } from '../../../api/CategoriesDash';
import { TaxesFetchAPI } from '../../../api/Taxes';
import { CombosFetchAPI } from '../../../api/Combos';

import pencil from '../../img/icons/pencil.png'
import eye from '../../img/icons/eye.png'
//Modal to view the combo details
function ModalViewCombo({ combo, branchoffice_id }) {
    const [showModal, setShowModal] = useState(false)
    const [eventHandle, setEventHandle] = useState(0);
    const [infoCombo, setInfoCombo] = useState([])
    const [categories, setCategories] = useState([])
    const [categoriesSelect, setCategoriesSelect] = useState([])
    const [categorySelectNow, setCategorySelectNow] = useState('')
    const [showModalAsing, setShowModalAsing] = useState(false);
    const [showModalTaxes, setShowModalTaxes] = useState(false);
    const [taxes_list, setTaxes_list] = useState([])
    const [imgpreview, setImgpreview] = useState(combo.img);
    const [taxSelect, setTaxSelect] = useState("");
    const [taxes, setTaxes] = useState([])
    let token = localStorage.getItem("x-access-token")
    let user = localStorage.getItem('user');


    //get combo by id
    const getComboById = async () => {
        let response = []
        try {
            //We check if the user want to edit details of the combo on a branchoffice
            if (branchoffice_id !== 0) {
                response = await CombosFetchAPI.getComboDetailsByBranchOffice(combo.combo_id, branchoffice_id, token)
            } else {
                response = await CombosFetchAPI.getComboById(combo.combo_id, token)
            }
            setInfoCombo(response.data.combo)
            setCategories(response.data.combo.categories)
            setTaxes_list(response.data.combo.taxes)
        } catch (err) {
            setCategories([])
            setTaxes_list([])
        }
    }

    //Remove tax from combo
    const removeTaxFromCombo = async (tax_id) => {
        let data = {
            tax_id: tax_id,
            combo_id: branchoffice_id !== 0 ? infoCombo.combo_id : infoCombo.id
        }
        try {
            await TaxesFetchAPI.deleteTaxOfCombo(data, token);
            getComboById();
        } catch (err) {
            ErrorAlert(err.response.data.error, 'No se pudo remover el impuesto', 'warning')
        }

    }

    //Remove category from combo
    const removeCategoryFromCombo = async (category_id) => {
        let data = {
            combos: [{
                combo_id: branchoffice_id !== 0 ? infoCombo.combo_id : infoCombo.id
            }]
        }
        try {
            const response = await CombosFetchAPI.removeComboFromCategory(category_id, data, token)
            getComboById()
        } catch (err) {
            console.log(err);
            ErrorAlert(err.response.data.error, 'Error, no se pudo remover la categoria', 'error')
        }
    }

    //get Categories
    const getCategoriesByBusiness = async () => {
        user = JSON.parse(localStorage.getItem('user'))
        try {
            const response = await CategoriesDashFetchAPI.getCategoriesByBusiness(user.business_id, token)

            console.log(response.data.data);
            let categorieInfo = []
            response.data.data.forEach(category => {
                categorieInfo.push({ value: category.category_id, label: category.category_name })
            });

            setCategoriesSelect(categorieInfo)
        } catch (err) {
            console.log(err)
        }
    }

    //add tax to combo
    const asingTaxToCombo = async () => {
        let data = {
            tax_id: taxSelect,
            combo_id: branchoffice_id !== 0 ? infoCombo.combo_id : infoCombo.id
        }

        try {
            const response = await TaxesFetchAPI.asingTaxToCombo(data, token)
            console.log(response);
            getComboById()
            setShowModalTaxes(false)
            ErrorAlert('', 'Impuesto asignado correctamente', 'success')
        } catch (err) {
            console.log(err);
            ErrorAlert('Favor intente con otro', 'warning')
        }
    }

    //add category to combo
    const asingComboToCategory = async () => {
        try {
            let data = {
                combos: [
                    {
                        combo_id: branchoffice_id !== 0 ? infoCombo.combo_id : infoCombo.id
                    }
                ]
            }
            const response = await CombosFetchAPI.addCombosToCategory(categorySelectNow, data, token)
            console.log(response);
            getComboById()
            setShowModalAsing(false)
            ErrorAlert('', 'Categoria asignado correctamente', 'success')

        } catch (err) {
            console.log(err)
            ErrorAlert(err.response.data.error, 'Error de asignacion', 'error')
        }
    }
    //handle select category
    const handleSaveTableSelect = (option) => {
        setCategorySelectNow(option.value);
    }
    //get taxes
    const getTaxes = async () => {
        try {
            const response = await TaxesFetchAPI.getTaxes(token);
            setTaxes(response.data.data)
        } catch (err) {
            setTaxes([])
        }
    }

    return (
        <>


            <button className='btn btn btn-outline-light  pulse rounded-3 p-2 lh-1 border'
                onClick={() => (setShowModal(true), getComboById(), getCategoriesByBusiness())}
            >
                <img className='icon-options-cards' src={eye} alt="restart" />

            </button>

            <Modal
                show={showModal}
                onHide={() => (setShowModal(false))}
                size='lg'
                centered>

                <Modal.Body>

                    <div className='position-relative'>
                        <div className='position-absolute top-0 start-100 translate-middle mt-2 '>
                            <div className='me-3'>
                                <button class="btn btn-outline-secondary border-0 bg-light text-secondary rounded-circle p-2 lh-1" type="button" title='Cerrar'
                                    onClick={() => (setShowModal(false))}
                                >
                                    <i class="uil uil-multiply"></i>
                                </button>
                            </div>
                        </div>
                        <h2 className="title-main-login text-center ">
                            Detalle del combo
                        </h2>
                    </div>

                    <div class="d-flex align-items-center border rounded-3">
                        <div class="flex-shrink-0">
                            <img src={combo.img}
                                alt="Edit" width={95} className='border  rounded-3' />
                        </div>
                        <div class="flex-grow-1 ms-3">
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">Nombre</div>
                                {infoCombo.name}
                            </div>
                        </div>
                    </div>

                    <div className='d-flex flex-wrap mt-2 gap-2' >
                        <RenderIf isTrue={branchoffice_id !== 0}>
                            <div className="border rounded-2 p-2">
                                {/** We show the branch office just if we have selected branch */}

                                <div className="ms-2 me-auto">
                                    <div className="fw-normal opacity-75 text-muted">Estado</div>
                                    {combo.status == 1 ? <><Badge bg="success">Habilitado</Badge></> : <><Badge bg="danger">Deshabilitado</Badge></>}
                                </div>

                            </div>
                        </RenderIf>

                        <div className="border rounded-2 p-2">

                            <div className="ms-2 me-auto">
                                <div className="fw-normal opacity-75 text-muted ">Descripción</div>
                                <span className='fw-bold'>
                                    {infoCombo.description}
                                </span>
                            </div>
                        </div>

                        <div className="border rounded-2 p-2">
                            <div className="ms-2 me-auto">
                                <div className="fw-normal opacity-75 text-muted">Referencia externa</div>
                                <span className='fw-bold'>
                                    {!infoCombo.external_reference ? 'No asignado' : infoCombo.external_reference}
                                </span>

                            </div>
                        </div>

                        <div className="border rounded-2 p-2">
                            <div className="ms-2 me-auto">
                                <div className="fw-normal opacity-75 text-muted">Producto de referencia</div>

                                <span className='fw-bold'>
                                    {infoCombo.referenced_group !== undefined && infoCombo.referenced_group !== null ? infoCombo.referenced_group.name : 'No asignado'}</span>

                            </div>
                        </div>
                        {/** We show the favorite and cross sell fields just if we have selected branch */}
                        <RenderIf isTrue={branchoffice_id !== 0}>

                            <div className="border rounded-2 p-2">

                                <div className="ms-2 me-auto">
                                    <div className="fw-normal opacity-75 text-muted">Favorito/destacado</div>
                                    <div className='fw-bold'>         {infoCombo.favorite == 0 ? <>No</> : <>Si</>} </div>
                                </div>
                            </div>


                            <div className="border rounded-2 p-2">


                                <div className="ms-2 me-auto">
                                    <div className="fw-normal opacity-75 text-muted">Habilitado para venta cruzada</div>
                                    {infoCombo.cross_sell == 0 ? <>No</> : <>Si</>}
                                </div>
                            </div>
                        </RenderIf>


                    </div>
                    <div className='row mt-3'>

                        <div className="col-md-6  ">
                            <div className="d-flex justify-content-between align-items-center">
                                <span className='text-primary fw-semibold'>
                                    Impuestos
                                </span>

                                <button className='btn btn-primary btn-sm border-0 m-2' onClick={() => (getTaxes(), setShowModalTaxes(true))}>
                                    <i className="uil uil-plus"></i> Agregar impuestos
                                </button>
                            </div>

                            <div className='idioma-wrapper border rounded-3 p-2'  >
                                {taxes_list.map((tax) => (
                                    <div className="d-flex justify-content-between align-items-start border-dashed  rounded-2 mt-2 p-2 ">

                                        {tax.tax + "-  " + tax.percentage}
                                        <button className='btn btn-outline-danger btn-sm border-0' onClick={() => removeTaxFromCombo(tax.tax_id)} >
                                            <i className="uil uil-trash-alt"></i>
                                        </button>

                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className=" col-md-6  " >
                            <div className="ms-2 me-auto">
                                <div className="d-flex justify-content-between align-items-center">
                                    <span className='text-primary fw-semibold'>
                                        Categorias
                                    </span>

                                    <button className='btn btn-primary btn-sm border-0 m-2' onClick={() => setShowModalAsing(true)}>
                                        <i className="uil uil-plus"></i> Agregar categoria
                                    </button>
                                </div>

                                <div className='idioma-wrapper border rounded-3 p-2' >
                                    {categories.map((category) => (


                                        <div className="d-flex justify-content-between align-items-start border-dashed  rounded-2 mt-2 p-2 ">

                                            {category.name}
                                            <button className='btn btn-outline-danger btn-sm border-0' onClick={async () => removeCategoryFromCombo(category.category_id)}>
                                                <i className="uil uil-trash-alt"></i>
                                            </button>

                                        </div>
                                    ))}
                                </div>

                            </div>
                        </div>
                    </div>
                </Modal.Body >
            </Modal >


            {/*Modal add  to combo*/}
            < Modal
                show={showModalTaxes}
                onHide={() => (setShowModalTaxes(false))
                }
                size='md'
                centered >

                <Modal.Body className='shadow-lg' >
                    <div className='position-relative'>

                        <div className='position-absolute top-0 start-100 translate-middle mt-2 '>
                            <div className='me-3'>
                                <button class="btn btn-outline-secondary border-0 bg-light text-secondary rounded-circle p-2 lh-1" type="button" title='Cerrar'
                                    onClick={() => (setShowModalTaxes(false))}
                                >
                                    <i class="uil uil-multiply"></i>
                                </button>
                            </div>
                        </div>

                        <h2 className="title-main-login text-center ">
                            Asignar impuesto
                        </h2>
                        <div className='d-flex justify-content-center align-item-center'>
                            <select className='form-select m-3' name="user_branch" id="user_branch" onChange={(e) => setTaxSelect(e.target.value)}>
                                <option >Seleccione un impuesto</option>
                                {taxes.map(tax => (
                                    <option value={tax.id}>{tax.tax + "-" + tax.percentage}</option>
                                ))}
                            </select>
                            <Button type="button" className='btn btn-save-sp border-0 m-3' onClick={async () => asingTaxToCombo()} >
                                Guardar
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/*Modal add category to product*/}
            < Modal
                show={showModalAsing}
                onHide={() => (setShowModalAsing(false))}
                size='md'
                centered >

                <Modal.Body className='shadow-lg'>

                    <div className='position-relative'>

                        <div className='position-absolute top-0 start-100 translate-middle mt-2 '>
                            <div className='me-3'>
                                <button class="btn btn-outline-secondary border-0 bg-light text-secondary rounded-circle p-2 lh-1" type="button" title='Cerrar'
                                    onClick={() => (setShowModalAsing(false))}
                                >
                                    <i class="uil uil-multiply"></i>
                                </button>
                            </div>
                        </div>

                        <h2 className="title-main-login text-center ">
                            Asignar Categoria
                        </h2>
                        <div className='d-flex justify-content-center align-item-center'>
                            <Select
                                name="user_branch" id="user_branch"
                                className='w-100 m-3'
                                options={categoriesSelect}
                                onChange={handleSaveTableSelect}
                            />
                            <Button type="button" className='btn btn-save-sp border-0 m-3' onClick={async () => asingComboToCategory()}>
                                Guardar
                            </Button>
                        </div>

                    </div>
                </Modal.Body>

            </Modal >

        </>
    )
}

export default ModalViewCombo