//React components
import { useState } from "react";
import { useParams } from "react-router-dom";

import { Table, InputGroup, FormControl, Form, Button } from "react-bootstrap";
//Modules
import * as XLSX from "xlsx";
//Modals 
import PreChargeBankDataModal from "./Modals/PreChargeBankDataModal";
//API 
import { BankConsilationFetchAPI } from "../../api/BankConsilation";
//Utils 
import { RenderIf } from "../utils/RenderIf";
//Alerts
import SuccessAlert from "../Alerts/SuccessAlert";
import ErrorAlert from "../Alerts/ErrorAlert";

//Component to handle the bank reconciliation
export default function BankReconciliation() {
    //Constants
    const token = localStorage.getItem('x-access-token');
    let { business_code } = useParams();

    //States
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [batchConsiliations, setBatchConsiliations] = useState([]);
    const [batchConsiliationToSend, setBatchConsiliationToSend] = useState([]);
    const [files, setFiles] = useState([]);
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [selectedColumn, setSelectedColumn] = useState("");
    const [excelData, setExcelData] = useState([]);
    const [showModal, setShowModal] = useState(false);


    //Function to fetch the history of the batch consolidations
    const fetchHistoryBatchConsolidations = async (initialtDate, finalDate) => {
        try {
            let response = await BankConsilationFetchAPI.getBankConsiliationHistory(
                initialtDate,
                finalDate + "T23:59:59",
                token
            );
            setBatchConsiliations(response.data.history);
            setBatchConsiliationToSend(response.data.history);
            SuccessAlert("Historial cargado", "El historial de conciliaciones ha sido obtenido correctamente", "success");
        } catch (err) {
            console.error(err);
            setBatchConsiliations([]);
            setBatchConsiliationToSend([]);
            ErrorAlert(err.response.data.error !== undefined ? err.response.data.error : "Ocurrió un error al obtener el historial de conciliaciones", "Advertencia", "warning");
        }
    }

    //Function to handle the file upload
    const handleFileUpload = (e) => {
        const selectedFiles = Array.from(e.target.files);
        let error = false;
        setFiles(selectedFiles);
        let allData = [];
        let allColumns = [];
        selectedFiles.forEach((file) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const workbook = XLSX.read(event.target.result, { type: "binary" });
                const sheetName = workbook.SheetNames[0];
                const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });
                //We get the first row as the columnss
                allColumns = [...allColumns, ...sheetData[0]];
                setColumns(allColumns);
                //We get the rest of the data
                allData = [...allData, ...sheetData.slice(1)];
                setExcelData(allData);
            };
            reader.readAsArrayBuffer(file);
        });
        //We show the modal if there is no error
        if (!error) {
            setShowModal(true);
        }
    };

    //Function to consiliate 
    const consiliate = async () => {
        if (!excelData.length || !batchConsiliationToSend.length) {
            ErrorAlert("No hay datos para conciliar", "error", "warining");
            return;
        } else {
            // We build the data with the orders and excel data 
            const payload = {
                transactions_db: batchConsiliationToSend, // Transacciones obtenidas del backend
                transactions_excel: excelData.map((row) => ({
                    referenceIndex: columns.findIndex(element => element === selectedColumn), // La columna seleccionada como referencia
                    raw_data: row, // Guardamos toda la fila por si es necesaria
                }))
            };

            //We send the data to the backend
            const response = await BankConsilationFetchAPI.getConsilationBankStatement(payload, token);
            console.log(response);

            console.log("Enviando JSON al backend:", payload);

            // Actualizar el estado de las órdenes con los resultados de conciliación
            const updatedOrders = batchConsiliations.map(order => {
                const isMatched = response.data.matched.findIndex(match => match.reference === order.lote_reference);
                if (isMatched !== -1) {
                    return {
                        ...order,
                        consiliated: true,
                    };
                } else {
                    return {
                        ...order,
                        consiliated: false,
                    }
                }
            });
            setBatchConsiliations(updatedOrders); // Asegúrate de que este estado existe en tu componente
            setBatchConsiliationToSend(updatedOrders); // Asegúrate de que este estado existe en tu componente
            // Alert 
            SuccessAlert("Conciliación realizada", "La conciliación se ha realizado con éxito", "success");
            setShowModal(false);

        }

    }
    //Function to save the bank consolidation
    const saveBankConciliation = async () => {
          try {
            let data = {
                startDate: startDate,
                endDate: endDate,
                referencedColum: selectedColumn,
                business_code: "9912",
                files: files[0]
            }
             let response = await BankConsilationFetchAPI.saveBankConciliation(data, token);
             SuccessAlert("Conciliación guardada", "La conciliación se ha guardado correctamente", "success");
         } catch (err) {
             console.error(err);
             ErrorAlert(err.response.data.error !== undefined ? err.response.data.error : "Ocurrió un error al guardar la conciliación", "Advertencia", "warning");
         } 
        console.log("Save bank conciliation");
    }

    return (
        <>
            <div className="mx-lg-2 mx-1">
                <main className="m-2">
                    <h2 className="text-xl font-bold">Consolidación de estados Bancarios</h2>
                    <Form.Label>Rango de fechas para conciliación en base a lotes:</Form.Label>
                    <InputGroup className="mb-3">
                        <FormControl type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                        <FormControl type="date" value={endDate} onChange={(e) => { setEndDate(e.target.value); fetchHistoryBatchConsolidations(startDate, e.target.value) }} />
                    </InputGroup>
                    {/** Input file  */}
                    <Form.Group>
                        <Form.Label>Subir archivos Excel</Form.Label>
                        <Form.Control type="file" multiple accept=".xlsx, .xls" name="excels" onChange={handleFileUpload} />
                    </Form.Group>
                    {/**Table of batch consiliations */}
                    <RenderIf isTrue={batchConsiliations.length > 0}>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Fecha</th>
                                    <th>Lote</th>
                                    <th>Total</th>
                                    <th>Estado</th>
                                    {/*<th>Acciones</th>*/}
                                </tr>
                            </thead>
                            <tbody>
                                {batchConsiliations.map((record, idx) => (
                                    <tr key={idx}>
                                        <td>{record.createdAt}</td>
                                        <td>{record.lote_reference}</td>
                                        <td>${record.total.toFixed(2)}</td>
                                        <td>{record.consiliated === false ? "No conciliado" : "Conciliado"}</td>
                                        {/*<td>
                                            <Button
                                                variant="info"
                                               // onClick={() => handleShowDetails(record)}
                                            >
                                                Ver Detalles
                                            </Button>
                                        </td>*/}
                                    </tr>
                                ))}
                            </tbody>
                        </Table>

                        {/** Button to save the consiliation */}
                        <Button variant="primary" onClick={() => saveBankConciliation()}>
                            Confirmar y Enviar
                        </Button>
                    </RenderIf>

                    {/** Pre charge bank modal */}
                    <PreChargeBankDataModal
                        showModal={showModal}
                        handleClose={() => setShowModal(false)}
                        columns={columns}
                        selectedColumn={selectedColumn}
                        setSelectedColumn={setSelectedColumn}
                        excelData={excelData}
                        handleSubmit={consiliate}
                    />
                </main>
            </div>
        </>
    );
}
