import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';

import ErrorAlert from '../../Alerts/ErrorAlert';
import { Badge } from 'react-bootstrap';
import { ListGroup } from 'react-bootstrap';
import { SegmentsFechtAPI } from '../../../api/Segments';
import { GroupsFechtAPI } from '../../../api/Groups';
import { TaxesFetchAPI } from '../../../api/Taxes';
import Select from 'react-select';

import ModalAddItemSegment from './ModalAddItemSegment';
import ModalEditItemTosegment from '../ModalItems.jsx/ModalEditItemTosegment';

import segmentsList from '../../img/icons/acompañantes.png'

function ModalListSegmentByGroup({ product }) {
    const [showModal, setShowModal] = useState(false)
    const [eventHandle, setEventHandle] = useState(0);
    const [segments, setSegments] = useState([])
    const [segmentsAll, setSegmentsAll] = useState([])
    const [showModalAsing, setShowModalAsing] = useState(false)
    const [showModalAddItem, setShowModalAddItem] = useState(false);
    const [showModalTaxes, setShowModalTaxes] = useState(false);
    const [selectSegmentNow, setSelectSegmentNow] = useState("")
    const [taxes, setTaxes] = useState([]);
    const [taxSelect, setTaxSelect] = useState("");
    const [infoItemAndSegmentSelect, setInfoItemAndSegmentSelect] = useState({ item_id: 0, segment_id: 0 })

    let token = localStorage.getItem("x-access-token")

    let user = localStorage.getItem('user');


    const getSegmentsByGroups = async () => {
        try {
            const response = await SegmentsFechtAPI.getSegmentsByGroup(product.group_id, token)
            console.log(response)
            setSegments(response.data.data)
        } catch (err) {
            console.log(err);
            setSegments([])
        }
    }



    const getSegmentByBusiness = async () => {
        user = JSON.parse(localStorage.getItem('user'))
        try {
            const response = await SegmentsFechtAPI.getSegmentByBusiness(user.business_id, token)

            let segmentInfo = []
            response.data.data.forEach(segment => {
                segmentInfo.push({ value: segment.segment_id, label: segment.name })
            });

            setSegmentsAll(segmentInfo)

        } catch (err) {
            console.log(err)
        }
    }

    const deleteItemToSegment = async (item_id, segment_id) => {
        let data = {
            "item_id": item_id
        }
        try {
            const response = await SegmentsFechtAPI.deleteItemtoSegment(segment_id, data, token)
            getSegmentsByGroups()
        } catch (err) {
            console.log(err);
            ErrorAlert(err.response.data.error, 'Error no se pudo remover el item', 'error')
        }
    }

    const deleteTaxofItemAndSegment = async (tax_id, segment_id, item_id) => {
        let data = {
            tax_id: tax_id,
            segment_id: segment_id,
            item_id: item_id
        }
        try {
            const response = await TaxesFetchAPI.deleteTaxOfItemAndSegment(data, token)
            getSegmentsByGroups()
        } catch (err) {
            console.log(err);
            ErrorAlert(err.response.data.error, 'Error no se pudo remover el impuesto', 'error')
        }
    }

    const deleteSegmenttoGroup = async (segment_id) => {
        let data = {
            "group_id": product.group_id
        }
        try {
            const response = await GroupsFechtAPI.deleteSegmenttoGroup(segment_id, data, token)
            getSegmentsByGroups()
            ErrorAlert('', 'Acompañante eliminado con exito', 'success')
        } catch (err) {
            console.log(err)
            ErrorAlert(err.response.data.error, 'Error no se pudo remover el acompañante', 'error')
        }
    }

    const addSegmentToGroup = async () => {
        try {
            const response = await SegmentsFechtAPI.addSegmentToGroup(product.group_id, selectSegmentNow, token)

            getSegmentsByGroups()
            setShowModalAsing(false)
            ErrorAlert('', 'Acompañante asignado con exito', 'success')
        } catch (err) {
            console.log(err)
            ErrorAlert(err.response.data.error, 'Error no se pudo asignar el acompañante', 'error')
        }
    }

    const asingItemToSegmentByGroup = async (segment_id, item_id) => {
        let data = {
            "item_id_segment_default": [item_id]
        }
        try {
            const response = await GroupsFechtAPI.asingDefaultItemTosegmentOnGroup(segment_id, product.group_id, data, token)
            getSegmentsByGroups()
        } catch (err) {
            console.log(err);
            ErrorAlert(err.response.data.error, 'Error no se pudo asignar item por defecto', 'error')
        }
    }

    //Function to remove default item of a segment asigned on a group
    const removeDefaultItemTosegmentOnGroup = async (segment_id, item_id) => {
        let data = {
            "item_id_segment_default_to_remove": [item_id]
        }
        try {
            const response = await GroupsFechtAPI.removeDefaultItemTosegmentOnGroup(segment_id, product.group_id, data, token); 
            getSegmentsByGroups(); 
        } catch (err) {
            console.log(err);
            ErrorAlert(err.response.data.error, 'Error no se pudo remover item por defecto', 'error')
        }
    }


    const getTaxes = async () => {
        try {
            const response = await TaxesFetchAPI.getTaxes(token);
            console.log(response);
            setTaxes(response.data.data)

        } catch (err) {
            console.log(err);
        }
    }

    const asingTaxToItemOfSegment = async () => {
        let data = {
            tax_id: taxSelect,
            segment_id: infoItemAndSegmentSelect.segment_id,
            item_id: infoItemAndSegmentSelect.item_id
        }
        try {
            const response = await TaxesFetchAPI.asingTaxToItemOfSegment(data, token)
            console.log(response);
            setInfoItemAndSegmentSelect({ item_id: 0, segment_id: 0 })
            getSegmentsByGroups()
            setShowModalTaxes(false)
            ErrorAlert('', 'Impuesto asignado correctamente', 'success')
        } catch (err) {
            console.log(err);
            ErrorAlert(err.response.data.error, 'Error de asignacion', 'error')
        }
    }




    const itemsBySegment = (segment) => {
        let items = segment.items;
        let defaultItem = segment.default_items;
        const viewItems = items.map(item => (
            <ListGroup as="ol" >
                <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                >
                    <img
                        className='imgitemsList m-1'
                        src={item.img}
                        alt={item.name}
                    />
                    <div className="ms-2 me-auto">
                        <div className="fw-bold">{item.name}
                        </div>
                        <div className="">{
                            defaultItem.findIndex(e => e.item_id === item.item_id) !== -1 ? <>
                                <Badge bg="success"><i className="uil uil-crosshair">Item por defecto</i></Badge>
                                <button className='btn btn-outline-danger btn-sm border-0' onClick={() => removeDefaultItemTosegmentOnGroup(segment.segment_id, item.item_id)} >Remover por defecto
                                    <i className="uil uil-trash-alt"></i>
                                </button></>
                                : <>
                                    <button className='btn btn-outline-primary btn-sm border-0' onClick={async () => asingItemToSegmentByGroup(segment.segment_id, item.item_id)} >
                                        <i className="uil uil-plus">Asignar por defecto</i>
                                    </button>
                                </>}</div>
                        <div className="">Precio: <b>${parseFloat(item.price).toFixed(2)}</b> - {item.description} <b>

                        </b></div>
                        <div className=''>
                            <p className=''><strong>Impuestos: </strong>

                                <button className='btn btn-outline-primary btn-sm border-0' onClick={async () => (setInfoItemAndSegmentSelect({ item_id: item.item_id, segment_id: segment.segment_id }), getTaxes(), setShowModalTaxes(true))} >
                                    <i className="uil uil-plus">Asignar impuesto</i>
                                </button></p>

                        </div>
                        <div className='mt-1'>
                            <ListGroup as="ol" >
                                {item.taxes.map((tax) => (
                                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                        <div className="ms-2 me-auto" >
                                        {tax.tax } - { tax.percentage}
                                            <button className='btn btn-outline-danger btn-sm border-0' onClick={() => deleteTaxofItemAndSegment(tax.tax_id, segment.segment_id, item.item_id)} >
                                                <i className="uil uil-trash-alt"></i>
                                            </button>
                                        </div>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </div>
                    </div>

                    <div>
                        <ModalEditItemTosegment item={item} segment={segment} load={getSegmentsByGroups}></ModalEditItemTosegment>
                        <button className='btn btn-outline-danger btn-sm border-0' onClick={async () => (deleteItemToSegment(item.item_id, segment.segment_id))}>
                            <i className="uil uil-trash-alt"></i>
                        </button>

                    </div>
                </ListGroup.Item>
            </ListGroup>
        ))
        return (viewItems)
    }

    const handleSaveTableSelect = (option) => {
        setSelectSegmentNow(option.value);
    }


    useEffect(() => {
    }, [eventHandle])


    return (
        <>
            <button type="button" className='btn btn-outline-light pulse rounded-3 p-2 lh-1 border'
                onClick={(e) => (setShowModal(true), getSegmentsByGroups(), getSegmentByBusiness())}
            >
                <img className='icon-options-cards' src={segmentsList} alt="Edit" />

            </button>
            <Modal
                show={showModal}
                onHide={() => (setShowModal(false))}
                size='lg'
                centered>
                <Modal.Header className='text-center'>
                    <h3><i className="uil uil-list-ol"></i>Acompañantes del producto </h3>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className='row'>
                            <div className='col-11' >
                                <ListGroup as="ol" >
                                    <ListGroup.Item
                                        as="li"
                                        className="d-flex justify-content-between align-items-start"
                                    >
                                        <img
                                            className='imgitemsList m-1'
                                            src={product.img}
                                            alt={product.name}
                                        />
                                        <div className="ms-2 me-auto">
                                            <div className="fw-bold">{product.name}</div>
                                            {product.description}
                                        </div>
                                    </ListGroup.Item>
                                </ListGroup>
                                <h5 className='fs-4 fw-bold m-2 text-center'>Detalle acompañantes</h5>
                                <div className='d-flex justify-content-center align-item-center m-3'>

                                    <button className='btn btn-primary btn-sm border-0' onClick={() => (setShowModalAsing(true))}> <i className="uil uil-plus"></i> Asignar acompañante
                                    </button>
                                </div>
                            </div>
                            <div className='col-11'>
                                <ListGroup as="ol" >
                                    {segments.map(segment => (
                                        <ListGroup.Item
                                            as="li"
                                            className="d-flex justify-content-between align-items-start"
                                        >
                                            <div className="m-2 me-auto col-12">
                                                <div className="fw-bold d-flex justify-content-between align-items-start">{segment.name}
                                                    <ModalAddItemSegment segment={segment} load={getSegmentsByGroups} ></ModalAddItemSegment>
                                                </div>

                                                <Container>
                                                    <Row>
                                                        <Col >{segment.description}</Col>
                                                        <Col >
                                                            <button className='btn btn-outline-danger btn-sm border-0' onClick={async () => deleteSegmenttoGroup(segment.segment_id)}>
                                                                <i className="uil uil-trash-alt" >Eliminar acompañante</i>
                                                            </button>
                                                        </Col>
                                                    </Row>
                                                </Container>

                                                {itemsBySegment(segment)}
                                            </div>
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => (setShowModal(false))}>Cerrar</Button>
                </Modal.Footer>
            </Modal >

            <Modal
                show={showModalAsing}
                onHide={() => (setShowModalAsing(false))}
                size='large'
                centered>
                <Modal.Header>
                    <h3><i className="uil uil-plus"></i>Asignar productos</h3>
                </Modal.Header>
                <Modal.Body>


                    <Form className="needs-validation">

                        <Form.Group className="mb-3" controlId="clientId">
                            <Form.Label>Acompañantes</Form.Label>
                            <Select
                                name="category_type"
                                className='w-100 '
                                options={segmentsAll}
                                onChange={handleSaveTableSelect}
                            />
                        </Form.Group>





                        <div className="mt-3 d-flex justify-content-end">
                            <Button size="sm" variant="success" type="button" className='m-3' onClick={() => addSegmentToGroup()} >
                                Guardar
                            </Button>
                        </div>
                    </Form>


                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => (setShowModalAsing(false))}>Cerrar</Button>
                </Modal.Footer>
            </Modal>

            {/*Modal add tax to product*/}
            <Modal
                show={showModalTaxes}
                onHide={() => (setShowModalTaxes(false))}
                size='large'
                centered>
                <Modal.Header>
                    <h3><i className="uil uil-plus"></i>Asignar impuesto</h3>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex justify-content-center align-item-center'>
                        <select className='form-select m-3' name="user_branch" id="user_branch" onChange={(e) => setTaxSelect(e.target.value)}>
                            <option >Seleccione un impuesto</option>
                            {taxes.map(tax => (
                                <option value={tax.id}>{tax.tax } - { tax.percentage}</option>
                            ))}
                        </select>
                        <Button size="sm" variant="success" type="button" className='m-3' onClick={async () => asingTaxToItemOfSegment()} >
                            Guardar
                        </Button>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => (setShowModalTaxes(false))}>Cerrar</Button>
                </Modal.Footer>
            </Modal>


        </>
    )
}

export default ModalListSegmentByGroup