import React, { useState } from 'react'

const Footer = () => {

    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    return (
        <>

            <footer className=' p-lg-3 bg-light'>

                <div className='container d-lg-flex justify-content-between align-content-center align-items-center bg-light w-100 text-center'>
                    <div className='my-2'>
                        <small className='opacity-50'>
                            <i class="uil uil-copyright"></i> {currentYear} Smart Pay
                        </small>
                    </div>

                    <div>


                        <small className='opacity-50'>
                            <i class="uil uil-bolt"></i> Powered by Smart Retail Group
                        </small>
                    </div>
                </div>

            </footer>

        </>
    )
}

export default Footer