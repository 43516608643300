//React components
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Nav from 'react-bootstrap/Nav';
import Select from 'react-select'
import ReportTable from '../reports/table/ReportTable';
//API
import { BranchOfficeFetchAPI } from '../../api/BranchOffices';
import { WarehouseFetchAPI } from '../../api/Warehouse';
import { InventoryFetchAPI } from '../../api/Inventory';
import { ListGroup } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import trash from '../img/icons/delete.png'
//Internal components 
import NavBar from '../NavBar/Navbar';
import { Container } from 'react-bootstrap';
import { Badge } from 'react-bootstrap';
import Table from '../Table/Table';
import add from '../img/icons/add-product.png'
//Modals 
import ModalNewUpdateWarehouse from './ModalWarehouse/ModalNewUpdateWarehouse';
import SuccessAlert from '../Alerts/SuccessAlert';
import ErrorAlert from '../Alerts/ErrorAlert';
const Reception = ({selectedBranch, warehouses, setWarehouses, getWarehouseByBrachoffice}) => {

    let { business_code } = useParams();
    //We get the user data 
    let user = JSON.parse(localStorage.getItem('user'))
    let token = localStorage.getItem('x-access-token');
    //States 
    const [eventHandler, setEventHandler] = useState(0)
    const [warehouseSelect, setWarehouseSelect] = useState({})
    const [showModalNewWarehouse, setShowModalNewWarehouse] = useState(false)
    //const [warehouses, setWarehouses] = useState([])
    const [showModaldetailReception, setShowModaldetailReception] = useState(false)
    const [warehouseselected, setWarehouseSelected] = useState(0);
    const [selectedUserOption, setSelectedUserOption] = useState(null);
    const [userBranchoffices, setUserBranchs] = useState([]);
    /*const [selectedBranch, setSelectedBranch] = useState({
        code: 0,
        id: 0,
        name: ""
    });*/

    const [transfer, setTransfer] = useState([])
    const [typeMovement, setTypeMovement] = useState("")
    const [detailData, setDetailData] = useState({
        group_name: "CF Bocadillos",
        source_warehouse_name: "Cocina 22",
        destination_warehouse_name: "Cocina 23",
        quantity: 2,
        user_name: "",
        created_at: "",
        updated_at: ""
    })

    //Function to get the branchoffice info 
    /*const getBranchoffice = async (business_code, branchoffice_code) => {
        try {
            const response = await BranchOfficeFetchAPI.getBranchOfficeByCode(business_code, branchoffice_code);
            setSelectedBranch(response.data.branchoffice);
            //We set the user selected to null
            setSelectedUserOption(null);

            getWarehouseByBrachoffice(response.data.branchoffice.id)
        } catch (err) {
            console.log(err)
            setSelectedBranch({
                code: 0,
                id: 0,
                name: ""
            });
            //We set the user selected to null
            setSelectedUserOption(null);
        }
    }*/

    //Function to delete warehouse
    const deleteWarehouse = async (warehouse_id) => {
        try {
            const response = await WarehouseFetchAPI.deleteWarehouse(warehouse_id, token)
            console.log(response);
            ErrorAlert('', 'Bodega eliminada corectamente', 'success')
            getWarehouseByBrachoffice(selectedBranch.id)
        } catch (err) {
            console.log(err);
            ErrorAlert(err.response.data.error, 'Bodega eliminada corectamente', 'success')
        }
    }

    //Function to populate the users branch 
    const branchList = () => {
        const listBracnh = userBranchoffices.map((branch) => (
            <>
                <option value={branch.code}>{branch.branchoffice_name}</option>
            </>
        ));
        return listBracnh
    }

    //Function to populate the warehouses
    const warehouseList = () => {
        const listWarehouse = warehouses.map((warehouse) => (
            <>
                <option value={warehouse.id}>{warehouse.name}</option>
            </>
        ));
        return listWarehouse
    }

    const viewPending = () => {
        const list = transfer.map(transfer => (
            <>

                <Col xs="12" sm="6" md="6" lg="4" xl="3">

                    <article className={'border rounded-4 mt-4 p-2 position-relative'} >
                        <div className=" ">

                            <div className='d-flex justify-content-between'>
                                <div className=" m-1">
                                    <span>Producto: </span><br></br><div className="fw-bold text-truncate">
                                        <span>{transfer.group_name}</span>
                                    </div>
                                </div>
                                <div className=" m-1">
                                    <span>Cantidad: </span><br></br><div className="fw-bold text-truncate">
                                        <span>{transfer.quantity}</span>
                                    </div>
                                </div>
                            </div>
                            {/* <span >Precio: <b>${parseFloat(product.price).toFixed(2)}</b></span> */}



                        </div>

                        {typeMovement == "pending" ? <>
                            <p className='text-secondary fw-light text-truncate card-item-categorie-description m-0'>Enviado por: <br></br><i className="uil uil-user-square"></i> {transfer.user_name}</p>
                            <div className='d-flex justify-content-center mt-2'>
                                <button className='btn btn-warning pulse rounded-3 p-2 lh-1 border  w-100 ' onClick={() => (setDetailData(transfer), setShowModaldetailReception(true))} >
                                    Recibir
                                </button>
                            </div></> : <>

                            {typeMovement == "approved" ? <>
                                <p className='text-secondary fw-light text-truncate card-item-categorie-description m-0'>Aprovado por: <br></br><i className="uil uil-user-square"></i> {transfer.user_name}</p>
                                <div className='d-flex justify-content-center mt-2'>
                                    <button className='btn btn-success pulse rounded-3 p-2 lh-1 border  w-100 ' onClick={() => (setDetailData(transfer), setShowModaldetailReception(true))} >
                                        <i className="uil uil-check-circle"></i> Aprovado
                                    </button>
                                </div></> : <>

                                <p className='text-secondary fw-light text-truncate card-item-categorie-description m-0'>Rechazada por: <br></br><i className="uil uil-user-square"></i> {transfer.user_name}</p>
                                <div className='d-flex justify-content-center mt-2'>
                                    <button className='btn btn-danger pulse rounded-3 p-2 lh-1 border  w-100 ' onClick={() => (setDetailData(transfer), setShowModaldetailReception(true))} >
                                        <i className="uil uil-times-circle"></i>  Rechazada
                                    </button>
                                </div>

                            </>}

                        </>}

                    </article>
                </Col></>))
        return (list)
    }



    const viewWarehouseDefauld = () => {

        const list = warehouses.map(warehouse => (<>
            {warehouse.is_default == 1 ? <>
                <Col xs="12" sm="6" md="6" lg="4" xl="3">

                    <article className={'card-on shadow-sm rounded-4 mt-4 p-2 position-relative'} >
                        <div className="d-flex justify-content-between ">
                            <div className="ms-1 me-auto d-inline-block">
                                <div className='d-flex justify-content-between'>
                                    <div className="fw-bold text-truncate card-item-categorie-title">
                                        <span>{warehouse.name}</span>
                                    </div>


                                </div>
                                {/* <span >Precio: <b>${parseFloat(product.price).toFixed(2)}</b></span> */}
                                <p className='text-secondary fw-light text-truncate card-item-categorie-description'>{warehouse.location}</p>
                            </div>
                        </div>
                        <div className='d-flex justify-content-between align-items-center rounded-3 mt-1'>
                            <div className=' d-flex align-item-center justify-content-start gap-1'>
                            </div>

                        </div>
                        <div className='d-flex justify-constent-center align-items-center'>
                            <label className="icon-on pulse rounded-3 p-2 lh-1 border-on border-0">
                                {/* <img className='icon-options-cards' src={on} alt="restart" /> */}
                                <small className='ms-1 text-on fw-semibold'>Bodega por defecto </small>
                            </label>



                        </div>
                    </article>
                </Col>
            </> : <></>}</>))
        return (list)
    }

    /*const getWarehouseByBrachoffice = async (branchoffice_id) => {
        try {
            const response = await WarehouseFetchAPI.getwarehousebybracnhoffice(branchoffice_id, 0, token)
            console.log(response);
            setWarehouses(response.data.warehouses)
            console.log(response.data.warehouses);
            setEventHandler(1)

        } catch (err) {
            console.log(err);
            setWarehouses([])
        }
    }*/

    const getmovementsByType = async (status) => {
        setTypeMovement(status)
        try {
            const response = await InventoryFetchAPI.getInventoryMovements(warehouseselected, token, status)
            console.log(response);
            setTransfer(response.data.movements)


        } catch (err) {
            console.log(err);
            setTransfer([])

        }

    }

    const updateMovements = async (type) => {
        let data = {
            "movement_id": detailData.movement_id,
            "user_id": user.id,
            "status": type
        }
        try {
            const response = await InventoryFetchAPI.Updatetransferinventorymovement(token, data)
            getmovementsByType(typeMovement)
            setShowModaldetailReception(false)
            ErrorAlert('', 'Moviemiento ' + type + ' realizado con exito', 'success')

        } catch (err) {
            ErrorAlert('', 'Moviemiento ' + type + ' no se pudo realizar con exito', 'error')
            console.log(err);
        }
    }

    //Hook to load the initial data 
    useEffect(() => {
        //We set the branchoffices of the user 
        setUserBranchs(user.branchoffices);

    }, []);

    useEffect(() => {
        setEventHandler(0)
    }, [eventHandler]);



    return (
        <>
            <div className=''>

                <div className='d-flex justify-content-between m-3'>
                    <h4><i className="uil uil-envelope-download-alt"></i> Recepcion de inventario </h4>
                </div>

                <div className='m-3'>
                    <div className="row gx-3 gy-2 align-items-center bg-white p-2 border-sp border">
                        {/*<div className="col-sm-12 col-md-6 col-lg-3">
                            <div className="form-group">
                                <label htmlFor="input_from" className='fw-bold' >Sucursal</label>
                                <select className='form-select' name="branch" id="branch" onChange={e => (getBranchoffice(business_code, e.target.value))}>
                                    <option value={0}>Selecciona una sucursal</option>
                                    {branchList()}
                                </select>
                            </div>
                        </div>*/}
                        <div className="col-sm-12 col-md-6 col-lg-3">
                            <div className="form-group">
                                <label htmlFor="input_from" className='fw-bold' >Bodegas</label>
                                <select className='form-select' name="branch" id="branch" onChange={(e) => setWarehouseSelected(e.target.value)}>
                                    <option value={0}>Selecciona una bodega</option>
                                    {warehouseList()}
                                </select>
                            </div>
                        </div>

                        <div className=" d-flex justify-content-center justify-content-sm-end mb-3">
                            <button type="button" className="btn btn-success px-5" onClick={() => (getmovementsByType("pending"))}>Aceptar</button>
                        </div>
                    </div>


                    <section className=''>
                        {selectedBranch.code != 0 ? <>
                            <div className="d-lg-flex justify-content-lg-between d-none mt-3">
                                <h4 className="fw-bold text-primary">Detalle recepcion </h4>
                            </div>

                            <div className="">

                                <Tab.Container defaultActiveKey="pending">
                                    <Row>
                                        <Col sm={12} >
                                            <Nav variant="underline" className="d-flex horizontal-scroll border-bottom  pe-3 ">
                                                <li className="nav-sp-item" onClick={() => getmovementsByType("pending")} >
                                                    <Nav.Link eventKey="pending" title="pending" ><i className="uil uil-process"></i> Pendientes</Nav.Link>
                                                </li>
                                                <li className="nav-sp-item" onClick={() => getmovementsByType("approved")}  >
                                                    <Nav.Link eventKey="approved" ><i className="uil uil-file-check"></i> Aprobados</Nav.Link>
                                                </li>
                                                <li className="nav-sp-item" onClick={() => getmovementsByType("rejected")} >
                                                    <Nav.Link eventKey="denied"><i className="uil uil-file-times"></i> Rechazados</Nav.Link>
                                                </li>

                                            </Nav>
                                        </Col>
                                        <Col sm={12}>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="pending" >
                                                    <div className=' m-0'>
                                                        pendiente
                                                        <Row>
                                                            {viewPending()}
                                                        </Row>
                                                    </div>
                                                </Tab.Pane>

                                                <Tab.Pane eventKey="approved">
                                                    <div className='m-0'>
                                                        aprobado
                                                        <Row>
                                                            {viewPending()}
                                                        </Row>
                                                    </div>
                                                </Tab.Pane>

                                                <Tab.Pane eventKey="denied" >
                                                    <div className='m-0'>
                                                        denegado
                                                        <Row>
                                                            {viewPending()}
                                                        </Row>
                                                    </div>
                                                </Tab.Pane>

                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>



                            </div></> : <></>}


                    </section>
                </div>
            </div>


            <Modal
                show={showModaldetailReception}
                onHide={() => (setShowModaldetailReception(false))}
                size=''
                centered>
                <Modal.Header className='text-center'>
                    <h3>
                        <i className="uil uil-file-info-alt"></i>
                        Detalle de la recepcion
                    </h3>
                </Modal.Header>
                <Modal.Body>
                    <Container>


                        <Row>
                            <Col>
                                <div className='mt-3'>
                                    <ListGroup as="ol" >



                                        <ListGroup.Item
                                            as="li"
                                            className="d-flex justify-content-between align-items-start"
                                        >
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Estado</div>


                                                {detailData.movement_status == "pending" ? <>
                                                    <Badge bg="warning"><h6 > <i className="uil uil-process"></i> Pendiente</h6></Badge></> : <>

                                                    {typeMovement == "approved" ? <><Badge bg="success"><h6> <i className="uil uil-check-circle"></i> Aprovado</h6></Badge></> :
                                                        <>
                                                            <Badge bg="danger"> <h6><i className="uil uil-times-circle"></i> Rechazado</h6></Badge>
                                                        </>}</>}
                                            </div>



                                        </ListGroup.Item>
                                        <ListGroup.Item
                                            as="li"
                                            className="d-flex justify-content-between align-items-start"
                                        >
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Bodega de envio</div>
                                                {detailData.source_warehouse_name}
                                            </div>

                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Bodega destino</div>
                                                {detailData.destination_warehouse_name}

                                            </div>

                                        </ListGroup.Item>
                                        <ListGroup.Item
                                            as="li"
                                            className="d-flex justify-content-between align-items-start"
                                        >
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Producto</div>
                                                {detailData.group_name}
                                            </div>

                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Cantidad</div>
                                                {detailData.quantity}
                                            </div>

                                        </ListGroup.Item>
                                        <ListGroup.Item
                                            as="li"
                                            className="d-flex justify-content-between align-items-start"
                                        >
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Gestionado por</div>
                                                {detailData.user_name}
                                            </div>

                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">Fecha</div>
                                                {detailData.updated_at}
                                            </div>

                                        </ListGroup.Item>
                                    </ListGroup>
                                </div>
                            </Col>
                        </Row>
                    </Container>


                    <div className='d-flex justify-content-between m-3'>
                        <button className='btn btn-primary pulse rounded-3 p-2 lh-1 border  ' onClick={() => setShowModaldetailReception(false)}>
                            <i className="uil uil-arrow-left"></i> Volver
                        </button>
                        {typeMovement == "pending" ? <>


                            <button className='btn btn-danger pulse rounded-3 p-2 lh-1 border  ' onClick={() => updateMovements('rejected')}>
                                <i className="uil uil-times-circle"></i> Rechazar
                            </button>
                            <button className='btn btn-success pulse rounded-3 p-2 lh-1 border  ' onClick={() => updateMovements('approved')}>
                                <i className="uil uil-check-circle"></i> Aprovar
                            </button>
                        </> : <>
                        </>}
                    </div>



                </Modal.Body>
            </Modal>
        </>
    );
}

export default Reception;
