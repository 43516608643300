import React from 'react'
//Images
import refund from '../img/icons/refund.png'
import cashback from '../img/icons/cashback.png'
function TransactionPill(props) {
   return (
      <>
         <div className="card-ht px-lg-2 px-1 py-2 rounded-4 bg-white">
            <div className=' d-flex gap-0'>
               <img className='flex-shrink-0 mx-1 mt-2' width="40" height="40" src={props.data.is_devolution_notification == true ? cashback : refund} alt="bill" />
               {/* <img className="flex-shrink-0 " src={props.data.order_type === "devolution" ? devolution : invoice} alt="twbs" width="32" height="32" /> */}
               <div className='w-100 px-lg-2'>
                  {/* 0*/}
                  <div className='d-flex justify-content-between'>
                     <span className={props.data.order_type === "devolution" ? "mb-0 text-warning fw-semibold" : "mb-0 text-primary fw-semibold"}>
                        Orden: #{props.data.transaction_code}
                     </span>

                     {props.data.is_pre_order == true ? <>
                        <span className="text-nowrap text-primary h6 d-block mb-2">
                           <i class="uil uil-receipt"></i>
                           Precuenta
                        </span>
                        <small className="text-nowrap text-danger h6 d-block mb-2">
                           <i class="uil uil-shield-question"></i>
                           {props.data.is_authorized == false ? 'Se requiere autorizacion' : ''}
                        </small></> : <></>
                     }


                     <span className="text-nowrap text-secondary h6">
                        <i className="fa-solid fa-mobile-screen"></i>&nbsp;
                        Máquina: {props.data.machine_code}
                     </span>
                  </div>

                  {/* 1 */}
                  <div className='d-flex justify-content-between'>
                     <span className="text-nowrap text-secondary h6 border-dashed p-2 rounded-2">
                        <i className="fa-solid fa-store"></i>&nbsp;
                        Sucursal:
                        <span className='fw-bold'>
                           {props.data.branchoffice.name}
                        </span>

                     </span>
                     <span className="text-nowrap text-secondary h6 border-dashed p-2 rounded-2">
                        <i className="fa-solid fa-store"></i>&nbsp;
                        Uusario:
                        <span className='fw-bold'>
                           {!props.data.machine_name ? 'No es caja' : props.data.machine_name} / {!props.data.user ? 'No es cajera' : props.data.user.name}

                        </span>

                     </span>
                  </div>

                  {/* 1 */}
                  <div className="d-flex justify-content-between align-items-center">
                     <div>
                        <small className="text-nowrap  text-uppercase fw-light"> {props.data.order_type}  </small>
                     </div>
                     <div className='d-inline-flex align-items-center'>
                        <span className='text-muted fw-light'> {props.data.paymenthod} </span>
                     </div>
                  </div>
                  <div>
                     <div className='total-card-ht d-flex  justify-content-center align-items-center'>
                        <span className='text-card-ht me-1 fw-bold'> Total:  {props.data.total.toFixed(2)} $ </span>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   )
}

export default TransactionPill