//React components 
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
//Components 
import Navbar from '../NavBar/Navbar';
import Table from '../Table/Table';
import FormSmartpayModal from '../Modal/Modal';
//Api 
import { BranchOfficeFetchAPI } from '../../api/BranchOffices';
import { TablesFetchAPI } from '../../api/Tables';
import { ClientsFetchAPI } from '../../api/Clients';
//Alerts
import ErrorAlert from '../Alerts/ErrorAlert';
//Utils 
import { RenderIf } from '../utils/RenderIf';

import ModalBranchofficeSelect from '../NavBar/ModalBranchofficeSelect';
import { generateRandomString } from '../utils/RandomString';
import Swal from 'sweetalert2';
//RoomsTables component
const RoomsTables = () => {
    //Params
    let { business_code } = useParams();
    //LocalStorage
    let user = JSON.parse(localStorage.getItem('user'))
    let token = localStorage.getItem('x-access-token');
    //States
    const [selectedBranch, setSelectedBranch] = useState({
        code: 0,
        id: 0,
        name: ""
    });
    const [userBranchoffices, setUserBranchs] = useState([]);
    const [eventHandler, setEventHandler] = useState(0);
    const [tablesList, setTablesList] = useState([]);
    //Modal states 
    const [showModal, setShowModal] = useState(false);
    const [editShowModal, setEditShowModal] = useState(false);
    const [businessClients, setBusinessClients] = useState([]);
    //We set the modal fields
    const [modalFields, setModalFields] = useState([
        { name: 'name', label: 'Nombre', type: 'text', placeholder: 'Ingrese nombre', required: true },
        { name: 'client', label: 'Cliente', type: 'select', options: [], required: false },
    ]);
    //Modal fields of new table 
    const [modalFieldsNewTable, setModalFieldsNewTable] = useState([
        { name: 'name', label: 'Nombre', type: 'text', placeholder: 'Ingrese nombre', required: true },
    ]);

    const [formData, setFormData] = useState({});
    const [selectedTable, setSelectedTable] = useState({
        name: "",
        code: "",
        password: "",
        client: 0,
        status: 0,
        open_order: 0,
        id: 0
    });

    //Function to detect the change of value on the users forms
    const handleFieldChange = (fieldName, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [fieldName]: value,
        }));
    };

    const actionSelectBranch = (branchCode) => {
        getBranchoffice(branchCode); 
     }

    //Function to get the branch office
    const getBranchoffice = async (code) => {
        try {
            //We search the branch office
            const branch = await BranchOfficeFetchAPI.getBranchOfficeByCode(business_code, code);
            setSelectedBranch({
                name: branch.data.branchoffice.name,
                code: branch.data.branchoffice.code,
                id: branch.data.branchoffice.id
            });
            //We set the event handler
            setEventHandler(1);
        } catch (err) {
            //We set the branch office to 0
            setSelectedBranch({
                code: 0,
                id: 0
            });
            //We set the event handler
            setEventHandler(1);
        }
    }

    //Function to populate the users branch 
    const branchList = () => {
        const listBracnh = userBranchoffices.map((branch) => (
            <>
                <option value={branch.code}>{branch.branchoffice_name}</option>
            </>
        ));
        return listBracnh
    }

    //Function to get the tables of the branch office
    const getTables = async (branchoffice_id) => {
        try {
            const tables = await TablesFetchAPI.getBranchofficeTables(branchoffice_id, token);
            console.log(tables)
            setTablesList(tables.data.tables)
        } catch (err) {
            setTablesList([])
        }
    }

    //Function to get the clients of the business 
    const getClients = async () => {
        try {
            const clients = await ClientsFetchAPI.getClientsByBusiness(user.business_id, token);
            let clientsList = clients.data.data.map((client) => (
                { value: client.id, label: client.name }
            ));
            let modalFieldsCopy = [...modalFields];
            modalFieldsCopy[1].options = clientsList;
            modalFieldsCopy[1].options.unshift({ value: 0, label: "Seleccione cliente" });
            setModalFields(modalFieldsCopy);
            setBusinessClients(modalFieldsCopy[1].options);
        } catch (err) {
            let modalFieldsCopy = [...modalFields];
            modalFieldsCopy[1].options = [];
            setModalFields(modalFieldsCopy);
            setBusinessClients([]);
        }
    }

    //Function to get the details of a table 
    const getTableDetails = async (table_id, modal_type) => {
        try {
            const table = await TablesFetchAPI.getTableDetails(table_id, token);
            console.log(table);
            //We set the selected table 
            setSelectedTable({
                name: table.data.table.name,
                code: table.data.table.code,
                password: table.data.table.password,
                client: table.data.table.client_id !== null ? table.data.table.client_id : 0,
                status: table.data.table.status,
                open_order: table.data.table.open_order,
                id: table_id
            });
            //We check if the modal is for edit or details
            if (modal_type === "edit") {
                //We set the form data
                let lastModalFields = [...modalFields];
                lastModalFields[0].value = table.data.table.name;
                lastModalFields[1].value = table.data.table.client_id !== null ? table.data.table.client_id : 0;
                lastModalFields[1].options = businessClients;
                lastModalFields[1].defaultValue = 0
                setModalFields(lastModalFields);
                //We set the form data
                setFormData({
                    name: table.data.table.name,
                    client: 0
                });

                setEventHandler(1)
            }
        } catch (err) {
            console.log(err)
        }
    }

    //Function to edit the user
    const editUser = async (e) => {
        e.preventDefault();
        try {
            //We edit the user
            const response = await TablesFetchAPI.updateTable(selectedTable.id, formData, token);
            //We set the event handler
            setEventHandler(1);
            //We show the success alert
            ErrorAlert("Usuario editado", "El usuario fue editado correctamente", "success")
            //We close the modal
            setEditShowModal(false);
        } catch (err) {
            console.log(err)
            ErrorAlert("Error al editar usuario", "No se pudo editar el usuario, intente de nuevo", "error")
        }
    }

    //Function to remove the table
    const removeTable = async (e) => {
        let table_id = e.currentTarget.dataset.id;
        try {
            //We use swal to confirm the action
            Swal.fire({
                title: '¿Está seguro?',
                text: "No podrá revertir esta acción!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, eliminar!',
                cancelButtonText: 'Cancelar'
            }).then(async (result) => {
                if (result) {
                    //We delete the table
                    await TablesFetchAPI.deleteTable(table_id, token);
                    //We set the event handler
                    setEventHandler(1);
                    //We show the success alert
                    ErrorAlert("Mesa eliminada", "La mesa fue eliminada correctamente", "success")
                }
            });

        } catch (err) {
            console.log(err)
            ErrorAlert("Error al eliminar mesa", "No se pudo eliminar la mesa, intente de nuevo", "error")
        }
    }

    //Function to generate a random password for the table
    const generatePassword = async (e) => {
        let password = generateRandomString(8);
        let data = {
            table_password: password
        }
        try {
            //We update the data 
            await TablesFetchAPI.updateTable(e.currentTarget.dataset.id, data, token);
            //We set the event handler
            setEventHandler(1);
        } catch (err) {
            //We show the success alert 
            ErrorAlert(err.response.data.error);
        }
    }

    //Function to new table
    const newTable = async (e) => {
        e.preventDefault();
        try {
            //We set the branch office id
            formData.branchoffice_id = selectedBranch.id;
            //We create the table
            const response = await TablesFetchAPI.createTable(formData, token);
            //We set the event handler
            setEventHandler(1);
            //We show the success alert
            ErrorAlert("Mesa creada", "La mesa fue creada correctamente", "success")
            //We close the modal
            setShowModal(false);
        } catch (err) {
            console.log(err)
            ErrorAlert("Error al crear mesa", "No se pudo crear la mesa, intente de nuevo", "error")
        }
    }

    //Function to listen the swicht 
    const handleSwitchChange = async (e) => {
        //We set the data to update 
        let data = {
            status: e.target.checked ? 1 : 0
        }
        try {
            //We update the data 
            await TablesFetchAPI.updateTable(e.target.id, data, token);
            //We set the event handler
            setEventHandler(1);
        } catch (err) {
            //We show the success alert 
            ErrorAlert(err.response.data.error);
            //We put the swicht checked false 
            e.target.checked = false
            //We set the event handler
            setEventHandler(1);
        }
    };

    //Hook to load the initial data 
    useEffect(() => {
        //We set the branchoffices of the user 
        setUserBranchs(user.branchoffices);
        getClients();
        setEventHandler(0);
    }, []);

    //Hook to get tables of the branch office
    useEffect(() => {
        if (selectedBranch.code !== 0) {
            getTables(selectedBranch.id);
        }
        //We set the form data
        setFormData({

        });
        //We set the event handler to 0
        setEventHandler(0);
    }, [eventHandler]);

    return (

        <>
            <Navbar titlePage="Mesas" />
            <div className=' bg-light pt-5'>
                <div className='container'>
                    {/** Body header */}
                    <div className="m-2">
                        <section className='banner-reports d-flex d-lg-flex justify-content-lg-between align-items-center
                    mb-4 banner-sp rounded-4 py-2 px-3 '>
                            <div>
                                <div className='d-flex justify-content-lg-between align-items-center'>
                                    <h2 className='banner-reports-title mb-0 pb-0'>
                                        {user.business.business_type.panamanianCode !== "9010" ? "Gestión de habitaciones" : "Gestión de mesas"}:  {selectedBranch.name ? <>{selectedBranch.name}</> : <>Seleccione sucursal</>}
                                    </h2>
                                </div>
                                <p className='m-0 p-0 banner-reports-description'> {user.business.business_type.panamanianCode !== "9010" ? "Gestión de habitaciones." : "Gestión de mesas."} </p>
                            </div>
                            {/*<div className="mt-2 w-50">

                                <select onChange={e => actionSelectBranch(e.target.value)} className='form-select' name="user_branch" id="user_branch">
                                    <option value={0}>Seleccione sucursal</option>
                                    {branchList()}
                                </select>
                            </div>*/}

                            <ModalBranchofficeSelect actionSelectBranch={actionSelectBranch}></ModalBranchofficeSelect>
                        </section>
                        <RenderIf isTrue={selectedBranch.code !== 0}>
                            {/** Add table button */}
                            <FormSmartpayModal
                                buttonModal={true}
                                button_text={"Agregar mesa"}
                                modal_size={"lg"}
                                modal_header_text={"Agregar mesa"}
                                modal_body={modalFieldsNewTable}
                                submit={newTable}
                                setShowModal={setShowModal}
                                showModal={showModal}
                                handleFieldChange={handleFieldChange}
                                initialFormValues={formData}
                                formType={"addForm"}
                                setEventHandler={setEventHandler}
                            />

                        </RenderIf>
                        {/** Table */}
                        <Table
                            title={user.business.business_type.panamanianCode !== "9010" ? "Habitaciones" : "Mesas"}
                            table_headers={["", "Estados", "Nombre", "Codigo", "Contraseña", "Cliente", "Acciones"]}
                            table_data={tablesList}
                            table_type={"rooms_table"}
                            user={user}
                            table_name={user.business.business_type.panamanianCode !== "9010" ? "Habitaciones" : "Mesas"}
                            setEditShowModal={setEditShowModal}
                            getTableDetails={getTableDetails}
                            handleSwitchChange={handleSwitchChange}
                            generatePassword={generatePassword}
                            removeData={removeTable}
                        />

                        {/** Edit table modal */}
                        <FormSmartpayModal
                            buttonModal={false}
                            modal_size={"lg"}
                            modal_header_text={"Editar usuario"}
                            modal_body={modalFields}
                            submit={editUser}
                            setShowModal={setEditShowModal}
                            showModal={editShowModal}
                            handleFieldChange={handleFieldChange}
                            initialFormValues={formData}
                            formType={"editForm"}
                        />
                    </div>
                </div>


            </div>



        </>





    );
}

export default RoomsTables;
