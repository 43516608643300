import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';

import ErrorAlert from '../../Alerts/ErrorAlert';
import { Badge } from 'react-bootstrap';
import { ListGroup } from 'react-bootstrap';
import { CategoriesDashFetchAPI } from '../../../api/CategoriesDash';
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { InputGroup } from 'react-bootstrap';

function ModalAsingAndRemovedCategoryToBranchoffice({ branchoffice_id, load, categoriesBranchoffice }) {
    const [showModal, setShowModal] = useState(false)
    const [eventHandle, setEventHandle] = useState(0)
    const [categories, setCategories] = useState([])
    const [categorySelect, setCategorySelect] = useState("")
    const [deleteMode, setDeleteMode] = useState(false)
    const [searchedCategories, setSearchedCategories] = useState("")
    const [searchTerm, setSearchTerm] = useState('');
    const [categoriesSelectList, setCategoriesSelectList] = useState([])


    let token = localStorage.getItem("x-access-token")

    let user = localStorage.getItem('user');


    const getCategoryByBusiness = async () => {
        user = JSON.parse(localStorage.getItem('user'))
        try {
            const response = await CategoriesDashFetchAPI.getCategoriesByBusiness(user.business_id, token)
            console.log(response.data.data);
            setCategories(response.data.data)
            setSearchedCategories(response.data.data)

        } catch (err) {
            console.log(err);
        }
    }

    const asingCategoryToBranchoffice = async (category_id) => {

        try {
            const response = await CategoriesDashFetchAPI.asingCategoryToBranchoffice(category_id, branchoffice_id, token)
            console.log(response);
            ErrorAlert('', 'Categoria asignado con exito', 'success')
            load()
            setShowModal(false)
        } catch (err) {
            ErrorAlert(err.response.data.error, 'Error de asignacion', 'error')
            console.log(err);
        }
    }

    const handlerChecked = (e, category_id) => {
        if (e.target.checked == true) {

            if (categoriesSelectList.length > 29) {
                ErrorAlert('', 'Superaste la cantidad seleccionada', 'warning')
                e.target.checked = false
            } else {
                setCategoriesSelectList([...categoriesSelectList, category_id]);
            }
        } else {
            let filtrado = categoriesSelectList.filter(id => id != category_id)
            setCategoriesSelectList(filtrado)
        }
    }

    //delete category
    const deleteCategorytoBranchoffice = async (category_id) => {
        let user = JSON.parse(localStorage.getItem("user"))

        let data = {
            category_id: category_id
        }
        try {
            const response = await CategoriesDashFetchAPI.deleteCategorytoBranchoffice(category_id, branchoffice_id, token);
            console.log((response))
            ErrorAlert('', 'Categoria eliminada', 'success')
            load()
            setShowModal(false)

        } catch (err) {
            console.log(err)
            ErrorAlert(err.response.data.error, 'Error, no se pudo eliminar la categoria', 'error')
        }
    }

    //Filter the categories by the term searched 
    const filteredProducts = useMemo(() => {
        if (searchTerm === '') {
            return categories
        }
        return searchedCategories.filter(categorie => {
            const nameMatch = categorie.category_name.toLowerCase().includes(searchTerm.toLocaleLowerCase());
            const descriptionMatch = categorie.secundaryName.toLowerCase().includes(searchTerm.toLowerCase());
            return nameMatch || descriptionMatch;
        })
    }, [searchTerm, categories]);

    const saveArrayCategories = () => {
        if (categoriesSelectList.length > 30) {
            ErrorAlert('', 'Solo puedes agregar 30 categorias a la ves', 'warning')
        } else {
            categoriesSelectList.forEach((id, index) => {

                try {
                    if (deleteMode == true) {
                        deleteCategorytoBranchoffice(id)
                    } else {
                        asingCategoryToBranchoffice(id)
                    }

                    if (index == (categoriesSelectList.length - 1)) { setCategoriesSelectList([]); setDeleteMode(false); setSearchTerm('') }
                } catch (err) {
                    ErrorAlert('', 'Una de las categorias ya estaba asignada', 'warning')
                }
            });
        }
    }

    const hadleModeDelete = () => {
        setShowModal(true)
        setDeleteMode(true)
        setCategories(categoriesBranchoffice)
        setSearchedCategories(categoriesBranchoffice)
    }


    useEffect(() => {

    }, [eventHandle])

    return (
        <>


            <div className='d-flex justify-content-center align-item-center gap-1'>
                <button className='btn btn-dark  btn-sm  fw-normal bg-body text-dark border border-1 rounded-2 pulse'
                    onClick={() => (setShowModal(true), getCategoryByBusiness())}
                >
                    <i className="uil uil-file-plus-alt"></i>
                    <span>
                        Asignar
                    </span>

                </button>

                <button className='btn btn-dark  btn-sm  fw-normal bg-body text-dark border border-1 rounded-2 pulse' onClick={() => (hadleModeDelete())}>
                    <i className="uil uil-trash-alt"></i>
                    <span>
                        Eliminar
                    </span>

                </button>
            </div>

            <Modal
                show={showModal}
                onHide={() => (setShowModal(false))}
                size='xl'
                centered>

                <Modal.Body className='overflow-hidden'>

                    <div className='position-relative'>
                        <div className='position-absolute top-0 start-100 translate-middle mt-2 '>
                            <div className='me-3'>
                                <button class="btn btn-outline-secondary border-0 bg-light text-secondary rounded-circle p-2 lh-1"
                                    type="button" title='Cerrar'
                                    onClick={() => (setShowModal(false), setCategoriesSelectList([]),
                                        setDeleteMode(false), setSearchTerm(''))}

                                >
                                    <i class="uil uil-multiply"></i>
                                </button>
                            </div>
                        </div>
                        <h2 className="title-main-login  text-center ">
                            {deleteMode == true ? <>
                                Eliminar categorias de la sucursal</> : <>Asignar categorias a la sucursal</>}
                        </h2>

                        <div className="d-flex justify-content-between align-items-center w-100 ">
                            <div>
                                <span className='mb-0 text-dark fw-bold' >Categorias Seleccionados: {categoriesSelectList.length}</span>
                            </div>

                            <div>
                                <form className="row g- align-content-center align-items-center">
                                    <div className="col-auto ">
                                        <span className='mb-0 text-dark fw-bold' >Buscar</span>
                                    </div>
                                    <div className="col-auto">
                                        <input
                                            spellcheck="false"
                                            className="form-control"
                                            type="text"
                                            step="any"
                                            placeholder="Ingrese la categoria"
                                            onChange={e => setSearchTerm(e.target.value)}

                                        />
                                    </div>

                                </form>

                            </div>

                        </div>
                        <div>

                            <div className='row transaction-wrapper bg-light pb-4'>
                                {filteredProducts.map((categorie, index) => (
                                    <div className='col-md-4 position-relative  mt-3'>
                                        <div className='border-dashed rounded-3 bg-white'>
                                            <div class="d-flex align-items-center ">
                                                <div class="flex-shrink-0">

                                                    <img
                                                        className='imgitemsList m-1'
                                                        src={categorie.img}
                                                    />
                                                </div>
                                                <div class="flex-grow-1 ms-3  rounded-3">
                                                    <div className="fw-bold">{categorie.category_name}</div>
                                                    <p className=''>{categorie.secundaryName}</p>
                                                </div>

                                            </div>
                                            <div className='position-absolute top-0 end-0'>
                                                <Form.Check
                                                    inline
                                                    name="items"
                                                    type="checkbox"
                                                    id={`inline-${index}-2`}
                                                    onChange={(e) => handlerChecked(e, categorie.category_id)}
                                                />
                                            </div>

                                        </div>

                                        {/* <div
                                        className="d-flex justify-content-between rounded-3 border mt-3 position-relative"
                                    >

                                        <img
                                            className='imgitemsList m-1'
                                            src={categorie.img}
                                            alt={categorie.category_name}
                                        />
                                        <div className="ms-2 me-auto d-inline-block text-truncate">

                                        </div>
                                        <div className='position-absolute top-0 end-0'>
                                            <Form.Check
                                                inline
                                                name="items"
                                                type="checkbox"
                                                id={`inline-${index}-2`}
                                                onChange={(e) => handlerChecked(e, categorie.category_id)}
                                            />
                                        </div>


                                    </div> */}

                                    </div>
                                ))}
                            </div>



                            <div className='w-100 text-center'>
                                <small className='text-secondary opacity-50'><i class="uil uil-info-circle pe-1"></i>Puedes seleccionar una catidad máxima de 30 categorias</small>
                            </div>
                        </div>

                        <div className='d-flex justify-content-end'>
                            {deleteMode == true ?
                                <Button className='btn btn-danger  m-2' type="button" onClick={() => saveArrayCategories()}>
                                    <i class="uil uil-trash"></i>   Eliminar
                                </Button> :
                                <Button className='btn btn-save-sp m-2' type="button" onClick={() => saveArrayCategories()}>
                                    <i class="uil uil-save"></i>  Guardar
                                </Button>}
                        </div>

                    </div>


                </Modal.Body>

            </Modal >

        </>
    )
}

export default ModalAsingAndRemovedCategoryToBranchoffice