//Componet react 
import React, { useState, useEffect } from 'react';
//Components 
import { Modal, Button, Container, Row, Col, ListGroup, Badge, Form } from 'react-bootstrap';
//Internal modals 
import SmartpayModal from '../../Modal/Modal';
import ModalAsingPaymetMethod from './ModalAsingPaymetMethod';
//Api
import { MachinesFetchAPI } from '../../../api/Machines';
import { PaymentMethodFetchAPI } from '../../../api/PaymentMethod';
//Alerts 
import SuccessAlert from '../../Alerts/SuccessAlert';
import ErrorAlert from '../../Alerts/ErrorAlert';
//Utils
import { RenderIf } from '../../utils/RenderIf';
import { checkUserPermission } from '../../utils/CheckUserPermitions';
import { checkUserModules } from '../../utils/CheckUserModules';

//Component
const ModalMachineDetails = ({ userCategories, paymentMethodIntegrations, userBranchoffices, showModal, setShowModal, machine_id, token, eventHandler, setEventHandler, user }) => {
    //States
    const [machineDetails, setMachineDetails] = useState({
        name: "",
        status: 0,
        type: "",
        branchoffice_name: "",
        branchoffice_code: "",
        code: "",
        business_code: "",
    });
    const [modalFields, setModalFields] = useState([
        { name: 'branchoffice_id', label: 'Sucursal', type: 'select', options: userBranchoffices, required: true }
    ]);
    const [modalFieldsCategory, setModalFieldsCategory] = useState([
        { name: 'category_id', label: 'Categoria', type: 'select', options: userCategories, required: true }
    ]);
    const [modalFieldsEditParamValue, setModalFieldsEditParamValue] = useState([
        { name: 'param_value', label: 'Valor del parametro', type: 'text', required: true }
    ]);
    const [formData, setFormData] = useState({});
    const [machinePaymentMethods, setPaymentMethods] = useState([]);
    const [machineCategories, setCategories] = useState([]);
    //Internal modal states
    const [showAsingBranchModal, setShowAsingBranchModal] = useState(false);
    const [showAsingCategoryModal, setShowAsingCategoryModal] = useState(false);
    const [showAsingPaymentMethodModal, setShowAsingPaymentMethodModal] = useState(false);
    const [showEditParamValueModal, setShowEditParamValueModal] = useState(false);

    //Function to detect the change of value on the users forms
    const handleFieldChange = (fieldName, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [fieldName]: value,
        }));
    };

    //We get the machine details
    const getMachineDetails = async () => {
        try {
            //We get the machine details
            const machine = await MachinesFetchAPI.getmachineByid(machine_id, token);
            //We get the payment methods of the machine 
            const paymentMethods = await MachinesFetchAPI.getMachinePaymentMethods(machine_id, token);
            //We set the machine details
            setMachineDetails({
                name: machine.data.data.machine_name,
                status: machine.data.data.is_logged,
                type: machine.data.data.name_type_machine,
                subtype: machine.data.data.subtype,
                windows_ip: machine.data.data.windows_ip,
                android_ip: machine.data.data.android_ip,
                mcadress_wifi: machine.data.data.mcadress_wifi,
                mcadress_lan: machine.data.data.mcadress_lan,
                layaout: machine.data.data.layaout_id,
                machine_menu: machine.data.data.machine_menu,
                devolution: machine.data.data.machine_return,
                machine_pre_accounts: machine.data.data.machine_pre_accounts,
                machine_discounts: machine.data.data.machine_discounts,
                cupons: machine.data.data.machine_cupons,
                language: machine.data.data.language.name,
                branchoffice_name: machine.data.data.branchoffice_name,
                branchoffice_code: machine.data.data.branchoffice_code,
                code: machine.data.data.code,
                business_code: machine.data.data.business_code
            });
            //We set the machine categories
            setCategories(machine.data.data.categories);
            setPaymentMethods(paymentMethods.data.data);
        } catch (err) {
            //We set the show to false
            setMachineDetails({
                name: "",
                status: 0,
                type: "",
                subtype: "",
                windows_ip: "",
                android_ip: "",
                mcadress_wifi: "",
                mcadress_lan: "",
                layaout: 0,
                machine_menu: 0,
                devolution: 0,
                machine_pre_accounts: 0,
                machine_discounts: 0,
                cupons: 0,
                language: "",
                branchoffice_name: ""

            });
            //We set the machine categories
            setCategories([]);
            setPaymentMethods([]);
        }
    }

    //Function to update a machine param payment method value
    const updateMachineParamPaymentMethodValue = async (e) => {
        e.preventDefault(); 
        try {
            let value = formData.param_value;
            let param_value_id = formData.param_value_id;
            //We update the value of the param
            await PaymentMethodFetchAPI.updatePaymentMethodParamValue(param_value_id, value, machine_id, token);
            // We get the machine details again
            getMachineDetails();
            //We show the success alert
            SuccessAlert("Valor del parametro actualizado correctamente");
            //We set the show modal to false
            setShowEditParamValueModal(false);
        } catch (err) {
            ErrorAlert("Error al actualizar el valor del parametro del metodo de pago", "Error", "error")
        }
    }

    //Funtion to update the payment method
    const handlePaymentMethodSwitch = async (paymentMethodId, integration_id, machine_id, newStatus) => {
        try {
            //We update the payment method
            await PaymentMethodFetchAPI.updatePaymentMethod(machine_id, paymentMethodId, integration_id, newStatus ? 1 : 0, token);

            // We get the machine details again
            getMachineDetails();
            //We show the success alert
            SuccessAlert("Metodo de pago actualizado correctamente")
        } catch (err) {
            console.error('Error al actualizar el método de pago:', err);
            //We show the error alert
            ErrorAlert("Error al actualizar el método de pago")
        }
    };

    //Function to remove payment method from machine
    const removePaymentMethod = async (paymentMethodId, integration_id, machine_id) => {
        try {
            //We remove the payment method
            await PaymentMethodFetchAPI.removePaymentMethod(machine_id, paymentMethodId, integration_id, token);
            // We get the machine details again
            getMachineDetails();
            //We show the success alert
            SuccessAlert("Metodo de pago eliminado correctamente")
        } catch (err) {
            console.error('Error al eliminar el método de pago:', err);
            //We show the error alert
            ErrorAlert("Error al eliminar el método de pago")
        }
    };

    //Function to asing the branch office to the machine
    const asingBranchToMachine = async (e) => {
        e.preventDefault()
        try {
            //We asing the branch office to the machine
            await MachinesFetchAPI.updateMachineInfo({ branchoffice_id: formData.branchoffice_id }, machine_id, token);
            //We set the show modal to false
            setShowAsingBranchModal(false);
            // We get the machine details again
            setEventHandler(1);
            //We show the success alert
            SuccessAlert("Sucursal reasignada correctamente")
        } catch (err) {
            console.error('Error al asignar la sucursal a la máquina:', err);
            //We show the error alert
            ErrorAlert("Error al asignar la sucursal a la máquina")
        }
    }

    //Function to asing the category to the machine
    const asingCategoryToMachine = async (e) => {
        e.preventDefault()
        try {
            let category_id = formData.category_id;
            //We asing the category to the machine
            await MachinesFetchAPI.asingCategoryToMachine(machine_id, category_id, token);
            //We set the show modal to false
            setShowAsingCategoryModal(false);
            // We get the machine details again
            setEventHandler(1);
            //We show the success alert
            SuccessAlert("Categoria asignada correctamente")
        } catch (err) {
            //We show the error alert
            if (err.response.status === 400) {
                //We show the error alert
                ErrorAlert("La categoria ya esta asignada a la máquina", "Advertencia", "warning")
            } else {
                //We show the error alert
                ErrorAlert("Error al asignar la categoria a la máquina", "Error", "error")
            }
        }
    }

    //Function to remove category from machine
    const removeCategoryFromMachine = async (category_id) => {
        try {
            //We remove the category from the machine
            await MachinesFetchAPI.removeCategoryFromMachine(machine_id, category_id, token);
            // We get the machine details again
            setEventHandler(1);
            //We show the success alert
            SuccessAlert("Categoria eliminada correctamente")
        } catch (err) {
            console.error('Error al eliminar la categoria de la máquina:', err);
            //We show the error alert
            ErrorAlert("Error al eliminar la categoria de la máquina")
        }
    }


    //We get the machine details
    useEffect(() => {
        let lastModalFields = modalFields;
        //We set the branches on the asing branch modal
        lastModalFields[0].options = userBranchoffices;
        //We map the modal fields
        lastModalFields[0].options = userBranchoffices.map((branch) => ({
            value: branch.branchoffice_id, label: branch.branchoffice_name
        }));
        lastModalFields[0].options.unshift({ value: 0, label: 'Seleccione una sucursal' });
        //We set the modal fields
        setModalFields(lastModalFields);
        //We set the categories on the asing category modal
        let lastModalFieldsCategory = modalFieldsCategory;
        lastModalFieldsCategory[0].options = userCategories;
        lastModalFieldsCategory[0].options = userCategories.map((category) => ({
            value: category.category_id, label: category.category_name
        }));
        lastModalFieldsCategory[0].options.unshift({ value: 0, label: 'Seleccione una categoria' });
        //We set the modal fields
        setModalFieldsCategory(lastModalFieldsCategory);

        //We get the machine details
        getMachineDetails()
        setEventHandler(0)
    }, [eventHandler])

    return (
        <>
            <Modal
                show={showModal}
                onHide={() => (setShowModal(false))}
                size='xl'
                centered
            >

                <Modal.Header className='fw-bold fs-5' closeButton>
                    Datos de la Máquina
                </Modal.Header>
                <Modal.Body>
                    <h5 className='fw-bold text-primary mt-2'>Pin de Ingreso  </h5>
                    <div
                        className="d-lg-flex justify-content-lg-evenly "
                    >
                        <div className="border rounded-3 p-3  text-center bg-primary bg-gradient shadow-sm">
                            <div className="fw-semibold text-light opacity-75   ">Código de Máquina</div>
                            <span className='fw-bold text-white fs-2'>
                                {machineDetails.code != null && machineDetails.code != "" ? <>{machineDetails.code}</> : <>Sin Código</>}
                            </span>
                        </div>

                        <div className="border rounded-3 p-3 text-center bg-primary bg-gradient shadow-sm ">
                            <div className="fw-semibold text-light opacity-75  ">Código de Sucursal</div>
                            <span className=' fw-bold text-white fs-2'>
                                {machineDetails.branchoffice_code != null ? <>{machineDetails.branchoffice_code}</> : <>Sin Código</>}

                            </span>
                        </div>
                        <div className="border rounded-3 p-3  text-center bg-primary bg-gradient shadow-sm ">
                            <div className="fw-semibold text-light opacity-75   ">Código de Empresa</div>
                            <span className=' fw-bold text-white  fs-2' >
                                {machineDetails.business_code != null ? <>{machineDetails.business_code}</> : <>Sin Código</>}

                            </span>
                        </div>
                    </div>
                    <h5 className='fw-bold text-primary mt-4'>Información General </h5>
                    <div className=" row mt-3">
                        <div className="col-12 col-md-2 col-lg-3 mt-1">
                            <div className='border rounded-3 p-2'>
                                <div className="fw-bold">Nombre</div>
                                {machineDetails.name != null && machineDetails.name != "" ? <>{machineDetails.name}</> : <>Sin nombre</>}

                            </div>
                        </div>

                        <div className="col-12 col-md-2 col-lg-3 mt-1">
                            <div className="border rounded-3 p-2">
                                <div className="fw-bold">Sesión</div>
                                {machineDetails.status == 1 ? <><Badge bg="success">Activa</Badge></> : <><Badge bg="danger">Inactiva</Badge></>}
                            </div>
                        </div>
                        <div className="col-12 col-md-2 col-lg-3 mt-1">
                            <div className="border rounded-3 p-2">
                                <div className="fw-bold">Tipo</div>
                                {machineDetails.type != null && machineDetails.type != "" ? <>{machineDetails.type}</> : <>No registrado</>}
                            </div>
                        </div>
                        <div className="col-12 col-md-2 col-lg-3 mt-1">
                            <div className="border rounded-3 p-2">
                                <div className="fw-bold">Subtipo</div>
                                {machineDetails.subtype != null && machineDetails.subtype != "" ? <>{machineDetails.subtype}</> : <>No registrado</>}
                            </div>
                        </div>

                        <div className="col-12 col-md-4 col-lg-4 mt-1">
                            <div className="border rounded-3 p-2">
                                <div className="fw-bold d-flex justify-content-between">
                                    <span>
                                        Sucursal de la Máquina
                                    </span>

                                    {/** Render the button to asing the branch office */}
                                    <RenderIf isTrue={checkUserPermission("asing_branchoffice_machine", user.permissions) !== -1}>
                                        <button className='btn btn-light btn-sm border' onClick={() => setShowAsingBranchModal(true)}>
                                            <i className="uil uil-pen"></i>
                                        </button>
                                    </RenderIf>
                                </div>
                                {machineDetails.branchoffice_name}
                            </div>
                        </div>

                        <div className="col-12 col-md-2 col-lg-4 mt-1">
                            <div className="border rounded-3 p-2">
                                <div className="fw-bold">Layout de la Máquina</div>
                                {machineDetails.layaout != 1 ? <><Badge bg="primary">LITE</Badge></> : <><Badge bg="warning">PLUS + </Badge></>}
                            </div>
                        </div>

                        <div className="col-12 col-md-2 col-lg-4 mt-1">
                            <div className="border rounded-3 p-2">
                                <div className="fw-bold">Módulo de Cupones</div>
                                {machineDetails.cupons === 1 ? <> <Badge bg="success">Activo</Badge> </> : <><Badge bg="danger">Inactivo</Badge></>}
                            </div>
                        </div>

                        <div className="col-12 col-md-2 col-lg-4 mt-1">
                            <div className="border rounded-3 p-2">
                                <div className="fw-bold">Módulo de Menú</div>
                                {machineDetails.machine_menu === 1 ? <> <Badge bg="success">Activo</Badge> </> : <><Badge bg="danger">Inactivo</Badge></>}
                            </div>
                        </div>

                        <div className="col-12 col-md-2 col-lg-4 mt-1">
                            <div className="border rounded-3 p-2">
                                <div className="fw-bold">Módulo de Devoluciones</div>
                                {machineDetails.devolution === 1 ? <> <Badge bg="success">Activo</Badge> </> : <><Badge bg="danger">Inactivo</Badge></>}
                            </div>
                        </div>

                        <div className="col-12 col-md-2 col-lg-4 mt-1">
                            <div className="border rounded-3 p-2">
                                <div className="fw-bold">Módulo de Descuentos</div>
                                {machineDetails.machine_discounts === 1 ? <> <Badge bg="success">Activo</Badge> </> : <><Badge bg="danger">Inactivo</Badge></>}
                            </div>
                        </div>

                        <div className="col-12 col-md-2 col-lg-4 mt-1">
                            <div className="border rounded-3 p-2">
                                <div className="fw-bold">Módulo de Pre-cuentas</div>
                                {machineDetails.machine_pre_accounts === 1 ? <><Badge bg="success">Activo</Badge> </> : <><Badge bg="danger">Inactivo</Badge></>}
                            </div>
                        </div>

                        <div className="col-12 col-md-2 col-lg-4 mt-1">
                            <div className="border rounded-3 p-2">
                                <div className="fw-bold">Lenguaje por Defecto</div>
                                {machineDetails.language !== null && machineDetails.language !== undefined ? <>{machineDetails.language}</> : <>No registrado</>}
                            </div>
                        </div>

                        <div className="col-12 col-md-2 col-lg-4 mt-1">
                            <div className="border rounded-3 p-2">
                                <div className="fw-bold">
                                    {/* La máquina cerrará transacciones en sistemas externos */}
                                    Transacciones en sistemas externos
                                </div>
                                {machineDetails.automatic_payment != 0 && machineDetails.automatic_payment != false ? <>Cerrara</> : <>No cerrara</>}
                            </div>
                        </div>
                    </div>

                    <div className='d-flex justify-content-between mt-4'>
                        <h5 className='fw-bold text-primary mt-2'>Categorias </h5>
                        <RenderIf isTrue={checkUserPermission("asing_category_machine", user.permissions) !== -1}>
                            <button className='btn btn btn-outline-light  pulse rounded-3 p-2 lh-1 border text-dark' onClick={(e) => setShowAsingCategoryModal(true)}>
                                <i className="uil uil-plus"></i> Agregar Categoria
                            </button>
                        </RenderIf>

                    </div>
                    {
                        machineCategories.length === 0
                            ? <>
                                <div className='w-100 border rounded-3 mx-1 mt-1 p-2'>
                                    <div className="alert alert-warning border rounded-3 p-2 border rounded-2 text-center 
                                    fs-6
                                    ">
                                        <i className="uil uil-exclamation-triangle "></i>  <br />
                                        Aún no has agregado ninguna categoría. <br /> Las que añadas se mostrarán en este espacio.
                                    </div>
                                </div>
                            </>

                            : <>
                                <div className='row border rounded-3 mx-1 mt-1 p-2 g-3'>
                                    {machineCategories.map((category) => (
                                        <div className="col-12 col-lg-4   mx-1 d-flex border rounded-3 p-2 border rounded-2 p-2 justify-content-between" >
                                            <span className='fw-bold '>
                                                {category.name}
                                            </span>
                                            <button className='btn btn-outline-danger btn-sm border-0' onClick={e => removeCategoryFromMachine(category.category_id)}>
                                                <i className="uil uil-trash-alt"></i>
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </>
                    }

                    <h5 className='fw-bold text-primary mt-2'>Información de Red </h5>
                    <div className='row'>
                        <div className='col-12 col-md-2 col-lg-3'>
                            <div className="border rounded-3 p-2">

                                <div className="fw-bold">Windows IP</div>
                                {machineDetails.windows_ip != null && machineDetails.windows_ip != "" ? <>{machineDetails.windows_ip}</> : <>No registrado</>}

                            </div>
                        </div>
                        <div className='col-12 col-md-2 col-lg-3'>
                            <div className="border rounded-3 p-2">
                                <div className="fw-bold">Android IP</div>
                                {machineDetails.android_ip != null && machineDetails.android_ip != "" ? <>{machineDetails.android_ip}</> : <>No registrado</>}
                            </div>
                        </div>

                        <div className='col-12 col-md-2 col-lg-3'>
                            <div className="border rounded-3 p-2">
                                <div className="fw-bold">Mac Address lan </div>
                                {machineDetails.mcadress_lan != null && machineDetails.mcadress_lan != "" ? <>{machineDetails.mcadress_lan}</> : <>No registrado</>}
                            </div>
                        </div>
                        <div className='col-12 col-md-2 col-lg-3'>
                            <div className="border rounded-3 p-2">
                                <div className="fw-bold">Mcadress wifi </div>
                                {machineDetails.mcadress_wifi != null && machineDetails.mcadress_wifi != "" ? <>{machineDetails.mcadress_wifi}</> : <>No registrado</>}
                            </div>
                        </div>
                    </div>


                    <div className="d-flex justify-content-between mt-4">
                        <h5 className='fw-bold text-primary mt-2'>Métodos de Pago </h5>
                        <button className='btn btn btn-outline-light  pulse rounded-3 p-2 lh-1 border text-dark' onClick={() => setShowAsingPaymentMethodModal(true)} >
                            <i className="uil uil-plus"></i> Agregar Método de Pago
                        </button>
                    </div>
                    <div className='row border rounded-3'>

                        {machinePaymentMethods.map((paymentMethod) => (
                            <div className="col-12 col-md-2 col-lg-4 mt-2">
                                <article className="border rounded-4 mt-4 p-2 position-relative">
                                    <div className="d-flex justify-content-between ">
                                        <div className="ms-1 me-auto d-inline-block">
                                            <div className='d-flex justify-content-between'>
                                                <div className="fw-bold text-truncate card-item-categorie-title">
                                                    <span>  {paymentMethod.payment} - {paymentMethod.integration.name}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <RenderIf isTrue={checkUserPermission("enable_disable_paymentmethod", user.permissions) !== -1}>
                                            <div>
                                                {/** The swicht of the payment method */}
                                                <Form.Switch type="switch" id={paymentMethod.payment_id} label=""
                                                    checked={paymentMethod.status === 1 ? true : false}
                                                    onChange={(e) => handlePaymentMethodSwitch(paymentMethod.payment_id, paymentMethod.integration_id, machine_id, e.target.checked)}
                                                />

                                            </div>
                                        </RenderIf>
                                    </div>
                                    <div className=" p-2" >
                                        {/** Render of the payment method the buton of delete and the swich to enable and disble it */}
                                        <div className="d-flex justify-content-end">

                                            <div className='d-block'>
                                                <div className='d-inline'>
                                                    {paymentMethod.status === 1 ?
                                                        <Badge bg="success">Activo</Badge> :
                                                        <Badge bg="danger">Inactivo</Badge>}
                                                </div>
                                            </div>
                                        </div>




                                        {/** Render of the params values of the payment method */}
                                        <div className="">
                                            <small className='fw-bold'>Dato</small>
                                            <ListGroup as="ol">
                                                {paymentMethod.params.map((param) => (
                                                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                                                        {param.name} : {param.value}
                                                        <button className='btn btn-outline-warning btn-sm border-0' onClick={(e)=> {setShowEditParamValueModal(true); handleFieldChange('param_value', param.value); handleFieldChange('param_value_id', param.param_value_id)}}>
                                                            <i className="uil uil-pen"></i>
                                                        </button>
                                                    </ListGroup.Item>
                                                ))}
                                            </ListGroup>
                                        </div>
                                    </div>

                                    <div className='d-felx justify-content-end'>
                                        {/** Render of the delete button */}
                                        <RenderIf isTrue={checkUserPermission("remove_paymentmethod_machine", user.permissions) !== -1}>
                                            <div>
                                                <button className='btn btn-outline-danger btn-sm border-0' onClick={(e) => { removePaymentMethod(paymentMethod.payment_id, paymentMethod.integration_id, machine_id) }}>
                                                    <i className="uil uil-trash-alt"></i> Eliminar
                                                </button>
                                            </div>
                                        </RenderIf>
                                    </div>
                                </article>
                            </div>
                        ))}

                    </div>


                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => (setShowModal(false))}>Cerrar</Button>
                </Modal.Footer>
            </Modal >
            {/** Modal to update the machine  */}
            <SmartpayModal
                button_text={"Reasignar sucursal de la máquina"}
                title={"Reasignar sucursal de la máquina"}
                modal_header_text={"Reasignar sucursal de la máquina"}
                modal_size={"md"}
                buttonModal={false}
                modal_body={modalFields}
                setShowModal={setShowAsingBranchModal}
                showModal={showAsingBranchModal}
                formType={"editForm"}
                initialFormValues={formData}
                handleFieldChange={handleFieldChange}
                submit={asingBranchToMachine}
            />

            {/** Modal to asing categories to machine */}
            <SmartpayModal
                title={"Asignar categoria a la máquina"}
                modal_header_text={"Asignar categoria a la máquina"}
                modal_size={"md"}
                buttonModal={false}
                modal_body={modalFieldsCategory}
                setShowModal={setShowAsingCategoryModal}
                showModal={showAsingCategoryModal}
                formType={"editForm"}
                initialFormValues={formData}
                handleFieldChange={handleFieldChange}
                submit={asingCategoryToMachine}
            />

            {/** Modal to edit a param value */}
            <SmartpayModal
                title={"Editar valor del parametro"}
                modal_header_text={"Editar valor del parametro"}
                modal_size={"md"}
                buttonModal={false}
                modal_body={modalFieldsEditParamValue}
                setShowModal={setShowEditParamValueModal}
                showModal={showEditParamValueModal}
                formType={"editForm"}
                initialFormValues={formData}
                handleFieldChange={handleFieldChange}
                submit={updateMachineParamPaymentMethodValue}
            />

            {/** Modal to add a payment method to the machine */}
            <ModalAsingPaymetMethod
                showModal={showAsingPaymentMethodModal}
                setShowModal={setShowAsingPaymentMethodModal}
                paymentMethodIntegrations={paymentMethodIntegrations}
                token={token}
                machine_id={machine_id}
                setEventHandler={setEventHandler}
            />
        </>
    );
}

export default ModalMachineDetails;
