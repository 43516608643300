import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Button } from 'react-bootstrap';
import { LanguagesFetchAPI } from '../../api/Languages';
import LoadingAlert from '../Alerts/LoadingAlert';

function ModalBranchofficeSelect({ actionSelectBranch }) {
    const [branchofficeName, setBranchofficeName] = useState("")
    const [showModalBranchofficeslect, setShowModalBranchofficesselect] = useState(false);
    const [statusLanguage, setStatusLanguage] = useState(0)

    //We get the user data 
    let user = JSON.parse(localStorage.getItem('user'))
    let token = localStorage.getItem('x-access-token');
    const [userBranchoffices, setUserBranchs] = useState([]);

    {/*view branchoffice*/ }
    const viewBranch = () => {
        const listItems = userBranchoffices.map((branch, index) => (
            <>
                {/* <div className='m-3'>
                    <div className='btn btn-outline-primary  d-block border border-3 rounded-pill p-3 ' onClick={() => (safeSelect(branch))}>
                        <span className='p-3  text-wrap px-3'>{branch.branchoffice_name}</span>
                    </div>
                </div> */}
                <input type="radio" className="btn-check" name="items-branchs" id={branch.branchoffice_name + index} onClick={() => (safeSelect(branch))}

                />
                <label className="btn-branchs card-title rounded-3  p-2 border border-primary text-center fw-semibold w-100 text-primary" translate='no' role='button'
                    for={branch.branchoffice_name + index}>
                    <span> {branch.branchoffice_name}</span>
                </label>

            </>
        ));
        return (listItems)
    }

    const safeSelect = (branch) => {
        if (branch == 0) {
            actionSelectBranch(0)
            setShowModalBranchofficesselect(false)
            localStorage.setItem("branchoffice", 0);
            setBranchofficeName("Todas las sucursales")
        } else {
            actionSelectBranch(branch.code, branch)
            setShowModalBranchofficesselect(false)
            localStorage.setItem("branchoffice", JSON.stringify(branch));
            setBranchofficeName(branch.branchoffice_name)
        }

    }

    useEffect(() => {
        try {

            if (localStorage.getItem("branchoffice") == null || localStorage.getItem("branchoffice") == undefined || localStorage.getItem("branchoffice") == "") {
                setShowModalBranchofficesselect(true)
            } else {
                if (JSON.parse(localStorage.getItem("branchoffice")) == 0) {
                    setBranchofficeName("Todas las sucursales")
                    actionSelectBranch(0, { code: 0, branchoffice_name: "Todas las sucursales", branchoffice_id: 0 })
                } else {
                    setBranchofficeName(JSON.parse(localStorage.getItem("branchoffice")).branchoffice_name)
                    actionSelectBranch(JSON.parse(localStorage.getItem("branchoffice")).code, JSON.parse(localStorage.getItem("branchoffice")))
                }
            }
            //We set the branchoffices of the user 

            setUserBranchs(user.branchoffices);
        } catch (err) {
        }

        console.log("UseEffect Modal sucursal fin", user);

    }, []);
    return (
        <>
            {/* <Button className='btn   btn-sm rounded-4 m-1' variant='outline-primary' onClick={() => (setShowModalBranchofficesselect(true), console.log(userBranchoffices))}>
                <i class="uil uil-store-alt"> Sucursal: {branchofficeName}</i>
            </Button> */}

            <button class="btn-branchs card-title rounded-3  p-1 border border-primary text-center " onClick={() => (setShowModalBranchofficesselect(true), console.log(userBranchoffices))}>

                <small class="d-block fw-semibold" role='button'>

                   <i class="uil uil-store-alt"></i> Sucursal</small>
                <span class="fw-semibold" role='button' >
                    {branchofficeName}
                </span>
            </button>

            <Modal
                centered
                className='modal-dialog-kk'
                size="md"
                show={showModalBranchofficeslect}
                onHide={() => setShowModalBranchofficesselect(false)}>
                <Modal.Body>

                    <div className='idioma-sp position-relative '>
                        <section className="form-signin-sp bg-white border-0">
                            <h2 className="title-main-login  text-center ">      <i class="uil uil-store-alt"></i>Sucursales</h2>
                            <div className=' pb-4' >
                                <label className="form-label fw-normal text-gray-600">
                                    Lista de sucursales
                                </label>
                                <div className='branch-wrapper  no-scroll'>
                                    <div className='d-flex flex-column gap-3 p-2 bg-body' >
                                        <input type="radio" className="btn-check" name="items-branchs" id="all" onClick={() => (safeSelect(0))} />
                                        <label className="btn-branchs card-title rounded-3  p-2 border border-primary text-center fw-semibold w-100 text-primary" for="all" role='button'>
                                            <span>Todas las sucursales</span>
                                        </label>
                                        {viewBranch()}
                                    </div>
                                </div>
                                <div className='d-block text-center mt-3 opacity-50'>
                                    <i class="uil uil-info-circle"></i>
                                    Escoge  la sucursal de tu preferencia.
                                </div>
                            </div>
                        </section>
                        <div className='position-absolute top-0 start-100 translate-middle mt-2 '>
                            <div className='me-3'>
                                <button class="btn btn-outline-secondary border-0 bg-light text-secondary rounded-circle p-2 lh-1" type="button" onClick={() => (setShowModalBranchofficesselect(false))} title='Cerrar'>
                                    <i class="uil uil-multiply"></i>
                                    <span class="visually-hidden">Dismiss</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalBranchofficeSelect