import React, { useState } from "react";
import { Button, Modal, Table, Form } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { RenderIf } from "../../utils/RenderIf";

const PreChargeBankDataModal = ({ showModal, handleClose, columns, selectedColumn, setSelectedColumn, excelData, handleSubmit }) => {
    const [excelDataPage, setExcelDataPagePage] = useState(0);

    const excelDataPerPage = 10; // Items por página para las conciliadas
    const handlePageChange = ({ selected }) => {
        setExcelDataPagePage(selected);
    };

    const tableExcelData = excelData.slice(excelDataPage * excelDataPerPage, (excelDataPage + 1) * excelDataPerPage);


    return (
        <>
            <Modal show={showModal} onHide={handleClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Previsualización de Datos</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <RenderIf isTrue={columns.length > 0}>
                        <div className="d-flex justify-content-center">

                            <Form.Label>Selecciona la columna de referencia de pago:</Form.Label>
                            <Form.Select
                                value={selectedColumn}
                                onChange={(e) => setSelectedColumn(e.target.value)}
                            >
                                <option value="">Seleccione...</option>
                                {columns.map((col, index) => (
                                    <option key={index} value={col}>{col}</option>
                                ))}
                            </Form.Select>
                        </div>
                        <div className=" border rounded table-responsive">
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        {columns.length > 0 && columns.map((key) => <th key={key}>{key}</th>)}
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableExcelData.map((row, idx) => (
                                        <tr key={idx}>
                                            {columns.map((_, colIndex) => (
                                                <td key={colIndex}>{row[colIndex]}</td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                            <ReactPaginate
                                previousLabel={"Anterior"}
                                nextLabel={"Siguiente"}
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                pageCount={Math.ceil(excelData.length / excelDataPerPage)}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageChange}
                                containerClassName="pagination"
                                activeClassName="active"
                            />
                        </div>
                    </RenderIf>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        Consiliar Datos
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default PreChargeBankDataModal;
