import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
//API
import { BranchOfficeFetchAPI } from '../../../api/BranchOffices';
import { BuysFetchAPI } from '../../../api/Buys';
// External Componets
import moment from 'moment-timezone';
// Internal Components 
import Table from '../../Table/Table'; 
import ErrorAlert from '../../Alerts/ErrorAlert';

//Components 

const BuysTable = ({selectedUserOption, setSelectedUserOption, cashiers, setCashiers, selectedBranch, setSelectedBranch }) => {
    //Params
    let { business_code } = useParams();
    //We get the user data 
    let user = JSON.parse(localStorage.getItem('user'));
    let token = localStorage.getItem('x-access-token');
    //States 
    const [userBranchoffices, setUserBranchs] = useState([]);
    const [initialDate, setInitialDate] = useState(moment(new Date()).tz('America/Bogota').format('YYYY-MM-DD') + 'T00:00:00.000+00:00');
    const [maxDate, setMaxDate] = useState(moment(new Date()).tz('America/Bogota').format('YYYY-MM-DD') + 'T23:59:59.000+00:00')
    const [buysAndSaleList, setBuySaleList] = useState([])
 

    //Function to get buy by and sale history branch 
    const getBuyAndSaleHistoryByBranchoffice = async (branchoffice_id) => {
        try {
            const response = await BuysFetchAPI.getBuySaleHistoryByBranchoffice(branchoffice_id, initialDate, maxDate, token); 
            console.log(response)
            setBuySaleList(response.data.buys_sales)
        } catch (err) {
            setBuySaleList([])
            //Error alert 
            ErrorAlert(err.response.data.error, "Error", "warning"); 
        }
    }
    //Hook to load the initial data 
    useEffect(() => {
        //We set the branchoffices of the user 
        setUserBranchs(user.branchoffices);

       
    }, []);

     //Hook to load the users depending the selection of branch 
        useEffect(() => {
            //We get the users 
          
        }, [selectedBranch]);


    return (
        <div>
            <h4>Filtros</h4>
            <div className="row gx-3 gy-2 align-items-center bg-white p-2 my-3 border-sp border shadow-sm">
            
                <div className="col-sm-6 col-md-6 col-lg-6">
                    <div className="form-group">
                        <label htmlFor="input_from">Desde</label>
                        <input type="date" name="initialDate" onChange={e => setInitialDate(e.target.value + "T00:00:00.000+00:00")} className="control mt-3" />
                    </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6">
                    <div className="form-group">
                        <label htmlFor="input_from">Hasta</label>
                        <input type="date" name="finishDate" onChange={e => setMaxDate(e.target.value + "T23:59:59.000+00:00")} className="control mt-3" />
                    </div>
                </div>
                <div className=" d-flex justify-content-center justify-content-sm-end mb-3">
                    <button type="button" onClick={e=> getBuyAndSaleHistoryByBranchoffice(selectedBranch.id)} className="btn btn-success px-5">Aceptar</button>
                </div>
            </div>

            {/** Buy table */}
            <Table 
                table_name="Tabla de ingresos y egresos de productos"
                table_headers={["Fecha de creacion", "Producto", "Cantidad", "Tipo de transaccion" ]}
                table_type="buy_sale_table"
                table_data={buysAndSaleList}
            />

        </div>
    );
}

export default BuysTable;
