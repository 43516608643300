import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': "application/json" },
});

export class MachinesFetchAPI {

    //Function to get all the machines of a branch office
    static async getMachinesByBranchOffice(branchoffice_id, token) {
        const res = await server(`/machines/${branchoffice_id}?is_archived=false`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res
    }
    //Function to delete a order in base of the state
    static async updateStateOrder(data) {
        const res = await server(`/temp/order`, {
            method: "delete",
            data: data,
        });
        return res
    }
    //Function to update the machine on a branch office
    static async updateMachineStatus(machine_code, status, machine_token) {
        const res = await server(`/machines`, {
            method: "put",
            headers: {
                'x-access-token': machine_token
            },
            data: {
                machine_code, status
            },
        });
        return res
    }
    //Function to get the machine by id
    static async getmachineByid(machine_id, token){
        const res = await server(`/branch_machine/${machine_id}`, {
            method: "get",
            headers: {
                'x-access-token': token
            },
        });
        return res
    }

    //Function to update machine info
    static async updateMachineInfo(data, machine_id, token) {
        const res = await server(`/branch_machine/${machine_id}`, {
            method: "put",
            headers: {
                'x-access-token': token
            },
            data: data
        });
        return res
    }

    //Function to get machine categories 
    static async getMachineCategories(machine_id, token) {
        const res = await server(`/category_per_machine/${machine_id}`, {
            method: "get",
            headers: { 'x-access-token': token },

        });
        return res
    }

    //Function to update machine category info 
    static async updateMachineCategory(machine_id, category_id, status, token){
        const res = await server(`/category_per_machine`, {
            method: "put",
            headers: { 'x-access-token': token },
            data: {
                branch_machine_id: machine_id, 
                category_id, 
                status
            }

        });
        return res

    }


      //Function to get by code
      static async getMachineByCode(machine_code, branchoffice_code, token){
        const res = await server(`/machine/${machine_code}/branchoffice/${branchoffice_code}`, {
            method: "get",
            headers: { 'x-access-token': token },

        });
        return res

    }


      //Function to update machine language
      static async lastOrderMachine(machine_id, token){
        const res = await server(`/last_order/machine/${machine_id}`, {
            method: "get",
            headers: { 'x-access-token': token }

        });
        return res

    }

    //Function to get the machine payment methods
    static async getMachinePaymentMethods(machine_id, token){
        const res = await server(`/machine/${machine_id}/paymentmethod`, {
            method: "get",
            headers: { 'x-access-token': token }
        });
        return res
    }

    //Function to get machine payment method integrations 
    static async getMachinePaymentMethodIntegrations(machine_id, token){
        const res = await server(`/paymentmethod/integrations?machine_id=${machine_id}`, {
            method: "get",
            headers: { 'x-access-token': token }
        });
        return res
    }
    
    //Function to asing category to machine
    static async asingCategoryToMachine(machine_id, category_id, token){
        const res = await server(`/category_per_machine`, {
            method: "post",
            headers: { 'x-access-token': token },
            data: {
                branch_machine_id: machine_id,
                category_id
            }
        });
        return res
    }

    //Function to remove category from machine
    static async removeCategoryFromMachine(machine_id, category_id, token){
        const res = await server(`/category_per_machine`, {
            method: "delete",
            headers: { 'x-access-token': token },
            data: {
                branch_machine_id: machine_id,
                category_id
            }
        });
        return res
    }

    //Function to make a settle of a machine 
    static async settleMachine(machine_code, branchoffice_code, paymentmethod_integration_id, token){
        const res = await server(`/settle/machine`, {
            method: "post",
            headers: { 'x-access-token': token }, 
            data: {
                machine_code, branchoffice_code, paymentmethod_integration_id
            }
        });
        return res
    }
}

