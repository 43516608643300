import { useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Table, Form } from "react-bootstrap";
//API
import { OrdersFetchAPI } from "../../api/Orders";
import { BankConsilationFetchAPI } from "../../api/BankConsilation";

//Utils
import { RenderIf } from "../utils/RenderIf";

//Modals 
import AssignBatchModal from "./Modals/AssignBatchModal";

//Alerts
import SuccessAlert from "../Alerts/SuccessAlert";
import ErrorAlert from "../Alerts/ErrorAlert";
import LoadingAlert from "../Alerts/LoadingAlert";

//Component 
const StatesBankBatchConsolidation = () => {
    let { business_code } = useParams();
    let token = localStorage.getItem('x-access-token');
    let user = JSON.parse(localStorage.getItem('user'));

    const [dateRange, setDateRange] = useState({ startStateBank: "", endStateBank: "" });
    const [transactions, setTransactions] = useState([]);
    const [groupedTransactions, setGroupedTransactions] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState(null);

    //Function to handle the date change
    const handleDateChange = (e) => {
        const { name, value } = e.target;
        setDateRange((prev) => ({ ...prev, [name]: value }));
    };

    //Function to fetch the transactions
    const fetchTransactions = async (e) => {
        try {
            let response = await OrdersFetchAPI.getTransactionsByBranchOfficePaymentMethodbyUser(business_code, 0, dateRange.startStateBank, dateRange.endStateBank + "T23:59:59", 0, user.id, token, undefined, undefined, true);
            console.log(response);
            setTransactions(response.data.orders);
            groupTransactions(response.data.orders);
            //Show the success alert
            SuccessAlert("Transacciones obtenidas", "Las transacciones se han cargado correctamente", "success");
        } catch (err) {
            console.error(err);
            setTransactions([]);
            //Show the error alert
            ErrorAlert("Error al obtener las transacciones", "Ha ocurrido un error al obtener las transacciones", "error");
        }
    };

    //Function to group the transactions by date and payment method
    const groupTransactions = (transactions) => {
        let grouped = {};
        console.log(transactions);
        transactions.forEach(tx => {
            let key = `${tx.payment_method}-${tx.createdAt.split("T")[0]}`;
            if (!grouped[key]) {
                grouped[key] = {
                    createdAt: tx.createdAt.split("T")[0],
                    paymenthod: tx.paymenthod,
                    total: 0,
                    count: 0,
                    orders: [],
                    consiliation: false,
                    consiliationType: "No consolidado",

                };
            }
            grouped[key].total += tx.total_with_tips;
            grouped[key].count += 1;
            grouped[key].orders.push(tx);

        });
        setGroupedTransactions(Object.values(grouped));
    };
    //Function to handle the assign of the batch
    const handleAssign = (date, paymentMethod, updatedTransactions, batchReference) => {
        setGroupedTransactions(prev =>
            prev.map(group =>
                group.createdAt === date && group.paymenthod === paymentMethod
                    ? { ...group, consiliation: true, consiliationType: "Manual", lote_reference: batchReference, orders: updatedTransactions }
                    : group
            )
        );

        //Alert to show the success of the assign
        SuccessAlert("Lote asignado", "El lote se ha asignado correctamente", "success");
    };

    //Function to save the consiliation
    const saveConsiliation = async () => {
        //Show the loading alert
        LoadingAlert("Guardando consiliación", "Espere un momento por favor");
        //We filter the transactions that are consolidated
        const consolidatedTransactions = groupedTransactions.filter(group => group.consiliation);
        //For each group of conslidated transactions we save the batch
        try {
            consolidatedTransactions.forEach(async groupConsolidated => {
                let data = {
                    orders: groupConsolidated.orders,
                    lote_reference: groupConsolidated.lote_reference,
                    business_code: business_code,
                    totalExcel: 0,
                    totalSystem: groupConsolidated.orders.length,
                    total: groupConsolidated.total,
                };

                //We send the data to the backend
                await BankConsilationFetchAPI.saveBankConciliationBatch(data, token);
            });
        } catch (err) {
            console.error(err);
            //Show the error alert
            ErrorAlert("Error al asignar el lote", "Ha ocurrido un error al asignar el lote", "error");
        }
        //Show the success alert
        SuccessAlert("Consolidacion realizada", "Accion realizada", "success");
        //We clean the grouped transactions
        setGroupedTransactions([]);
        //We clean the transactions
        setTransactions([]);
        //We clean the date range
        setDateRange({ start: "", end: "" });
        //We clean the selected group
        setSelectedGroup(null);
        //We clean the form inputs 
        document.getElementsByName("startStateBank")[0].value = "";
        document.getElementsByName("endStateBank")[0].value = "";


    }


    return (
        <div className="mx-lg-2 mx-1">
            <h2 className="text-xl font-bold">Conciliación de metodos de pago por lotes</h2>

            <Form>
                <Form.Group>
                    <Form.Label>Fecha de inicio</Form.Label>
                    <Form.Control type="date" name="startStateBank" onChange={handleDateChange} />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Fecha de fin</Form.Label>
                    <Form.Control type="date" name="endStateBank" onChange={handleDateChange} />
                </Form.Group>
                <Button onClick={fetchTransactions}>Cargar transacciones</Button>
            </Form>

            <RenderIf isTrue={groupedTransactions.length > 0}>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Fecha</th>
                            <th>Método de Pago</th>
                            <th>Total</th>
                            <th>Lote</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {groupedTransactions.map((group, idx) => (
                            <tr key={idx}>
                                <td>{group.createdAt}</td>
                                <td>{group.paymenthod}</td>
                                <td>{group.total.toFixed(2)}</td>
                                <td>{group.lote_reference !== undefined ? group.lote_reference : "Sin asignar"}</td>
                                <td>
                                    <Button variant="primary" onClick={() => { setSelectedGroup(group); setShowModal(true); }}>
                                        Asignar Lote
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

                {/** Button to save the consiliation */}
                <Button variant="primary" onClick={() => saveConsiliation()}>
                    Confirmar y Enviar
                </Button>
            </RenderIf>

            <AssignBatchModal
                showModal={showModal}
                handleClose={() => setShowModal(false)}
                selectedGroup={selectedGroup}
                handleAssign={handleAssign}
            />
        </div>
    );
};

export default StatesBankBatchConsolidation;
