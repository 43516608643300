import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';

import ErrorAlert from '../../Alerts/ErrorAlert';
import { Badge } from 'react-bootstrap';

import { ListGroup } from 'react-bootstrap';
import { CategoriesDashFetchAPI } from '../../../api/CategoriesDash';


function ModalCreateCategories({ loadCategories }) {
    const [showModal, setShowModal] = useState(false)
    const [eventHandle, setEventHandle] = useState(0);
    const [typeCategory, setTypeCategory] = useState([])
    const [notifications, setnotifications] = useState([])
    let token = localStorage.getItem("x-access-token")

    let user = localStorage.getItem('user');


    //Function to register the product 
    const submit = async (e) => {
        e.preventDefault();
        user = JSON.parse(localStorage.getItem('user'))
        const form = new FormData(e.target);
        form.append("business_id", user.business_id);
        form.append("branchoffice_id", null);
        const createCategorie = Object.fromEntries(form.entries());

        try {
            //We update the data 
            let response = await CategoriesDashFetchAPI.createCategorie(createCategorie, token);
            //We show the success alert 

            ErrorAlert('', 'Categoria creada con exito', 'success')
            loadCategories();
            setShowModal(false);
        } catch (err) {
            ErrorAlert('', 'Error de registro', 'error')
            console.log(err)
        }
    }

    const getCategoryType = async () => {
        try {
            const response = await CategoriesDashFetchAPI.getCategoryType(token);
            setTypeCategory(response.data.categorie_types)
        } catch (err) {
            console.log(err)
        }
    }

    const viewTypeCategory = () => {
        const view = typeCategory.map((type) => (
            <><option value={type.id}>{type.name}</option></>
        ));

        return (view)
    }






    useEffect(() => {
        setEventHandle(0)

    }, [eventHandle])




    return (
        <>

            <div>
                <button className='btn btn-dark btn-sm  fw-normal bg-body text-dark border border-1 rounded-2'
                    onClick={() => (setShowModal(true), getCategoryType())} >
                    <i className="uil uil-plus-circle"></i>Nueva
                </button>
            </div>

            <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
                size='large'
                centered>
                <Modal.Body>

                    <div className='position-relative '>

                        <div className='position-absolute top-0 start-100 translate-middle mt-2 '>
                            <div className='me-3'>
                                <button class="btn btn-outline-secondary border-0 bg-light text-secondary rounded-circle p-2 lh-1"
                                    type="button" title='Cerrar'
                                    onClick={() => setShowModal(false)}

                                >
                                    <i class="uil uil-multiply"></i>
                                </button>
                            </div>
                        </div>
                        <h2 className="title-main-login  text-center ">
                            Crear nueva categoria
                        </h2>

                        <Form
                            className="needs-validation"
                            onSubmit={submit}
                        >

                            <div className='row'>

                                <div className='col-12'>

                                    <Form.Group controlId="formFileMultiple" className="mb-3">
                                        <Form.Label>Image categoria</Form.Label>
                                        <Form.Control type="file"
                                            placeholder="Imagen"
                                            name="files"
                                            required />
                                    </Form.Group>

                                </div>
                                <div className="col-6">

                                    <Form.Group className="mb-3" controlId="name">
                                        <Form.Label>Nombre</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Nombre de la categoria"
                                            name="name"
                                            required
                                            autoFocus
                                        />
                                    </Form.Group></div>
                                <div className="col-6">
                                    <Form.Group className="mb-3" controlId="secundaryName">
                                        <Form.Label>Decripcion</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Descripcion de la categoria"
                                            name="description"
                                            required
                                        />
                                    </Form.Group>
                                </div>

                            </div>



                            <Form.Group className="mb-3" controlId="clientId">
                                <Form.Label>Tipo de categoria</Form.Label>
                                <Form.Select aria-label="" name="category_type">
                                    <option>Seleccione un tipo</option>
                                    {viewTypeCategory()}
                                </Form.Select>
                            </Form.Group>






                            <div className="mt-3 d-flex justify-content-end">
                                <button className="btn btn-save-sp" type="submit">
                                    Guardar
                                </button>
                            </div>
                        </Form>
                    </div>



                </Modal.Body>
            </Modal >

        </>
    )
}

export default ModalCreateCategories