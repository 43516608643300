import React, { useState, useEffect } from 'react';
import { Modal, Button, ListGroup, Row, Col, Container, Tab, Nav } from 'react-bootstrap';
import ErrorAlert from '../../Alerts/ErrorAlert';
import { GroupsFechtAPI } from '../../../api/Groups';
import { CombosFetchAPI } from '../../../api/Combos';
import Select from 'react-select';
import eye from '../../img/icons/eye.png';
import Empty from '../../utils/Empty';

// Modal details category 
function ModalCategoryListProducts({ category }) {
    const [showModal, setShowModal] = useState(false)
    const [eventHandle, setEventHandle] = useState(0);
    const [groups, setGroups] = useState([]);
    const [combos, setCombos] = useState([]);
    const [groupsSelectAsing, setGroupsSelectAsing] = useState([]);
    const [combosSelectAsing, setCombosSelectAsing] = useState([]);
    const [showModalAsing, setShowModalAsing] = useState(false);
    const [showModalAsingCombo, setShowModalAsingCombo] = useState(false);
    const [groupSelectNow, setGroupSelectNow] = useState("")
    const [comboSelectNow, setComboSelectNow] = useState("")

    let token = localStorage.getItem("x-access-token")

    let user = localStorage.getItem('user');


    //Function to get the groups by category
    const getgroupsByCategory = async () => {
        try {
            const response = await GroupsFechtAPI.getGroupByIdCategory(category.category_id, token)
            setGroups(response.data.data)
        } catch (err) {
            setGroups([])
            console.log(err)
        }
    }
    //Function to get the combos by category
    const getCombosByCategory = async () => {
        try {
            const response = await CombosFetchAPI.getComboByCategory(category.category_id, token)
            setCombos(response.data.combos)
        } catch (err) {
            setCombos([])
        }
    }
    //We get the groups of the business
    const getGroups = async () => {

        user = JSON.parse(localStorage.getItem('user'));
        try {
            const response = await GroupsFechtAPI.getGroupsByBusiness(user.business_id, token)
            //setGroupsSelectAsing(response.data.data)

            let groupInfo = []
            response.data.data.forEach(group => {
                groupInfo.push({ value: group.group_id, label: group.name })
            });

            setGroupsSelectAsing(groupInfo)

        } catch (err) {
            console.log(err)
            setGroupsSelectAsing([])
        }
    }

    //We get the combos of the business
    const getCombos = async () => {
        user = JSON.parse(localStorage.getItem('user'));
        try {
            const response = await CombosFetchAPI.getCombosByBusiness(user.business_id, token)
            let comboInfo = []
            response.data.combos.forEach(combo => {
                comboInfo.push({ value: combo.id, label: combo.name })
            });

            setCombosSelectAsing(comboInfo)

        } catch (err) {
            console.log(err)
            setCombosSelectAsing([])
        }
    }


    const deleteCategoryToProduct = async (group_id) => {
        let data = {
            group_id: group_id
        }
        try {
            const response = await GroupsFechtAPI.deleteCategoryProduct(category.category_id, data, token)
            console.log(response);
            getgroupsByCategory();
        } catch (err) {
            console.log(err);
            ErrorAlert('', 'Error, no se pudo remover el producto', 'error')
        }
    }

    //Function to remove a combo from a category
    const removeComboFromCategory = async (combo_id) => {
        let data = {
            combos: [{ combo_id: combo_id }]
        }
        try {
            const response = await CombosFetchAPI.removeComboFromCategory(category.category_id, data, token)
            console.log(response);
            getCombosByCategory();
        } catch (err) {
            console.log(err);
            ErrorAlert('', 'Error, no se pudo remover el combo', 'error')
        }
    }

    //Function to asing product to category
    const asingProductToCategory = async () => {
        try {
            const response = await GroupsFechtAPI.asingproductToCategory(category.category_id, groupSelectNow, token)
            getgroupsByCategory()
            setShowModalAsing(false)
            ErrorAlert('', 'Producto asignado correctamente', 'success')

        } catch (err) {
            console.log(err)
            ErrorAlert('', 'Error de asignacion', 'error')
        }
    }
    //Function to asing combo to category
    const asingComboToCategory = async () => {
        try {
            let data = {
                combos: [{ combo_id: comboSelectNow }]
            }
            const response = await CombosFetchAPI.addCombosToCategory(category.category_id, data, token)
            getCombosByCategory()
            setShowModalAsingCombo(false)
            ErrorAlert('', 'Combo asignado correctamente', 'success')

        } catch (err) {
            console.log(err)
            ErrorAlert('', 'Error de asignacion', 'error')
        }
    }
    //View the products of the category
    const viewProduct = () => {

              if (groups.length === 0) {
            return (
                <div className=" mt-5">
                    <div className='d-flex justify-content-center align-items-center '>
                        <div className="text-center">                    
                            <Empty title="Vacío" msg="No hay productos asignados a esta categoría"></Empty>
                        </div>

                    </div>
                </div>
            );
        }

        const list = groups.map(product => (
            <div className='col-md-12 col-12 col-lg-6 col-xl-6 mt-3 ' >

                <div
                    className="d-flex justify-content-between align-items-start border-dashed rounded-3 p-2 h-100"
                >
                    <img
                        className='imgitemsList m-1 border-dashed rounded-3'
                        src={product.img}
                        alt="Img Product"
                    />
                    <div className="ms-2 me-auto d-inline-block text-truncate">
                        <div className="fw-bold">{product.name}</div>
                        <div className="text-muted opacity-50">Precio: <b>${parseFloat(product.price).toFixed(2)}</b></div>
                        <div className="text-muted opacity-50">Impuesto: <b>{product.taxes.map((tax) => (
                            <>
                                - {tax.tax} -
                            </>
                        ))}</b></div>
                        <p className='text-muted opacity-50 text-wrap'>Descripción:{product.description}</p>
                    </div>
                    <div className='m-1'>
                        <button className='btn btn-outline-danger btn-sm border-0' title='Eliminar producto'
                            onClick={async () => deleteCategoryToProduct(product.group_id)}>
                            <i className="uil uil-trash-alt"></i>
                        </button>
                    </div>


                </div>

            </div>


        ))
        return (list)
    }

    //Function to view the combos of the category
    const viewCombos = () => {
        if (combos.length === 0) {
            return (
                <div className=" mt-5">
                    <div className='d-flex justify-content-center align-items-center '>
                        <div className="text-center">                    
                            <Empty title="Vacío" msg="No hay combos asignados a esta categoría"></Empty>
                        </div>

                    </div>
                </div>
            );
        }

        const list = combos.map(combo => (
            <div className='col-md-12 col-12 col-lg-6 col-xl-6 mt-3 ' >
                <div
                    className="d-flex justify-content-between align-items-start border-dashed rounded-3 p-2 h-100"
                >
                    <img
                        className='imgitemsList m-1'
                        src={combo.img}
                        alt={combo.name}
                    />
                    <div className="ms-2 me-auto d-inline-block text-truncate">
                        <div className="fw-bold">{combo.name}</div>
                        <p className=''>{combo.description}</p>
                    </div>
                    <div className='m-1'>
                        <button className='btn btn-outline-danger btn-sm border-0'
                            onClick={async () => removeComboFromCategory(combo.combo_id)}
                        >
                            <i className="uil uil-trash-alt"></i>
                        </button>
                    </div>
                </div>
            </div>
        ));
        return list;
    }

    const handleSaveTableSelect = (option) => {
        setGroupSelectNow(option.value);
    }

    const handleSaveTableSelectCombo = (option) => {
        setComboSelectNow(option.value);
    }

    return (
        <>
            <button className='btn btn btn-outline-light  pulse rounded-3 p-2 lh-1 border'
                tooltip="Detalles Categoria" flow="up"
                onClick={() => (setShowModal(true), getgroupsByCategory(), getCombosByCategory(), getGroups(), getCombos())}
            >
                <img className='icon-options-cards' src={eye} alt="restart" />

            </button>

            <Modal
                show={showModal}
                onHide={() => (setShowModal(false))}
                size='lg'
                centered>
                <Modal.Body>

                    <div className='position-relative'>
                        <div className='position-absolute top-0 start-100 translate-middle mt-2 '>
                            <div className='me-3'>
                                <button class="btn btn-outline-secondary border-0 bg-light text-secondary rounded-circle p-2 lh-1" type="button" title='Cerrar'
                                    onClick={() => setShowModal(false)}

                                >
                                    <i class="uil uil-multiply"></i>
                                </button>
                            </div>
                        </div>

                        <h2 className="title-main-login text-center ">
                            Detalles de la categoria
                        </h2>
                    </div>


                    <div
                        className="d-flex justify-content-between align-items-start"
                    >
                        <img
                            className='imgitemsList m-1'
                            src={category.img}
                            alt={category.category_name}
                        />
                        <div className="ms-2 me-auto">
                            <div className="fw-bold">{category.category_name}</div>
                            {category.secundaryName}
                        </div>
                    </div>


                    <Tab.Container id="list-categorys-tabs-example" defaultActiveKey="categoryProducts">
                        <Row>


                        </Row>
                        <Col sm={12}>
                            <Nav variant="pills" className="d-flex horizontal-scroll pe-3 gap-3">
                                <li className="nav-sp-item bg-light rounded-3 border ">
                                    <Nav.Link eventKey="categoryProducts">Productos de la categoria</Nav.Link>
                                </li>
                                <li className="nav-sp-item bg-light rounded-3 border"  >
                                    <Nav.Link eventKey="categoryCombos" > Combos de la categoria</Nav.Link>
                                </li>
                            </Nav>
                        </Col>

                        <Col sm={12}>
                            <Tab.Content>
                                <Tab.Pane eventKey={"categoryProducts"}>
                                    <div className='d-flex justify-content-between  align-items-center mt-3'>
                                        <h5 className='fw-bold  text-primary text-center'>Productos de la categoria</h5>

                                        <div className='d-flex justify-content-center align-item-center'>
                                            <button className='btn btn-primary border-0' onClick={() => (setShowModalAsing(true))}> <i className="uil uil-plus"></i> Asignar producto
                                            </button>
                                        </div>
                                    </div>
                                    <div className="mt-1">
                                        <Container className='product-wrapper pb-3'>
                                            <Row>
                                                {viewProduct()}
                                            </Row>
                                        </Container>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                            <Tab.Content>
                                <Tab.Pane eventKey={"categoryCombos"}>
                                    <div className='d-flex justify-content-between  align-items-center mt-3'>
                                        <h5 className='fw-bold  text-primary text-center'>Combos de la categoria</h5>
                                        <div className='d-flex justify-content-center align-item-center'>
                                            <button className='btn btn-primary border-0' onClick={() => (setShowModalAsingCombo(true))}> <i className="uil uil-plus"></i> Asignar combos
                                            </button>
                                        </div>
                                    </div>
                                    <div className="mt-1">
                                        <Container className='product-wrapper'>
                                            <Row>
                                                {viewCombos()}
                                            </Row>
                                        </Container>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Tab.Container>
                </Modal.Body>
            </Modal >
            {/** Modal to asing product to category */}
            <Modal
                show={showModalAsing}
                onHide={() => (setShowModalAsing(false))}
                size='md'
                centered>
                <Modal.Body className='shadow-lg' >
                    <div className='position-relative'>
                        <div className='position-absolute top-0 start-100 translate-middle mt-2 '>
                            <div className='me-3'>
                                <button class="btn btn-outline-secondary border-0 bg-light text-secondary rounded-circle p-2 lh-1" type="button" title='Cerrar'
                                    onClick={() => setShowModalAsing(false)}                               >
                                    <i class="uil uil-multiply"></i>
                                </button>
                            </div>
                        </div>
                        <h2 className="title-main-login text-center ">
                            Asignar productos
                        </h2>
                        <div className='d-flex justify-content-center align-item-center'>
                            <Select
                                name="user_branch" id="user_branch"
                                className='w-100 m-3'
                                options={groupsSelectAsing}
                                onChange={handleSaveTableSelect}
                            />
                            <button type="button" className='btn-save-sp m-3 rounded-3' onClick={async () => asingProductToCategory()}>
                                Guardar
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/** Modal to asing combo to category */}
            <Modal
                show={showModalAsingCombo}
                onHide={() => (setShowModalAsingCombo(false))}
                size='md'
                centered>

                <Modal.Body className='shadow-lg'>
                    <div className='position-relative'>
                        <div className='position-absolute top-0 start-100 translate-middle mt-2 '>
                            <div className='me-3'>
                                <button class="btn btn-outline-secondary border-0 bg-light text-secondary rounded-circle p-2 lh-1"
                                    type="button" title='Cerrar'
                                    onClick={() => (setShowModalAsingCombo(false))}                           >
                                    <i class="uil uil-multiply"></i>
                                </button>
                            </div>
                        </div>
                        <h2 className="title-main-login text-center ">
                            Asignar combo
                        </h2>
                        <div className='d-flex justify-content-center align-item-center'>


                            <Select
                                name="user_branch" id="user_branch"
                                className='w-100 m-3'
                                options={combosSelectAsing}
                                onChange={handleSaveTableSelectCombo}
                            />
                            <button className='btn btn-save-sp m-3' onClick={async () => asingComboToCategory()}>
                                Guardar
                            </button>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>

        </>
    )
}

export default ModalCategoryListProducts