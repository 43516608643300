import { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";

const AssignBatchModal = ({ showModal, handleClose, selectedGroup, handleAssign }) => {
    const [batchReference, setBatchReference] = useState("");

    //Function to add the batch reference to the selected group
    const handleSubmit = () => {
        if (batchReference.trim() === "") return;
        
        // Asignar la referencia del lote a cada transacción del grupo seleccionado
        const updatedTransactions = selectedGroup.orders.map(order => ({
            ...order,
            consiliation: true, 
            consiliationType: "Manual"
        }));

        handleAssign(selectedGroup.createdAt, selectedGroup.paymenthod, updatedTransactions, batchReference);
        setBatchReference("");
        handleClose();
    };


    return (
        <Modal show={showModal} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Asignar Lote</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {selectedGroup && (
                    <>
                        <p><strong>Fecha:</strong> {selectedGroup.createdAt}</p>
                        <p><strong>Método de Pago:</strong> {selectedGroup.paymenthod}</p>
                        <p><strong>Monto Total:</strong> ${selectedGroup.total.toFixed(2)}</p>
                        <p><strong>Transacciones:</strong> {selectedGroup.orders.map(element=> { 
                            return <span key={element.transaction_code}>{element.transaction_code}, </span>
                        })}</p> 
                        <hr />
                        <Form>
                            <Form.Group>
                                <Form.Label>Referencia de Lote</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={batchReference}
                                    onChange={(e) => setBatchReference(e.target.value)}
                                    placeholder="Ingrese la referencia del lote"
                                />
                            </Form.Group>
                        </Form>
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Cancelar</Button>
                <Button variant="primary" onClick={handleSubmit}>Asignar</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AssignBatchModal;
