//React Components
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

//Components 
import Navbar from '../NavBar/Navbar';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Nav from 'react-bootstrap/Nav';
import ProductTable from './ProductTable';
import SegmentTable from './SegmentTable';
import ItemTable from './ItemsTable';
import PromotionTable from './PromotionTable';
import CateoriesTable from './CategoriesTable';
import CombosTable from './CombosTable';
import { Tabs, Tab } from 'react-bootstrap';
import ModalBranchofficeSelect from '../NavBar/ModalBranchofficeSelect';

//API 
import { BranchOfficeFetchAPI } from '../../api/BranchOffices';
import SupportBot from '../SupportBot/SupportBot';

//css
import "./products.css"

const ProductStore = () => {
    //Params 
    let { business_code } = useParams();
    //We get the user data 
    let user = JSON.parse(localStorage.getItem('user'))
    //States 
    const [userBranchoffices, setUserBranchs] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState({
        code: 0,
        id: 0
    });
    //Event handler 
    const [eventHandler, setEventHandler] = useState(0);
    //Token
    let token = localStorage.getItem('x-access-token');

    //Function to get the branch office
    const getBranchoffice = async (code) => {
        try {
            //We search the branch office
            const branch = await BranchOfficeFetchAPI.getBranchOfficeByCode(business_code, code);
            setSelectedBranch({
                name: branch.data.branchoffice.name,
                code: branch.data.branchoffice.code,
                id: branch.data.branchoffice.id
            });
            //We set the event handler
            setEventHandler(eventHandler + 1);
        } catch (err) {
            //We set the branch office to 0
            setSelectedBranch({
                code: 0,
                id: 0
            });
            //We set the event handler
            setEventHandler(eventHandler + 1);
        }
    }


    //Function to populate the users branch 
    const branchList = () => {
        const listBracnh = userBranchoffices.map((branch) => (
            <>
                <option value={branch.code}>{branch.branchoffice_name}</option>
            </>
        ));
        return listBracnh
    }

    const actionSelectBranch = (branchCode) => {
        getBranchoffice(branchCode);
    }

    //Hook to charge the data when the page is loaded 
    useEffect(() => {
        (async () => {
            try {
                console.log(user)
                //We set the branchoffices of the user 
                setUserBranchs(user.branchoffices);
            } catch (err) {
                console.log(err)
            }
        })();
    }, []);

    return (
        <>
            <Navbar titlePage="Productos" />
            <div className='bg-light-subtle pt-4'>
                {/* Branch office data  */}
                <div className='container '>
                    <main className=" m-0">
                        {/* Section Header  */}
                        <section className='banner-sp bg-light-subtle d-lg-flex justify-content-lg-between 
                  align-items-center rounded-4 mb-3 flex-column flex-lg-row justify-content-center py-2 py-lg-0'>

                            <div className='d-lg-flex flex-lg-stack flex-wrap gap-4 w-100 flex-nowrap d-flex justify-content-center align-items-center 
                  justify-content-lg-start'>
                                <div className='page-title d-flex flex-column gap-0 me-3'>
                                    <h2 className='banner-reports-title mb-0 pb-0'>
                                        Productos: {selectedBranch.name ? <>{selectedBranch.name}</> : <>Todas las sucursales</>}
                                    </h2>
                                    <p className='m-0 p-0 banner-reports-description'>Gestión y creación de artículos y elementos para las sucursales de su negocio.</p>
                                </div>
                            </div>

                            {/*<div className="mt-2">
                                <select onChange={e => actionSelectBranch(e.target.value)} className='form-select' name="user_branch" id="user_branch">
                                    <option value={0}>Mostrar todos</option>
                                    {branchList()}
                                </select>
                            </div>*/}
                            <div className='d-lg-flex flex-lg-stack flex-wrap gap-4 w-100 flex-nowrap d-flex justify-content-center align-items-center 
                  justify-content-lg-end mt-3 mt-lg-0'>

                                <ModalBranchofficeSelect actionSelectBranch={actionSelectBranch}></ModalBranchofficeSelect>

                            </div>

                        </section>

                        <Tab.Container defaultActiveKey="categorie">
                            <Row>
                                <Col sm={12} >
                                    <Nav variant="pills" className="d-flex horizontal-scroll bg-white rounded-3 gap-3" >
                                        <li className="nav-sp-item bg-light rounded-3 border"  >
                                            <Nav.Link eventKey="categorie" title="Categorias">Categorias</Nav.Link>
                                        </li>
                                        <li className="nav-sp-item bg-light rounded-3 border">
                                            <Nav.Link eventKey="combo">Combos</Nav.Link>
                                        </li>

                                        <li className="nav-sp-item bg-light rounded-3 border">
                                            <Nav.Link eventKey="all" >Productos</Nav.Link>
                                        </li>

                                        <li className="nav-sp-item bg-light rounded-3 border">
                                            <Nav.Link eventKey="active" >Acompañantes</Nav.Link>
                                        </li>

                                        <li className="nav-sp-item bg-light rounded-3 border">
                                            <Nav.Link eventKey="inactive" >Productos individuales</Nav.Link>
                                        </li>

                                        <li className="nav-sp-item bg-light rounded-3 border">
                                            <Nav.Link eventKey="promotions" >Promociones</Nav.Link>
                                        </li>


                                    </Nav>
                                </Col>
                                <Col sm={12}>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="categorie">
                                            <div className=' m-0'>
                                                {selectedBranch ? <>
                                                    <CateoriesTable branchoffice_id={selectedBranch ? selectedBranch.id : 0} token={token} user={user} eventHandler={eventHandler} title={selectedBranch && selectedBranch.id ? 'Categorias de la Sucursal' : 'Categorias de la Empresa'} />
                                                </> : ''}
                                            </div>

                                        </Tab.Pane>
                                        {/**Tab of combos */}
                                        <Tab.Pane eventKey="combo">
                                            <div className='m-0'>
                                                {selectedBranch ? <>
                                                    <CombosTable branchoffice_id={selectedBranch ? selectedBranch.id : 0} token={token} user={user} eventHandler={eventHandler} title={selectedBranch && selectedBranch.id ? 'Combos de la Sucursal' : 'Combos de la Empresa'} />
                                                </> : ''}
                                            </div>
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="all" title="Productos">
                                            <div className='m-0'>
                                                {selectedBranch ? <>
                                                    <ProductTable branchoffice_id={selectedBranch ? selectedBranch.id : 0} token={token} user={user} eventHandler={eventHandler} title={selectedBranch && selectedBranch.id ? 'Productos de la Sucursal' : 'Productos de la Empresa'} />
                                                </> : ''}
                                            </div>
                                        </Tab.Pane>

                                        {/**Tabs of modifiers */}
                                        <Tab.Pane eventKey="active" title="Acompañantes">

                                            <div className='m-0'>
                                                {selectedBranch ? <>
                                                    <SegmentTable branchoffice_id={selectedBranch ? selectedBranch.id : 0} eventHandler={eventHandler} token={token} user={user} title={selectedBranch && selectedBranch.id ? 'Acompañantes de la Sucursal' : 'Acompañantes de la Empresa'} />
                                                </> : ''}
                                            </div>

                                        </Tab.Pane>

                                        {/**Tabs of individual products */}
                                        <Tab.Pane eventKey="inactive" title="Productos individuales">

                                            <div className='m-0'>
                                                {selectedBranch ? <>
                                                    <ItemTable branchoffice_id={selectedBranch ? selectedBranch.id : 0} eventHandler={eventHandler} token={token} user={user} title={selectedBranch && selectedBranch.id ? 'Producto individual de la Sucursal' : 'Producto individual de la Empresa'} />
                                                </> : ''}
                                            </div>

                                        </Tab.Pane>

                                        {/** Tab of promotions */}
                                        <Tab.Pane eventKey="promotions" title="Promociones">

                                            <div className="m-0">
                                                {selectedBranch ? <>
                                                    <PromotionTable branchoffice_id={selectedBranch ? selectedBranch.id : 0} token={token} user={user} eventHandler={eventHandler} title={selectedBranch && selectedBranch.id ? 'Promociones de la Sucursal' : 'Promociones de la Empresa'} />
                                                </> : ''}

                                            </div>

                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                        {/**This is for the bot 
                <SupportBot ></SupportBot>*/}
                    </main>
                </div>
            </div>



        </>
    );
}

export default ProductStore;
