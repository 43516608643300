//React Components
import React, { useState, useEffect, useMemo } from 'react';
import { Container, Row, Col, ListGroup, Form } from 'react-bootstrap';
//Modals
import ModalEditCombo from './ModalCombos/ModalEditCombo';
import ModalViewCombo from './ModalCombos/ModalViewCombo';
import ModalListSetGroupByCombo from './ModalCombos/ModalListSetGroupByCombo';
import ModalCreateCombo from './ModalCombos/ModalCreateCombos';
import ModalListSetGroups from './ModalSetGroups/ModalListSetGroups';
import ModalImport from './ModalImport';

//Alerts
import ErrorAlert from '../Alerts/ErrorAlert';
//Utils
import { checkUserPermission } from '../utils/CheckUserPermitions';
import { RenderIf } from '../utils/RenderIf';

//API
import { CombosFetchAPI } from '../../api/Combos';
import { GroupsFechtAPI } from '../../api/Groups';

//css
//icons
import on from '../img/icons/on.png'
import off from '../img/icons/off.png'
import trash from '../img/icons/delete.png'

const CombosTable = ({ branchoffice_id, eventHandler, token, user, title }) => {
    //States
    const [combos, setCombos] = useState([]);
    const [searchedCombos, setSearchedCombos] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [products, setProducts] = useState([]);
    //Load the combos 
    useEffect(() => {
        loadCombos();
        loadProducts();
    }, [eventHandler]);

    //Function to load the combos
    const loadCombos = async () => {
        try {
            //We get the combos
            const combos = await CombosFetchAPI.getCombosByBranchOffice(branchoffice_id, token);
            //We set the combos
            setCombos(combos.data.combos);
            setSearchedCombos(combos.data.combos);
        } catch (err) {
            setCombos([]);
            setSearchedCombos([]);
        }
    }

    //Function to load products
    const loadProducts = async () => {
        try {
            //We search the groups of the branchoffice 
            const groups = await GroupsFechtAPI.getGroupsByBranchoffice(branchoffice_id, token);
            setProducts(groups.data.data)
        } catch (err) {
            setProducts([])
        }

    };


    //Filter the combos by the search term
    const filteredCombos = useMemo(() => {
        if (searchTerm === '') {
            return combos
        }
        return searchedCombos.filter(combo => {
            const nameMatch = combo.name.toLowerCase().includes(searchTerm.toLocaleLowerCase());
            const descriptionMatch = combo.description.toLowerCase().includes(searchTerm.toLowerCase());
            return nameMatch || descriptionMatch;
        })
    }, [searchTerm, combos]);

    //Function to listen the swicht 
    const handleSwitchChange = async (event) => {
        //We set the data to update 
        let data = {
            status: event.target.checked ? 1 : 0,
            combo_id: event.target.id,
            user_id: user.id
        }
        try {
            //We update the data
            await CombosFetchAPI.updateComboBranch(data, branchoffice_id, token);
            //We load the combos
            loadCombos();
            //We show the success alert 
            ErrorAlert('Combo habilitado en la sucursal', 'Combo habilitado', 'success')
        } catch (err) {
            //We show the error alert 
            ErrorAlert(err.response.data.error, 'Error en la edicion', 'error')
            event.target.checked = false
            //We load the combos
            loadCombos();
        }
    };

    //View the combos
    const viewCombo = () => {
        {/**We check if the combos are empty */ }
        const list = filteredCombos.map(combo => (
            <Col xs="12" sm="6" md="6" lg="4" xl="3" >
                <article className={combo.status === 1 ? 'card-on shadow-sm rounded-4 mt-4 p-2' : 'border rounded-4 mt-4 p-2'} >
                    <div
                        className="d-flex justify-content-between align-items-start ">
                        <img
                            className='imgitemsList  border'
                            src={combo.img}
                            alt={combo.name}
                        />
                        <div className="ms-1 me-auto d-inline-block">
                            <div className="fw-bold text-truncate card-item-categorie-title">{combo.name}</div>
                            <p className='text-secondary fw-light text-truncate card-item-categorie-description'>{combo.description}</p>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center rounded-3 mt-1'>
                        <div className=' d-flex align-item-center justify-content-start gap-1'>
                            {/**We check if the user has permition to delete products */}
                            <RenderIf isTrue={branchoffice_id != 0 && checkUserPermission('delete_combo', user.permissions) !== -1}>

                                <div className='m-0'>
                                    <button className='btn btn btn-outline-light  pulse rounded-3 p-2 lh-1 border'
                                    //onClick={async () => beforeDelete(product.group_id)}
                                    >
                                        <img className='icon-options-cards' src={trash} alt="List" />
                                    </button>
                                </div>

                            </RenderIf>
                            {/**We check if the user has permition to asing products to combo */}
                            <RenderIf isTrue={checkUserPermission('asing_product_combo', user.permissions) !== -1}>
                                <div className='mx-0'>{<ModalListSetGroupByCombo combo={combo} branchoffice_id={branchoffice_id}></ModalListSetGroupByCombo>}</div>
                            </RenderIf>

                            {/*We check if the user has permition to update combo  */}
                            <RenderIf isTrue={checkUserPermission('update_combo', user.permissions) !== -1}>
                                <div className='m-0'>{<ModalEditCombo user={user} combo={combo} branchoffice_id={branchoffice_id} loadCombo={loadCombos} products={products}></ModalEditCombo>}</div>
                            </RenderIf>

                            <div className='mx-0'>
                                {<ModalViewCombo combo={combo} loadCombos={loadCombos} branchoffice_id={branchoffice_id}></ModalViewCombo>}
                            </div>
                        </div>
                        {/*We check if user have enable disable combos  */}
                        <RenderIf isTrue={(checkUserPermission('enable_disable_combo', user.permissions) !== -1) && branchoffice_id !== 0}>
                            <div>
                                <input type="checkbox" className="btn-check" autocomplete="off"
                                    id={combo.combo_id} label="" checked={combo.status === 1 ? true : false} onChange={handleSwitchChange}
                                />

                                {combo.status === 1
                                    ? <> <label className="icon-on pulse rounded-3 p-2 lh-1 border-on" for={combo.combo_id}>
                                        <img className='icon-options-cards' src={on} alt="restart" />
                                        <small className='ms-1 text-on fw-semibold'>Activo</small>
                                    </label>
                                    </>
                                    : <>
                                        <label className="btn bg-light pulse rounded-3 p-2 lh-1 border opacity-75" for={combo.combo_id} >
                                            <img className='icon-options-cards' src={off} alt="restart" />
                                            <small className='ms-1 text-secondary'>Inactivo</small>
                                        </label>
                                    </>}
                            </div>
                        </RenderIf>

                    </div>
                </article>
            </Col>
        ))
        return (list)
    }

    return (
        <>
            <div className='mb-5'>
                <div className='row mx-1 mt-3 border rounded-3 bg-card-filters-combos py-2 p-lg-4 sticky-lg-top'>
                    <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4  p-1 order-0 order-lg-0 order-md-0'>
                        <div className='d-flex justify-content-lg-between justify-content-md-start justify-content-center'>
                            <h4 className=' fs-4 fw-bold text-left text-white'>
                                {title}
                                <span className='badge rounded-pill bg-success-subtle text-success border-primary shadow-sm rounded-pill mx-1'>
                                    {combos.length}
                                </span>
                            </h4>
                        </div>
                    </div>

                    <div className="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4  p-1 order-2 order-lg-1 order-md-2">
                        <div className="d-flex justify-content-md-center justify-content-center">
                            <form className="w-75 mx-2">
                                <div className="col-auto ">
                                    <input
                                        spellcheck="false"
                                        className="form-control"
                                        type="text"
                                        step="any"
                                        placeholder="Buscar producto..."
                                        onChange={e => setSearchTerm(e.target.value)}
                                    />
                                </div>
                            </form>
                        </div>

                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-8 col-xl-4 p-1 order-1 order-lg-2 order-md-1">
                        <div className='d-flex  justify-content-md-end justify-content-lg-end  justify-content-center align-items-center gap-1 p-1 '>

                            {/**Check user permition to create products */}
                            <RenderIf isTrue={checkUserPermission('create_combo', user.permissions) !== -1}>
                                <div className=''>{<ModalCreateCombo user={user} loadCombos={loadCombos} products={products} ></ModalCreateCombo>}</div>
                            </RenderIf>
                            {/**Check user permition to import products */}
                            <RenderIf isTrue={checkUserPermission('create_set_group', user.permissions) !== -1}>
                                <div className=''>{<ModalListSetGroups user={user} branchoffice_id={branchoffice_id}></ModalListSetGroups>}</div>
                            </RenderIf>

                            {/**Check user permition to asing products to branchoffice */}
                            <RenderIf isTrue={branchoffice_id != 0 && checkUserPermission('asing_branchoffice_product', user.permissions) !== -1}>
                                <div className=''>{/* <ModalAsinAndRemovedgGroupToBranchoffice branchoffice_id={branchoffice_id}></ModalAsinAndRemovedgGroupToBranchoffice> */}</div>
                            </RenderIf>
                        </div>

                    </div>
                </div>
                <div className="mt-0 m-0 p-0">
                    <Row>
                        {viewCombo()}
                    </Row>

                </div>
            </div>
        </>
    );
}

export default CombosTable;
