import React from 'react'
//img
import empty from '../img/icons/carpeta-vacia.png'

function Empty(props){
   return (
      <>
         <div className="d-flex justify-content-center align-items-center border-dashed p-3 h-100 rounded-3 bg-light">
            <div className="border-history d-flex justify-content-md-center px-4 opacity-75 " >
               <div className="d-flex w-100 justify-content-center align-items-center  flex-column">
                   <img src={empty} className='opacity-50' alt="Vacio" width="80px" height="80px" /> 
                  <div className='text-center text-body-secondary mt-3'>
                     <h5 className='text-body-secondary fw-bold '>{props.title}</h5>
                     <p className="mb-0 text-body-secondary fw-normal ">
                        ¡{props.msg}!
                     </p>
                  </div>
               </div>
            </div>
         </div>
      </>
   )
}

export default Empty