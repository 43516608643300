//React Components
import React, { useState, useEffect, useMemo } from 'react';
//Components 
import { Form } from 'react-bootstrap';
import SuccessAlert from '../Alerts/SuccessAlert';
import ErrorAlert from '../Alerts/ErrorAlert';
import Swal from 'sweetalert2';
import { RenderIf } from '../utils/RenderIf';
import { checkUserPermission } from '../utils/CheckUserPermitions';
//API
import { GroupsFechtAPI } from '../../api/Groups';
import ModalImport from './ModalImport';
import ModalCreateGroup from './ModalGroups/ModalCreateGroup';
import ModalEditGroup from './ModalGroups/ModalEditGroup';
import ModalViewGroup from './ModalGroups/ModalViewGroup';
import ModalListSegmentByGroup from './ModalGroups/ModalListSegmentByGroup';
import ModalAsinAndRemovedgGroupToBranchoffice from './ModalGroups/ModalAsingAndRemovedGroupToBranchoffice';
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { ListGroup } from 'react-bootstrap';
//icons
import on from '../img/icons/on.png'
import off from '../img/icons/off.png'
import trash from '../img/icons/delete.png'


const ProductTable = ({ branchoffice_id, eventHandler, token, user, title }) => {
    const [products, setProducts] = useState([]);
    const [searchedProducts, setSearchedProducts] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');



    useEffect(() => {
        // We load the products list 
        loadProducts();
    }, [eventHandler]);

    //Function to load products
    const loadProducts = async () => {

        try {
            //We search the groups of the branchoffice 
            const groups = await GroupsFechtAPI.getGroupsByBranchoffice(branchoffice_id, token);

            console.log(groups.data.data);
            setProducts(groups.data.data)
            setSearchedProducts(groups.data.data)
        } catch (err) {
            setProducts([])
            setSearchedProducts([])
        }

    };

    const beforeDelete = async (group_id) => {

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })

        swalWithBootstrapButtons.fire({
            title: 'Estas seguro?',
            text: "Quieres eliminar este producto de la sucursal?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si eliminar',
            cancelButtonText: 'No, cancelar!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                deleteGroupBYbranchoffice(group_id);
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire(
                    'Cancelado',
                    'No se elimino el producto',
                    'error'
                )
            }
        })
    }


    const deleteGroupBYbranchoffice = async (group_id) => {
        let user = JSON.parse(localStorage.getItem('user'))
        let data = {
            group_id: group_id
        }
        try {
            const response = await GroupsFechtAPI.deleteProductbybranchoffice(branchoffice_id, data, token)
            loadProducts()
            ErrorAlert('', "Producto removido con exito", "success")
            console.log(response)
        } catch (err) {
            ErrorAlert(err.data.response.data, "Error, no se pudo remover el producto", "error")
            console.log(err)
        }
    }

    //Filter the products by the term searched 
    const filteredProducts = useMemo(() => {
        if (searchTerm === '') {
            return products
        }
        return searchedProducts.filter(product => {
            const nameMatch = product.name.toLowerCase().includes(searchTerm.toLocaleLowerCase());
            const descriptionMatch = product.description.toLowerCase().includes(searchTerm.toLowerCase());
            return nameMatch || descriptionMatch;
        })
    }, [searchTerm, products]);

    //Function to listen the swicht 
    const handleSwitchChange = async (event) => {
        //We set the data to update 
        let data = {
            status: event.target.checked ? 1 : 0,
            group_id: event.target.id,
            user_id: user.id
        }
        try {
            //We update the data 
            let response = await GroupsFechtAPI.updateGroupBranchoffice(branchoffice_id, data, token);
            //We show the success alert 
            SuccessAlert(response.data.message);
            // Recarga los productos al cambiar el estado del switch
            loadProducts();
        } catch (err) {
            //We show the success alert 
            ErrorAlert(err.response.data.error);
            //We put the swicht checked false 
            event.target.checked = false
            // Recarga los productos al cambiar el estado del switch
            loadProducts();
        }
    };

    const viewProduct = () => {
        const list = filteredProducts.map(product => (
            <Col xs="12" sm="6" md="6" lg="4" xl="3">

                <article className={product.status === 1 ? 'card-on shadow-sm rounded-4 mt-4 p-2' : 'border rounded-4 mt-4 p-2'} >
                    <div className="d-flex justify-content-between ">
                        <img
                            className='imgitemsList  border'
                            src={product.img}
                            alt={product.name}
                        />
                        <div className="ms-1 me-auto d-inline-block">
                            <div className='d-flex justify-content-between'>
                                <div className="fw-bold text-truncate card-item-categorie-title">
                                    <span>{product.name}</span>
                                </div>
                            </div>
                            <span >Precio: <b>${parseFloat(product.price).toFixed(2)}</b></span>
                            <p className='text-secondary fw-light text-truncate card-item-categorie-description'>{product.description}</p>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center rounded-3 mt-1'>
                        <div className=' d-flex align-item-center justify-content-start gap-1'>
                            {/**We check if the user has permition to delete products */}
                            <div className='m-0'>
                                <RenderIf isTrue={branchoffice_id != 0 && checkUserPermission('delete_product', user.permissions) !== -1}>
                                    <button type="button" className='btn btn-outline-light pulse rounded-3 p-2 lh-1 border'
                                        onClick={async () => beforeDelete(product.group_id)}
                                    >
                                        <img className='icon-options-cards' src={trash} alt="Edit" />

                                    </button>
                                </RenderIf>
                            </div>
                            {/**btn icos  */}
                            {/**We check if the user has permition to asing products to segment */}
                            <RenderIf isTrue={checkUserPermission('asing_segment_product', user.permissions) !== -1}>
                                <div className='m-0'><ModalListSegmentByGroup product={product}></ModalListSegmentByGroup></div>
                            </RenderIf>

                            {/*We check if the user has permition to update products  */}
                            <RenderIf isTrue={checkUserPermission('update_product', user.permissions) !== -1}>
                                <div className='m-0'><ModalEditGroup product={product} loadProducts={loadProducts} branchoffice_id={branchoffice_id} user={user}></ModalEditGroup></div>
                            </RenderIf>
                            <div className='m-0'>
                                <ModalViewGroup product={product} loadProducts={loadProducts} branchoffice_id={branchoffice_id} ></ModalViewGroup>
                            </div>
                        </div>

                        {/* <RenderIf isTrue={checkUserPermission('enable_disable_product', user.permissions) !== -1}>
                            <Form.Switch type="switch" id={product.group_id} label="" checked={product.status === 1 ? true : false} onChange={handleSwitchChange} />
                        </RenderIf>
                        {product.status === 1 ? <> <div className="badge bg-success">Habilitado</div> </> : <> <div className="badge bg-danger">Desabilitado</div> </>} */}

                        <div>
                            <RenderIf isTrue={checkUserPermission('enable_disable_product', user.permissions) !== -1 && branchoffice_id !== 0}>
                                <input type="checkbox" className="btn-check" autocomplete="off"
                                    id={product.group_id} label="" checked={product.status === 1 ? true : false} onChange={handleSwitchChange}
                                />
                                {product.status === 1
                                    ? <> <label className="icon-on pulse rounded-3 p-2 lh-1 border-on" for={product.group_id}>
                                        <img className='icon-options-cards' src={on} alt="restart" />
                                        <small className='ms-1 text-on fw-semibold'>Activo</small>
                                    </label>
                                    </>
                                    : <>
                                        <label className="btn bg-light pulse rounded-3 p-2 lh-1 border opacity-75" for={product.group_id} >
                                            <img className='icon-options-cards opacity-25' src={off} alt="restart" />
                                            <small className='ms-1 text-secondary'>Inactivo</small>
                                        </label>
                                    </>}
                            </RenderIf>
                        </div>

                    </div>
                </article>
            </Col>
        ))
        return (list)
    }
    return (
        <>
            <div className='mb-5'>
                <div className="row  mx-1 mt-3 bg-section-filter-product border rounded-3 py-2 p-lg-4 sticky-lg-top">
                    <div className='col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4  p-1 order-0 order-lg-0 order-md-0'>
                        <div className='d-flex justify-content-lg-between justify-content-md-start justify-content-center'>
                            <h4 className=' fs-4 fw-bold text-left text-white'>
                                {title}
                                <span className='badge rounded-pill text-color-products  shadow-sm rounded-pill mx-1'>
                                    {products.length}
                                </span>
                            </h4>
                        </div>
                    </div>

                    <div className="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-4  p-1 order-2 order-lg-1 order-md-2">
                        <div className="d-flex justify-content-md-center justify-content-center">
                            <form className=" mx-2">
                                <div className="col-12 ">
                                    <input            
                                        spellcheck="false"
                                        className="w-100 form-control form-control-sm"
                                        type="text"
                                        step="any"
                                        placeholder="Buscar producto..."
                                        onChange={e => setSearchTerm(e.target.value)}
                                    />
                                </div>


                                {/* <div className="input-icon-container">
                                    <input type="text" className="form-control form-control-sm"
                                        step="any"
                                        placeholder="Buscar producto..."
                                        onChange={e => setSearchTerm(e.target.value)} />
                                    <span className="icon"><i className="uil uil-search"></i></span>
                                </div> */}
                            </form>
                        </div>
                    </div>

                    <div className="col-12 col-sm-6 col-md-6 col-lg-8 col-xl-4 p-1 order-1 order-lg-2 order-md-1">
                        <div className='d-flex  justify-content-md-end justify-content-lg-end  justify-content-center align-items-center gap-1 p-1 '>

                            {/**Check user permition to import products */}
                            <RenderIf isTrue={checkUserPermission('create_product', user.permissions) !== -1}>
                                <div className=''><ModalImport title={"producto"} load={loadProducts}></ModalImport></div>
                            </RenderIf>
                            {/**Check user permition to asing products to branchoffice */}
                            <RenderIf isTrue={branchoffice_id != 0 && checkUserPermission('asing_branchoffice_product', user.permissions) !== -1}>
                                <div className=''><ModalAsinAndRemovedgGroupToBranchoffice branchoffice_id={branchoffice_id} load={loadProducts} productsBranchoffice={products}></ModalAsinAndRemovedgGroupToBranchoffice></div>
                            </RenderIf>
                        </div>

                    </div>
                </div>
                <div className=" mt-0 m-0 p-0 ">          
                        <Row className=''>
                            <RenderIf isTrue={checkUserPermission('create_product', user.permissions) !== -1}>

                                <Col xs="12" sm="6" md="6" lg="4" xl="3" >
                                    <ModalCreateGroup loadProducts={loadProducts}user={user} ></ModalCreateGroup>

                                </Col>
                            </RenderIf>
                            {viewProduct()}
                        </Row>           
                </div>
            </div>
        </>

    );
};

export default ProductTable;
