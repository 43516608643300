import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
//API
import { BranchOfficeFetchAPI } from '../../../api/BranchOffices';
import { UsersFetchAPI } from '../../../api/Users';
import { OrdersFetchAPI } from '../../../api/Orders';

// External Componets
import moment from 'moment-timezone';
import Select from 'react-select';
//Internal components 
import ReportTable from '../table/ReportTable';
import SuccessAlert from '../../Alerts/SuccessAlert';
import LoadingAlert from '../../Alerts/LoadingAlert';
import ErrorAlert from '../../Alerts/ErrorAlert';



const TipsReport = ({selectedUserOption, setSelectedUserOption, cashiers, setCashiers, selectedBranch, setSelectedBranch }) => {
    //Params
    let { business_code } = useParams();
    //We get the user data 
    let user = JSON.parse(localStorage.getItem('user'))
    let token = localStorage.getItem('x-access-token');
    //States 
 
    const [userBranchoffices, setUserBranchs] = useState([]);
    const [userBusiness, setUserBusiness] = useState({
        id: 0
    });
    const [initialDate, setInitialDate] = useState(moment(new Date()).tz('America/Bogota').format('YYYY-MM-DD') + 'T00:00:00.000+00:00');
    const [maxDate, setMaxDate] = useState(moment(new Date()).tz('America/Bogota').format('YYYY-MM-DD') + 'T23:59:59.000+00:00')
    //States of search select 

    const [url, setUrl] = useState(null);

    const [data, setReportData] = useState({
        business: '',
        branchoffice: '',
        machines: [],
        paymenthods: [],
        createdAt: '',
        user: '',
        orders: [],
    });

 
    //Functio to get cashier users depending of the branch selected
    const getCashiers = async (branchSelected) => {
        let response;
        try {
            //We get the users depending the branch selected 
            if (branchSelected === 0) {
                //If the branch selected is 0 it means that we want the users of the business (all)
                //We get the user cashiers of the business
                response = await UsersFetchAPI.getUsersByBusinessAndRole(userBusiness.id, [4]);
            } else {
                //We get the user cashiers of the branch selected
                response = await UsersFetchAPI.getUsersByRoleAndBranchoffice(branchSelected, 4);
            }
            setCashiers(response.data.users)
        } catch (err) {
            setCashiers([])
        }
    }

    //The default option of the select input 
    const defaultOption = [{ label: 'Seleccione...', value: 0 }];

    //Get users (cashiers)
    const usersList = cashiers.map((user) => ({
        label: user.user_name + ' ' + user.lastName + ': ' + user.idCard,
        value: user.user_id
    }));

    //Function to get the report of the branch office 
    const getReport = async (dateMin, dateMax) => {
        let response;
        try {
            //We show a loading alert 
            LoadingAlert();

            //We get the transactions depending of the user selected
            if (selectedUserOption === null) {
                //We get the transaction general info with the admin user logged 
                response = await OrdersFetchAPI.getTransactionsByBranchOfficePaymentMethodbyUser(business_code, selectedBranch.code, dateMin, dateMax, 0, user.id, token, false, true);
            } else {
                //We get the transaction info with the user selected
                response = await OrdersFetchAPI.getTransactionsByBranchOfficePaymentMethodbyUser(business_code, selectedBranch.code, dateMin, dateMax, 0, selectedUserOption.value, token, false, true);
            }
            //We set the info 
            setReportData({
                business: response.data.analitics.business,
                branchoffice: response.data.analitics.branchoffice,
                machines: response.data.analitics.machines,
                paymenthods: response.data.analitics.paymenthods,
                createdAt: response.data.analitics.createdAt,
                user: response.data.analitics.user_selected,
                orders: response.data.orders,
            });

            //We show a success alert
            SuccessAlert();
        } catch (err) {
            //We show a error alert 
            ErrorAlert('No tiene información')
            console.log(err)
        }
    }

    //Event to detect the user selected on search select 
    const handleChangeUsers = (option) => {
        //We check if the option is null 
        if (option.value === 0) {
            setSelectedUserOption(null);
        } else {
            setSelectedUserOption(option);
        }
    };



    //Hook to load the initial data 
    useEffect(() => {
        //We set the branchoffices of the user 
        setUserBranchs(user.branchoffices);
        //We set the business user 
        setUserBusiness(user.business);

        
    }, []);

    //Hook to load the users depending the selection of branch 
    useEffect(() => {
        //We get the users 
        getCashiers(selectedBranch.id)
       
    }, [selectedBranch]);


    return (
        <div>
            <h4>Filtros</h4>
            <div className="row gx-3 gy-2 align-items-center bg-white p-2 my-3 border-sp border shadow-sm">
                
                <div className="col-sm-6 col-md-6 col-lg-6">
                    <div className="form-group">
                        <label htmlFor="input_from">Desde</label>
                        <input type="date" name="initialDate" onChange={e => setInitialDate(e.target.value + "T00:00:00.000+00:00")} className="control mt-3" />
                    </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6">
                    <div className="form-group">
                        <label htmlFor="input_from">Hasta</label>
                        <input type="date" name="finishDate" onChange={e => setMaxDate(e.target.value + "T23:59:59.000+00:00")} className="control mt-3" />
                    </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <label htmlFor="input_from">Cajeros (opcional)</label>
                    <Select
                        className='w-100'
                        value={selectedUserOption}
                        options={[defaultOption[0], ...usersList]}
                        onChange={handleChangeUsers}
                    />

                </div>
                <div className=" d-flex justify-content-center justify-content-sm-end mb-3">
                    <button type="button" onClick={e => getReport(initialDate, maxDate)} className="btn btn-success px-5">Aceptar</button>
                </div>
            </div>

            <div className="d-flex justify-content-between mt-3">
                <h5 className="mt-4">Reporte de propinas</h5>
                <div className="mt-3 mb-2">
                    <a href={`${process.env.REACT_APP_API_URL}/excel/tips/${initialDate}/${maxDate}/${selectedBranch.id}/${user.business.id}/download`} className="btn btn-success">
                        <i className="fa-solid fa-file-excel"></i>
                        Descargar Excel</a>

                </div>

            </div>

            {/**Table */}
            <ReportTable
                table_name="Tabla de propinas"
                table_headers={["Fecha", "Hora", "Orden", "Total de la orden", "Propina"]}
                table_type="tips_report"
                table_data={data.orders}
            />
        </div>



    );
}

export default TipsReport;