import axios from 'axios';

const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': "application/json" },
});

export class InformZFetchAPI {
    static async getinformZ(branchoffice_code, business_code, token, minDate, maxDate, user_code) {
        const res = await server(`/informz/orders?minDate=${minDate}&maxDate=${maxDate}&business_code=${business_code}&user_code=${user_code}&branchoffice_code=${branchoffice_code}`, {
            method: "get",
            headers: {'x-access-token': token},
        });
        return res
    }

    static async getinformzById(inform_z_id, token) {
        const res = await server(`/informz/orders/${inform_z_id}`, {
            method: "get",
            headers: {'x-access-token': token},
        });
        return res
    }

    
}