import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

//Internal Components
import Navbar from '../NavBar/Navbar';
import BuyForm from './BuyForm';
import ErrorAlert from '../Alerts/ErrorAlert';
//Components 
import { Tabs, Tab } from 'react-bootstrap';
import BuysTable from './BuysTable';
import ModalBranchofficeSelect from '../NavBar/ModalBranchofficeSelect';
import { BranchOfficeFetchAPI } from '../../api/BranchOffices';
import { WarehousesFetchAPI } from '../../api/Warehouses';
import { GroupsFechtAPI } from '../../api/Groups';

const Buys = () => {
    let { business_code } = useParams();
    const [eventHandler, setEventHandler] = useState(0);
    const [warehouses, setWarehouses] = useState([]);
    const [products, setProducts] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState({
        code: 0,
        id: 0,
        name: ""
    });



    //We get the user data 
    let user = JSON.parse(localStorage.getItem('user'));
    let token = localStorage.getItem('x-access-token');

    //Function to get the branchoffice info 
    const getBranchoffice = async (branchoffice_code) => {
        try {
            const response = await BranchOfficeFetchAPI.getBranchOfficeByCode(business_code, branchoffice_code);
            setSelectedBranch(response.data.branchoffice);
        } catch (err) {
            setSelectedBranch({
                code: 0,
                id: 0,
                name: ""
            });
        }
    }

    //Function to get the warehouses info by branchoffice
    const getWarehouses = async (branchoffice_id) => {
        try {
            console.log(branchoffice_id)
            const response = await WarehousesFetchAPI.getWarehousesByBranchoffice(branchoffice_id, token);
            setWarehouses(response.data.warehouses);
        } catch (err) {
            setWarehouses([]);
        }
    }

    const loadProducts = async () => {
        try {
            //We search the groups of the user (we send 0 to load the user products available)
            const response = await GroupsFechtAPI.getGroupsByBranchoffice(selectedBranch.id, token);
            setProducts(response.data.data);
        } catch (err) {
            setProducts([])
        }
    };

    const actionSelectBranch = (branchCode, branch) => {

        console.log(branch);
        getBranchoffice(branchCode);
        setEventHandler(1);
        if (branchCode != 0) {
            getWarehouses(branch.branchoffice_id);
            loadProducts()
        }

    }

    useEffect(() => {

        setEventHandler(0)
    }, [eventHandler])

    return (
        <>
            <Navbar titlePage="Compras" />
            <div className='bg-light pt-4'>

                {/** Main container */}
                <div className="container">

                    <section className='banner-sp bg-light-subtle d-lg-flex justify-content-lg-between 
                  align-items-center rounded-4 mb-3 flex-column flex-lg-row justify-content-center py-2 py-lg-0'>
                        {/* Header Page */}
                        <div className='d-lg-flex flex-lg-stack flex-wrap gap-4 w-100 flex-nowrap d-flex justify-content-center align-items-center 
                  justify-content-lg-start '>
                            <div className='page-title d-flex flex-column gap-0 me-3'>
                                <h2 className='banner-reports-title mb-0 pb-0'>
                                    Compras
                                </h2>
                                <p className='m-0 pt-1 banner-reports-description'>Área de comprar de sus producto para sus sucursales
                                    (Inventario).</p>
                            </div>
                        </div>

                        <div className='d-lg-flex flex-lg-stack flex-wrap gap-4 w-100 flex-nowrap d-flex justify-content-center align-items-center 
                  justify-content-lg-end mt-3 mt-lg-0'>
                            <ModalBranchofficeSelect actionSelectBranch={actionSelectBranch}></ModalBranchofficeSelect>
                        </div>
                    </section>

                    {/**Tabs */}
                    <Tabs defaultActiveKey="all" variant='underline' className='mt-4 nav-scroller d-flex justify-content-start bg-white'>
                        {/**Tab of general report */}
                        <Tab eventKey="all" title={<><i className="fa-solid fa-house"></i> Ingreso de Compras </>}>
                            <div className='mx-lg-3 mt-4'>
                                <BuyForm selectedBranch={selectedBranch} setSelectedBranch={setSelectedBranch} warehouses={warehouses} setWarehouses={setWarehouses} products={products} setProducts={setProducts} />
                            </div>
                        </Tab>
                        <Tab eventKey="active" title={<><i className="fa-solid fa-money-check-dollar"></i> Compras Realizadas</>}>
                            <div className="mx-lg-3 mt-4">
                                <BuysTable selectedBranch={selectedBranch} setSelectedBranch={setSelectedBranch} />
                            </div>
                        </Tab>
                    </Tabs>


                </div>
            </div>
        </>
    );
}

export default Buys;
