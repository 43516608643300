//React components 
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col } from 'react-bootstrap';
//Components 
import Navbar from '../NavBar/Navbar';
import ModalUpdateCupon from './Modals/ModalUpdateCupon';
import ModalCreateCupon from './Modals/ModalCreateCupon';
import QRCodeModal from '../Modal/QRCodeModal';
import CuponDetailModal from './Modals/ModalDetailCupons';
//API 
import { CouponPosFetchAPI } from '../../api/Coupon';
import { CombosFetchAPI } from '../../api/Combos';
import { GroupsFechtAPI } from '../../api/Groups';
import { CategoriesDashFetchAPI } from '../../api/CategoriesDash';
//Images
import defaultImage from "../../assets/img/default.png"
import Footer from '../Footer/Footer';

//Function of cupons
const Cupons = () => {
    //Params
    let { business_code } = useParams();
    //LocalStorage
    let user = JSON.parse(localStorage.getItem('user'))
    let token = localStorage.getItem('x-access-token');
    //States
    const [cupons, setCupons] = useState([]);
    const [groups, setGroups] = useState([]);
    const [combos, setCombos] = useState([]);
    const [categories, setCategories] = useState([]);
    const [eventHandler, setEventHandler] = useState(false);
    const [showQrModal, setShowQrModal] = useState(false);
    const [detailModal, setShowDetailModal] = useState(false);
    const [selectedCupon, setSelectedCupon] = useState(0);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    //Function to get cupons by business
    const getCuponsByBusiness = async () => {
        try {
            let res = await CouponPosFetchAPI.getCuponsByBusiness(user.business.id, token);
            setCupons(res.data.data);
        } catch (err) {
            setCupons([]);
        }
    }

    //Function to render the list of the cupons 
    const renderCupons = () => {
        const list = cupons.map((cupon, index) => (
            <div className='col-12 col-sm-6  col-md-6 col-lg-3 col-xl-3 mt-4 ' key={index}>

                <div
                    className="d-flex justify-content-between align-items-center border rounded-3 p-2 bg-white "
                >
                    <img
                        className='imgitemsList m-1 border'
                        src={cupon.img ? cupon.img : defaultImage}
                        alt=""
                    />
                    <div className="ms-2 me-auto d-inline-block text-truncate">


                        <div className="ms-1 me-auto d-inline-block">
                            <div className="fw-bold text-truncate card-item-categorie-title">{cupon.name}</div>
                            <p className='text-secondary fw-light text-truncate card-item-categorie-description'>
                                {cupon.description}
                            </p>
                        </div>
                        <div className='d-flex justify-constent-center align-items-center gap-1'>
                            <div onClick={(event) => (setShowDetailModal(true), setSelectedCupon(cupon.id))}>
                                <button className='btn btn-outline-primary btn-sm border-0' >
                                    <i className="uil uil-eye"></i>
                                </button>
                            </div>
                            <div onClick={(event) => (setShowQrModal(true), setSelectedCupon(cupon.code_prefix))}>
                                <button className='btn btn-outline-primary btn-sm border-0' >
                                    <i className="uil uil-clipboard"></i>
                                </button>
                            </div>
                            <div onClick={(event) => (setShowUpdateModal(true), setSelectedCupon(cupon.id))}>
                                <button className='btn btn-outline-primary btn-sm border-0' >
                                    <i className="uil uil-pen"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        ))
        return (list)
    }

    //Function to get the groups of the business
    const getGroups = async () => {
        try {
            let res = await GroupsFechtAPI.getGroupsByBusiness(user.business.id, token);
            console.log(res.data)
            setGroups(res.data.data);
        } catch (err) {
            setGroups([]);
        }
    }

    //Function to get the combos of the business
    const getCombos = async () => {
        try {
            let res = await CombosFetchAPI.getCombosByBusiness(user.business.id, token);
            //We set the combos
            setCombos(res.data.combos);
        } catch (err) {
            setCombos([]);
        }
    }

    //Function to get the categories of the business
    const getCategories = async () => {
        try {
            let res = await CategoriesDashFetchAPI.getCategoriesByBusiness(user.business.id, token);
            setCategories(res.data.data);
        } catch (err) {
            setCategories([]);
        }
    }

    //UseEffect
    useEffect(() => {
        getCombos()
        getGroups()
        getCuponsByBusiness();
        getCategories();
    }, []);

    //Hook to recharge info 
    useEffect(() => {
        if (eventHandler === 1) {
            getCuponsByBusiness();
        }
        setEventHandler(0);
    }, [eventHandler]);
    return (

        <>

            <Navbar titlePage="Cupones" />
            <div className='bg-light pt-4'>
                <div className='container'>
                    {/** Body header */}
                    <main>
                        <section className='banner-sp d-flex justify-content-between 
                  align-items-center rounded-4  bg-light mb-3'>



                            <div className='d-flex flex-stack flex-wrap gap-4 w-100'>
                                <div className='page-title d-flex flex-column gap-0 me-3'>
                                    <h2 className='banner-reports-title mb-0 pb-0'>
                                        Cupones
                                    </h2>
                                    <p className='m-0 pt-1 banner-reports-description'>Gestión y creación de cupones.</p>
                                </div>
                            </div>
                            {/** Button to create a new cupon */}
                            <ModalCreateCupon user={user} token={token} groups={groups} combos={combos} setEventHandler={setEventHandler} />
                        </section>

                        {/** Main container */}
                        <section>

                            <div className='d-flex justify-content-center'>
                                <h4 className=' fs-5 fw-bold text-left text-primary'>
                                    Listado de cupones
                                </h4>
                            </div>


                            <div className="row">
                                {renderCupons()}
                            </div>
                        </section>
                        {/** Modal to show the QR */}
                        <QRCodeModal
                            textToEncode={selectedCupon}
                            showModal={showQrModal}
                            setShowModal={setShowQrModal}
                        />

                        {/** Detail modal */}
                        <CuponDetailModal
                            cupon={selectedCupon}
                            showModal={detailModal}
                            setShowModal={setShowDetailModal}
                            groups={groups}
                            combos={combos}
                            categories={categories}
                            user={user}
                        />
                        {/** Modal to update a cupon */}
                        <ModalUpdateCupon
                            cupon={selectedCupon}
                            token={token}
                            setEventHandler={setEventHandler}
                            showModal={showUpdateModal}
                            setShowModal={setShowUpdateModal}
                        />
                    </main>
                </div>

            </div>
            {/* Footer */}
            <Footer />
        </>

    )
}


export default Cupons;